import "../../../bootstrap.css";
import React, { Component } from "react";
import SideMenu from "../../Content/SideMenu/SideMenu";
import Header from "../../Content/Header/Header";

import Pagination from "react-js-pagination";

import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";

import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";

import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  language = cookies.get("language") ? cookies.get("language") : "TH";
  componentDidMount() {
    // cookies.remove("token", { path: "/" });
    //       cookies.remove("user", { path: "/" });
    //       window.location.href = "/";
  }
  render() {
    return (
      <div className="w-100 h-auto minh-100 d-flex justify-content-center align-items-center">
        <div className="text-center oop">
          <b className={this.language === "EN" ? "text-big" : "text-big2"}>
            {global[this.language]["Oops"]}
          </b>
          <br />
          <b className="text-medium">{global[this.language]["not_found"]}</b>
          <br />
          <br />
          <label>{global[this.language]["not_found_detail"]}</label>
          <button
            className="btn btn-primary w-100 mt-4"
            onClick={() => {
              cookies.remove("token", { path: "/" });
              cookies.remove("user", { path: "/" });
              window.location.href = "/";
            }}
          >
            {global[this.language]["back_to_index"]}
          </button>
        </div>
      </div>
    );
  }
}
