import "../../bootstrap.css";
import "./style.css";
import React, { Component } from "react";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { Link } from "react-router";
import ninja from "./ninja2.png";
import ninja2 from "./ninja3.png";

import axios from "axios";
const cookies = new Cookies();
export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="bg-white">
        <div style={{ background: "#E54045" }} className="py-4">
          <div className="container text-center">
            <h1 style={{ fontSize: 39, fontWeight: "bold", color: "#FFF" }}>
              ติดต่อ
            </h1>
            <h1 style={{ fontSize: 29, fontWeight: "bold", color: "#FFF" }}>
              Ninja Cargo
            </h1>
            <label style={{ fontSize: 20, color: "#FFF" }}>
              บริการสั่งซื้อสินค้าจากประเทศจีนมาจำหน่ายในประเทศไทยทั้งแบบ
              ปลีกและแบบส่งโดยไม่ต้องผ่านคนกลางพร้อม จัดส่งสินค้าถึงมือคุณ
            </label>
          </div>
          <div style={{ height: 150 }}></div>
        </div>
        <div
          className="container text-center  text-center card mb-4 p-3 shadow-lg border-0"
          style={{ marginTop: -100, borderRadius: 15 }}
        >
          <h1 className="mb-4" style={{ fontSize: 20, fontWeight: "bold" }}>
            เบอร์โทร: 084-5027-537
          </h1>
          <h1 className="mb-4" style={{ fontSize: 20, fontWeight: "bold" }}>
            อีเมล: ninjacargoservice@gmail.com
          </h1>
          <h1 style={{ fontSize: 20, fontWeight: "bold" }}>
            Facebook Fanpage: Ninjacargo
          </h1>
        </div>
      </div>
    );
  }
}
