import "../../../bootstrap.css";
import React, { Component } from "react";
import SideMenu from "../../Content/SideMenu/SideMenu";
import SideMenuUser from "../../Content/SideMenuUser/SideMenuUser";
import Header from "../../Content/Header/Header";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import { ZoomMtg } from "@zoomus/websdk";
import "@szhsin/react-menu/dist/index.css";
import crypto from "crypto";

import Jitsi from "react-jitsi";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class LiveClassScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      signatureEndpoint: global.leaveUrl,
      apiKey: "",
      apiSecret: "",
      meetingNumber: "",
      role: 0,
      leaveUrl: global.leaveUrl + "liveclass",
      userName: "",
      userEmail: "",
      passWord: "",
      zoom: false,
      activePage: 1,
      page_count: 1,
      modal_add_liveclass: false,
      modal_edit_liveclass: false,
      modal_detail_live: false,
      batch_name: "",
      zoom_api_key: "",
      zoom_api_secret: "",
      meeting_number: "",
      meeting_password: "",
      live_date: "",
      live_startime: "",
      live_endtime: "",
      teacher: "",
      student: [],
      now_date: "",
      now_time: "",

      search_teacher: "",
      search_student: "",
      menu_Teacher: false,
      menu_Student: false,
      detail: 0,
      data: [],
      sort: [0, 0, 0, 0, 0, 0],
      zoomstart: false,
      apisecret: "",
      search: "",
    };
  }
  language = cookies.get("language") ? cookies.get("language") : "TH";
  componentDidMount() {
    ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.5/lib", "/av");
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();
    ZoomMtg.i18n.load("en-US");
    ZoomMtg.i18n.reload("en-US");
    if (cookies.get("user")) {
      this.GetLiveClass();
      let day = new Date().getDate(),
        month = new Date().getMonth() + 1,
        year = new Date().getFullYear(),
        hour = new Date().getHours(),
        minute = new Date().getMinutes();
      if (month.toString().length === 1) {
        month = "0" + month;
      }
      if (day.toString().length === 1) {
        day = "0" + day;
      }
      if (hour.toString().length === 1) {
        hour = "0" + hour;
      }
      if (minute.toString().length === 1) {
        minute = "0" + minute;
      }
      this.setState({
        now_date: day + "/" + month + "/" + year,
        now_time: hour + "." + minute,
      });
      setInterval(() => {
        let day = new Date().getDate(),
          month = new Date().getMonth() + 1,
          year = new Date().getFullYear(),
          hour = new Date().getHours(),
          minute = new Date().getMinutes();
        if (month.toString().length === 1) {
          month = "0" + month;
        }
        if (day.toString().length === 1) {
          day = "0" + day;
        }
        if (hour.toString().length === 1) {
          hour = "0" + hour;
        }
        if (minute.toString().length === 1) {
          minute = "0" + minute;
        }
        this.setState({
          now_date: day + "/" + month + "/" + year,
          now_time: hour + "." + minute,
        });
      }, 30000);
      if (cookies.get("user").user.status === "teacher") {
        this.setState({ role: 1 });
      }
    } else {
      Swal.fire({
        title: global[this.language]["alert"],
        text: global[this.language]["retrieve_data_fail"],
        icon: "warning",
        showCancelButton: false,
        allowOutsideClick: false,
        confirmButtonText: global[this.language]["ok"],
      }).then((result) => {
        if (result.isConfirmed) {
          cookies.remove("token", { path: "/" });
          cookies.remove("user", { path: "/" });

          window.location.href = "/";
        }
      });
    }
  }
  UNSAFE_componentWillUpdate() {}
  getSignature = (data) => {
    this.setState({
      zoomstart: false,
    });
    var zoom_api_key = data.zoom_api_key;
    var zoom_api_secret = data.zoom_api_secret;
    const timestamp = new Date().getTime() - 50000;
    // var zoom_api_secret = this.state.apisecret;
    const msg = Buffer.from(
      zoom_api_key + data.meeting_number + timestamp + this.state.role
    ).toString("base64");
    const hash = crypto
      .createHmac("sha256", zoom_api_secret)
      .update(msg)
      .digest("base64");
    const signature = Buffer.from(
      `${zoom_api_key}.${data.meeting_number}.${timestamp}.${this.state.role}.${hash}`
    ).toString("base64");
    this.startMeeting(signature, data);
  };
  startMeeting = (signature, data) => {
    document.getElementById("zmmtg-root").style.display = "block";
    this.setState({ zoom: true });
    ZoomMtg.init({
      leaveUrl: this.state.leaveUrl,
      isSupportAV: true,
      isSupportChat: true,
      isSupportQA: true,
      showMeetingHeader: false,
      videoHeader: false,
      disableInvite: true,
      videoDrag: true,
      debug: true,
      inviteUrlFormat: "hide",
      screenShare: true,
      sharingMode: "both",
      success: (success) => {
        console.log("1", success);
        ZoomMtg.join({
          signature: signature,
          meetingNumber: data.meeting_number,
          userName: cookies.get("user").user.username,
          apiKey: data.zoom_api_key,
          userEmail: cookies.get("user").user.username + "@jmschool.com",
          passWord: data.meeting_password,
          success: (success) => {
            console.log("2", success);
            this.setState({
              zoomstart: true,
            });
          },
          error: (error) => {
            console.error("2", error);
            this.setState({
              zoomstart: true,
            });
            if (error.result && error.result.includes("not started")) {
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["not_start_class"],
                "warning"
              );
            } else {
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["can_not_join"],
                "warning"
              );
            }
            this.setState({ zoom: false });
            document.getElementById("zmmtg-root").style.display = "none";
          },
        });
      },
      error: (error) => {
        console.error("1", error);
        this.setState({
          zoomstart: true,
        });
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["can_not_join"],
          "warning"
        );
        this.setState({ zoom: false });
        document.getElementById("zmmtg-root").style.display = "none";
      },
    });
  };
  GetLiveClass = () => {
    this.setState({ loading: true });
    fetch(global.httpURL + "/api/liveclass/active/" + this.state.activePage, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (
          response.message === "Auth Failed" ||
          response.message === "Token is null"
        ) {
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["unavailable"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.Logout();
            }
          });
        } else if (response.message === "Connot find page") {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          let data = response.data.live_class;
          for (let i = 0; i < data.length; i++) {
            if (Number(data[i].live_startime.split(":")[1]) < 30) {
              data[i].live_startime_before =
                Number(data[i].live_startime.replace(":", ".")) - 0.7;
            } else {
              data[i].live_startime_before =
                Number(data[i].live_startime.replace(":", ".")) - 0.3;
            }
          }
          this.setState({
            data: data,
            loading: false,
            page_count: response.page_count,
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        Swal.fire({
          title: global[this.language]["alert"],
          text: global[this.language]["retrieve_data_fail"],
          icon: "warning",
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonText: global[this.language]["ok"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.Logout();
          }
        });
      });
  };
  Logout = () => {
    fetch(global.httpURL + "/api/logout", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "token " + cookies.get("token"),
      },
      // mode: "no-cors",
    })
      .then((response) => response.json())
      .then((response) => {
        cookies.remove("token", { path: "/" });
        cookies.remove("user", { path: "/" });

        window.location.href = "/";
      })
      .catch((err) => {
        //window.location.href = "/dashboard";
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_logout"],
          "warning"
        );
      });
  };
  Cancel = () => {
    this.setState({
      modal_add_liveclass: false,
      modal_edit_liveclass: false,
      modal_detail_live: false,
      batch_name: "",
      zoom_api_key: "",
      zoom_api_secret: "",
      meeting_number: "",
      meeting_password: "",
      live_date: "",
      live_startime: "",
      live_endtime: "",
      teacher: "",
      student: [],
    });
  };
  Submit_add_liveclass = () => {
    Swal.fire(
      global[this.language]["alert"],
      global[this.language]["save_success"],
      "success"
    );
    this.Cancel();
  };
  Submit_edit_liveclass = () => {
    Swal.fire(
      global[this.language]["alert"],
      global[this.language]["save_success"],
      "success"
    );
    this.Cancel();
  };
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber, loading: true });
    if (this.state.search === "") {
      setTimeout(() => {
        this.GetLiveClass();
      }, 1);
    } else {
      setTimeout(() => {
        this.search();
      }, 1);
    }
  }
  handleClick(e) {
    if (e.target.id === "teacher-menu") {
      this.setState({
        menu_Teacher: true,
      });
    } else if (e.target.id === "student-menu") {
      this.setState({
        menu_Student: true,
      });
    } else {
      this.setState({
        menu_Teacher: false,
        menu_Student: false,
      });
    }
  }
  Sort = (key) => {
    let list = this.state.data;
    let sorted = this.state.sort;
    if (key === "subject") {
      if (sorted[0] === 0) {
        list.sort((a, b) => (a.batch_name < b.batch_name ? 1 : -1));
        sorted[0] = 1;
      } else {
        list.sort((a, b) => (a.batch_name > b.batch_name ? 1 : -1));
        sorted[0] = 0;
      }
    } else if (key === "teacher") {
      if (sorted[1] === 0) {
        list.sort((a, b) => (a.teacher.name < b.teacher.name ? 1 : -1));
        sorted[1] = 1;
      } else {
        list.sort((a, b) => (a.teacher.name > b.teacher.name ? 1 : -1));
        sorted[1] = 0;
      }
    } else if (key === "date") {
      if (sorted[2] === 0) {
        list.sort((a, b) => (a.live_date < b.live_date ? 1 : -1));
        sorted[2] = 1;
      } else {
        list.sort((a, b) => (a.live_date > b.live_date ? 1 : -1));
        sorted[2] = 0;
      }
    } else if (key === "time") {
      if (sorted[3] === 0) {
        list.sort((a, b) => (a.live_startime < b.live_startime ? 1 : -1));
        sorted[3] = 1;
      } else {
        list.sort((a, b) => (a.live_startime > b.live_startime ? 1 : -1));
        sorted[3] = 0;
      }
    } else if (key === "number") {
      if (sorted[4] === 0) {
        list.sort((a, b) => (a.meeting_number < b.meeting_number ? 1 : -1));
        sorted[4] = 1;
      } else {
        list.sort((a, b) => (a.meeting_number > b.meeting_number ? 1 : -1));
        sorted[4] = 0;
      }
    } else if (key === "password") {
      if (sorted[5] === 0) {
        list.sort((a, b) => (a.meeting_password < b.meeting_password ? 1 : -1));
        sorted[5] = 1;
      } else {
        list.sort((a, b) => (a.meeting_password > b.meeting_password ? 1 : -1));
        sorted[5] = 0;
      }
    }

    this.setState({ data: list, sort: sorted });
  };
  time = (time) => {
    let t = 0.0;
    if (Number(time.split(":")[1]) < 30) {
      t = Number(time.replace(":", ".")) - 0.7;
    } else {
      t = Number(time.replace(":", ".")) - 0.3;
    }
    return t;
    // (Number(time.split(
    //   ":"
    // )[1]) <40?Number(time.replace(
    //   ":","."
    // )-70:Number(time.replace(
    //   ":","."
    // )-30)
  };
  search = () => {
    fetch(global.httpURL + "/api/search/lc/active/page/1", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ data_search: this.state.search }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({ spinner: false });
        if (
          response.message === "Auth Failed" ||
          response.message === "Token is null"
        ) {
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["unavailable"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.Logout();
            }
          });
        } else if (
          response.message === "Connot find page" ||
          response.message === "No information found"
        ) {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            data: response.data.live_class,
            loading: false,
            page_count: response.page_count,
          });
        }
      })
      .catch((err) => {
        this.setState({ spinner: false });
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_logout"],
          "warning"
        );
      });
  };
  render() {
    return (
      <div className="w-100 h-auto minh-100">
        <div className="d-flex h-auto minh-100">
          <div className="h-auto minh-100">
            {!this.state.zoom && cookies.get("user") && (
              <>
                {cookies.get("user").user.status === "teacher" ||
                cookies.get("user").user.status === "student" ? (
                  <SideMenuUser page="liveclass" />
                ) : (
                  <SideMenu page="liveclass" />
                )}
              </>
            )}
          </div>
          <div className="w-100-body">
            <Header />
            <div className="container-fluid pt-3">
              {/* TABLE */}
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="card border-0 p-4">
                    <div className="flex-row justify-content-between align-items-center row p-4">
                      <div>
                        <h3>
                          <b>Live Class</b>
                        </h3>
                      </div>
                      {/* BUTTON XL
                       */}
                      <div className="search-box-div ml-2">
                        <input
                          type="text"
                          className="search-box mt-2"
                          placeholder={
                            global[this.language]["course_name"] +
                            "," +
                            global[this.language]["teacher_name"]
                          }
                          value={this.state.search}
                          onChange={(event) => {
                            this.setState({
                              search: event.target.value,
                              activePage: 1,
                            });
                            setTimeout(() => {
                              if (this.state.search === "") {
                                this.search();
                              }
                            }, 1);
                          }}
                          onKeyUp={(event) => {
                            console.log(event.key);
                            if (event.key === "Enter") {
                              this.search();
                            }
                          }}
                        />
                        <button
                          className="icon-absolute"
                          onClick={() => {
                            this.search();
                          }}
                        >
                          {"\uf002"}
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {!this.state.loading ? (
                        <table className="table table-borderless table-striped table-w-1 table-hover ">
                          <thead className="thead-dark">
                            <tr>
                              <th className="text-center">
                                {global[this.language]["order"]}
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("subject");
                                }}
                              >
                                {global[this.language]["course_name"]}
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("teacher");
                                }}
                              >
                                {global[this.language]["teacher_name"]}
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("date");
                                }}
                              >
                                {global[this.language]["open_date"]}
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("time");
                                }}
                              >
                                {global[this.language]["open_time"]}
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("number");
                                }}
                              >
                                Meeting Number
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("password");
                                }}
                              >
                                Password
                              </th>
                              <th className="text-center">
                                {global[this.language]["all_info"]}
                              </th>
                              <th className="text-center">
                                {global[this.language]["status"]}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* DEPARTMENT */}
                            {this.state.data &&
                              this.state.data.map(
                                (liveclass, liveclass_index) => (
                                  <tr>
                                    <td className="text-center">
                                      {liveclass_index +
                                        1 +
                                        (this.state.activePage - 1) * 10}
                                    </td>
                                    <td>{liveclass.batch_name}</td>
                                    <td>{liveclass.teacher.user.name}</td>
                                    <td>{liveclass.live_date}</td>
                                    <td>
                                      {liveclass.live_startime} -{" "}
                                      {liveclass.live_endtime}
                                    </td>
                                    <td>{liveclass.meeting_number}</td>
                                    <td>{liveclass.meeting_password}</td>
                                    <td className="text-center">
                                      <button
                                        className="btn btn-link pt-0 pb-0"
                                        onClick={() => {
                                          this.setState({
                                            modal_detail_live: true,
                                            detail: liveclass_index,
                                          });
                                        }}
                                      >
                                        {global[this.language]["info"]}
                                      </button>
                                    </td>

                                    <td className="text-center">
                                      {/* <label className="icon">{"\uf304"}</label> */}

                                      {this.state.now_date ===
                                        liveclass.live_date &&
                                      Number(this.state.now_time) >=
                                        Number(
                                          liveclass.live_startime_before
                                        ) &&
                                      Number(this.state.now_time) <
                                        Number(
                                          liveclass.live_endtime.replace(
                                            ":",
                                            "."
                                          )
                                        ) ? (
                                        <>
                                          {/* {cookies.get("user").user.status ===
                                          "teacher" ? ( */}
                                          <button
                                            className="btn btn-primary pt-0 pb-0"
                                            onClick={() => {
                                              setTimeout(() => {
                                                window.location.href =
                                                  "/live?zoom_api_key=" +
                                                  JSON.stringify(
                                                    liveclass.zoom_api_key +
                                                      "&zoom_api_secret=" +
                                                      liveclass.zoom_api_secret +
                                                      "&meeting_number=" +
                                                      liveclass.meeting_number +
                                                      "&meeting_password=" +
                                                      liveclass.meeting_password +
                                                      "&role=" +
                                                      this.state.role
                                                  );
                                              }, 1);
                                            }}
                                          >
                                            {global[this.language]["join"]}
                                          </button>
                                          {/* ) : (
                                            <a
                                              className="btn btn-primary pt-0 pb-0 d-flex align-items-center justify-content-center"
                                              href={liveclass.join_url}
                                              target="_blank"
                                            >
                                              {global[this.language]["join"]}
                                            </a>
                                          )} */}
                                        </>
                                      ) : (
                                        global[this.language]["not_open"]
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                            {this.state.data && this.state.data.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={9}>
                                  {global[this.language]["no_data"]}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <div className="w-100 d-flex justify-content-center">
                          <div className="loader" />
                        </div>
                      )}
                    </div>
                    <div className="d-flex flex-row-reverse mt-3">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.page_count * 10}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        prevPageText="Previous"
                        nextPageText="Next"
                        hideFirstLastPages={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* MODAL */}
              {/* ดูรายละเอียด live */}
              <Modal
                show={this.state.modal_detail_live}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["liveclass_detail"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["course_name"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].batch_name
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["live_date"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].live_date
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["start_time"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].live_startime
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["end_time"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].live_endtime
                        }
                        disabled={true}
                      />
                    </div>
                    {/* 
                    <div className="col-4 pb-3">
                      <label>Zoom API Key:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["Type_the_course_name"]}
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].zoom_api_key
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>Zoom API Secret:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["Type_the_course_name"]}
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].zoom_api_secret
                        }
                        disabled={true}
                      />
                    </div> */}
                    <div className="col-4 pb-3">
                      <label>Meeting Number:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].meeting_number
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>Password:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].meeting_password
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["teacher_name"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].teacher &&
                          this.state.data[this.state.detail].teacher.user.name
                        }
                        disabled={true}
                      />
                    </div>
                    {cookies.get("user") &&
                      cookies.get("user").user.status !== "student" && (
                        <>
                          <div className="col-4 pb-3">
                            <label>{global[this.language]["students"]}:</label>
                          </div>
                          <div className="col-8 pb-3">
                            <div
                              className="form-control-style"
                              onClick={this.handleClick.bind(this)}
                              id="student-menu"
                            >
                              {this.state.data &&
                              this.state.data[this.state.detail] &&
                              this.state.data[this.state.detail].student
                                .length === 0 ? (
                                <p className={"text-secondary pb-0"}>
                                  {global[this.language]["no_student"]}
                                </p>
                              ) : (
                                <div className="row">
                                  {this.state.data &&
                                    this.state.data[this.state.detail] &&
                                    this.state.data[
                                      this.state.detail
                                    ].student.map((item, index) => (
                                      <div className="selected-box bg-primary text-white m-1">
                                        <label>{item.nickname}</label>
                                      </div>
                                    ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    <div className="col-6 pb-3" />
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["close"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
