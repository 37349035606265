import "../../bootstrap.css";
import "./style.css";
import React, { Component } from "react";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { Link } from "react-router";

import axios from "axios";
const cookies = new Cookies();
export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <div style={{ background: "#E54045" }} className="py-5">
          <div className="container text-center">
            <h1 style={{ fontSize: 39, fontWeight: "bold", color: "#FFF" }}>
              We are Logistics
            </h1>
            <h2 style={{ fontSize: 29, fontWeight: "bold", color: "#FFF" }}>
              NetWork Company
            </h2>
            <label className="mt-5" style={{ fontSize: 20, color: "#FFF" }}>
              เราคือผู้เชี่ยวชาญและชำนาญด้านการนำเข้า จากประเทศจีนมาไทย
              ด้วยทีมงานที่มี ประสบการณ์ทำงานอย่างมืออาชีพ บริการขน
              ส่งพร้อมเคลียร์ภาษีนำเข้าอย่างถูกต้องตาม กฎหมาย
            </label>
            <img
              width="300"
              height="242"
              src="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/ninja_Logo-fvicon-1@3x-300x242.png"
              class="attachment-medium size-medium mx-auto my-5"
              alt=""
              srcset="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/ninja_Logo-fvicon-1@3x-300x242.png 300w, https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/ninja_Logo-fvicon-1@3x-768x619.png 768w, https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/ninja_Logo-fvicon-1@3x.png 882w"
              sizes="(max-width: 300px) 100vw, 300px"
            ></img>
            <div style={{ height: 100 }}></div>
          </div>
        </div>
        <div
          className="container text-center"
          style={{ marginTop: -150, paddingBottom: 100 }}
        >
          <div
            className="card border-0 shadow-lg bg-white p-5"
            style={{ borderRadius: 15 }}
          >
            <img
              width="56"
              height="56"
              src="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/box-onyclo07mvu79jdz59cl58gpbmqb8q1ge7yo7wmq4w.png"
              class="attachment-full size-full mx-auto mt-5"
              alt=""
            />
            <b className="mt-3" style={{ fontSize: 20, color: "#e54045" }}>
              บริการสั่งของออนไลน์จากจีน
            </b>
            <label className="mt-3" style={{ fontSize: 20 }}>
              สั่งสินค้าออนไลน์จากเว็บไซต์ทั่วประเทศ
              จีนมาจำหน่ายในประไทยทั้งแบบปลีกและ แบบส่งโดยไม่ต้องผ่านคนกลางพร้อม
              จัดส่งสินค้าถึงมือคุณ
            </label>
            <img
              width="56"
              height="56"
              src="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/truck-onyclo07mvu79jdz59cl58gpbmqb8q1ge7yo7wmq4w.png"
              class="attachment-full size-full mx-auto mt-5"
              alt=""
            />
            <b className="mt-3" style={{ fontSize: 20, color: "#e54045" }}>
              บริการขนส่ง ทางรถ/ทางเรือ
            </b>
            <label className="mt-3" style={{ fontSize: 20 }}>
              สั่งสินค้าออนไลน์จากเว็บไซต์ทั่วประเทศ
              จีนมาจำหน่ายในประไทยทั้งแบบปลีกและ แบบส่งโดยไม่ต้องผ่านคนกลางพร้อม
              จัดส่งสินค้าถึงมือคุณ
            </label>
          </div>
        </div>
        <div style={{ background: "#E54045" }} className="py-5">
          <div className="container text-center">
            <h1 style={{ fontSize: 39, fontWeight: "bold", color: "#FFF" }}>
              บริการนำเข้าสินค้า
            </h1>
            <h2 style={{ fontSize: 29, fontWeight: "bold", color: "#FFF" }}>
              อย่างมืออาชีพ
            </h2>
            <label className="mt-5" style={{ fontSize: 20, color: "#FFF" }}>
              บริการ สั่งของจีน อื่น รวมไปถึง บริการ ขนส่งสินค้าจากจีนนำเข้า
              สินค้าจากจีน
            </label>

            <div style={{ height: 130 }}></div>
          </div>
        </div>
        <div
          className="container text-center"
          style={{ marginTop: -150, paddingBottom: 100 }}
        >
          <div
            className="card border-0 shadow-lg bg-white p-5"
            style={{ borderRadius: 15 }}
          >
            <img
              width="56"
              height="56"
              src="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/mobile-phone.png"
              class="attachment-full size-full mx-auto mt-5"
              alt=""
            />
            <b className="mt-3" style={{ fontSize: 20, color: "#e54045" }}>
              สั่งซื้อสินค้าจากจีน
            </b>
            <label className="mt-3" style={{ fontSize: 20 }}>
              สินค้าราคาถูกให้เลือกมากมาย พร้อมการให้บริการแนะนำจากทาง เรา
              เพื่อตอบโจทย์ของลูกค้าอย่างดีที่สุด
            </label>
            <img
              width="56"
              height="56"
              src="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/loan.png"
              class="attachment-full size-full mx-auto mt-5"
              alt=""
            />
            <b className="mt-3" style={{ fontSize: 20, color: "#e54045" }}>
              ไม่ต้องผ่านคนกลาง
            </b>
            <label className="mt-3" style={{ fontSize: 20 }}>
              สินค้าราคาถูกให้เลือกมากมาย พร้อมการให้บริการแนะนำจากทาง เรา
              เพื่อตอบโจทย์ของลูกค้าอย่างดีที่สุด
            </label>
            <img
              width="56"
              height="56"
              src="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/delivered.png"
              class="attachment-full size-full mx-auto mt-5"
              alt=""
            />
            <b className="mt-3" style={{ fontSize: 20, color: "#e54045" }}>
              จัดส่งสินค้าถึงมือคุณ
            </b>
            <label className="mt-3" style={{ fontSize: 20 }}>
              สินค้าราคาถูกให้เลือกมากมาย พร้อมการให้บริการแนะนำจากทาง เรา
              เพื่อตอบโจทย์ของลูกค้าอย่างดีที่สุด
            </label>
          </div>
        </div>
      </div>
    );
  }
}
