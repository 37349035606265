//global.httpURL = 'https://api.martech.co.th'; //TEST
global.httpURL = "https://api.martech.co.th"; //PRODUCTION
global.zoom = "";
global.zoom_api_key = "yPhcFWL6QYiSuj9OVUb3eQ";
global.zoom_api_secret = "PSJAmEIWnqkcVEwNhb6bah0H57ftDAPKrdCp";
// global.leaveUrl = "http://localhost:3000/"; //LOCAL
global.leaveUrl = "https://release-eschool-frontend-q2ymiujz5a-as.a.run.app/"; //TEST
// global.leaveUrl = "https://eschool-frontend-q2ymiujz5a-as.a.run.app/"; //PRODUCTION
global.TH = {
  month: [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ],
  day: ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"],
  file_format: "* รองรับข้อมูลจากรูปแบบไฟล์ csv, xls, xlsx",
  error_file: "นามสกุลไฟล์ที่อัพโหลดไม่ถูกต้อง",
  error_logout: "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
  error_format: "รูปแบบตารางข้อมูลไม่ตรงตามระบบ",
  person: "คน",
  howto: "วิธีใช้งาน",
  edit_Department: "แก้ไขภาควิชา",
  edit_subDepartment: "แก้ไขหลักสูตร",
  holidayname: "ชื่อวันหยุด",
  Fulldateformat: "วัน/เดือน/ปี",
  Datetext: "วันที่",
  list: "รายการ",
  company_name: "ชื่อบริษัท",
  website: "เว็บไซต์",
  fax: "หมายเลขแฟกซ์",
  tax: "เลขผู้เสียภาษี",
  manager: "ชื่อผู้จัดการ",
  branchid: "รหัสสาขา",
  phone_manager: "เบอร์โทรศัพท์ผู้จัดการ",
  Logo: "ภาพโลโก้สถาบัน",
  new_upload: "อัพโหลดภาพใหม่",
  upload_detail:
    "รองรับขนาดไฟล์ไม่เกิน 5Mb และมีขนาด 300px * 300px โดยไฟล์ที่อัปโหลดจะรองรับไฟล์ Jpg, Jpeg, Gif, และ Png เท่านั้น",
  edit_institute: "แก้ไขข้อมูลสถาบัน",
  upload: "อัพโหลด",
  upload_alert: "กรุณาเลือกไฟล์ก่อนทำการ อัพโหลด",
  zoom_api_key_error: "มี Zoom API Key นี้อยู่ในระบบแล้ว กรุณาลองใหม่อีกครั้ง",
  zoom_api_secret_error:
    "มี Zoom API Secret นี้อยู่ในระบบแล้ว กรุณาลองใหม่อีกครั้ง",
  all: "ทั้งหมด",
  id: "รหัส",
  see: "ดูวิดีโอ",
  download: "ดาวโหลดวิดีโอ",
  no_video: "ไม่มีวิดีโอ",
  fill_department: "กรุณาเลือกภาควิชา",
  fill_subdepartment: "กรุณาเพิ่มหลักสูตร",
  fill_subject_total_hour:
    "กรุณากรอกจำนวนชั่วโมงเรียนทั้งหมด ก่อนทำการเพิ่มเวลาเรียน",
  error_subdepartment: "กรุณากรอกหลักสูตรให้ครบถ้วน",
  error_subdepartment_same: "มีชื่อหลักสูตรที่เหมือนกัน กรุณากรอกใหม่อีกครั้ง",
  error_department_same: "ชื่อภาควิชามีอยู่แล้ว กรุณากรอกใหม่อีกครั้ง",
  add_department: "เพิ่มภาควิชา",
  add_subdepartment: "เพิ่มหลักสูตร",
  date_history: "วันที่เข้าใช้",
  time_history: "เวลาที่เข้าใช้",
  ipaddress: "IP Address",
  login: "ลงชื่อเข้าใช้",
  login_detail: "ลงชื่อเข้าใช้เพื่อจัดการ การเรียนการสอนในระบบ",
  username: "ชื่อผู้ใช้",
  password: "รหัสผ่าน",
  dashboard: "สถิติในระบบ&ตารางเรียน",
  subject: "วิชาเรียน",
  student: "ข้อมูลนักเรียน",
  teacher: "ข้อมูลอาจารย์ผู้สอน",
  finance: "บัญชีการเงิน",
  history: "ประวัติการใช้งานระบบ",
  institution: "ข้อมูลสถาบัน",
  institution_1: "สถาบัน",
  holiday: "วันหยุด",
  admin: "ผู้ดูแลระบบ",
  role: "บทบาท",
  manage_liveclass: "จัดการ Live Class",
  liveclass: "Live Class",
  liveclasshistory: "Live Class History",
  total_learner: "จำนวนผู้เรียนในระบบ",
  total_subject: "จำนวนวิชาในระบบ",
  total_teacher: "จำนวนครูในระบบ",
  search: "ค้นหา",
  manage_department: "จัดการภาควิชา&หลักสูตร",
  add_course: "เพิ่มวิชาเรียน",
  add_Department: "เพิ่มภาควิชา",
  add_subDepartment: "เพิ่มหลักสูตร",
  Department: "ภาควิชา",
  course: "หลักสูตร",
  course_code: "รหัสวิชา",
  course_name: "ชื่อวิชา",
  teacher_name: "อาจารย์ผู้สอน",
  start_date: "วันที่เริ่มเปิดสอน",
  class_date: "วันที่เรียน",
  online: "ออนไลน์",
  class_time: "เวลาที่เรียน",
  Course_place: "ประเภทการเรียน",
  all_info: "ข้อมูลทั้งหมด",
  info: "ดูข้อมูลทั้งหมด",
  edit: "แก้ไข",
  delete: "ลบ",
  cancel: "ยกเลิก",
  save: "บันทึก",
  save_edit: "บันทึกการแก้ไข",
  save_success: "บันทึกข้อมูลสำเร็จ",
  Invalid_username_password: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
  retrieve_data_fail: "ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง",
  unavailable: "บัญชีของคุณไม่สามารถใช้งานได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง",
  enter_role_name: "กรุณากรอกชื่อบทบาท",
  select_at_least_one_menu: "กรุณาเลือกเมนูอย่างน้อย 1 เมนู",
  enter_your_first_last_name: "กรุณากรอกชื่อ-นามสกุล",
  enter_your_nickname: "กรุณากรอกชื่อเล่น",
  enter_your_phone_number: "กรุณากรอกเบอร์โทรศัพท์",
  select_gender: "กรุณาเลือกเพศ",
  enter_your_ID_card: "กรุณากรอกเลขบัตรประชาชน",
  enter_your_ID_card_true: "กรุณากรอกเลขบัตรประชาชนให้ถูกต้อง",
  enter_your_apikey: "กรุณากรอก Zoom API Key",
  enter_your_apisecret: "กรุณากรอก Zoom API Secret",
  enter_your_address: "กรุณากรอกที่อยู่ปัจจุบัน",
  enter_your_province: "กรุณาเลือกจังหวัด",
  enter_your_district: "กรุณาเลือกอำเภอ",
  enter_your_subdistrict: "กรุณาเลือกตำบล",
  enter_your_zipcode: "กรุณาเลือกรหัสไปรษณีย์",
  select_role: "กรุณาเลือกบทบาท",
  enter_your_email: "กรุณากรอกอีเมล",
  enter_your_email_true: "กรุณากรอกอีเมลให้ถูกต้อง",
  enter_your_phone: "กรุณากรอกหมายเลขโทรศัพท์",
  enter_your_phone_true: "กรุณากรอกหมายเลขโทรศัพท์ให้ถูกต้อง",
  enter_your_parent_phone_true: "กรุณากรอกหมายเลขโทรศัพท์ผู้ปกครองให้ถูกต้อง",
  enter_your_username: "กรุณากรอก Username",
  enter_your_password: "กรุณาคลิกสุ่มรหัสผ่าน",
  enter_your_subject: "กรุณากรอกชื่อวิชา",
  enter_your_date: "กรุณาเลือกวันที่",
  enter_your_startdate: "กรุณาเลือกเวลาเริ่มต้น",
  enter_your_enddate: "กรุณาเลือกเวลาสิ้นสุด",
  enter_your_meetingnumber: "กรุณากรอก Meeting Number",
  enter_your_meetingpassword: "กรุณากรอก Password",
  enter_your_teacher: "กรุณาเลือกอาจารย์",
  enter_your_student: "กรุณาเลือกนักเรียนอย่างน้อย 1 คน",
  enter_your_meeting_similar: "Meeting Number นี้มีในระบบแล้ว",
  enter_your_newpassword: "กรุณากรอกรหัสผ่านใหม่",
  enter_your_confirmpassword: "กรุณากรอกยืนยันรหัสผ่านใหม่",
  enter_your_school: "กรุณากรอกโรงเรียน",
  enter_your_facebook: "กรุณากรอก Facebook",
  enter_your_line: "กรุณากรอก Line ID",
  enter_your_parentname: "กรุณากรอกชื่อ-นามสกุลผู้ปกครอง",
  enter_your_company: "กรุณากรอกชื่อบริษัท",
  enter_your_web: "กรุณากรอกเว็บไซต์",
  enter_your_fax: "กรุณากรอกเบอร์แฟกซ์",
  enter_your_tax: "กรุณากรอกเลขที่ผู้เสียภาษี",
  enter_your_managername: "กรุณากรอกชื่อ-นามสกุล ผู้จัดการ",
  enter_your_managerphone: "กรุณากรอกเบอร์โทรศัพท์ ผู้จัดการ",
  enter_your_holiday: "กรุณากรอกชื่อวันหยุด",
  password_notmatch: "รหัสผ่านไม่ตรงกัน",
  haved_email: "มีอีเมลนี้ในระบบแล้ว",
  haved_role: "มีชื่อบทบาทนี้ในระบบแล้ว",
  haved_name: "มีชื่อนี้ในระบบแล้ว",
  add_success: "เพิ่มข้อมูลสำเร็จ",
  fileError:
    "เพิ่มข้อมูลไม่สำเร็จ เนื่องจากมีข้อมูลบางส่วนผิดพลาด กรุณาลองใหม่อีกครั้ง",
  add_not_success: "เพิ่มข้อมูลไม่สำเร็จ",
  add_not_success_api:
    "เพิ่มข้อมูลไม่สำเร็จ กรุณาตรวจสอบ Zoom Api ของอาจารย์ผู้สอน",
  add_not_success_detail:
    "เพิ่มข้อมูลไม่สำเร็จ กรุณากรอกข้อมูลให้ครบถ้วนและถูกต้อง",
  edit_success: "แก้ไขข้อมูลสำเร็จ",
  edit_not_success: "แก้ไขข้อมูลไม่สำเร็จ",
  edit_not_success_2:
    "แก้ไขข้อมูลไม่สำเร็จ กรุณากรอกข้อมูลให้ครบถ้วนและถูกต้อง",
  delete_success: "ลบข้อมูลสำเร็จ",
  delete_not_success: "ไม่สามารถลบข้อมูลได้ กรุณาลองใหม่อีกครั้ง",
  confirm_delete: "ยืนยันการลบข้อมูลนี้หรือไม่",
  confirm: "ยืนยัน",
  not_start_class: "ชั้นเรียนยังไม่เริ่มต้น กรุณารอสักครู่...",
  can_not_join: "ไม่สามารถเข้าร่วมชั้นเรียนได้",
  issuing_student_card: "ออกบัตรนักเรียน",
  enter_your_parentphone: "กรุณากรอกหมายเลขโทรศัพท์ ผู้ปกครอง",
  teaching_type: "เลือกประเภทการสอน",
  teaching_type_1: "ประเภทการสอน",
  trial_lessons: "ทดลองเรียน ฟรี!",
  open_for_teaching: "เปิดสอนจริง",
  add_course_information: "เพิ่มข้อมูลวิชาเรียน",
  Type_the_course_name: "พิมพ์ชื่อวิชา",
  select_course: "เลือกหลักสูตร",
  select_Department: "เลือกภาควิชา",
  end_date: "วันสิ้นสุดการสอน",
  End_date_not: "ไม่ระบุวันสิ้นสุด",
  number_of_class_hours: "จำนวนชั่วโมงเรียนทั้งหมด",
  Study_hours: "ชั่วโมงการเรียน/วิชา",
  Course_type: "ประเภทคอร์สเรียน",
  solo_study: "เรียนเดี่ยว",
  group_classes: "เรียนกลุ่ม",
  Class_type: "ประเภทการเรียน",
  same_time: "เวลาเหมือนกัน",
  different_time: "กำหนดเอง",
  study_time: "เวลาที่เรียน",
  price: "ราคา",
  add: "เพิ่ม",
  color: "สีประจำตัว",
  detail_Department: "รายละเอียดภาควิชา",
  student_in_course: "นักเรียนที่เรียนวิชานี้",
  close: "ปิดหน้าต่าง",
  edit_subject: "แก้ไขข้อมูลวิชาเรียน",
  total_number_of_departments: "จำนวนภาควิชาทั้งหมด",
  total_number_of_courses: "จำนวนหลักสูตรทั้งหมด",
  total_number_of_subjects: "จำนวนวิชาทั้งหมด",
  order: "ลำดับ",
  student_ID: "รหัสนักเรียน",
  fullname: "ชื่อ - นามสกุล",
  phone: "หมายเลขโทรศัพท์",
  Phone_Parents: "หมายเลขโทรศัพท์ ผู้ปกครอง",
  enroll: "เพิ่มนักเรียน",
  fullname_parent: "ชื่อ-นามสกุล ผู้ปกครอง",
  nickname: "ชื่อเล่น",
  gender: "เพศ",
  selected_gender: "เลือกเพศ",
  school: "โรงเรียน",
  address: "ที่อยู่ปัจจุบัน",
  province: "จังหวัด",
  select_province: "เลือกจังหวัด",
  select_district: "เลือกอำเภอ",
  district: "เขต/อำเภอ",
  select_subdistrict: "เลือกตำบล",
  subdistrict: "ตำบล",
  zipcode: "รหัสไปรษณีย์",
  select_zipcode: "เลือกรหัสไปรษณีย์",
  add_student: "เพิ่มข้อมูลนักเรียน",
  edit_student: "แก้ไขข้อมูลนักเรียน",
  detail_student: "รายละเอียดนักเรียน",
  all_status: "แสดงสถานะทั้งหมด",
  address_hint: "บ้านเลขที่, เลขที่ห้อง, ตึก, ชื่อถนน",
  text_hint: "พิมพ์ข้อความที่นี่",
  continue: "ดำเนินการต่อ",
  add_teacher: "เพิ่มข้อมูลอาจารย์ผู้สอน",
  ID_card: "หมายเลขบัตรประชาชน",
  header_password: "คลิกเพื่อทำการสุ่มรหัสผ่าน",
  detail_password: "ระบบจะทำการคัดลอกรหัสผ่านให้อัตโนมัติ",
  copy_password: "คัดลอกรหัสผ่านแล้ว",
  editpassword: "แก้ไขรหัสผ่าน",
  newpassword: "รหัสผ่านใหม่",
  confirmnewpassword: "ยืนยันรหัสผ่านใหม่",
  delete_subject: "ลบวิชาเรียน",
  see_subject: "ดูวิชาเรียน",
  selected_role: "เลือกบทบาท",
  role_name: "ชื่อบทบาท",
  add_role: "เพิ่มบทบาท",
  edit_role: "แก้ไขบทบาท",
  detail: "รายละเอียด",
  admin_detail: "รายละเอียดผู้ดูแลระบบ",
  teacher_detail: "รายละเอียดข้อมูลอาจารย์",
  teacher_add: "เพิ่มอาจารย์ผู้สอน",
  teacher_edit: "แก้ไขอาจารย์ผู้สอน",
  teacher_delete: "ลบอาจารย์ผู้สอน",
  teacher_see: "ดูข้อมูลอาจารย์ผู้สอน",
  student_detail: "รายละเอียดนักเรียน",
  subject_detail: "รายละเอียดวิชาเรียน",
  liveclass_detail: "รายละเอียด Live Class",
  video_detail: "วิดีโอบันทึกการเรียนการสอน",
  email: "อีเมล",
  topic: "หัวข้อ",
  file_size: "ขนาดไฟล์",
  add_admin: "เพิ่มผู้ดูแลระบบ",
  edit_admin: "แก้ไขผู้ดูแลระบบ",
  admin_id: "รหัสผู้ดูแลระบบ",
  manageable_menu: "เมนูที่สามารถจัดการได้",
  add_liveclass: "เพิ่ม Live Class",
  edit_liveclass: "แก้ไข Live Class",
  add_holiday: "เพิ่มวันหยุด",
  edit_holiday: "แก้ไขวันหยุด",
  delete_holiday: "ลบวันหยุด",
  teacher_name: "ชื่ออาจารย์",
  open_date: "วันที่เปิดสอน",
  open_time: "เวลาเปิดสอน",
  start_time: "เวลาเริ่มต้น",
  end_time: "เวลาสิ้นสุด",
  live_date: "วันที่ Live",
  select_teacher: "เลือกอาจารย์",
  teacher_id: "รหัสอาจารย์",
  students: "นักเรียน",
  select_student: "เลือกนักเรียน",
  no_student: "ไม่มีนักเรียน",
  register_to_teach: "ลงทะเบียนสอน",
  alert: "แจ้งเตือน",
  no_data: "ไม่มีข้อมูล",
  status: "สถานะ",
  edit_profile: "แก้ไขข้อมูลโปรไฟล์",
  edit_user: "แก้ไขข้อมูลส่วนตัว",
  logout: "ออกจากระบบ",
  logout_title: "ต้องการออกจากระบบหรือไม่",
  login_same_title: "บัญชีนี้มีการเข้าสู่ระบบอยู่แล้ว",
  login_same_detail: "เมื่อกด 'ยืนยัน' การใช้งานก่อนหน้าจะออกจากระบบ",
  back_to_index: "กลับสู่หน้าหลัก",
  Oops: "ค้นหาไม่พบ",
  not_found: "404 - ไม่พบหน้าที่คุณกำลังค้นหา",
  not_found_detail: "ตรวจสอบ URL ที่ถูกต้อง หรือ หน้านี้ได้ถูกลบ​ออกไปแล้ว",
  ok: "ตกลง",
  male: "ชาย",
  female: "หญิง",
  other: "ไม่ระบุ",
  back: "ย้อนกลับ",
  join: "เข้าร่วม",
  not_open: "ยังไม่เปิดสอน",
  end_of_live: "สิ้นสุดแล้ว",
};
global.EN = {
  month: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  day: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  error_logout: "an error occurred Please try again.",
  file_format: "* Supports data from csv, xls, xlsx file formats.",
  error_format: "The data table format does not match the system.",
  error_file: "The uploaded file extension is incorrect.",
  person: "students",
  howto: "How to use",
  edit_Department: "Edit Department",
  edit_subDepartment: "Edit Course",
  holidayname: "holiday name",
  Fulldateformat: "DD/MM/YYYY",
  Datetext: "Date",
  list: "list",
  company_name: "Company Name",
  website: "Website",
  fax: "fax number",
  tax: "taxpayer number",
  manager: "Manager name",
  branchid: "Branch ID",
  phone_manager: "Manager phone number",
  Logo: "Institutional logo image",
  new_upload: "Upload a new image",
  upload_detail:
    "Support file size up to 5Mb and size 300px * 300px. Upload files only support Jpg, Jpeg, Gif, and Png files.",
  edit_institute: "Edit institute information",
  upload: "Upload",
  upload_alert: "Please select a file before uploading.",
  zoom_api_key_error: "This Zoom API Key already exists. Please try again",
  zoom_api_secret_error:
    "This Zoom API Secret already exists. Please try again",
  all: "All",
  id: "ID",
  see: "watch video",
  download: "video download",
  no_video: "no video",
  fill_department: "Please select a department",
  fill_subdepartment: "Please add a course",
  fill_subject_total_hour:
    "Please enter the total number of class hours. before adding time to study",
  error_subdepartment: "Please complete the course",
  error_subdepartment_same: "have the same course name Please enter again.",
  error_department_same:
    "The name of the department already exists. Please enter again.",
  add_department: "Add Department",
  add_subdepartment: "Add Sub Department",
  date_history: "Access date",
  time_history: "Time of Access",
  ipaddress: "IP Address",
  login: "Login",
  login_detail: "Sign in to manage teaching in the system",
  username: "Username",
  password: "Password",
  dashboard: "System statistics & timetable",
  subject: "Study subject",
  student: "Student Information",
  teacher: "Instructor information",
  finance: "financial account",
  history: "System usage history",
  institution: "Institute information",
  institution_1: "Institute",
  holiday: "Holiday",
  admin: "Administrator",
  role: "Role",
  manage_liveclass: "Manage Live Class",
  liveclass: "Live Class",
  liveclasshistory: "Live Class History",
  total_learner: "Total number of learners",
  total_subject: "Total number of subjects",
  total_teacher: "Total number of teachers",
  search: "Search",
  manage_department: "Manage departments & courses",
  add_course: "Add Course",
  Department: "Department",
  course: "Course",
  course_code: "Course Code",
  course_name: "Course Name",
  teacher_name: "Teacher",
  start_date: "Teaching start date",
  class_date: "Class Date",
  online: "Online",
  class_time: "Class Time",
  Course_place: "Class type",
  all_info: "All Info",
  info: "Info",
  edit: "Edit",
  delete: "Delete",
  cancel: "Cancel",
  save: "Save",
  color: "Color",
  save_edit: "Edit",
  save_success: "Save data successfully",
  Invalid_username_password: "Invalid username or password",
  retrieve_data_fail: "Failed to retrieve data. Please try again",
  unavailable: "Your account is currently unavailable. Please try again",
  enter_role_name: "Please enter a role name.",
  select_at_least_one_menu: "Please select at least one menu.",
  enter_your_first_last_name: "Please enter your first and last name",
  enter_your_nickname: "Please enter a nickname",
  enter_your_phone_number: "Please enter your phone number",
  select_gender: "Please select gender",
  enter_your_ID_card: "Please enter your ID card number",
  enter_your_ID_card_true: "Please enter a valid your ID card number",
  enter_your_apikey: "Please enter Zoom API Key",
  enter_your_apisecret: "Please enter Zoom API Secret",
  enter_your_address: "Please enter your current address",
  enter_your_province: "Please select a province",
  enter_your_district: "Please select a district",
  enter_your_subdistrict: "Please select a sub district",
  enter_your_zipcode: "Please select a zip code",
  select_role: "Please select a role",
  enter_your_email: "Please enter your email",
  enter_your_email_true: "Please enter a valid email address",
  enter_your_phone: "Please enter your phone number",
  enter_your_phone_true: "Please enter a valid phone number.",
  enter_your_parent_phone_true: "Please enter a valid parent phone number.",
  enter_your_username: "Please enter Username",
  enter_your_password: "Please click random password",
  enter_your_subject: "Please enter the subject name",
  enter_your_date: "Please select a date",
  enter_your_startdate: "Please select a start time",
  enter_your_enddate: "Please select an end time",
  enter_your_meetingnumber: "Please enter Meeting Number",
  enter_your_meetingpassword: "Please enter Password",
  enter_your_teacher: "Please select a teacher",
  enter_your_student: "Please select at least one student.",
  enter_your_meeting_similar: "This Meeting Number is already in the system.",
  enter_your_newpassword: "Please enter a new password",
  enter_your_confirmpassword: "Please enter a new confirmation password",
  enter_your_school: "Please enter your school",
  enter_your_facebook: "Please enter Facebook",
  enter_your_line: "Please enter Line ID",
  enter_your_parentname: "Please enter your parent's fullname.",
  enter_your_company: "Please enter company name",
  enter_your_web: "Please enter your website",
  enter_your_fax: "Please enter your fax number",
  enter_your_tax: "Please enter taxpayer number",
  enter_your_managername: "Please enter your manager's name.",
  enter_your_managerphone: "Please enter manager phone number",
  enter_your_holiday: "Please enter a holiday name",
  password_notmatch: "Passwords don't match",
  haved_email: "This email is already.",
  haved_role: "This role name is already.",
  haved_name: "This name is already.",
  add_success: "Data added successfully",
  fileError:
    "Failed to add information Because some information is wrong. Please try again.",
  add_not_success: "Failed to add data",
  add_not_success_api:
    "Failed to add data, please check the instructor's Zoom Api.",
  add_not_success_detail:
    "Failed to add data. Please complete and correct information",
  edit_success: "Data edited successfully",
  edit_not_success: "Failed to edit data",
  edit_not_success_2:
    "Failed to edit data. Please complete and correct information",
  delete_success: "Delete data successfully",
  delete_not_success: "Cannot delete data. Please try again",
  confirm_delete: "Confirm this deletion?",
  confirm: "Confirm",
  not_start_class: "The class has not started yet. Please wait a moment...",
  can_not_join: "Unable to join class",
  issuing_student_card: "Issuing student card",
  enter_your_parentphone: "Please enter your parent's phone number.",
  teaching_type: "Choose a teaching type",
  teaching_type_1: "Teaching type",
  trial_lessons: "Trial lessons are free!",
  open_for_teaching: "Teaching for real",
  add_course_information: "Add course information",
  Type_the_course_name: "Type a course name",
  select_course: "Select a course",
  select_Department: "Select Department",
  end_date: "Teaching end date",
  End_date_not: "End date not specified",
  number_of_class_hours: "Total number of class hours",
  Study_hours: "Study/Subject Hours",
  Course_type: "Course Type",
  solo_study: "Solo Class",
  group_classes: "Group Classes",
  Class_type: "Class Type",
  same_time: "Same time",
  different_time: "Custom",
  study_time: "Study time",
  price: "Price",
  add: "Add",
  detail_Department: "Department details",
  student_in_course: "Students taking this course",
  close: "Close",
  edit_subject: "Edit course information",
  total_number_of_departments: "Total number of departments",
  total_number_of_courses: "Total number of courses",
  total_number_of_subjects: "Total number of subjects",
  order: "Sequence",
  student_ID: "Student ID",
  fullname: "Fullname",
  phone: "Phone number",
  Phone_Parents: "Parent's phone number",
  enroll: "Add student information",
  fullname_parent: "Parent's name",
  nickname: "Nickname",
  gender: "Gender",
  selected_gender: "Select Gender",
  school: "School",
  address: "Address",
  province: "Province",
  select_province: "Select a province",
  select_district: "Select District",
  district: "District",
  select_subdistrict: "Select Sub District",
  subdistrict: "Sub District",
  zipcode: "Zip Code",
  select_zipcode: "Select Zip Code",
  add_student: "Add student information",
  edit_student: "Edit Student Information",
  detail_student: "Student Details",
  all_status: "Show all statuses",
  address_hint: "Address",
  text_hint: "Type text here",
  continue: "Continue",
  add_teacher: "Add teacher information",
  ID_card: "ID card number",
  header_password: "Click here for random password.",
  detail_password: "The system will copy the password automatically.",
  copy_password: "Password copied",
  editpassword: "Edit Password",
  newpassword: "New password",
  confirmnewpassword: "Confirm new password",
  delete_subject: "Delete a course",
  see_subject: "See a course",
  selected_role: "Select Role",
  role_name: "Role Name",
  add_role: "Add Role",
  edit_role: "Edit Role",
  detail: "Detail",
  admin_detail: "Admin details",
  teacher_detail: "Teacher details",
  teacher_add: "Add teacher",
  teacher_edit: "Edit teacher",
  teacher_delete: "Delete teacher",
  teacher_see: "View teacher information",
  student_detail: "Student Details",
  subject_detail: "Details of the course",
  liveclass_detail: "Live Class details",
  video_detail: "Record instructional video",
  email: "Email",
  add_admin: "Add admin",
  edit_admin: "Edit admin",
  admin_id: "Admin ID",
  topic: "Topic",
  file_size: "File Size",
  manageable_menu: "Manageable Menu",
  add_liveclass: "Add a Live Class",
  edit_liveclass: "Edit Live Class",
  add_holiday: "Add Holiday",
  edit_holiday: "Edit Holiday",
  delete_holiday: "Delete Holidays",
  teacher_name: "Teacher's Name",
  open_date: "Launch Date",
  open_time: "Opening Time",
  start_time: "Start Time",
  end_time: "End Time",
  live_date: "Live Date",
  select_teacher: "Select Teacher",
  teacher_id: "Teacher ID",
  students: "Student",
  select_student: "Select a student",
  no_student: "No student",
  register_to_teach: "Register Teach",
  alert: "Alert",
  no_data: "No information",
  status: "Status",
  edit_profile: "Edit profile information",
  edit_user: "Edit Profile",
  logout: "Logout",
  logout_title: "Do you want to log out?",
  login_same_title: "This account is already logged in.",
  login_same_detail:
    "When 'Confirm' is pressed, the previous application will log out.",
  back_to_index: "GO TO HOMEPAGE",
  Oops: "Oops!",
  not_found: "404 - PAGE NOT FOUND",
  not_found_detail:
    "The page you are looking for might have been removed had its name changed or is temporarily unavailable.",
  ok: "confirm",
  male: "Male",
  female: "Female",
  other: "Other",
  back: "Back",
  join: "Join",
  not_open: "Still can't join",
  end_of_live: "End of live",
};
