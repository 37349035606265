import "../../../bootstrap.css";
import React, { Component } from "react";
import SideMenu from "../../Content/SideMenu/SideMenu";
import Header from "../../Content/Header/Header";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import blank from "./blank.png";
import Swal from "sweetalert2";

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "@szhsin/react-menu/dist/index.css";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class LoginScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: 1,
      page_count: 1,
      step: 0,
      _id: "",
      branch_code: "",
      company_name: "",
      email: "",
      phone: "",
      website: "",
      fax_number: "",
      tax_number: "",
      manager_name: "",
      manager_phone: "",
      address: "",
      province: "",
      district: "",
      subdistrict: "",
      zipcode: "",
      photo: "",
      size_photo: 0,

      holiday_name: "",
      holiday_date: "",

      modal_add_holiday: false,
      modal_edit_holiday: false,
      modal_add_institution_1: false,
      modal_add_institution_2: false,
      data: "",
      holiday: [],
      index: "",
      p: [],
      d: [],
      sd: [],
      z: [],
    };
  }
  language = cookies.get("language") ? cookies.get("language") : "TH";
  componentDidMount() {
    if (cookies.get("user")) {
      this.GetHoliday();
      this.GetInstitution();
      this.GetProvince();
    } else {
      Swal.fire({
        title: global[this.language]["alert"],
        text: global[this.language]["retrieve_data_fail"],
        icon: "warning",
        showCancelButton: false,
        allowOutsideClick: false,
        confirmButtonText: global[this.language]["ok"],
      }).then((result) => {
        if (result.isConfirmed) {
          cookies.remove("token", { path: "/" });
          cookies.remove("user", { path: "/" });

          window.location.href = "/";
        }
      });
    }
  }
  GetHoliday = () => {
    this.setState({ loading: true });
    fetch(global.httpURL + "/api/holiday/page/" + this.state.activePage, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("GetHoliday", response);
        if (
          response.message === "Auth Failed" ||
          response.message === "Token is null"
        ) {
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["unavailable"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.Logout();
            }
          });
        } else if (response.message === "Connot find page") {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          if (response.data) {
            this.setState({
              holiday: response.data,
              loading: false,
              page_count: response.page_count,
            });
          } else {
            this.setState({
              loading: false,
            });
          }
          // this.GetTeacher();
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loading: false });
        Swal.fire({
          title: global[this.language]["alert"],
          text: global[this.language]["retrieve_data_fail"],
          icon: "warning",
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonText: global[this.language]["ok"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.Logout();
          }
        });
      });
  };
  GetInstitution = () => {
    this.setState({ loading: true });
    fetch(global.httpURL + "/api/institution", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("GetInstitution", response);
        if (
          response.message === "Auth Failed" ||
          response.message === "Token is null"
        ) {
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["unavailable"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.Logout();
            }
          });
        } else if (response.message === "Connot find page") {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          if (response.data.length !== 0) {
            this.setState({
              data: response.data,
              loading: false,
              page_count: response.page_count,
              _id: response.data._id,
              branch_code: response.data.branch_code,
              company_name: response.data.company_name,
              email: response.data.email,
              phone: response.data.phone,
              website: response.data.website,
              fax_number: response.data.fax_number,
              tax_number: response.data.tax_number,
              manager_name: response.data.manager_name,
              manager_phone: response.data.manager_phone,
              address: response.data.address,
              province: response.data.province,
              district: response.data.district,
              subdistrict: response.data.sub_district,
              zipcode: response.data.zip_code,
              photo: response.data.logo_institution,
            });
          } else {
            this.setState({
              loading: false,
            });
          }
          // this.GetTeacher();
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loading: false });
        Swal.fire({
          title: global[this.language]["alert"],
          text: global[this.language]["retrieve_data_fail"],
          icon: "warning",
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonText: global[this.language]["ok"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.Logout();
          }
        });
      });
  };
  Logout = () => {
    fetch(global.httpURL + "/api/logout", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "token " + cookies.get("token"),
      },
      // mode: "no-cors",
    })
      .then((response) => response.json())
      .then((response) => {
        cookies.remove("token", { path: "/" });
        cookies.remove("user", { path: "/" });

        window.location.href = "/";
      })
      .catch((err) => {
        //window.location.href = "/dashboard";
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_logout"],
          "warning"
        );
      });
  };
  Cancel = () => {
    this.setState({
      holiday_name: "",
      holiday_date: "",

      modal_add_holiday: false,
      modal_edit_holiday: false,
      modal_add_institution_1: false,
      modal_add_institution_2: false,
    });
  };
  Submit_add_institution = (step) => {
    if (step === 1) {
      this.setState({
        modal_add_institution_1: true,
        modal_add_institution_2: false,
        step: 1,
      });
    }
    if (step === 2) {
      if (this.state.company_name === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_company"],
          "warning"
        );
      } else if (this.state.phone === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_phone_number"],
          "warning"
        );
      } else if (this.state.email === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_email"],
          "warning"
        );
      } else if (this.state.website === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_web"],
          "warning"
        );
      } else if (this.state.fax_number === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_fax"],
          "warning"
        );
      } else if (this.state.tax_number === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_tax"],
          "warning"
        );
      } else if (this.state.manager_name === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_managername"],
          "warning"
        );
      } else if (this.state.manager_phone === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_managerphone"],
          "warning"
        );
      } else {
        this.setState({
          modal_add_institution_1: false,
          modal_add_institution_2: true,
          step: 2,
        });
      }
    }
    if (step === "success") {
      if (this.state.address === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_address"],
          "warning"
        );
      } else if (this.state.province === "" || this.state.province === null) {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_province"],
          "warning"
        );
      } else if (this.state.district === "" || this.state.district === null) {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_district"],
          "warning"
        );
      } else if (
        this.state.subdistrict === "" ||
        this.state.subdistrict === null
      ) {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_subdistrict"],
          "warning"
        );
      } else if (
        this.state.zipcode === "" ||
        this.state.zipcode === null ||
        !this.state.zipcode
      ) {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_zipcode"],
          "warning"
        );
      } else {
        this.setState({
          modal_add_institution_1: false,
          modal_add_institution_2: false,
        });
        let district = !isNaN(this.state.district)
          ? this.state.d[this.state.district].DISTRICT_NAME
          : this.state.district;
        let subdistrict = !isNaN(this.state.subdistrict)
          ? this.state.sd[this.state.subdistrict].SUB_DISTRICT_NAME
          : this.state.subdistrict;
        let province = !isNaN(this.state.province)
          ? this.state.p[this.state.province].PROVINCE_NAME
          : this.state.province;
        let zipcode =
          this.state.zipcode.length !== 5
            ? this.state.z[this.state.zipcode].ZIPCODE
            : this.state.zipcode;

        setTimeout(() => {
          fetch(global.httpURL + "/api/institution/" + this.state._id, {
            method: "PUT",
            headers: {
              "content-type": "application/json",
              accept: "application/json",
              Authorization: "Bearer " + cookies.get("token"),
            },
            body: JSON.stringify({
              company_name: this.state.company_name,
              email: this.state.email,
              phone: this.state.phone,
              website: this.state.website,
              fax_number: this.state.fax_number,
              tax_number: this.state.tax_number,
              manager_name: this.state.manager_name,
              manager_phone: this.state.manager_phone,
              branch_code: this.state.branch_code,
              address: this.state.address,
              sub_district: subdistrict,
              district: district,
              province: province,
              zip_code: zipcode,
              logo_institution: this.state.photo,
            }),
          })
            .then((response) => response.json())
            .then((response) => {
              console.log(response);
              this.setState({ spinner: false });
              if (!response.auth) {
                Swal.fire(
                  global[this.language]["alert"],
                  global[this.language]["add_not_success"],
                  "warning"
                );
              } else {
                Swal.fire(
                  global[this.language]["alert"],
                  global[this.language]["add_success"],
                  "success"
                );
                Swal.fire({
                  icon: "success",
                  title: global[this.language]["alert"],
                  text: global[this.language]["add_success"],
                  showCancelButton: false,
                  confirmButtonText: global[this.language]["confirm"],
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.href = "/holiday";
                  }
                });
                // this.Cancel();
                // this.GetHoliday();
              }
            })
            .catch((err) => {
              console.error(err);
              this.setState({ spinner: false });
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["add_not_success"],
                "warning"
              );
            });
        }, 10);
        this.Cancel();
      }
    }
  };
  Submit_add_holiday = () => {
    if (this.state.holiday_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_holiday"],
        "warning"
      );
    } else if (this.state.holiday_date === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_date"],
        "warning"
      );
    } else {
      let date =
        this.state.holiday_date.getDate().toString().length === 1
          ? "0" + this.state.holiday_date.getDate()
          : this.state.holiday_date.getDate();
      let month =
        this.state.holiday_date.getMonth().toString().length === 1
          ? "0" + (this.state.holiday_date.getMonth() + 1)
          : this.state.holiday_date.getMonth() + 1;
      let fulldate =
        date + "/" + month + "/" + this.state.holiday_date.getFullYear();
      setTimeout(() => {
        fetch(global.httpURL + "/api/holiday", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
          body: JSON.stringify({
            holiday_name: this.state.holiday_name,
            holiday_date: fulldate,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            console.log(response);
            this.setState({ spinner: false });
            if (!response.auth) {
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["add_not_success"],
                "warning"
              );
            } else {
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["add_success"],
                "success"
              );
              this.Cancel();
              this.GetHoliday();
            }
          })
          .catch((err) => {
            this.setState({ spinner: false });
          });
      }, 10);
    }
  };
  Submit_edit_holiday = () => {
    if (this.state.holiday_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_holiday"],
        "warning"
      );
    } else if (this.state.holiday_date === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_date"],
        "warning"
      );
    } else {
      if (typeof this.state.holiday_date === "number") {
        this.setState({
          holiday_date: new Date(this.state.holiday_date),
        });
      }
      setTimeout(() => {
        let date =
          this.state.holiday_date.getDate().toString().length === 1
            ? "0" + this.state.holiday_date.getDate()
            : this.state.holiday_date.getDate();
        let month =
          this.state.holiday_date.getMonth().toString().length === 1
            ? "0" + (this.state.holiday_date.getMonth() + 1)
            : this.state.holiday_date.getMonth() + 1;
        let fulldate =
          date + "/" + month + "/" + this.state.holiday_date.getFullYear();
        console.log(
          JSON.stringify({
            holiday_name: this.state.holiday_name,
            holiday_date: fulldate,
          })
        );
        setTimeout(() => {
          fetch(
            global.httpURL +
              "/api/holiday/" +
              this.state.holiday[this.state.index]._id,
            {
              method: "PUT",
              headers: {
                "content-type": "application/json",
                accept: "application/json",
                Authorization: "Bearer " + cookies.get("token"),
              },
              body: JSON.stringify({
                holiday_name: this.state.holiday_name,
                holiday_date: fulldate,
              }),
            }
          )
            .then((response) => response.json())
            .then((response) => {
              console.log(response);
              this.setState({ spinner: false });
              if (!response.auth) {
                Swal.fire(
                  global[this.language]["alert"],
                  global[this.language]["add_not_success"],
                  "warning"
                );
              } else {
                Swal.fire(
                  global[this.language]["alert"],
                  global[this.language]["add_success"],
                  "success"
                );
                this.Cancel();
                this.GetHoliday();
              }
            })
            .catch((err) => {
              console.error(err);
              this.setState({ spinner: false });
            });
        }, 10);
      }, 1);
    }
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
  }
  handleClick(e) {
    this.refs.fileUploader.click();
  }
  Delete_holiday = () => {
    Swal.fire({
      title: global[this.language]["confirm_delete"],
      showCancelButton: true,
      confirmButtonText: global[this.language]["confirm"],
      cancelButtonText: global[this.language]["cancel"],
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ spinner: true });
        fetch(
          global.httpURL +
            "/api/holiday/" +
            this.state.holiday[this.state.index]._id,
          {
            method: "DELETE",
            headers: {
              "content-type": "application/json",
              accept: "application/json",
              Authorization: "Bearer " + cookies.get("token"),
            },
          }
        )
          .then((response) => response.json())
          .then((response) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_success"],
              "success"
            );
            this.GetHoliday();
          })
          .catch((err) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_not_success"],
              "warning"
            );
          });
      }
    });
  };
  GetProvince = () => {
    fetch(global.httpURL + "/api/address/provinces", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({
          p: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  GetDistrict = (index) => {
    var id = this.state.p[index].PROVINCE_ID;

    fetch(global.httpURL + "/api/address/districts", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ provinces_id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({
          d: response.data,
          district: "",
          subdistrict: "",
          zipcode: "",
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  GetSubDistrict = (index) => {
    var id = this.state.d[index].DISTRICT_ID;
    console.log(JSON.stringify({ districts_id: id }));
    fetch(global.httpURL + "/api/address/subDistricts", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ districts_id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({
          sd: response.data,
          subdistrict: "",
          zipcode: "",
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  GetZipcode = (index) => {
    var id = this.state.sd[index].SUB_DISTRICT_ID;

    fetch(global.httpURL + "/api/address/zipcodes", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ subDistricts_id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({
          z: response.data,
          zipcode: "",
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  handleFileSelect = (evt) => {
    var f = evt.target.files[0]; // FileList object
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        document.getElementById("base64").value = base64String;

        // this.ChangePhoto(base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
    setTimeout(() => {
      var photo = document.getElementById("base64").value;
      // var size = photo.length;
      // console.log("size", size);
      this.setState({ photo: photo });
    }, 100);
    setTimeout(() => {
      this.Submit_add_institution("success");
    }, 105);
  };
  render() {
    return (
      <div className="w-100-body h-auto minh-100">
        {/* {this.state.size_photo} */}
        <input id="base64" hidden />
        <div className="d-flex h-auto minh-100">
          <div className="h-auto minh-100">
            <SideMenu page="holiday" />
          </div>
          <div className="w-100-body">
            <Header />
            <div className="container-fluid pt-3">
              {/* TABLE */}
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-3">
                  <div className="card border-0 p-4">
                    <div className="flex-row justify-content-between align-items-center row p-4">
                      <div>
                        <h3>
                          <b>{global[this.language]["logo"]}</b>
                        </h3>
                      </div>
                    </div>
                    <div className="w-100">
                      <div className="card w-100 p-4">
                        <div className="row">
                          <div className="col-12 col-sm-4 col-md-5 col-lg-3 col-xl-4">
                            <img
                              src={
                                this.state.data.logo_institution === "" ||
                                !this.state.data.logo_institution
                                  ? blank
                                  : this.state.data.logo_institution
                              }
                              alt=""
                              width={120}
                              height={120}
                              className="institution-image mx-auto d-block"
                            />
                          </div>
                          <div className="col-12 col-sm-8 col-md-7 col-lg-9 col-xl-8 d-none d-sm-block">
                            <button
                              className="btn btn-outline-primary ml-2 pt-1 pb-0 "
                              onClick={this.handleClick.bind(this)}
                            >
                              {global[this.language]["new_upload"]}
                            </button>
                            <input
                              type="file"
                              ref="fileUploader"
                              hidden
                              onChange={(event) => {
                                this.handleFileSelect(event);
                              }}
                            />
                            <small>
                              <label className="mt-2 ">
                                <label className="icon small-icon mr-1">
                                  {"\uf05a"}
                                </label>
                                {global[this.language]["upload_detail"]}
                              </label>
                            </small>
                          </div>
                          <div className="mt-3 col-12 col-sm-9 col-md-8 col-lg-9 col-xl-8 d-block d-sm-none text-center">
                            <button
                              className="btn btn-outline-primary ml-2 pt-1 pb-0 "
                              onClick={this.handleClick.bind(this)}
                            >
                              {global[this.language]["new_upload"]}
                            </button>
                            {/* <input
                              type="file"
                              id="actual-btn"
                              hidden
                              onChange={(event) => {
                                this.setState({
                                  image: URL.createObjectURL(
                                    event.target.files[0]
                                  ),
                                });
                              }}
                            /> */}
                            <small>
                              <label className="mt-2 ">
                                <label className="icon small-icon mr-1">
                                  {"\uf05a"}
                                </label>
                                {global[this.language]["upload_detail"]}
                              </label>
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-row justify-content-between align-items-center row p-4">
                      <div>
                        <h3>
                          <b>{global[this.language]["institution"]}</b>
                        </h3>
                      </div>
                      {/* BUTTON XL */}
                      <div className="text-right">
                        <button
                          className="btn btn-primary ml-2"
                          onClick={() => {
                            this.setState({
                              modal_add_institution_1: true,
                            });
                          }}
                        >
                          <label className="icon mr-1">{"\uf304"}</label>
                          {global[this.language]["edit"]}
                        </button>
                      </div>
                    </div>
                    <div className="w-100">
                      <div className="card w-100 p-4">
                        <div className="row">
                          <div className="col-12 col-sm-6 mb-4">
                            <b>{global[this.language]["company_name"]}:</b>
                            <br />
                            <label>{this.state.data.company_name}</label>
                          </div>
                          <div className="col-12 col-sm-6 mb-4">
                            <b>{global[this.language]["email"]}:</b>
                            <br />
                            <label>{this.state.data.email}</label>
                          </div>
                          <div className="col-12 col-sm-6 mb-4">
                            <b>{global[this.language]["phone"]}:</b>
                            <br />
                            <label>{this.state.data.phone}</label>
                          </div>
                          <div className="col-12 col-sm-6 mb-4">
                            <b>{global[this.language]["website"]}:</b>
                            <br />
                            <label>{this.state.data.website}</label>
                          </div>
                          <div className="col-12 col-sm-6 mb-4">
                            <b>{global[this.language]["fax"]}:</b>
                            <br />
                            <label>{this.state.data.fax_number}</label>
                          </div>
                          <div className="col-12 col-sm-6 mb-4">
                            <b>{global[this.language]["tax"]}:</b>
                            <br />
                            <label>{this.state.data.tax_number}</label>
                          </div>
                          <div className="col-12 col-sm-6 mb-4">
                            <b>{global[this.language]["manager"]}:</b>
                            <br />
                            <label>{this.state.data.manager_name}</label>
                          </div>
                          <div className="col-12 col-sm-6 mb-4">
                            <b>{global[this.language]["phone_manager"]}:</b>
                            <br />
                            <label>{this.state.data.manager_phone}</label>
                          </div>
                          <div className="col-12 col-sm-6 mb-4">
                            <b>{global[this.language]["branchid"]}:</b>
                            <br />
                            <label>{this.state.data._id}</label>
                          </div>
                          <div className="col-12 col-sm-6 mb-4">
                            <b>{global[this.language]["address"]}:</b>
                            <br />
                            <label>
                              {this.state.data.address +
                                " ตำบล" +
                                this.state.data.sub_district +
                                " อำเภอ" +
                                this.state.data.district +
                                " จังหวัด" +
                                this.state.data.province +
                                " " +
                                this.state.data.zip_code}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-3">
                  <div className="card border-0 p-4">
                    <div className="flex-row justify-content-between align-items-center row p-4">
                      <div>
                        <h3>
                          <b>{global[this.language]["holiday"]}</b>
                        </h3>
                      </div>
                      {/* BUTTON XL */}
                      <div className="text-right">
                        <button
                          className="btn btn-primary ml-2"
                          onClick={() => {
                            this.setState({ modal_add_holiday: true });
                          }}
                        >
                          <label className="icon mr-1">{"\uf055"}</label>
                          {global[this.language]["add_holiday"]}
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-borderless table-striped table-w-2-3 table-hover ">
                        <thead className="thead-dark">
                          <tr>
                            <th className="text-center">
                              {global[this.language]["order"]}
                            </th>
                            <th>{global[this.language]["Datetext"]}</th>
                            <th>{global[this.language]["list"]}</th>
                            <th className="text-center">
                              {global[this.language]["edit"]}
                            </th>
                            <th className="text-center">
                              {global[this.language]["delete"]}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* DEPARTMENT */}
                          {this.state.holiday.map((holiday, holiday_index) => (
                            <tr>
                              <td className="text-center">
                                {holiday_index +
                                  1 +
                                  (this.state.activePage - 1) * 10}
                              </td>
                              <td>{holiday.holiday_date}</td>
                              <td>{holiday.holiday_name}</td>

                              <td className="text-center">
                                <a
                                  className="btn btn-link p-0"
                                  onClick={() => {
                                    this.setState({
                                      holiday_date: new Date().setFullYear(
                                        holiday.holiday_date.split("/")[2],
                                        Number(
                                          holiday.holiday_date.split("/")[1]
                                        ) - 1,
                                        holiday.holiday_date.split("/")[0]
                                      ),
                                      holiday_name: holiday.holiday_name,
                                      index: holiday_index,
                                    });
                                    setTimeout(() => {
                                      this.setState({
                                        modal_edit_holiday: true,
                                      });
                                    }, 1);
                                  }}
                                >
                                  <label className="icon">{"\uf304"}</label>
                                </a>
                              </td>
                              <td className="text-center">
                                <a
                                  className="btn btn-link p-0"
                                  onClick={() => {
                                    this.setState({
                                      index: holiday_index,
                                    });
                                    setTimeout(() => {
                                      this.Delete_holiday();
                                    }, 1);
                                  }}
                                >
                                  <label className="icon ">{"\uf2ed"}</label>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex flex-row-reverse mt-3">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.page_count * 10}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        prevPageText="Previous"
                        nextPageText="Next"
                        hideFirstLastPages={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* MODAL */}
              {/* แก้ไขข้อมูลสถาบัน 1 */}
              <Modal
                show={this.state.modal_add_institution_1}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["edit_institute"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["company_name"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.company_name}
                        onChange={(event) => {
                          this.setState({
                            company_name: event.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["phone"]}:</label>
                      <input
                        type="text"
                        maxLength={10}
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.phone}
                        onChange={(event) => {
                          this.setState({
                            phone: event.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="col-12 pb-3">
                      <label>{global[this.language]["email"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.email}
                        onChange={(event) => {
                          this.setState({
                            email: event.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["website"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.website}
                        onChange={(event) => {
                          this.setState({
                            website: event.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="col-6 pb-3">
                      <label>{global[this.language]["fax"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.fax_number}
                        onChange={(event) => {
                          this.setState({
                            fax_number: event.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["tax"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.tax_number}
                        onChange={(event) => {
                          this.setState({
                            tax_number: event.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["manager"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.manager_name}
                        onChange={(event) => {
                          this.setState({
                            manager_name: event.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["phone_manager"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        maxLength={10}
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.manager_phone}
                        onChange={(event) => {
                          this.setState({
                            manager_phone: event.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_add_institution(2);
                        }}
                      >
                        {global[this.language]["continue"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* แก้ไขข้อมูลสถาบัน 2 */}
              <Modal
                show={this.state.modal_add_institution_2}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["edit_institute"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["address"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["address_hint"]}
                        value={this.state.address}
                        onChange={(event) => {
                          this.setState({
                            address: event.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["province"]}:</label>
                      <select
                        className="form-control"
                        value={this.state.province}
                        onChange={(event) => {
                          this.setState({
                            province: event.target.value,
                          });
                          console.log(event.target.value);
                          this.GetDistrict(event.target.value);
                        }}
                      >
                        <option value="">
                          --{global[this.language]["select_province"]}
                          --
                        </option>
                        {this.state.p &&
                          this.state.p.map((item, index) => (
                            <option value={index}>{item.PROVINCE_NAME}</option>
                          ))}
                      </select>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["district"]}:</label>
                      <select
                        className="form-control"
                        value={this.state.district}
                        onChange={(event) => {
                          this.setState({
                            district: event.target.value,
                          });
                          this.GetSubDistrict(event.target.value);
                        }}
                      >
                        <option value="">
                          --{global[this.language]["select_district"]}
                          --
                        </option>
                        {this.state.d &&
                          this.state.d.map((item, index) => (
                            <option value={index}>{item.DISTRICT_NAME}</option>
                          ))}
                      </select>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["subdistrict"]}:</label>
                      <select
                        className="form-control"
                        value={this.state.subdistrict}
                        onChange={(event) => {
                          this.setState({
                            subdistrict: event.target.value,
                          });
                          this.GetZipcode(event.target.value);
                        }}
                      >
                        <option value="">
                          --{global[this.language]["select_subdistrict"]}
                          --
                        </option>
                        {this.state.sd &&
                          this.state.sd.map((item, index) => (
                            <option value={index}>
                              {item.SUB_DISTRICT_NAME}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["zipcode"]}:</label>
                      <select
                        className="form-control"
                        value={this.state.zipcode}
                        onChange={(event) => {
                          this.setState({
                            zipcode: event.target.value,
                          });
                        }}
                      >
                        <option value="">
                          --{global[this.language]["select_zipcode"]}
                          --
                        </option>
                        {this.state.z &&
                          this.state.z.map((item, index) => (
                            <option value={index}>{item.ZIPCODE}</option>
                          ))}
                      </select>
                    </div>

                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Submit_add_institution(1);
                        }}
                      >
                        {global[this.language]["back"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_add_institution("success");
                        }}
                      >
                        {global[this.language]["save"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* เพิ่มวันหยุด */}
              <Modal
                show={this.state.modal_add_holiday}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["add_holiday"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["holidayname"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.holiday_name}
                        onChange={(event) => {
                          this.setState({ holiday_name: event.target.value });
                        }}
                      />
                    </div>
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["Fulldateformat"]}:</label>
                      {/* <input
                        type="date"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.holiday_date}
                        onChange={(event) => {
                          this.setState({ holiday_date: event.target.value });
                        }}
                      /> */}
                      <DatePicker
                        className="form-control w-100"
                        selected={this.state.holiday_date}
                        minDate={new Date()}
                        locale="th"
                        onChange={(date) => {
                          this.setState({ holiday_date: date });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_add_holiday();
                        }}
                      >
                        {global[this.language]["add"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* แก้ไขวันหยุด */}
              <Modal
                show={this.state.modal_edit_holiday}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["edit_holiday"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["holidayname"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.holiday_name}
                        onChange={(event) => {
                          this.setState({ holiday_name: event.target.value });
                        }}
                      />
                    </div>
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["Fulldateformat"]}:</label>
                      {/* <input
                        type="date"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.holiday_date}
                        onChange={(event) => {
                          this.setState({ holiday_date: event.target.value });
                        }}
                      /> */}
                      <DatePicker
                        className="form-control w-100"
                        selected={this.state.holiday_date}
                        locale="th"
                        onChange={(date) => {
                          this.setState({ holiday_date: date });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_edit_holiday();
                        }}
                      >
                        {global[this.language]["save_edit"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
