import "../../../bootstrap.css";
import React, { Component } from "react";
import { Link } from "react-router";
import ReactDOM from "react-dom";
import LOGO from "./Logo.png";
import SideMenu from "../../Content/SideMenu/SideMenu";
import Header from "../../Content/Header/Header";
import Days from "../../Content/Days/Days";

import Swal from "sweetalert2";

import Pagination from "react-js-pagination";

import { Modal } from "react-bootstrap";

import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import Cookies from "universal-cookie";
import { eachDayOfInterval } from "date-fns";

const cookies = new Cookies();
export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage_admin: 1,
      activePage_role: 1,
      page_count_admin: 1,
      page_count_role: 1,
      loading1: true,
      loading2: true,
      data: [],
      role_name: "",
      role: [],
      menu: [
        {
          name: global[this.language]["dashboard"],
          check: false,
        },
        {
          name: global[this.language]["subject"],
          check: false,
        },
        {
          name: global[this.language]["student"],
          check: false,
        },
        {
          name: global[this.language]["teacher"],
          check: false,
        },
        {
          name: global[this.language]["finance"],
          check: false,
        },
        {
          name: global[this.language]["history"],
          check: false,
        },
        {
          name:
            global[this.language]["institution"] +
            "&" +
            global[this.language]["holiday"],
          check: false,
        },
        {
          name:
            global[this.language]["admin"] +
            "&" +
            global[this.language]["role"],
          check: false,
        },
        {
          name: global[this.language]["manage_liveclass"],
          check: false,
        },
        {
          name: global[this.language]["liveclass"],
          check: false,
        },
        {
          name: global[this.language]["liveclasshistory"],
          check: false,
        },
      ],
      modal_admin: false,
      modal_add_admin: false,
      modal_add_role: false,
      admin_id: "",
      admin_name: "",
      admin_role: "",
      admin_email: "",
      admin_phone: "",
      admin_username: "",
      admin_password: "",
      detail: "",
      copy: false,
      sort: [0, 0],
      spinner: false,
    };
  }
  language = cookies.get("language") ? cookies.get("language") : "TH";
  componentDidMount() {
    if (cookies.get("user")) {
      if (cookies.get("token")) {
        this.GetAdmin();
        this.GetRole();
      } else {
        Swal.fire({
          title: global[this.language]["alert"],
          text: global[this.language]["retrieve_data_fail"],
          icon: "warning",
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonText: global[this.language]["ok"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.Logout();
          }
        });
      }
    } else {
      Swal.fire({
        title: global[this.language]["alert"],
        text: global[this.language]["retrieve_data_fail"],
        icon: "warning",
        showCancelButton: false,
        allowOutsideClick: false,
        confirmButtonText: global[this.language]["ok"],
      }).then((result) => {
        if (result.isConfirmed) {
          cookies.remove("token", { path: "/" });
          cookies.remove("user", { path: "/" });

          window.location.href = "/";
        }
      });
    }
  }
  Logout = () => {
    fetch(global.httpURL + "/api/logout", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "token " + cookies.get("token"),
      },
      // mode: "no-cors",
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        cookies.remove("token", { path: "/" });
        cookies.remove("user", { path: "/" });

        window.location.href = "/";
      })
      .catch((err) => {
        //window.location.href = "/dashboard";
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_logout"],
          "warning"
        );
      });
  };
  GetAdmin = () => {
    this.setState({ loading1: true });
    console.log("TOKEN ", cookies.get("token"));
    fetch(global.httpURL + "/api/user/page/" + this.state.activePage_admin, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("ADMIN::", response);
        if (
          response.message === "Auth Failed" ||
          response.message === "Token is null" ||
          response.message === "Token is not found"
        ) {
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["unavailable"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.Logout();
            }
          });
        } else if (response.message === "Connot find page") {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            data: response.data,
            loading1: false,
            page_count_admin: response.page_count,
          });
        }
      })
      .catch((err) => {
        console.error("ADMIN::", err);
        this.setState({ loading1: false });
        Swal.fire({
          title: global[this.language]["alert"],
          text: global[this.language]["retrieve_data_fail"],
          icon: "warning",
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonText: global[this.language]["ok"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.Logout();
          }
        });
      });
  };
  GetRole = () => {
    this.setState({ loading2: true });
    fetch(global.httpURL + "/api/role", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("ROLE::", response);
        if (
          response.message === "Auth Failed" ||
          response.message === "Token is null" ||
          response.message === "Token is not found"
        ) {
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["unavailable"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.Logout();
            }
          });
        } else if (response.message === "Connot find page") {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            role: response.data,
            loading2: false,
            // page_count_role: response.page_count,
          });
        }
      })
      .catch((err) => {
        console.error("ROLE::", err);
        this.setState({ loading2: false });
        Swal.fire({
          title: global[this.language]["alert"],
          text: global[this.language]["retrieve_data_fail"],
          icon: "warning",
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonText: global[this.language]["ok"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.Logout();
          }
        });
      });
  };
  Cancel = () => {
    this.setState({
      modal_admin: false,
      modal_add_admin: false,
      modal_add_role: false,
      modal_edit_admin: false,
      modal_edit_role: false,
      admin_id: "",
      admin_name: "",
      admin_role: "",
      admin_email: "",
      admin_phone: "",
      admin_username: "",
      admin_password: "",
      role_name: "",
      menu: [
        {
          name: global[this.language]["dashboard"],
          check: false,
        },
        {
          name: global[this.language]["subject"],
          check: false,
        },
        {
          name: global[this.language]["student"],
          check: false,
        },
        {
          name: global[this.language]["teacher"],
          check: false,
        },
        {
          name: global[this.language]["finance"],
          check: false,
        },
        {
          name: global[this.language]["history"],
          check: false,
        },
        {
          name:
            global[this.language]["institution"] +
            "&" +
            global[this.language]["holiday"],
          check: false,
        },
        {
          name:
            global[this.language]["admin"] +
            "&" +
            global[this.language]["role"],
          check: false,
        },
        {
          name: global[this.language]["manage_liveclass"],
          check: false,
        },
        {
          name: global[this.language]["liveclass"],
          check: false,
        },
        {
          name: global[this.language]["liveclasshistory"],
          check: false,
        },
      ],
    });
  };
  Save_role = () => {
    let count_role = 0;
    for (let data of this.state.menu) {
      if (data.check) {
        count_role += 1;
      }
    }
    if (this.state.role_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_role_name"],
        "warning"
      );
    } else if (count_role === 0) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["select_at_least_one_menu"],
        "warning"
      );
    } else {
      this.setState({ spinner: true });
      let role = {
        dashboard: this.state.menu[0].check ? "True" : "False",
        subject: this.state.menu[1].check ? "True" : "False",
        student: this.state.menu[2].check ? "True" : "False",
        teacher: this.state.menu[3].check ? "True" : "False",
        finance: this.state.menu[4].check ? "True" : "False",
        history: this.state.menu[5].check ? "True" : "False",
        institution: this.state.menu[6].check ? "True" : "False",
        // branch: this.state.menu[7].check ? "True" : "False",
        admin: this.state.menu[7].check ? "True" : "False",
        manageliveclass: this.state.menu[8].check ? "True" : "False",
        liveclass: this.state.menu[9].check ? "True" : "False",
        liveclasshistory: this.state.menu[10].check ? "True" : "False",
      };

      fetch(global.httpURL + "/api/role", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + cookies.get("token"),
        },
        body: JSON.stringify({
          role_name: this.state.role_name,
          role_detail: role,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response.message);
          this.setState({ spinner: false });
          if (response.message === "This role name is existed") {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["haved_role"],
              "warning"
            );
          } else {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["add_success"],
              "success"
            );
            this.Cancel();
            this.GetRole();
          }
        })
        .catch((err) => {
          this.setState({ spinner: false });
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["add_not_success"],
            "warning"
          );
        });
    }
  };

  Save_admin = () => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
    let num = /^^[0][0-9]*$/;
    if (this.state.admin_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_first_last_name"],
        "warning"
      );
    } else if (this.state.admin_role === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["select_role"],
        "warning"
      );
    } else if (this.state.admin_email === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_email"],
        "warning"
      );
    } else if (!reg.test(this.state.admin_email)) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_email_true"],
        "warning"
      );
    } else if (this.state.admin_phone === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_phone"],
        "warning"
      );
    } else if (
      this.state.admin_phone.trim().length < 10 ||
      !num.test(this.state.admin_phone)
    ) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_phone_true"],
        "warning"
      );
    } else if (this.state.admin_username === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_username"],
        "warning"
      );
    } else if (this.state.admin_password === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_password"],
        "warning"
      );
    } else {
      this.setState({ spinner: true });
      fetch(global.httpURL + "/api/user", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + cookies.get("token"),
        },
        body: JSON.stringify({
          name: this.state.admin_name,
          phone: this.state.admin_phone,
          email: this.state.admin_email,
          username: this.state.admin_username,
          password: this.state.admin_password,
          role_id: this.state.role[this.state.admin_role]._id,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          this.setState({ spinner: false });
          if (
            response.message === "Email already exists" ||
            response.message === "This email is existed"
          ) {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["haved_email"],
              "warning"
            );
          } else {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["add_success"],
              "success"
            );
            this.Cancel();
            this.GetAdmin();
          }
        })
        .catch((err) => {
          this.setState({ spinner: false });
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["add_not_success_detail"],
            "warning"
          );
        });
    }
  };
  Edit_role = () => {
    let count_role = 0;
    for (let data of this.state.menu) {
      if (data.check) {
        count_role += 1;
      }
    }
    if (this.state.role_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_role_name"],
        "warning"
      );
    } else if (count_role === 0) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["select_at_least_one_menu"],
        "warning"
      );
    } else {
      this.setState({ spinner: true });
      let role = {
        dashboard: this.state.menu[0].check ? "True" : "False",
        subject: this.state.menu[1].check ? "True" : "False",
        student: this.state.menu[2].check ? "True" : "False",
        teacher: this.state.menu[3].check ? "True" : "False",
        finance: this.state.menu[4].check ? "True" : "False",
        history: this.state.menu[5].check ? "True" : "False",
        institution: this.state.menu[6].check ? "True" : "False",
        // branch: this.state.menu[7].check ? "True" : "False",
        admin: this.state.menu[7].check ? "True" : "False",
        manageliveclass: this.state.menu[8].check ? "True" : "False",
        liveclass: this.state.menu[9].check ? "True" : "False",
        liveclasshistory: this.state.menu[10].check ? "True" : "False",
      };

      fetch(
        global.httpURL + "/api/role/" + this.state.role[this.state.detail]._id,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
          body: JSON.stringify({
            role_name: this.state.role_name,
            role_detail: role,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          this.setState({ spinner: false });
          if (response.message === "This role name is existed") {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["haved_role"],
              "warning"
            );
          } else {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["edit_success"],
              "success"
            );
            this.Cancel();
            this.GetRole();
          }
        })
        .catch((err) => {
          this.setState({ spinner: false });
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["edit_not_success"],
            "warning"
          );
        });
    }
  };
  Edit_admin = () => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
    let num = /^^[0][0-9]*$/;
    if (this.state.admin_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_first_last_name"],
        "warning"
      );
    } else if (this.state.admin_role === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["select_role"],
        "warning"
      );
    } else if (this.state.admin_email === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_email"],
        "warning"
      );
    } else if (!reg.test(this.state.admin_email)) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_email_true"],
        "warning"
      );
    } else if (this.state.admin_phone === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_phone"],
        "warning"
      );
    } else if (
      this.state.admin_phone.trim().length < 10 ||
      !num.test(this.state.admin_phone)
    ) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_phone_true"],
        "warning"
      );
    }
    //  else if (this.state.admin_password === "") {
    //   Swal.fire(global[this.language]["alert"], global[this.language]["enter_your_password"], "warning");
    // }
    else {
      this.setState({ spinner: true });
      let data = "";
      if (this.state.admin_password === "") {
        data = {
          name: this.state.admin_name,
          phone: this.state.admin_phone,
          email: this.state.admin_email,
          username: this.state.admin_username,
          // password: this.state.admin_password,
          role_id: this.state.role[this.state.admin_role]._id,
        };
      } else {
        data = {
          name: this.state.admin_name,
          phone: this.state.admin_phone,
          email: this.state.admin_email,
          username: this.state.admin_username,
          password: this.state.admin_password,
          role_id: this.state.role[this.state.admin_role]._id,
        };
      }
      fetch(
        global.httpURL +
          "/api/user/" +
          this.state.data[this.state.detail].user._id,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          this.setState({ spinner: false });
          if (
            response.message === "Email already exists" ||
            response.message === "This email is existed"
          ) {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["haved_email"],
              "warning"
            );
          } else {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["edit_success"],
              "success"
            );
            this.Cancel();
            this.GetAdmin();
          }
        })
        .catch((err) => {
          this.setState({ spinner: false });
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["edit_not_success_2"],
            "warning"
          );
        });
    }
  };
  Delete_Admin = (index) => {
    Swal.fire({
      title: global[this.language]["confirm_delete"],
      showCancelButton: true,
      confirmButtonText: global[this.language]["confirm"],
      cancelButtonText: global[this.language]["cancel"],
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ spinner: true });
        fetch(global.httpURL + "/api/user/" + this.state.data[index].user._id, {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
        })
          .then((response) => response.json())
          .then((response) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_success"],
              "success"
            );
            this.GetAdmin();
          })
          .catch((err) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_not_success"],
              "warning"
            );
          });
      }
    });
  };
  Delete_Role = (index) => {
    Swal.fire({
      title: global[this.language]["confirm_delete"],
      showCancelButton: true,
      confirmButtonText: global[this.language]["confirm"],
      cancelButtonText: global[this.language]["cancel"],
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ spinner: true });
        fetch(global.httpURL + "/api/role/" + this.state.role[index]._id, {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
        })
          .then((response) => response.json())
          .then((response) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_success"],
              "success"
            );
            this.GetRole();
          })
          .catch((err) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_not_success"],
              "warning"
            );
          });
      }
    });
  };

  handlePageChange_admin(pageNumber) {
    this.setState({ activePage_admin: pageNumber, loading: true });
    setTimeout(() => {
      this.GetAdmin();
    }, 1);
  }
  handlePageChange_role(pageNumber) {
    this.setState({ activePage_role: pageNumber, loading: true });
    setTimeout(() => {
      this.GetRole();
    }, 1);
  }
  Sort = (key) => {
    let list = this.state.data;
    let role = this.state.role;
    let sorted = this.state.sort;
    if (key === "admin") {
      if (sorted[0] === 0) {
        list.sort((a, b) => (a.name < b.name ? 1 : -1));
        sorted[0] = 1;
      } else {
        list.sort((a, b) => (a.name > b.name ? 1 : -1));
        sorted[0] = 0;
      }
    } else if (key === "role") {
      if (sorted[1] === 0) {
        role.sort((a, b) => (a.role_name < b.role_name ? 1 : -1));
        sorted[1] = 1;
      } else {
        role.sort((a, b) => (a.role_name > b.role_name ? 1 : -1));
        sorted[1] = 0;
      }
    }

    this.setState({ data: list, sort: sorted, role: role });
  };
  GenUser = () => {
    let year = new Date().getFullYear() + 543;
    let month = new Date().getMonth() + 1;
    let day = new Date().getDate();
    let time =
      new Date().getHours() * 60 * 60 +
      new Date().getMinutes() * 60 +
      new Date().getSeconds();
    let gen = new Date().getTime();

    year = year.toString().slice(2, 4);
    if (month.toString().length === 1) {
      month = "0" + month;
    }
    if (day.toString().length === 1) {
      day = "0" + day;
    }
    gen = gen.toString().slice(4, 10);
    this.setState({
      admin_username: "US" + year + month + gen,
    });
  };
  Role = (r) => {
    let role = "";
    if (r.dashboard === "True") {
      role += global[this.language]["dashboard"] + ",";
    }
    if (r.subject === "True") {
      role += global[this.language]["subject"] + ",";
    }
    if (r.student === "True") {
      role += global[this.language]["student"] + ",";
    }
    if (r.teacher === "True") {
      role += global[this.language]["teacher"] + ",";
    }
    if (r.finance === "True") {
      role += global[this.language]["finance"] + ",";
    }
    if (r.history === "True") {
      role += global[this.language]["history"] + ",";
    }
    if (r["institution"] === "True") {
      role +=
        global[this.language]["institution"] +
        "&" +
        global[this.language]["holiday"] +
        ",";
    }
    if (r["admin"] === "True") {
      role +=
        global[this.language]["admin"] +
        "&" +
        global[this.language]["role"] +
        ",";
    }
    if (r.manage_liveclass === "True") {
      role += global[this.language]["manageliveclass"] + ",";
    }
    if (r.liveclass === "True") {
      role += global[this.language]["liveclass"] + ",";
    }
    if (r.liveclasshistory === "True") {
      role += global[this.language]["liveclasshistory"] + ",";
    }
    return role.slice(0, role.length - 1);
  };
  render() {
    return (
      <div className="w-100 h-auto minh-100">
        {this.state.spinner && (
          <div className="spinner">
            <div className="loader" />
          </div>
        )}
        <div className="d-flex h-auto minh-100">
          <div className="h-auto minh-100">
            <SideMenu page="admin" />
          </div>
          <div className="w-100-body">
            <Header />
            <div className="container-fluid pt-3">
              {/* TABLE */}
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-3">
                  <div className="card border-0 p-4">
                    <div className="flex-row justify-content-between align-items-center row p-4">
                      <div>
                        <h3>
                          <b>{global[this.language]["admin"]}</b>
                        </h3>
                      </div>
                      {/* BUTTON XL */}
                      <div className="text-right">
                        {/* d-none d-xl-block */}
                        {/* <button className="btn btn-outline-secondary border-secondary mr-2 ml-2 text-primary">
                          <label className="icon mr-1">{"\uf56d"}</label>
                          Export Report
                        </button> */}
                        <button
                          className="btn btn-primary ml-2"
                          onClick={() => {
                            this.setState({ modal_add_admin: true });
                            this.GenUser();
                          }}
                        >
                          <label className="icon mr-1">{"\uf055"}</label>
                          {global[this.language]["add_admin"]}
                        </button>
                      </div>
                      {/* BUTTON NOT XL */}
                      {/* <div className="text-right d-block d-xl-none flex-row justify-content-between align-items-center">
                        <Menu
                          menuButton={
                            <button className="btn btn-primary mr-2 ml-2">
                              <label className="icon">{"\uf0c9"}</label>
                            </button>
                          }
                        >
                          <MenuItem>
                            <label className="icon mr-1">{"\uf56d"}</label>
                            Export Report
                          </MenuItem>

                          <MenuItem
                            onClick={() => {
                              this.setState({ modal_add_admin: true });
                              this.GenUser();
                            }}
                          >
                            <label className="icon mr-1">{"\uf055"}</label>

                            {global[this.language]["add_admin"]}
                          </MenuItem>
                        </Menu>
                      </div> */}
                    </div>
                    <div className="table-responsive">
                      {!this.state.loading1 ? (
                        <table className="table table-borderless table-striped table-w-2-3 table-hover ">
                          <thead className="thead-dark">
                            <tr>
                              <th className="text-center">
                                {global[this.language]["order"]}
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("admin");
                                }}
                                style={{ minWidth: 200 }}
                              >
                                {global[this.language]["fullname"]}
                              </th>
                              <th className="text-center max-width-medium">
                                {global[this.language]["all_info"]}
                              </th>
                              <th className="text-center max-width-small">
                                {global[this.language]["edit"]}
                              </th>
                              <th className="text-center max-width-small">
                                {global[this.language]["delete"]}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* DEPARTMENT */}
                            {this.state.data &&
                              this.state.data.map((admin, admin_index) => (
                                <tr>
                                  <td className="text-center">
                                    {admin_index + 1}
                                  </td>
                                  {/* <td>{admin._id}</td> */}
                                  <td>{admin.user.name}</td>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-link pt-0 pb-0"
                                      onClick={() => {
                                        this.setState({
                                          modal_admin: true,
                                          detail: admin_index,
                                        });
                                      }}
                                    >
                                      {global[this.language]["info"]}
                                    </button>
                                  </td>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-link pt-0 pb-0"
                                      onClick={() => {
                                        let role = 0;
                                        for (
                                          let i = 0;
                                          i < this.state.role.length;
                                          i++
                                        ) {
                                          if (
                                            this.state.role[i]._id ===
                                            admin.user.role.role_id
                                          ) {
                                            role = i;
                                          }
                                        }

                                        this.setState({
                                          modal_edit_admin: true,
                                          detail: admin_index,
                                          admin_name: admin.user.name,
                                          admin_role: role,
                                          admin_email: admin.user.email,
                                          admin_phone:
                                            admin.user.phone &&
                                            admin.user.phone,
                                          admin_username: admin.user.username,
                                          admin_password: "",
                                        });
                                      }}
                                    >
                                      <label className="icon ">
                                        {"\uf304"}
                                      </label>
                                    </button>
                                  </td>

                                  <td className="text-center">
                                    <button
                                      className="btn btn-link pt-0 pb-0"
                                      onClick={() => {
                                        this.Delete_Admin(admin_index);
                                      }}
                                    >
                                      <label className="icon ">
                                        {"\uf2ed"}
                                      </label>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            {this.state.data && this.state.data.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={5}>
                                  {global[this.language]["no_data"]}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <div className="w-100 d-flex justify-content-center">
                          <div className="loader" />
                        </div>
                      )}
                    </div>
                    <div className="d-flex flex-row-reverse mt-3">
                      <Pagination
                        activePage={this.state.activePage_admin}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.page_count_admin * 10}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange_admin.bind(this)}
                        prevPageText="Previous"
                        nextPageText="Next"
                        hideFirstLastPages={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-3">
                  <div className="card border-0 p-4">
                    <div className="flex-row justify-content-between align-items-center row p-4">
                      <div>
                        <h3>
                          <b>{global[this.language]["role"]}</b>
                        </h3>
                      </div>
                      {/* BUTTON XL */}
                      <div className="text-right">
                        <button
                          className="btn btn-primary ml-2"
                          onClick={() => {
                            this.setState({ modal_add_role: true });
                          }}
                        >
                          <label className="icon mr-1">{"\uf055"}</label>
                          {global[this.language]["add_role"]}
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {!this.state.loading2 ? (
                        <table className="table table-borderless table-striped table-w-2 table-hover ">
                          <thead className="thead-dark">
                            <tr>
                              <th className="text-center">
                                {global[this.language]["order"]}
                              </th>
                              <th
                                className="point max-width-medium"
                                onClick={() => {
                                  this.Sort("role");
                                }}
                              >
                                {global[this.language]["role_name"]}
                              </th>
                              <th>{global[this.language]["detail"]}</th>
                              <th className="text-center max-width-small">
                                {global[this.language]["edit"]}
                              </th>
                              <th className="text-center max-width-small">
                                {global[this.language]["delete"]}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* DEPARTMENT */}
                            {this.state.role &&
                              this.state.role.map((role, role_index) => (
                                <tr>
                                  <td className="text-center">
                                    {role_index + 1}
                                  </td>
                                  <td>{role.role_name}</td>
                                  <td>{this.Role(role.role_detail)}</td>

                                  <td className="text-center">
                                    <button
                                      className="btn btn-link pt-0 pb-0"
                                      onClick={() => {
                                        this.setState({
                                          modal_edit_role: true,
                                          detail: role_index,
                                          role_name: role.role_name,
                                        });
                                        let menu = [
                                          {
                                            name: global[this.language][
                                              "dashboard"
                                            ],
                                            check:
                                              role.role_detail.dashboard ===
                                              "True"
                                                ? true
                                                : false,
                                          },
                                          {
                                            name: global[this.language][
                                              "subject"
                                            ],
                                            check:
                                              role.role_detail.subject ===
                                              "True"
                                                ? true
                                                : false,
                                          },
                                          {
                                            name: global[this.language][
                                              "student"
                                            ],
                                            check:
                                              role.role_detail.student ===
                                              "True"
                                                ? true
                                                : false,
                                          },
                                          {
                                            name: global[this.language][
                                              "teacher"
                                            ],
                                            check:
                                              role.role_detail.teacher ===
                                              "True"
                                                ? true
                                                : false,
                                          },
                                          {
                                            name: global[this.language][
                                              "finance"
                                            ],
                                            check:
                                              role.role_detail.finance ===
                                              "True"
                                                ? true
                                                : false,
                                          },
                                          {
                                            name: global[this.language][
                                              "history"
                                            ],
                                            check:
                                              role.role_detail.history ===
                                              "True"
                                                ? true
                                                : false,
                                          },
                                          {
                                            name:
                                              global[this.language][
                                                "institution"
                                              ] +
                                              "&" +
                                              global[this.language]["holiday"],
                                            check:
                                              role.role_detail.institution ===
                                              "True"
                                                ? true
                                                : false,
                                          },

                                          {
                                            name:
                                              global[this.language]["admin"] +
                                              "&" +
                                              global[this.language]["role"],
                                            check:
                                              role.role_detail.admin &&
                                              role.role_detail.admin === "True"
                                                ? true
                                                : false,
                                          },
                                          {
                                            name: global[this.language][
                                              "manage_liveclass"
                                            ],
                                            check:
                                              role.role_detail
                                                .manageliveclass &&
                                              role.role_detail
                                                .manageliveclass === "True"
                                                ? true
                                                : false,
                                          },
                                          {
                                            name: global[this.language][
                                              "liveclass"
                                            ],
                                            check:
                                              role.role_detail.liveclass &&
                                              role.role_detail.liveclass ===
                                                "True"
                                                ? true
                                                : false,
                                          },
                                          {
                                            name: global[this.language][
                                              "liveclasshistory"
                                            ],
                                            check:
                                              role.role_detail
                                                .liveclasshistory &&
                                              role.role_detail
                                                .liveclasshistory === "True"
                                                ? true
                                                : false,
                                          },
                                        ];
                                        this.setState({ menu: menu });
                                      }}
                                    >
                                      <label className="icon ">
                                        {"\uf304"}
                                      </label>
                                    </button>
                                  </td>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-link pt-0 pb-0"
                                      onClick={() => {
                                        this.Delete_Role(role_index);
                                      }}
                                    >
                                      <label className="icon ">
                                        {"\uf2ed"}
                                      </label>
                                    </button>
                                  </td>
                                </tr>
                              ))}{" "}
                            {this.state.role && this.state.role.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={5}>
                                  {global[this.language]["no_data"]}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <div className="w-100 d-flex justify-content-center">
                          <div className="loader" />
                        </div>
                      )}
                    </div>
                    <div className="d-flex flex-row-reverse mt-3">
                      <Pagination
                        activePage={this.state.activePage_role}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.page_count_role * 10}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange_role.bind(this)}
                        prevPageText="Previous"
                        nextPageText="Next"
                        hideFirstLastPages={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* MODAL */}
              {/* เพิ่ม admin */}
              <Modal
                show={this.state.modal_add_admin}
                onHide={() => {
                  this.setState({ modal_add_admin: false });
                }}
                backdrop="static"
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["add_admin"]}</b>
                  </h3>

                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["fullname"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.admin_name}
                        onChange={(event) => {
                          this.setState({ admin_name: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["role"]}:</label>
                      <select
                        className="form-control mat-4 mab-24"
                        placeholder={
                          "-- " + global[this.language]["selected_role"] + " --"
                        }
                        value={this.state.admin_role}
                        onChange={(event) =>
                          this.setState({
                            admin_role: event.target.value,
                          })
                        }
                      >
                        <option value="">
                          -- {global[this.language]["selected_role"]} --
                        </option>
                        {this.state.role &&
                          this.state.role.map((item, index) => (
                            <option value={index}>{item.role_name}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["email"]}:</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.admin_email}
                        onChange={(event) => {
                          this.setState({ admin_email: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["phone"]}:</label>
                      <input
                        type="phone"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        maxLength={10}
                        value={this.state.admin_phone}
                        onChange={(event) => {
                          this.setState({ admin_phone: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["username"]}:</label>
                      <input
                        type="phone"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        maxLength={10}
                        value={this.state.admin_username}
                        readOnly
                        onChange={(event) => {
                          this.setState({ admin_username: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["password"]}:</label>

                      <div
                        className="card w-100 bg-light p-4"
                        onClick={() => {
                          if (this.state.admin_password === "") {
                            var text = "";
                            var possible =
                              "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                            for (var i = 0; i < 6; i++)
                              text += possible.charAt(
                                Math.floor(Math.random() * possible.length)
                              );
                            this.setState({ admin_password: text });
                            const selBox = document.createElement("textarea");
                            selBox.style.position = "fixed";
                            selBox.style.left = "0";
                            selBox.style.top = "0";
                            selBox.style.opacity = "0";
                            selBox.value = text;
                            document.body.appendChild(selBox);
                            selBox.focus();
                            selBox.select();
                            document.execCommand("copy");
                            document.body.removeChild(selBox);
                            var x = document.getElementById("snackbar");
                            x.className = "show";
                            setTimeout(function () {
                              x.className = x.className.replace("show", "");
                            }, 3000);
                            // if (navigator.clipboard) {
                            //   navigator.clipboard.writeText(text);

                            //   this.setState({ copy: true });
                            // }
                            // setTimeout(() => {
                            //   this.setState({ copy: false });
                            // }, 2000);
                          }
                        }}
                      >
                        {this.state.admin_password === "" ? (
                          <div
                            className="w-100 text-center"
                            style={{ cursor: "pointer" }}
                          >
                            <h4 className="header-password">
                              <b className="text-primary">
                                {global[this.language]["header_password"]}
                              </b>
                            </h4>
                            {/* <small>
                              <label className="text-secondary">
                                {global[this.language]["detail_password"]}
                              </label>
                            </small> */}
                          </div>
                        ) : (
                          <div className="w-100 text-center">
                            <h4>{this.state.admin_password}</h4>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Save_admin();
                        }}
                      >
                        {global[this.language]["add"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* แก้ไข admin */}
              <Modal
                show={this.state.modal_edit_admin}
                onHide={() => {
                  this.setState({ modal_edit_admin: false });
                }}
                backdrop="static"
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["edit_admin"]}</b>
                  </h3>

                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["fullname"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.admin_name}
                        onChange={(event) => {
                          this.setState({ admin_name: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["role"]}:</label>
                      <select
                        className="form-control mat-4 mab-24"
                        placeholder={
                          "--" + global[this.language]["selected_role"] + "--"
                        }
                        value={this.state.admin_role}
                        onChange={(event) =>
                          this.setState({
                            admin_role: event.target.value,
                          })
                        }
                      >
                        <option value="">
                          -- {global[this.language]["selected_role"]} --
                        </option>
                        {this.state.role &&
                          this.state.role.map((item, index) => (
                            <option value={index}>{item.role_name}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["email"]}:</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.admin_email}
                        onChange={(event) => {
                          this.setState({ admin_email: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["phone"]}:</label>
                      <input
                        type="phone"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        maxLength={10}
                        value={this.state.admin_phone}
                        onChange={(event) => {
                          this.setState({ admin_phone: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["username"]}:</label>
                      <input
                        type="phone"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        readOnly
                        value={this.state.admin_username}
                        onChange={(event) => {
                          this.setState({ admin_username: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["password"]}:</label>
                      <div
                        className="card w-100 bg-light p-4"
                        onClick={() => {
                          if (this.state.admin_password === "") {
                            var text = "";
                            var possible =
                              "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                            for (var i = 0; i < 6; i++)
                              text += possible.charAt(
                                Math.floor(Math.random() * possible.length)
                              );
                            this.setState({ admin_password: text });
                            const selBox = document.createElement("textarea");
                            selBox.style.position = "fixed";
                            selBox.style.left = "0";
                            selBox.style.top = "0";
                            selBox.style.opacity = "0";
                            selBox.value = text;
                            document.body.appendChild(selBox);
                            selBox.focus();
                            selBox.select();
                            document.execCommand("copy");
                            document.body.removeChild(selBox);
                            var x = document.getElementById("snackbar");
                            x.className = "show";
                            setTimeout(function () {
                              x.className = x.className.replace("show", "");
                            }, 3000);
                            // navigator.clipboard.writeText(text);
                          }
                        }}
                      >
                        {this.state.admin_password === "" ? (
                          <div
                            className="w-100 text-center"
                            style={{ cursor: "pointer" }}
                          >
                            <h4 className="header-password">
                              <b className="text-primary">
                                {global[this.language]["header_password"]}
                              </b>
                            </h4>
                            {/* <small>
                              <label className="text-secondary">
                                {global[this.language]["detail_password"]}
                              </label>
                            </small> */}
                          </div>
                        ) : (
                          <div className="w-100 text-center">
                            <h4>{this.state.admin_password}</h4>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {" "}
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Edit_admin();
                        }}
                      >
                        {global[this.language]["save_edit"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* รายละเอียด admin */}
              <Modal
                show={this.state.modal_admin}
                onHide={() => {
                  this.setState({ modal_admin: false });
                }}
                backdrop="static"
              >
                <Modal.Body>
                  <div className="col-12 p-3">
                    <h3 className="mb-3">
                      <b>{global[this.language]["admin_detail"]}</b>
                    </h3>
                    {/* รหัสผู้ดูแลระบบ */}
                    <div className="row">
                      <div className="col-4 pb-3">
                        <label>{global[this.language]["admin_id"]}:</label>
                      </div>
                      <div className="col-8 pb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={global[this.language]["admin_id"]}
                          value={
                            this.state.detail !== "" &&
                            this.state.data[this.state.detail] &&
                            this.state.data[this.state.detail].user._id
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    {/* ชื่อ-นามสกุล */}
                    <div className="row">
                      <div className="col-4 pb-3">
                        <label>{global[this.language]["fullname"]}:</label>
                      </div>
                      <div className="col-8 pb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={global[this.language]["fullname"]}
                          value={
                            this.state.detail !== "" &&
                            this.state.data[this.state.detail] &&
                            this.state.data[this.state.detail].user.name
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    {/* บทบาท */}
                    <div className="row">
                      <div className="col-4 pb-3">
                        <label>{global[this.language]["role"]}:</label>
                      </div>
                      <div className="col-8 pb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={global[this.language]["role"]}
                          value={
                            this.state.detail !== "" &&
                            this.state.data[this.state.detail] &&
                            this.state.data[this.state.detail].user.role
                              .role_name
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    {/* อีเมล */}
                    <div className="row">
                      <div className="col-4 pb-3">
                        <label>{global[this.language]["email"]}:</label>
                      </div>
                      <div className="col-8 pb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={global[this.language]["email"]}
                          value={
                            this.state.detail !== "" &&
                            this.state.data[this.state.detail] &&
                            this.state.data[this.state.detail].user.email
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    {/* หมายเลขโทรศัพท์ */}
                    <div className="row">
                      <div className="col-4 pb-3">
                        <label>{global[this.language]["phone"]}:</label>
                      </div>
                      <div className="col-8 pb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={global[this.language]["phone"]}
                          value={
                            this.state.detail !== "" &&
                            this.state.data[this.state.detail] &&
                            this.state.data[this.state.detail].user.phone &&
                            this.state.data[this.state.detail].user.phone
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    {/* ชื่อ-นามสกุล */}
                    <div className="row">
                      <div className="col-4 pb-3">
                        <label>{global[this.language]["username"]}:</label>
                      </div>
                      <div className="col-8 pb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={global[this.language]["username"]}
                          value={
                            this.state.detail !== "" &&
                            this.state.data[this.state.detail] &&
                            this.state.data[this.state.detail].user.username
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 pb-3"></div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.setState({ modal_admin: false });
                        }}
                      >
                        {global[this.language]["close"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* เพิ่ม Role */}
              <Modal
                show={this.state.modal_add_role}
                onHide={() => {
                  this.setState({ modal_add_role: false });
                }}
                backdrop="static"
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["add_role"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["role_name"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.role_name}
                        onChange={(event) => {
                          this.setState({ role_name: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 pb-1">
                      <label>{global[this.language]["manageable_menu"]}:</label>
                    </div>
                    {this.state.menu &&
                      this.state.menu.map((item, index) => (
                        <div className="col-6">
                          <div className="w-100 d-flex">
                            <input
                              type="checkbox"
                              className="mt-2 mr-2"
                              value={item.check}
                              checked={item.check}
                              onChange={(event) => {
                                let data = this.state.menu;
                                data[index].check = !data[index].check;

                                this.setState({
                                  menu: data,
                                });
                              }}
                            />
                            <label>{item.name}</label>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="row">
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Save_role();
                        }}
                      >
                        {global[this.language]["add"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* แก้ไข Role */}
              <Modal
                show={this.state.modal_edit_role}
                onHide={() => {
                  this.setState({ modal_edit_role: false });
                }}
                backdrop="static"
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["edit_role"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["role_name"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.role_name}
                        onChange={(event) => {
                          this.setState({ role_name: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 pb-1">
                      <label>{global[this.language]["manageable_menu"]}:</label>
                    </div>
                    {this.state.menu &&
                      this.state.menu.map((item, index) => (
                        <div className="col-6">
                          <div className="w-100 d-flex">
                            <input
                              type="checkbox"
                              className="mt-2 mr-2"
                              value={item.check}
                              checked={item.check}
                              onChange={(event) => {
                                let data = this.state.menu;
                                data[index].check = !data[index].check;

                                this.setState({
                                  menu: data,
                                });
                              }}
                            />
                            <label>{item.name}</label>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="row">
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Edit_role();
                        }}
                      >
                        {global[this.language]["save_edit"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
        <div id="snackbar">{global[this.language]["copy_password"]}</div>
      </div>
    );
  }
}
