import "../../../bootstrap.css";
import React, { Component } from "react";
import LOGO from "./Logo.png";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import TH from "./thailand.png";
import EN from "./united-kingdom.png";
import { Link } from "react-router";

import axios from "axios";
const cookies = new Cookies();
export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      security: "password",
      spinner: false,
    };
  }

  componentDidMount() {
    if (!this.language) {
      cookies.set("language", "TH", { path: "/" });
    }
    if (cookies.get("token")) {
      if (
        cookies.get("user").user.status === "student" ||
        cookies.get("user").user.status === "teacher"
      ) {
        window.location.href = "/liveclass";
      } else {
        if (cookies.get("user").user.role.role_detail.dashboard === "True") {
          window.location.href = "/dashboard";
        } else if (
          cookies.get("user").user.role.role_detail.subject === "True"
        ) {
          window.location.href = "/subject";
        } else if (
          cookies.get("user").user.role.role_detail.student === "True"
        ) {
          window.location.href = "/student";
        } else if (
          cookies.get("user").user.role.role_detail.teacher === "True"
        ) {
          window.location.href = "/teacher";
        } else if (
          cookies.get("user").user.role.role_detail.account === "True"
        ) {
          window.location.href = "/finance";
        } else if (
          cookies.get("user").user.role.role_detail.history === "True"
        ) {
          window.location.href = "/history";
        } else if (
          cookies.get("user").user.role.role_detail.institution === "True"
        ) {
          window.location.href = "/holiday";
        } else if (cookies.get("user").user.role.role_detail.admin === "True") {
          window.location.href = "/admin";
        } else if (
          cookies.get("user").user.role.role_detail.manageliveclass === "True"
        ) {
          window.location.href = "/manageliveclass";
        } else if (
          cookies.get("user").user.role.role_detail.liveclass === "True"
        ) {
          window.location.href = "/liveclass";
        } else if (
          cookies.get("user").user.role.role_detail.liveclasshistory === "True"
        ) {
          window.location.href = "/liveclasshistory";
        }
      }
    }
  }

  language = cookies.get("language") ? cookies.get("language") : "TH";
  Login = () => {
    if (this.state.username === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_username"],
        "warning"
      );
    } else if (this.state.password === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_newpassword"],
        "warning"
      );
    } else {
      this.setState({ spinner: true });

      fetch(global.httpURL + "/api/login", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          username: this.state.username,
          password: this.state.password,
        }),
        // mode: "no-cors",
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          if (!response.auth) {
            this.setState({ spinner: false });
            if (response.message === "This token login") {
              Swal.fire({
                title: global[this.language]["login_same_title"],
                text: global[this.language]["login_same_detail"],
                showCancelButton: true,
                confirmButtonText: global[this.language]["confirm"],
                cancelButtonText: global[this.language]["cancel"],
              }).then((result) => {
                if (result.isConfirmed) {
                  fetch(global.httpURL + "/api/logout", {
                    method: "GET",
                    headers: {
                      "content-type": "application/json",
                      accept: "application/json",
                      Authorization: "token " + response.token,
                    },
                    // mode: "no-cors",
                  })
                    .then((response) => response.json())
                    .then((response) => {
                      cookies.remove("token", { path: "/" });
                      cookies.remove("user", { path: "/" });

                      this.Login();
                    })
                    .catch((err) => {
                      //window.location.href = "/dashboard";
                      Swal.fire(
                        global[this.language]["alert"],
                        global[this.language]["Invalid_username_password"],
                        "warning"
                      );
                    });
                }
              });
            } else {
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["Invalid_username_password"],
                "warning"
              );
            }
          } else {
            if (response.message === "Email is not found") {
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["Invalid_username_password"],
                "warning"
              );
            } else if (response.message === "Invalid password") {
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["Invalid_username_password"],
                "warning"
              );
            } else {
              cookies.set("user", response.data, { path: "/" });
              cookies.set("token", response.token, { path: "/" });
              setTimeout(() => {
                console.log("USER", cookies.get("user").user);
                if (
                  cookies.get("user").user.status === "student" ||
                  cookies.get("user").user.status === "teacher"
                ) {
                  window.location.href = "/dashboard";
                } else {
                  if (
                    cookies.get("user").user.role.role_detail.dashboard ===
                    "True"
                  ) {
                    window.location.href = "/dashboard";
                  } else if (
                    cookies.get("user").user.role.role_detail.subject === "True"
                  ) {
                    window.location.href = "/subject";
                  } else if (
                    cookies.get("user").user.role.role_detail.student === "True"
                  ) {
                    window.location.href = "/student";
                  } else if (
                    cookies.get("user").user.role.role_detail.teacher === "True"
                  ) {
                    window.location.href = "/teacher";
                  } else if (
                    cookies.get("user").user.role.role_detail.account === "True"
                  ) {
                    window.location.href = "/finance";
                  } else if (
                    cookies.get("user").user.role.role_detail.history === "True"
                  ) {
                    window.location.href = "/history";
                  } else if (
                    cookies.get("user").user.role.role_detail.institution ===
                    "True"
                  ) {
                    window.location.href = "/holiday";
                  } else if (
                    cookies.get("user").user.role.role_detail.admin === "True"
                  ) {
                    window.location.href = "/admin";
                  } else if (
                    cookies.get("user").user.role.role_detail
                      .manageliveclass === "True"
                  ) {
                    window.location.href = "/manageliveclass";
                  } else if (
                    cookies.get("user").user.role.role_detail.liveclass ===
                    "True"
                  ) {
                    window.location.href = "/liveclass";
                  } else if (
                    cookies.get("user").user.role.role_detail
                      .liveclasshistory === "True"
                  ) {
                    window.location.href = "/liveclasshistory";
                  }
                }
              }, 1);
            }
          }
        })
        .catch((err) => {
          this.setState({ spinner: false });
          console.log(err.message);
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["Invalid_username_password"],
            "warning"
          );
        });
    }
  };

  render() {
    return (
      <div className="container-fluid h-auto minh-100 d-flex align-items-center justify-content-center w-100-body">
        {this.state.spinner && (
          <div className="spinner">
            <div className="loader" />
          </div>
        )}
        {/* <Link to="/branch" className="button w-100">
          <a href="#">
            <label className="icon mr-1">test</label>
          </a>
        </Link> */}
        <div className="col-1 col-sm-1 col-md-3 col-lg-3 col-xl-4" />
        <div className="col-10 col-sm-10 col-md-6 col-lg-6 col-xl-4">
          <div className="card card-login shadow-big">
            <div className="w-100 p-5 d-flex align-items-center justify-content-center bg-primary card-login-top">
              <img
                className="card-img-top logo-login"
                src={LOGO}
                alt="Card image cap"
              />
            </div>
            <div className="card-body">
              <h3 className="card-title">
                <b>{global[this.language]["login"]}</b>
              </h3>
              <p className="card-text text-secondary">
                {global[this.language]["login_detail"]}
              </p>
              <label>
                <b>{global[this.language]["username"]}:</b>
              </label>
              <input
                type="text"
                placeholder={global[this.language]["username"]}
                className="form-control mb-3"
                value={this.state.username}
                onChange={(event) => {
                  this.setState({ username: event.target.value });
                }}
              />
              <label>
                <b>{global[this.language]["password"]}:</b>
              </label>
              <div className="text-right">
                <input
                  type={this.state.security}
                  placeholder={global[this.language]["password"]}
                  className="form-control"
                  value={this.state.password}
                  onChange={(event) => {
                    this.setState({ password: event.target.value });
                  }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && this.Login();
                  }}
                />
                <a
                  className="btn btn-link m-t-input"
                  onClick={() => {
                    this.state.security === "text"
                      ? this.setState({ security: "password" })
                      : this.setState({ security: "text" });
                  }}
                >
                  <b className="icon mr-1 text-primary">
                    {this.state.security === "text" ? "\uf06e" : "\uf070"}
                  </b>
                </a>
              </div>
              <button
                className="btn btn-primary w-100 mt-4"
                onClick={() => {
                  this.Login();
                }}
              >
                {global[this.language]["login"]}
              </button>
            </div>
          </div>
        </div>
        <div className="col-1 col-sm-1 col-md-3 col-lg-3 col-xl-4 h-auto minh-100 "></div>
        <div
          className="row p-3"
          style={{ position: "absolute", right: 0, top: 0 }}
        >
          <button
            className="btn btn-link d-flex border-r-0"
            style={{
              borderRightWidth: 1,
              borderRightColor: "#000",
              borderRadius: 0,
            }}
            onClick={() => {
              cookies.set("language", "TH", { path: "/" });
              window.location.href = "/";
            }}
          >
            TH
            <img className="ml-2" width={20} height={20} src={TH} alt="TH" />
          </button>
          <button
            className="btn btn-link d-flex"
            onClick={() => {
              cookies.set("language", "EN", { path: "/" });
              window.location.href = "/";
            }}
          >
            EN
            <img className="ml-2" width={20} height={20} src={EN} alt="TH" />
          </button>
        </div>
      </div>
    );
  }
}
