import "../../../bootstrap.css";
import React, { Component } from "react";
import SideMenu from "../../Content/SideMenu/SideMenu";
import SideMenuUser from "../../Content/SideMenuUser/SideMenuUser";
import Header from "../../Content/Header/Header";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import "@szhsin/react-menu/dist/index.css";
import jwt from "jsonwebtoken";

import { Menu, MenuItem } from "@szhsin/react-menu";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class LiveClassScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      page_count: 1,
      loading: true,
      modal_add_liveclass: false,
      modal_edit_liveclass: false,
      modal_detail_live: false,
      batch_name: "",
      zoom_api_key: "",
      zoom_api_secret: "",
      meeting_number: "",
      meeting_password: "",
      live_date: "",
      live_startime: "",
      live_endtime: "",
      teacher: "",
      student: [],
      search_teacher: "",
      search_student: "",
      menu_Teacher: false,
      menu_Student: false,
      detail: 0,
      data: [],
      sort: [0, 0, 0, 0, 0, 0],
      search: "",
      recordList: [],
      modalrecoard: false,
    };
  }
  language = cookies.get("language") ? cookies.get("language") : "TH";
  componentDidMount() {
    if (cookies.get("user")) {
      this.GetLiveClass();
    } else {
      Swal.fire({
        title: global[this.language]["alert"],
        text: global[this.language]["retrieve_data_fail"],
        icon: "warning",
        showCancelButton: false,
        allowOutsideClick: false,
        confirmButtonText: global[this.language]["ok"],
      }).then((result) => {
        if (result.isConfirmed) {
          cookies.remove("token", { path: "/" });
          cookies.remove("user", { path: "/" });

          window.location.href = "/";
        }
      });
    }
  }
  GetLiveClass = () => {
    this.setState({ loading: true });
    fetch(global.httpURL + "/api/liveclass/inactive/" + this.state.activePage, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (
          response.message === "Auth Failed" ||
          response.message === "Token is null"
        ) {
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["unavailable"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.Logout();
            }
          });
        } else if (response.message === "Connot find page") {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            data: response.data.live_class,
            loading: false,
            page_count: response.page_count,
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        Swal.fire({
          title: global[this.language]["alert"],
          text: global[this.language]["retrieve_data_fail"],
          icon: "warning",
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonText: global[this.language]["ok"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.Logout();
          }
        });
      });
  };
  Logout = () => {
    fetch(global.httpURL + "/api/logout", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "token " + cookies.get("token"),
      },
      // mode: "no-cors",
    })
      .then((response) => response.json())
      .then((response) => {
        cookies.remove("token", { path: "/" });
        cookies.remove("user", { path: "/" });

        window.location.href = "/";
      })
      .catch((err) => {
        //window.location.href = "/dashboard";
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_logout"],
          "warning"
        );
      });
  };
  Cancel = () => {
    this.setState({
      modal_add_liveclass: false,
      modal_edit_liveclass: false,
      modal_detail_live: false,
      modalrecoard: false,
      batch_name: "",
      zoom_api_key: "",
      zoom_api_secret: "",
      meeting_number: "",
      meeting_password: "",
      live_date: "",
      live_startime: "",
      live_endtime: "",
      liveclass_teacher: "",
      liveclass_student: [],
      recordList: [],
    });
  };
  Submit_add_liveclass = () => {
    Swal.fire(
      global[this.language]["alert"],
      global[this.language]["save_success"],
      "success"
    );
    this.Cancel();
  };
  Submit_edit_liveclass = () => {
    Swal.fire(
      global[this.language]["alert"],
      global[this.language]["save_success"],
      "success"
    );
    this.Cancel();
  };
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber, loading: true });
    if (this.state.search === "") {
      setTimeout(() => {
        this.GetLiveClass();
      }, 1);
    } else {
      setTimeout(() => {
        this.search();
      }, 1);
    }
  }
  handleClick(e) {
    if (e.target.id === "teacher-menu") {
      this.setState({
        menu_Teacher: true,
      });
    } else if (e.target.id === "student-menu") {
      this.setState({
        menu_Student: true,
      });
    } else {
      this.setState({
        menu_Teacher: false,
        menu_Student: false,
      });
    }
  }
  Sort = (key) => {
    let list = this.state.data;
    let sorted = this.state.sort;
    if (key === "subject") {
      if (sorted[0] === 0) {
        list.sort((a, b) => (a.batch_name < b.batch_name ? 1 : -1));
        sorted[0] = 1;
      } else {
        list.sort((a, b) => (a.batch_name > b.batch_name ? 1 : -1));
        sorted[0] = 0;
      }
    } else if (key === "teacher") {
      if (sorted[1] === 0) {
        list.sort((a, b) => (a.teacher.name < b.teacher.name ? 1 : -1));
        sorted[1] = 1;
      } else {
        list.sort((a, b) => (a.teacher.name > b.teacher.name ? 1 : -1));
        sorted[1] = 0;
      }
    } else if (key === "date") {
      if (sorted[2] === 0) {
        list.sort((a, b) => (a.live_date < b.live_date ? 1 : -1));
        sorted[2] = 1;
      } else {
        list.sort((a, b) => (a.live_date > b.live_date ? 1 : -1));
        sorted[2] = 0;
      }
    } else if (key === "time") {
      if (sorted[3] === 0) {
        list.sort((a, b) => (a.live_startime < b.live_startime ? 1 : -1));
        sorted[3] = 1;
      } else {
        list.sort((a, b) => (a.live_startime > b.live_startime ? 1 : -1));
        sorted[3] = 0;
      }
    } else if (key === "number") {
      if (sorted[4] === 0) {
        list.sort((a, b) => (a.meeting_number < b.meeting_number ? 1 : -1));
        sorted[4] = 1;
      } else {
        list.sort((a, b) => (a.meeting_number > b.meeting_number ? 1 : -1));
        sorted[4] = 0;
      }
    } else if (key === "password") {
      if (sorted[5] === 0) {
        list.sort((a, b) => (a.meeting_password < b.meeting_password ? 1 : -1));
        sorted[5] = 1;
      } else {
        list.sort((a, b) => (a.meeting_password > b.meeting_password ? 1 : -1));
        sorted[5] = 0;
      }
    }

    this.setState({ data: list, sort: sorted });
  };
  search = () => {
    fetch(global.httpURL + "/api/search/lc/inactive/page/1", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ data_search: this.state.search }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({ spinner: false });
        if (
          response.message === "Auth Failed" ||
          response.message === "Token is null"
        ) {
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["unavailable"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.Logout();
            }
          });
        } else if (
          response.message === "Connot find page" ||
          response.message === "No information found"
        ) {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            data: response.data.live_class,
            loading: false,
            page_count: response.page_count,
          });
        }
      })
      .catch((err) => {
        this.setState({ spinner: false });
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_logout"],
          "warning"
        );
      });
  };
  GetRecords = (meetingid, zoom_api_key, zoom_api_secret, option, date) => {
    const payload = {
      iss: zoom_api_key,
      exp: new Date().getTime() + 15000,
    };
    const token = jwt.sign(payload, zoom_api_secret);
    const from =
      date.split("/")[2] + "-" + date.split("/")[1] + "-" + date.split("/")[0];
    const to_date = new Date(
      date.split("/")[2],
      date.split("/")[1],
      date.split("/")[0]
    );
    to_date.setDate(to_date.getDate() + 1);
    const to =
      to_date.getFullYear() +
      "-" +
      ("00" + to_date.getMonth()).slice(-2) +
      "-" +
      ("00" + to_date.getDate()).slice(-2);

    fetch(
      global.httpURL +
        "/api/liveclass/record/" +
        meetingid +
        "/" +
        token +
        "/" +
        from +
        "/" +
        to,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + cookies.get("token"),
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response.data.meetings);
        let data = response.data.meetings;
        let array = [];
        data.find((element) => {
          if (element.id === Number(meetingid)) {
            array.push(element);
          }
        });
        console.log(array);
        let index = 0;
        array[0].recording_files.find((e, i) => {
          if (e.file_type === "MP4") {
            index = i;
          }
        });
        if (array.length === 1) {
          if (option === "play") {
            window.open(array[0].recording_files[index].play_url);
          } else {
            window.open(array[0].recording_files[index].download_url);
          }
        } else if (array.length > 1) {
          this.setState({ recordList: array, modalrecoard: true });
        } else {
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["no_video"],
            "warning"
          );
        }
      })
      .catch((err) => {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["no_video"],
          "warning"
        );
      });
  };
  render() {
    return (
      <div
        className="w-100 h-auto minh-100"
        onClick={this.handleClick.bind(this)}
      >
        <div className="d-flex h-auto minh-100">
          <div className="h-auto minh-100">
            {cookies.get("user") && (
              <>
                {cookies.get("user").user.status === "teacher" ||
                cookies.get("user").user.status === "student" ? (
                  <>
                    <SideMenuUser page="liveclasshistory" />
                  </>
                ) : (
                  <SideMenu page="liveclasshistory" />
                )}
              </>
            )}
          </div>
          <div className="w-100-body">
            <Header />
            <div className="container-fluid pt-3">
              {/* TABLE */}
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="card border-0 p-4">
                    <div className="flex-row justify-content-between align-items-center row p-4">
                      <div>
                        <h3>
                          <b>Live Class History</b>
                        </h3>
                      </div>
                      {/* BUTTON XL
                       */}
                      <div className="search-box-div ml-2">
                        <input
                          type="text"
                          className="search-box mt-2"
                          placeholder={
                            global[this.language]["course_name"] +
                            "," +
                            global[this.language]["teacher_name"]
                          }
                          value={this.state.search}
                          onChange={(event) => {
                            this.setState({
                              search: event.target.value,
                              activePage: 1,
                            });
                            setTimeout(() => {
                              if (this.state.search === "") {
                                this.search();
                              }
                            }, 1);
                          }}
                          onKeyUp={(event) => {
                            if (event.key === "Enter") {
                              this.search();
                            }
                          }}
                        />
                        <button
                          className="icon-absolute"
                          onClick={() => {
                            this.search();
                          }}
                        >
                          {"\uf002"}
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {!this.state.loading ? (
                        <table className="table table-borderless table-striped table-w-1 table-hover ">
                          <thead className="thead-dark">
                            <tr>
                              <th className="text-center">
                                {global[this.language]["order"]}
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("subject");
                                }}
                              >
                                {global[this.language]["course_name"]}
                              </th>
                              {cookies.get("user") &&
                                cookies.get("user").user.status !== "teacher" &&
                                cookies.get("user").user.status !==
                                  "student" && (
                                  <th
                                    className="point"
                                    onClick={() => {
                                      this.Sort("teacher");
                                    }}
                                  >
                                    {global[this.language]["teacher_name"]}
                                  </th>
                                )}
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("date");
                                }}
                              >
                                {global[this.language]["open_date"]}
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("time");
                                }}
                              >
                                {global[this.language]["open_time"]}
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("number");
                                }}
                              >
                                Meeting Number
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("password");
                                }}
                              >
                                Password
                              </th>
                              <th className="text-center">
                                {global[this.language]["all_info"]}
                              </th>
                              {cookies.get("user") &&
                                cookies.get("user").user.status !== "teacher" &&
                                cookies.get("user").user.status !==
                                  "student" && (
                                  <th className="text-center"></th>
                                )}
                            </tr>
                          </thead>
                          <tbody>
                            {/* DEPARTMENT */}
                            {this.state.data &&
                              this.state.data.map(
                                (liveclass, liveclass_index) => (
                                  <tr>
                                    <td className="text-center">
                                      {liveclass_index +
                                        1 +
                                        (this.state.activePage - 1) * 10}
                                    </td>
                                    <td>{liveclass.batch_name}</td>
                                    {cookies.get("user") &&
                                      cookies.get("user").user.status !==
                                        "teacher" &&
                                      cookies.get("user").user.status !==
                                        "student" && (
                                        <td>{liveclass.teacher.user.name}</td>
                                      )}
                                    <td>{liveclass.live_date}</td>
                                    <td>
                                      {liveclass.live_startime} -{" "}
                                      {liveclass.live_endtime}
                                    </td>
                                    <td>{liveclass.meeting_number}</td>
                                    <td>{liveclass.meeting_password}</td>
                                    <td className="text-center">
                                      <button
                                        className="btn btn-link pt-0 pb-0"
                                        onClick={() => {
                                          this.setState({
                                            modal_detail_live: true,
                                            detail: liveclass_index,
                                          });
                                        }}
                                      >
                                        {global[this.language]["info"]}
                                      </button>
                                    </td>
                                    {cookies.get("user") &&
                                      cookies.get("user").user.status !==
                                        "teacher" &&
                                      cookies.get("user").user.status !==
                                        "student" && (
                                        <td className="text-center">
                                          {/* <label className="icon">{"\uf304"}</label> */}
                                          {/* {global[this.language]["end_of_live"]} */}
                                          <Menu
                                            menuButton={
                                              <button className="btn btn-link mr-2 ml-2">
                                                <label className="icon">
                                                  {"\uf03d"}
                                                </label>
                                              </button>
                                            }
                                          >
                                            <MenuItem
                                              onClick={() => {
                                                this.GetRecords(
                                                  liveclass.meeting_number,
                                                  liveclass.zoom_api_key,
                                                  liveclass.zoom_api_secret,
                                                  "play",
                                                  liveclass.live_date
                                                );
                                              }}
                                            >
                                              <label className="icon mr-1">
                                                {"\uf04b"}
                                              </label>{" "}
                                              {global[this.language]["see"]}
                                            </MenuItem>
                                            <MenuItem
                                              onClick={() => {
                                                this.GetRecords(
                                                  liveclass.meeting_number,
                                                  liveclass.zoom_api_key,
                                                  liveclass.zoom_api_secret,
                                                  "download",
                                                  liveclass.live_date
                                                );
                                              }}
                                            >
                                              <label className="icon mr-1">
                                                {"\uf0ed"}
                                              </label>
                                              {
                                                global[this.language][
                                                  "download"
                                                ]
                                              }
                                            </MenuItem>
                                          </Menu>
                                        </td>
                                      )}
                                  </tr>
                                )
                              )}
                            {this.state.data && this.state.data.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={9}>
                                  {global[this.language]["no_data"]}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <div className="w-100 d-flex justify-content-center">
                          <div className="loader" />
                        </div>
                      )}
                    </div>
                    <div className="d-flex flex-row-reverse mt-3">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.page_count * 10}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        prevPageText="Previous"
                        nextPageText="Next"
                        hideFirstLastPages={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* MODAL */}
              {/* ดูรายละเอียด live */}
              <Modal
                show={this.state.modal_detail_live}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["liveclass_detail"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["course_name"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].batch_name
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["live_date"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].live_date
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["start_time"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].live_startime
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["end_time"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].live_endtime
                        }
                        disabled={true}
                      />
                    </div>
                    {/* 
                    <div className="col-4 pb-3">
                      <label>Zoom API Key:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["Type_the_course_name"]}
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].zoom_api_key
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>Zoom API Secret:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["Type_the_course_name"]}
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].zoom_api_secret
                        }
                        disabled={true}
                      />
                    </div> */}
                    <div className="col-4 pb-3">
                      <label>Meeting Number:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].meeting_number
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>Password:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].meeting_password
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["teacher_name"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].teacher &&
                          this.state.data[this.state.detail].teacher.user.name
                        }
                        disabled={true}
                      />
                    </div>
                    {cookies.get("user") &&
                      cookies.get("user").user.status !== "student" && (
                        <>
                          <div className="col-4 pb-3">
                            <label>{global[this.language]["students"]}:</label>
                          </div>

                          <div className="col-8 pb-3">
                            <div
                              className="form-control-style"
                              onClick={this.handleClick.bind(this)}
                              id="student-menu"
                            >
                              {this.state.data &&
                              this.state.data[this.state.detail] &&
                              !this.state.data[this.state.detail].student ? (
                                <p className={"text-secondary pb-0"}>
                                  {global[this.language]["no_student"]}
                                </p>
                              ) : (
                                <div className="row">
                                  {this.state.data &&
                                    this.state.data[this.state.detail] &&
                                    this.state.data[this.state.detail]
                                      .student &&
                                    this.state.data[
                                      this.state.detail
                                    ].student.map((item, index) => (
                                      <div className="selected-box bg-primary text-white m-1">
                                        <label>{item.nickname}</label>
                                      </div>
                                    ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    <div className="col-6 pb-3" />
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["close"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* Record */}
              <Modal
                show={this.state.modalrecoard}
                size="lg"
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["video_detail"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-12">
                      <table className="table table-borderless table-striped table-hover ">
                        <thead className="thead-dark">
                          <tr>
                            <th className="text-center">
                              {global[this.language]["order"]}
                            </th>
                            <th>{global[this.language]["topic"]}</th>
                            <th>Meeting Number</th>
                            <th>{global[this.language]["start_time"]}</th>
                            <th>{global[this.language]["file_size"]}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* DEPARTMENT */}
                          {this.state.recordList &&
                            this.state.recordList.map((record, index) => (
                              <tr>
                                <td className="text-center">{index + 1}</td>
                                <td>{record.topic}</td>

                                <td>{record.id}</td>
                                <td>
                                  {(
                                    "00" + record.start_time.slice(9, 10)
                                  ).slice(-2) +
                                    "/" +
                                    (
                                      "00" + record.start_time.slice(6, 7)
                                    ).slice(-2) +
                                    "/" +
                                    record.start_time.slice(0, 4) +
                                    "  " +
                                    (
                                      "00" +
                                      (Number(record.start_time.slice(12, 13)) +
                                        7)
                                    ).slice(-2) +
                                    ":" +
                                    (
                                      "00" + record.start_time.slice(15, 16)
                                    ).slice(-2) +
                                    ":" +
                                    (
                                      "00" + record.start_time.slice(18, 19)
                                    ).slice(-2)}
                                </td>
                                <td>
                                  {(record.total_size / (1000 * 1000)).toFixed(
                                    2
                                  )}{" "}
                                  MB
                                </td>

                                <td className="text-center">
                                  <Menu
                                    menuButton={
                                      <button className="btn btn-link mr-2 ml-2">
                                        <label className="icon">
                                          {"\uf03d"}
                                        </label>
                                      </button>
                                    }
                                  >
                                    <MenuItem
                                      onClick={() => {
                                        let index = 0;
                                        record.recording_files.find((e, i) => {
                                          if (e.file_type === "MP4") {
                                            index = i;
                                          }
                                        });
                                        window.open(
                                          record.recording_files[index].play_url
                                        );
                                      }}
                                    >
                                      <label className="icon mr-1">
                                        {"\uf04b"}
                                      </label>{" "}
                                      {global[this.language]["see"]}
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        let index = 0;
                                        record.recording_files.find((e, i) => {
                                          if (e.file_type === "MP4") {
                                            index = i;
                                          }
                                        });
                                        window.open(
                                          record.recording_files[index]
                                            .download_url
                                        );
                                      }}
                                    >
                                      <label className="icon mr-1">
                                        {"\uf0ed"}
                                      </label>
                                      {global[this.language]["download"]}
                                    </MenuItem>
                                  </Menu>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-6 pb-3" />
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["close"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
