import "../../bootstrap.css";
import "./style.css";
import React, { Component } from "react";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { Link } from "react-router";
import ninja from "./ninja.png";

import axios from "axios";
const cookies = new Cookies();
export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <div style={{ background: "#E54045" }} className="py-4">
          <div className="container text-center">
            <h1 style={{ fontSize: 29, fontWeight: "bold", color: "#FFF" }}>
              วิธีฝากสั่งซื้อสินค้า
            </h1>
          </div>
        </div>
        <div className="container text-center pt-5 text-center">
          <img
            width="192"
            height="410"
            src="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/MB_Walkthrought_Register_1.png"
            class="attachment-full size-full mx-auto mt-5"
            alt=""
            sizes="(max-width: 192px) 100vw, 192px"
          />
          <label className="mt-4" style={{ fontSize: 20, fontWeight: "bold" }}>
            1. สมัครสมาชิกกับ Ninja Cargo
          </label>
          <img
            width="192"
            height="410"
            src="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/MB_Walkthrought_Login_1.png"
            class="attachment-full size-full mx-auto mt-5"
            alt=""
            sizes="(max-width: 192px) 100vw, 192px"
          />
          <label className="mt-4" style={{ fontSize: 20, fontWeight: "bold" }}>
            2. เข้าสู่ระบบ
          </label>
          <img
            width="192"
            height="410"
            src={ninja}
            class="attachment-full size-full mx-auto mt-5"
            alt=""
            sizes="(max-width: 192px) 100vw, 192px"
          />
          <label className="mt-4" style={{ fontSize: 20, fontWeight: "bold" }}>
            3. ทำรายการบนเว็บไซต์ของทางเรา
          </label>
        </div>
      </div>
    );
  }
}
