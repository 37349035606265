import logo from "./logo.svg";
import "./style.css";
import "../../../bootstrap.css";
import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class AppScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return <div className="full-screen center bg-secondary"></div>;
  }
}
