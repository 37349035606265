import "../../../bootstrap.css";
import React, { Component } from "react";
import SideMenu from "../../Content/SideMenu/SideMenu";
import Header from "../../Content/Header/Header";

import Pagination from "react-js-pagination";

import Swal from "sweetalert2";

import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      page_count: 1,
      search: "",
      data: [],
      status: "",
    };
  }
  language = cookies.get("language") ? cookies.get("language") : "TH";
  componentDidMount() {
    if (cookies.get("user")) {
      this.GetHistory();
    } else {
      Swal.fire({
        title: global[this.language]["alert"],
        text: global[this.language]["retrieve_data_fail"],
        icon: "warning",
        showCancelButton: false,
        allowOutsideClick: false,
        confirmButtonText: global[this.language]["ok"],
      }).then((result) => {
        if (result.isConfirmed) {
          cookies.remove("token", { path: "/" });
          cookies.remove("user", { path: "/" });

          window.location.href = "/";
        }
      });
    }
  }
  GetHistory = () => {
    this.setState({ loading: true });
    fetch(global.httpURL + "/api/history/filter/" + this.state.activePage, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ status: this.state.status }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        if (
          response.message === "Auth Failed" ||
          response.message === "Token is null" ||
          response.message === "Token is not found"
        ) {
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["unavailable"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.Logout();
            }
          });
        } else if (response.message === "Connot find page") {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            data: response.data,
            loading: false,
            page_count: response.page_count,
          });
        }
      })
      .catch((err) => {
        console.error("ROLE::", err);
        this.setState({ loading: false });
        Swal.fire({
          title: global[this.language]["alert"],
          text: global[this.language]["retrieve_data_fail"],
          icon: "warning",
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonText: global[this.language]["ok"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.Logout();
          }
        });
      });
  };
  Cancel = () => {};
  Submit_add_subject = () => {};
  Delete_History = () => {
    Swal.fire(
      global[this.language]["alert"],
      global[this.language]["delete_success"],
      "success"
    );
  };
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    if (this.state.search === "") {
      setTimeout(() => {
        this.GetHistory();
      }, 1);
    } else {
      setTimeout(() => {
        this.search();
      }, 1);
    }
  }
  search = () => {
    fetch(global.httpURL + "/api/search/ht/page/1", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ data_search: this.state.search }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({ spinner: false });
        if (
          response.message === "Auth Failed" ||
          response.message === "Token is null"
        ) {
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["unavailable"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.Logout();
            }
          });
        } else if (
          response.message === "Connot find page" ||
          response.message === "No information found"
        ) {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            data: response.data,
            loading: false,
            page_count: response.page_count,
          });
        }
      })
      .catch((err) => {
        this.setState({ spinner: false });
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_logout"],
          "warning"
        );
      });
  };
  render() {
    return (
      <div className="w-100 h-auto minh-100">
        <div className="d-flex h-auto minh-100">
          <div className="h-auto minh-100">
            <SideMenu page="history" />
          </div>
          <div className="w-100-body">
            <Header />
            <div className="container-fluid pt-3">
              {/* TABLE */}
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="card border-0 p-4">
                    <div className="flex-row justify-content-between align-items-center row p-4">
                      <div>
                        <h3>
                          <b>{global[this.language]["history"]}</b>
                        </h3>
                      </div>
                      {/* BUTTON XL */}
                      <div className="text-right d-none d-xl-block">
                        <select
                          id="add_student_gender"
                          className="btn btn-outline-secondary text-primary mr-2"
                          value={this.state.status}
                          onChange={(event) => {
                            this.setState({
                              status: event.target.value,
                              activePage: 1,
                            });
                            setTimeout(() => {
                              this.GetHistory();
                            }, 1);
                          }}
                        >
                          <option value="">
                            {global[this.language]["all"]}
                          </option>
                          <option value="admin">
                            {global[this.language]["admin"]}
                          </option>
                          <option value="student">
                            {global[this.language]["students"]}
                          </option>
                          <option value="teacher">
                            {global[this.language]["teacher_name"]}
                          </option>
                        </select>
                        {/* <button className="btn btn-outline-secondary ml-2 mr-2 text-primary">
                          <label className="icon mr-1">{"\uf56d"}</label>
                          Export Report
                        </button> */}
                        <div className="search-box-div mr-2 ml-2">
                          <input
                            type="text"
                            className="search-box"
                            placeholder={global[this.language]["search"]}
                            value={this.state.search}
                            onChange={(event) => {
                              this.setState({
                                search: event.target.value,
                                activePage: 1,
                              });
                              setTimeout(() => {
                                if (this.state.search === "") {
                                  this.search();
                                }
                              }, 1);
                            }}
                            onKeyUp={(event) => {
                              console.log(event.key);
                              if (event.key === "Enter") {
                                this.search();
                              }
                            }}
                          />
                          <button
                            className="icon-absolute"
                            onClick={() => {
                              this.search();
                            }}
                          >
                            {"\uf002"}
                          </button>
                        </div>
                      </div>
                      {/* BUTTON NOT XL */}
                      <div className="text-right d-block d-xl-none flex-row justify-content-between align-items-center">
                        <div className="search-box-div mr-2 ml-2">
                          <select
                            id="add_student_gender"
                            className="btn btn-outline-secondary text-primary mr-2 mt-2"
                            value={this.state.status}
                            onChange={(event) => {
                              this.setState({
                                status: event.target.value,
                                activePage: 1,
                              });
                              setTimeout(() => {
                                this.GetHistory();
                              }, 1);
                            }}
                          >
                            <option value="">
                              {global[this.language]["all"]}
                            </option>
                            <option value="admin">
                              {global[this.language]["admin"]}
                            </option>
                            <option value="student">
                              {global[this.language]["students"]}
                            </option>
                            <option value="teacher">
                              {global[this.language]["teacher_name"]}
                            </option>
                          </select>
                          <input
                            type="text"
                            className="search-box mt-2"
                            placeholder={global[this.language]["search"]}
                            value={this.state.search}
                            onChange={(event) => {
                              this.setState({
                                search: event.target.value,
                                activePage: 1,
                              });
                              setTimeout(() => {
                                if (this.state.search === "") {
                                  this.search();
                                }
                              }, 1);
                            }}
                            onKeyUp={(event) => {
                              console.log(event.key);
                              if (event.key === "Enter") {
                                this.search();
                              }
                            }}
                          />
                          <button
                            className="icon-absolute"
                            onClick={() => {
                              this.search();
                            }}
                          >
                            {"\uf002"}
                          </button>
                        </div>
                        {/* <Menu
                          menuButton={
                            <button className="btn btn-primary mr-2 ml-2">
                              <label className="icon">{"\uf0c9"}</label>
                            </button>
                          }
                        >
                          <MenuItem>
                            <label className="icon mr-1">{"\uf56d"}</label>
                            Export Report
                          </MenuItem>
                        </Menu> */}
                      </div>
                    </div>
                    <div className="table-responsive">
                      {!this.state.loading ? (
                        <table className="table table-borderless table-striped table-w-9 table-hover ">
                          <thead className="thead-dark">
                            <tr>
                              <th className="text-center">
                                {global[this.language]["order"]}
                              </th>
                              <th>{global[this.language]["teacher_id"]}</th>
                              <th>{global[this.language]["fullname"]}</th>
                              <th>{global[this.language]["date_history"]}</th>
                              <th>{global[this.language]["time_history"]}</th>
                              <th className="text-center">
                                {global[this.language]["ipaddress"]}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* DEPARTMENT */}
                            {this.state.data &&
                              this.state.data.map((history, history_index) => (
                                <tr>
                                  <td className="text-center">
                                    {history_index +
                                      1 +
                                      (this.state.activePage - 1) * 10}
                                  </td>
                                  <td>{history.user_code}</td>
                                  <td>{history.name}</td>
                                  <td>{history.date_login}</td>

                                  <td>{history.time_login}</td>
                                  <td className="text-center">
                                    {history.ip_address}
                                  </td>
                                </tr>
                              ))}
                            {this.state.data && this.state.data.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={6}>
                                  {global[this.language]["no_data"]}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <div className="w-100 d-flex justify-content-center">
                          <div className="loader" />
                        </div>
                      )}
                    </div>
                    <div className="d-flex flex-row-reverse mt-3">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.page_count * 10}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        prevPageText="Previous"
                        nextPageText="Next"
                        hideFirstLastPages={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* MODAL */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
