import "../../../bootstrap.css";
import React, { Component } from "react";
import SideMenu from "../../Content/SideMenu/SideMenu";
import Header from "../../Content/Header/Header";
import Days from "../../Content/Days/Days";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      page_count: 1,
      copy: false,
      data: [],
      loading: true,
      search: "",
      teacher_name: "",
      teacher_nickname: "",
      teacher_phone: "",
      teacher_gender: "",
      teacher_idcard: "",
      teacher_type: "",
      teacher_color: "",
      teacher_tax: "",
      teacher_salary: "",
      teacher_facebook: "",
      teacher_line: "",
      teacher_address: "",
      teacher_province: "",
      teacher_subdistrict: "",
      teacher_district: "",
      teacher_zipcode: "",
      teacher_apikey: "",
      teacher_apisecret: "",
      teacher_username: "",
      teacher_password: "",
      teacher_apikey_edit: false,
      teacher_apisecret_edit: false,
      modal_add_teacher_1: false,
      modal_add_teacher_2: false,
      modal_detail: false,
      modal_edit_teacher: false,
      modal_add_teacher_userpass: false,
      step: 0,
      days: [
        { day: "จันทร์", select: false, time: "" },
        { day: "อังคาร", select: true, time: "" },
        { day: "พุธ", select: true, time: "" },
        { day: "พฤหัสบดี", select: false, time: "" },
        { day: "ศุกร์", select: false, time: "" },
        { day: "เสาร์", select: true, time: "" },
        { day: "อาทิตย์", select: false, time: "" },
      ],
      sort: [0, 0, 0],
      spinner: false,
      province: [],
      district: [],
      sub_district: [],
      zipcode: [],
    };
  }
  language = cookies.get("language") ? cookies.get("language") : "TH";
  componentDidMount() {
    if (cookies.get("user")) {
      if (cookies.get("token")) {
        this.GetTeacher();
        this.GetProvince();
      } else {
        Swal.fire({
          title: global[this.language]["alert"],
          text: global[this.language]["retrieve_data_fail"],
          icon: "warning",
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonText: global[this.language]["ok"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.Logout();
          }
        });
      }
    } else {
      Swal.fire({
        title: global[this.language]["alert"],
        text: global[this.language]["retrieve_data_fail"],
        icon: "warning",
        showCancelButton: false,
        allowOutsideClick: false,
        confirmButtonText: global[this.language]["ok"],
      }).then((result) => {
        if (result.isConfirmed) {
          cookies.remove("token", { path: "/" });
          cookies.remove("user", { path: "/" });

          window.location.href = "/";
        }
      });
    }
  }
  Logout = () => {
    fetch(global.httpURL + "/api/logout", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "token " + cookies.get("token"),
      },
      // mode: "no-cors",
    })
      .then((response) => response.json())
      .then((response) => {
        cookies.remove("token", { path: "/" });
        cookies.remove("user", { path: "/" });

        window.location.href = "/";
      })
      .catch((err) => {
        //window.location.href = "/dashboard";
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_logout"],
          "warning"
        );
      });
  };
  Cancel = () => {
    this.setState({
      teacher_name: "",
      teacher_nickname: "",
      teacher_phone: "",
      teacher_gender: "",
      teacher_idcard: "",
      teacher_type: "",
      teacher_color: "",
      teacher_tax: "",
      teacher_salary: "",
      teacher_facebook: "",
      teacher_line: "",
      teacher_address: "",
      teacher_province: "",
      teacher_subdistrict: "",
      teacher_district: "",
      teacher_zipcode: "",
      teacher_apikey: "",
      teacher_apisecret: "",
      teacher_username: "",
      teacher_password: "",
      teacher_apikey_edit: false,
      teacher_apisecret_edit: false,
      modal_add_teacher_1: false,
      modal_add_teacher_2: false,
      modal_detail: false,
      modal_edit_teacher: false,
      modal_add_teacher_userpass: false,
      step: 0,
      district: [],
      sub_district: [],
      zipcode: [],
    });
  };
  GetTeacher = () => {
    this.setState({ loading: true });
    fetch(global.httpURL + "/api/teacher/page/" + this.state.activePage, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (
          response.message === "Auth Failed" ||
          response.message === "Token is null"
        ) {
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["unavailable"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.Logout();
            }
          });
        } else if (response.message === "Connot find page") {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            data: response.data,
            loading: false,
            page_count: response.page_count,
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        Swal.fire({
          title: global[this.language]["alert"],
          text: global[this.language]["retrieve_data_fail"],
          icon: "warning",
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonText: global[this.language]["ok"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.Logout();
          }
        });
      });
  };
  Submit_add_teacher = (step) => {
    if (step === 1) {
      this.setState({
        modal_add_teacher_1: true,
        modal_add_teacher_2: false,
        modal_add_teacher_userpass: false,
        step: 1,
      });
    }
    if (step === 2) {
      let num = /^^[0][0-9]*$/;
      let numid = /^[0-9]*$/;
      if (this.state.teacher_name === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_first_last_name"],
          "warning"
        );
      } else if (this.state.teacher_nickname === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_nickname"],
          "warning"
        );
      } else if (this.state.teacher_phone === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_phone_number"],
          "warning"
        );
      } else if (
        this.state.teacher_phone.trim().length < 10 ||
        !num.test(this.state.teacher_phone)
      ) {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_phone_true"],
          "warning"
        );
      } else if (this.state.teacher_gender === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["select_gender"],
          "warning"
        );
      } else if (this.state.teacher_idcard === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_ID_card"],
          "warning"
        );
      } else if (
        this.state.teacher_idcard.length < 13 ||
        !numid.test(this.state.teacher_idcard)
      ) {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_ID_card_true"],
          "warning"
        );
      } else if (this.state.teacher_apikey === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_apikey"],
          "warning"
        );
      } else if (this.state.teacher_apisecret === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_apisecret"],
          "warning"
        );
      } else {
        this.setState({
          modal_add_teacher_1: false,
          modal_add_teacher_2: true,
          modal_add_teacher_userpass: false,
          step: 2,
        });
      }
    }
    if (step === "userpass") {
      if (this.state.teacher_address === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_address"],
          "warning"
        );
      } else if (this.state.teacher_province === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_province"],
          "warning"
        );
      } else if (this.state.teacher_district === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_district"],
          "warning"
        );
      } else if (this.state.teacher_subdistrict === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_subdistrict"],
          "warning"
        );
      } else if (this.state.teacher_zipcode === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_zipcode"],
          "warning"
        );
      } else {
        this.setState({
          modal_add_teacher_1: false,
          modal_add_teacher_2: false,
          modal_add_teacher_userpass: true,
          step: 2,
        });
      }
    }
    if (step === "success") {
      let num = /^^[0][0-9]*$/;
      let numid = /^[0-9]*$/;
      if (this.state.teacher_name === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_first_last_name"],
          "warning"
        );
      } else if (this.state.teacher_nickname === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_nickname"],
          "warning"
        );
      } else if (this.state.teacher_phone === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_phone_number"],
          "warning"
        );
      } else if (
        this.state.teacher_phone.trim().length < 10 ||
        !num.test(this.state.teacher_phone)
      ) {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_phone_true"],
          "warning"
        );
      } else if (this.state.teacher_gender === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["select_gender"],
          "warning"
        );
      } else if (this.state.teacher_idcard === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_ID_card"],
          "warning"
        );
      } else if (
        this.state.teacher_idcard.length < 13 ||
        !numid.test(this.state.teacher_idcard)
      ) {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_ID_card_true"],
          "warning"
        );
      } else if (this.state.teacher_apikey === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_apikey"],
          "warning"
        );
      } else if (this.state.teacher_apisecret === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_apisecret"],
          "warning"
        );
      } else if (this.state.teacher_address === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_address"],
          "warning"
        );
      } else if (this.state.teacher_province === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_province"],
          "warning"
        );
      } else if (this.state.teacher_district === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_district"],
          "warning"
        );
      } else if (this.state.teacher_subdistrict === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_subdistrict"],
          "warning"
        );
      } else if (this.state.teacher_zipcode === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_zipcode"],
          "warning"
        );
      } else if (this.state.teacher_username === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_username"],
          "warning"
        );
      } else if (this.state.teacher_password === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_password"],
          "warning"
        );
      } else {
        let district = !isNaN(this.state.teacher_district)
          ? this.state.district[this.state.teacher_district].DISTRICT_NAME
          : this.state.teacher_district;
        let subdistrict = !isNaN(this.state.teacher_subdistrict)
          ? this.state.sub_district[this.state.teacher_subdistrict]
              .SUB_DISTRICT_NAME
          : this.state.teacher_subdistrict;
        let province = !isNaN(this.state.teacher_province)
          ? this.state.province[this.state.teacher_province].PROVINCE_NAME
          : this.state.teacher_province;
        let zipcode =
          this.state.teacher_zipcode.length !== 5
            ? this.state.zipcode[this.state.teacher_zipcode].ZIPCODE
            : this.state.teacher_zipcode;
        this.setState({ spinner: true });
        fetch(global.httpURL + "/api/teacher", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
          body: JSON.stringify({
            name: this.state.teacher_name,
            nickname: this.state.teacher_nickname,
            phone: this.state.teacher_phone,
            gender: this.state.teacher_gender,
            id_card_number: this.state.teacher_idcard,
            facebook: this.state.teacher_facebook,
            line: this.state.teacher_line,
            address: this.state.teacher_address,
            district: district,
            sub_district: subdistrict,
            province: province,
            zip_code: zipcode,
            zoom_api_key: this.state.teacher_apikey,
            zoom_api_secret: this.state.teacher_apisecret,
            username: this.state.teacher_username,
            password: this.state.teacher_password,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            console.log(response);
            this.setState({ spinner: false });
            if (response.message === "This zoom api key is existed") {
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["zoom_api_key_error"],
                "warning"
              );
            } else if (response.message === "This zoom api secret is existed") {
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["zoom_api_secret_error"],
                "warning"
              );
            } else {
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["add_success"],
                "success"
              );
              this.Cancel();
              this.GetTeacher();
            }
          })
          .catch((err) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["add_not_success"],
              "warning"
            );
          });
      }
    }
  };
  Submit_edit_teacher = () => {
    let num = /^^[0][0-9]*$/;
    let numid = /^[0-9]*$/;
    if (this.state.teacher_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_first_last_name"],
        "warning"
      );
    } else if (this.state.teacher_nickname === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_nickname"],
        "warning"
      );
    } else if (this.state.teacher_phone === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_phone_number"],
        "warning"
      );
    } else if (
      this.state.teacher_phone.trim().length < 10 ||
      !num.test(this.state.teacher_phone)
    ) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_phone_true"],
        "warning"
      );
    } else if (this.state.teacher_gender === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["select_gender"],
        "warning"
      );
    } else if (this.state.teacher_idcard === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_ID_card"],
        "warning"
      );
    } else if (
      this.state.teacher_idcard.length < 13 ||
      !numid.test(this.state.teacher_idcard)
    ) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_ID_card_true"],
        "warning"
      );
    } else if (this.state.teacher_apikey === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_apikey"],
        "warning"
      );
    } else if (this.state.teacher_apisecret === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_apisecret"],
        "warning"
      );
    } else if (this.state.teacher_address === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_address"],
        "warning"
      );
    } else if (this.state.teacher_province === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_province"],
        "warning"
      );
    } else if (this.state.teacher_district === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_district"],
        "warning"
      );
    } else if (this.state.teacher_subdistrict === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_subdistrict"],
        "warning"
      );
    } else if (this.state.teacher_zipcode === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_zipcode"],
        "warning"
      );
    } else {
      this.setState({ spinner: true });
      let data = "";

      let district = !isNaN(this.state.teacher_district)
        ? this.state.district[this.state.teacher_district].DISTRICT_NAME
        : this.state.teacher_district;
      let subdistrict = !isNaN(this.state.teacher_subdistrict)
        ? this.state.sub_district[this.state.teacher_subdistrict]
            .SUB_DISTRICT_NAME
        : this.state.teacher_subdistrict;
      let province = !isNaN(this.state.teacher_province)
        ? this.state.province[this.state.teacher_province].PROVINCE_NAME
        : this.state.teacher_province;
      let zipcode =
        this.state.teacher_zipcode.length !== 5
          ? this.state.zipcode[this.state.teacher_zipcode].ZIPCODE
          : this.state.teacher_zipcode;
      if (this.state.teacher_password === "") {
        data = {
          name: this.state.teacher_name,
          nickname: this.state.teacher_nickname,
          phone: this.state.teacher_phone,
          gender: this.state.teacher_gender,
          id_card_number: this.state.teacher_idcard,
          facebook: this.state.teacher_facebook,
          line: this.state.teacher_line,
          address: this.state.teacher_address,
          district: district,
          sub_district: subdistrict,
          province: province,
          zip_code: zipcode,
          zoom_api_key: this.state.teacher_apikey,
          zoom_api_secret: this.state.teacher_apisecret,
          username: this.state.teacher_username,
        };
      } else {
        data = {
          name: this.state.teacher_name,
          nickname: this.state.teacher_nickname,
          phone: this.state.teacher_phone,
          gender: this.state.teacher_gender,
          id_card_number: this.state.teacher_idcard,
          facebook: this.state.teacher_facebook,
          line: this.state.teacher_line,
          address: this.state.teacher_address,
          district: district,
          sub_district: subdistrict,
          province: province,
          zip_code: zipcode,
          zoom_api_key: this.state.teacher_apikey,
          zoom_api_secret: this.state.teacher_apisecret,
          username: this.state.teacher_username,
          password: this.state.teacher_password,
        };
      }
      fetch(
        global.httpURL +
          "/api/teacher/" +
          this.state.data[this.state.detail]._id,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          this.setState({ spinner: false });
          if (response.message === "This zoom api key is existed") {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["zoom_api_key_error"],
              "warning"
            );
          } else if (response.message === "This zoom api secret is existed") {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["zoom_api_secret_error"],
              "warning"
            );
          } else {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["add_success"],
              "success"
            );
            this.Cancel();
            this.GetTeacher();
          }
        })
        .catch((err) => {
          this.setState({ spinner: false });
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["add_not_success"],
            "warning"
          );
        });
    }
  };
  Delete_teacher = (index) => {
    Swal.fire({
      title: global[this.language]["confirm_delete"],
      showCancelButton: true,
      confirmButtonText: global[this.language]["confirm"],
      cancelButtonText: global[this.language]["cancel"],
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ spinner: true });
        fetch(global.httpURL + "/api/teacher/" + this.state.data[index]._id, {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
        })
          .then((response) => response.json())
          .then((response) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_success"],
              "success"
            );
            this.GetTeacher();
          })
          .catch((err) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_not_success"],
              "warning"
            );
          });
      }
    });
  };
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber, loading: true });
    setTimeout(() => {
      this.GetTeacher();
    }, 1);
  }
  Sort = (key) => {
    let list = this.state.data;
    let sorted = this.state.sort;
    if (key === "id") {
      if (sorted[0] === 0) {
        list.sort((a, b) => (a.user.user_code < b.user.user_code ? 1 : -1));
        sorted[0] = 1;
      } else {
        list.sort((a, b) => (a.user.user_code > b.user.user_code ? 1 : -1));
        sorted[0] = 0;
      }
    } else if (key === "name") {
      if (sorted[1] === 0) {
        list.sort((a, b) => (a.teacher_name < b.teacher_name ? 1 : -1));
        sorted[1] = 1;
      } else {
        list.sort((a, b) => (a.teacher_name > b.teacher_name ? 1 : -1));
        sorted[1] = 0;
      }
    } else if (key === "phone") {
      if (sorted[2] === 0) {
        list.sort((a, b) => (a.user.phone < b.user.phone ? 1 : -1));
        sorted[2] = 1;
      } else {
        list.sort((a, b) => (a.user.phone > b.user.phone ? 1 : -1));
        sorted[2] = 0;
      }
    }

    this.setState({ data: list, sort: sorted });
  };
  GenUser = () => {
    let year = new Date().getFullYear() + 543;
    let month = new Date().getMonth() + 1;
    let day = new Date().getDate();
    let time =
      new Date().getHours() * 60 * 60 +
      new Date().getMinutes() * 60 +
      new Date().getSeconds();

    let gen = new Date().getTime();
    year = year.toString().slice(2, 4);
    if (month.toString().length === 1) {
      month = "0" + month;
    }
    if (day.toString().length === 1) {
      day = "0" + day;
    }
    gen = gen.toString().slice(4, 10);
    this.setState({
      teacher_username: "TC" + year + month + gen,
    });
  };
  GetProvince = () => {
    fetch(global.httpURL + "/api/address/provinces", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({
          province: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  GetDistrict = (index) => {
    var id = this.state.province[index].PROVINCE_ID;

    fetch(global.httpURL + "/api/address/districts", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ provinces_id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({
          district: response.data,
          teacher_district: "",
          teacher_subdistrict: "",
          teacher_zipcode: "",
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  GetSubDistrict = (index) => {
    var id = this.state.district[index].DISTRICT_ID;
    console.log(JSON.stringify({ districts_id: id }));
    fetch(global.httpURL + "/api/address/subDistricts", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ districts_id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({
          sub_district: response.data,
          teacher_subdistrict: "",
          teacher_zipcode: "",
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  GetZipcode = (index) => {
    var id = this.state.sub_district[index].SUB_DISTRICT_ID;

    fetch(global.httpURL + "/api/address/zipcodes", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ subDistricts_id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({
          zipcode: response.data,
          teacher_zipcode: "",
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  search = () => {
    fetch(global.httpURL + "/api/search/th/page/1", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({
        data_search: this.state.search,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({ spinner: false });
        if (
          response.message === "Auth Failed" ||
          response.message === "Token is null"
        ) {
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["unavailable"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.Logout();
            }
          });
        } else if (
          response.message === "Connot find page" ||
          response.message === "No information found"
        ) {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            data: response.data,
            loading: false,
            page_count: response.page_count,
          });
        }
      })
      .catch((err) => {
        this.setState({ spinner: false });
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_logout"],
          "warning"
        );
      });
  };
  render() {
    return (
      <div className="w-100 h-auto minh-100">
        {this.state.spinner && (
          <div className="spinner">
            <div className="loader" />
          </div>
        )}
        <div className="d-flex h-auto minh-100">
          <div className="h-auto minh-100">
            <SideMenu page="teacher" />
          </div>
          <div className="w-100-body">
            <Header />
            <div className="container-fluid pt-3">
              {/* TABLE */}
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="card border-0 p-4">
                    <div className="flex-row justify-content-between align-items-center row p-4">
                      <div>
                        <h3>
                          <b>{global[this.language]["teacher"]}</b>
                        </h3>
                      </div>
                      {/* BUTTON XL */}
                      <div className="text-right d-none d-xl-block">
                        {/* <select className="filter-box mr-2  ml-2">
                          <option>{global[this.language]["all_status"]}</option>
                          <option>ชำระแล้ว</option>
                          <option>รอชำระ</option>
                          <option>ยกเลิกแล้ว</option>
                        </select> */}
                        <div className="search-box-div mr-2 ml-2">
                          <input
                            type="text"
                            className="search-box"
                            placeholder={
                              global[this.language]["id"] +
                              "," +
                              global[this.language]["fullname"]
                            }
                            value={this.state.search}
                            onChange={(event) => {
                              this.setState({
                                search: event.target.value,
                                activePage: 1,
                              });
                              setTimeout(() => {
                                if (this.state.search === "") {
                                  this.search();
                                }
                              }, 1);
                            }}
                            onKeyUp={(event) => {
                              console.log(event.key);
                              if (event.key === "Enter") {
                                this.search();
                              }
                            }}
                          />
                          <button
                            className="icon-absolute"
                            onClick={() => {
                              this.search();
                            }}
                          >
                            {"\uf002"}
                          </button>
                        </div>
                        {/* <button className="btn btn-outline-secondary mr-2 ml-2 text-primary">
                          <label className="icon mr-1">{"\uf56d"}</label>
                          Export Report
                        </button> */}

                        <button
                          className="btn btn-primary mr-2 ml-2"
                          onClick={() => {
                            this.Cancel();
                            this.setState({ modal_add_teacher_1: true });
                            this.GenUser();
                          }}
                        >
                          <label className="icon mr-1">{"\uf055"}</label>
                          {global[this.language]["add_teacher"]}
                        </button>
                      </div>
                      {/* BUTTON NOT XL */}
                      <div className="text-right d-block d-xl-none flex-row justify-content-between align-items-center">
                        <div className="search-box-div mr-2 ml-2">
                          <input
                            type="text"
                            className="search-box mt-2"
                            placeholder={
                              global[this.language]["id"] +
                              "," +
                              global[this.language]["fullname"]
                            }
                            value={this.state.search}
                            onChange={(event) => {
                              this.setState({
                                search: event.target.value,
                                activePage: 1,
                              });
                              setTimeout(() => {
                                if (this.state.search === "") {
                                  this.search();
                                }
                              }, 1);
                            }}
                            onKeyUp={(event) => {
                              console.log(event.key);
                              if (event.key === "Enter") {
                                this.search();
                              }
                            }}
                          />
                          <button
                            className="icon-absolute"
                            onClick={() => {
                              this.search();
                            }}
                          >
                            {"\uf002"}
                          </button>
                        </div>
                        <Menu
                          menuButton={
                            <button className="btn btn-primary mr-2 ml-2 mt-2">
                              <label className="icon">{"\uf0c9"}</label>
                            </button>
                          }
                        >
                          {/* <MenuItem>
                            <label className="icon mr-1">{"\uf56d"}</label>
                            Export Report
                          </MenuItem> */}

                          <MenuItem
                            onClick={() => {
                              this.Cancel();
                              this.setState({ modal_add_teacher_1: true });
                              this.GenUser();
                            }}
                          >
                            <label className="icon mr-1">{"\uf055"}</label>
                            {global[this.language]["add_teacher"]}
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {!this.state.loading ? (
                        <table className="table table-borderless table-striped table-w-9 table-hover ">
                          <thead className="thead-dark">
                            <tr>
                              <th className="text-center">
                                {global[this.language]["order"]}
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("id");
                                }}
                              >
                                {global[this.language]["teacher_id"]}
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("name");
                                }}
                                style={{ minWidth: 200 }}
                              >
                                {global[this.language]["fullname"]}
                              </th>
                              <th
                                className="point text-center"
                                onClick={() => {
                                  this.Sort("phone");
                                }}
                              >
                                {global[this.language]["phone"]}
                              </th>
                              <th className="text-center">
                                {global[this.language]["all_info"]}
                              </th>
                              {/* <th className="text-center">อัตราค่าจ้าง</th>
                            <th className="text-center">สีประจำตัว</th> */}
                              {/* <th className="text-center">
                                {global[this.language]["register_to_teach"]}
                              </th> */}
                              <th className="text-center">
                                {global[this.language]["color"]}
                              </th>
                              <th className="text-center">
                                {global[this.language]["edit"]}
                              </th>
                              <th className="text-center">
                                {global[this.language]["delete"]}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* DEPARTMENT */}
                            {this.state.data &&
                              this.state.data.map((teacher, teacher_index) => (
                                <tr>
                                  <td className="text-center">
                                    {teacher_index +
                                      1 +
                                      (this.state.activePage - 1) * 10}
                                  </td>
                                  <td>{teacher.user.user_code}</td>
                                  <td>{teacher.user.name}</td>
                                  <td className="text-center">
                                    {teacher.user.phone}
                                  </td>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-link pt-0 pb-0"
                                      onClick={() => {
                                        this.setState({
                                          modal_detail: true,
                                          detail: teacher_index,
                                          teacher_name: teacher.user.name,
                                          teacher_nickname: teacher.nickname,
                                          teacher_phone: teacher.user.phone,
                                          teacher_gender: teacher.gender,
                                          teacher_idcard:
                                            teacher.id_card_number,

                                          teacher_facebook: teacher.facebook,
                                          teacher_line: teacher.line,
                                          teacher_address: teacher.address,
                                          teacher_province: teacher.province,
                                          teacher_subdistrict:
                                            teacher.sub_district,
                                          teacher_district: teacher.district,
                                          teacher_zipcode: teacher.zip_code,
                                          teacher_apikey: teacher.zoom_api_key,
                                          teacher_apisecret:
                                            teacher.zoom_api_secret,
                                          teacher_username:
                                            teacher.user.username,
                                        });
                                      }}
                                    >
                                      {global[this.language]["info"]}
                                    </button>
                                  </td>
                                  {/* <td>
                                  <div className="days-bar">
                                    <div
                                      className={
                                        teacher.teacher_type === "รายชั่วโมง"
                                          ? "toggle-left-active"
                                          : "toggle-left"
                                      }
                                    >
                                      รายชั่วโมง
                                    </div>
                                    <div
                                      className={
                                        teacher.teacher_type === "รายเดือน"
                                          ? "toggle-right-active"
                                          : "toggle-right"
                                      }
                                    >
                                      รายเดือน
                                    </div>
                                  </div>
                                </td>
                                <td></td> */}
                                  {/* <td className="text-center">
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary pt-1 pb-0"
                                    >
                                      <label className="icon mr-1">
                                        {"\uf5db"}
                                      </label>
                                      {
                                        global[this.language][
                                          "register_to_teach"
                                        ]
                                      }
                                    </button>
                                  </td> */}
                                  <td className="align-items-center justify-content-center">
                                    <div className="w-100 d-flex align-items-center justify-content-center">
                                      <div
                                        style={{
                                          width: 36,
                                          height: 30,
                                          backgroundColor: teacher.color,
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td className="text-center">
                                    <a
                                      className="btn btn-link p-0 d-flex align-items-center justify-content-center"
                                      onClick={() => {
                                        this.Cancel();
                                        this.setState({
                                          modal_edit_teacher: true,
                                          detail: teacher_index,
                                          teacher_name: teacher.user.name,
                                          teacher_nickname: teacher.nickname,
                                          teacher_phone: teacher.user.phone,
                                          teacher_gender: teacher.gender,
                                          teacher_idcard:
                                            teacher.id_card_number,
                                          teacher_facebook: teacher.facebook,
                                          teacher_line: teacher.line,
                                          teacher_address: teacher.address,
                                          teacher_province: teacher.province,
                                          teacher_district: teacher.district,
                                          teacher_subdistrict:
                                            teacher.sub_district,
                                          teacher_zipcode: teacher.zip_code,
                                          teacher_apikey: teacher.zoom_api_key,
                                          teacher_apisecret:
                                            teacher.zoom_api_secret,
                                          teacher_apikey_edit: false,
                                          teacher_apisecret_edit: false,
                                          teacher_username:
                                            teacher.user.username,
                                        });
                                      }}
                                    >
                                      <label className="icon">{"\uf304"}</label>
                                    </a>
                                  </td>

                                  <td className="text-center">
                                    <a
                                      className="btn btn-link p-0 d-flex align-items-center justify-content-center"
                                      onClick={() => {
                                        this.Delete_teacher(teacher_index);
                                      }}
                                    >
                                      <label className="icon">{"\uf2ed"}</label>
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            {this.state.data && this.state.data.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={8}>
                                  {global[this.language]["no_data"]}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <div className="w-100 d-flex justify-content-center">
                          <div className="loader" />
                        </div>
                      )}
                    </div>
                    <div className="d-flex flex-row-reverse mt-3">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.page_count * 10}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        prevPageText="Previous"
                        nextPageText="Next"
                        hideFirstLastPages={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* MODAL */}
              {/* เพิ่มข้อมูลอาจารย์ผู้สอน 1 */}
              <Modal
                size="lg"
                show={this.state.modal_add_teacher_1}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <div className="row">
                    <div
                      className="col-xl-6 col-lg-6 col-12 "
                      style={{
                        borderRightColor: "#DFDFDF",
                        borderRightWidth: 1,
                      }}
                    >
                      <h3 className="mb-3">
                        <b>{global[this.language]["add_teacher"]}</b>
                      </h3>
                      <div className="row">
                        <div className="col-6 pb-3">
                          <label>{global[this.language]["fullname"]}:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_name}
                            onChange={(event) => {
                              this.setState({
                                teacher_name: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-6 pb-3">
                          <label>{global[this.language]["nickname"]}:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_nickname}
                            onChange={(event) => {
                              this.setState({
                                teacher_nickname: event.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className="col-6 pb-3">
                          <label>{global[this.language]["phone"]}:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            maxLength={10}
                            value={this.state.teacher_phone}
                            onChange={(event) => {
                              this.setState({
                                teacher_phone: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-6 pb-3">
                          <label>{global[this.language]["gender"]}:</label>
                          <select
                            className="form-control"
                            value={this.state.teacher_gender}
                            onChange={(event) => {
                              this.setState({
                                teacher_gender: event.target.value,
                              });
                            }}
                          >
                            <option value="">
                              --{global[this.language]["selected_gender"]}
                              --
                            </option>
                            <option value="ชาย">
                              {global[this.language]["male"]}
                            </option>
                            <option value="หญิง">
                              {global[this.language]["female"]}
                            </option>
                            <option value="ไม่ระบุ">
                              {global[this.language]["other"]}
                            </option>
                          </select>
                        </div>
                        <div className="col-12 pb-3">
                          <label>{global[this.language]["ID_card"]}:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_idcard}
                            maxLength={13}
                            onChange={(event) => {
                              this.setState({
                                teacher_idcard: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-6 pb-3">
                          <label>Facebook:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_facebook}
                            onChange={(event) => {
                              this.setState({
                                teacher_facebook: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-6 pb-3">
                          <label>Line:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_line}
                            onChange={(event) => {
                              this.setState({
                                teacher_line: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div
                          style={{
                            width: "100%",
                            borderBottomColor: "#ccc",
                            borderBottomWidth: 1,
                            margin: 16,
                            marginTop: 20,
                            marginBottom: 30,
                          }}
                        />
                        <div className="col-12 pb-3 d-flex align-items-center">
                          <a
                            className="btn btn-link bg-zoom mr-2 text-white"
                            href="https://marketplace.zoom.us"
                            target="_blank"
                          >
                            <label className="icon mr-1">{"\uf03d"}</label>
                            ZOOM MARKETPLACE
                          </a>
                          <a
                            href="https://drive.google.com/file/d/1JBNK-34NoyiPABenovAMScas0rE-cHKs/view?usp=sharing"
                            target="_blank"
                          >
                            {global[this.language]["howto"]}
                            <label className="icon mr-1">{"\uf05a"}</label>
                          </a>
                        </div>
                        <div className="col-12 pb-3">
                          <label>Zoom API Key:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_apikey}
                            onChange={(event) => {
                              this.setState({
                                teacher_apikey: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-12 pb-3">
                          <label>Zoom API Secret:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_apisecret}
                            onChange={(event) => {
                              this.setState({
                                teacher_apisecret: event.target.value,
                              });
                            }}
                          />
                        </div>
                        {/* <div className="col-6 pb-3">
                      <label>ประเภทอัตราจ้าง:</label>
                      <div className="toggle-bar">
                        <button
                          onClick={() => {
                            this.setState({
                              teacher_type: "รายชั่วโมง",
                            });
                          }}
                          className={
                            this.state.teacher_type === "รายชั่วโมง"
                              ? "toggle-left-active"
                              : "toggle-left"
                          }
                        >
                          รายชั่วโมง
                        </button>
                        <button
                          onClick={() => {
                            this.setState({
                              teacher_type: "รายเดือน",
                            });
                          }}
                          className={
                            this.state.teacher_type === "รายเดือน"
                              ? "toggle-right-active"
                              : "toggle-right"
                          }
                        >
                          รายเดือน
                        </button>
                      </div>
                    </div>

                    <div className="col-6 pb-3">
                      <label>สีประจำตัว:</label>
                      <input
                        type="color"
                        className="form-control"
                        placeholder=""
                        value={this.state.teacher_color}
                        onChange={(event) => {
                          this.setState({
                            teacher_color: event.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="col-6 pb-3">
                      <label>เลขผู้เสียภาษี:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.teacher_tax}
                        onChange={(event) => {
                          this.setState({
                            teacher_tax: event.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>อัตราค่าจ้าง:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.teacher_salary}
                        onChange={(event) => {
                          this.setState({
                            teacher_salary: event.target.value,
                          });
                        }}
                      />
                    </div> */}

                        {/* <div className="col-6 pb-3">
                          <button
                            className="btn btn-secondary w-100"
                            onClick={() => {
                              this.Cancel();
                            }}
                          >
                            {global[this.language]["cancel"]}
                          </button>
                        </div> */}
                        {/* <div className="col-6 pb-3">
                          <button
                            className="btn btn-primary w-100"
                            onClick={() => {
                              this.Submit_add_teacher(2);
                            }}
                          >
                            {global[this.language]["continue"]}
                          </button>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-12 ">
                      <h3 className="mb-3 text-light">
                        <b>. </b>
                      </h3>
                      <div className="row">
                        <div className="col-12 pb-3">
                          <label>{global[this.language]["address"]}:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["address_hint"]}
                            value={this.state.teacher_address}
                            onChange={(event) => {
                              this.setState({
                                teacher_address: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-6 pb-3">
                          <label>{global[this.language]["province"]}:</label>
                          <select
                            className="form-control"
                            value={this.state.teacher_province}
                            onChange={(event) => {
                              this.setState({
                                teacher_province: event.target.value,
                              });
                              console.log(event.target.value);
                              this.GetDistrict(event.target.value);
                            }}
                          >
                            <option value="">
                              --{global[this.language]["select_province"]}
                              --
                            </option>
                            {this.state.province &&
                              this.state.province.map((item, index) => (
                                <option value={index}>
                                  {item.PROVINCE_NAME}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-6 pb-3">
                          <label>{global[this.language]["district"]}:</label>
                          <select
                            className="form-control"
                            value={this.state.teacher_district}
                            onChange={(event) => {
                              this.setState({
                                teacher_district: event.target.value,
                              });
                              this.GetSubDistrict(event.target.value);
                            }}
                          >
                            <option value="">
                              --{global[this.language]["select_district"]}
                              --
                            </option>
                            {this.state.district &&
                              this.state.district.map((item, index) => (
                                <option value={index}>
                                  {item.DISTRICT_NAME}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-6 pb-3">
                          <label>{global[this.language]["subdistrict"]}:</label>
                          <select
                            className="form-control"
                            value={this.state.teacher_subdistrict}
                            onChange={(event) => {
                              this.setState({
                                teacher_subdistrict: event.target.value,
                              });
                              this.GetZipcode(event.target.value);
                            }}
                          >
                            <option value="">
                              --{global[this.language]["select_subdistrict"]}
                              --
                            </option>
                            {this.state.sub_district &&
                              this.state.sub_district.map((item, index) => (
                                <option value={index}>
                                  {item.SUB_DISTRICT_NAME}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-6 pb-3">
                          <label>{global[this.language]["zipcode"]}:</label>
                          <select
                            className="form-control"
                            value={this.state.teacher_zipcode}
                            onChange={(event) => {
                              this.setState({
                                teacher_zipcode: event.target.value,
                              });
                            }}
                          >
                            <option value="">
                              --{global[this.language]["select_zipcode"]}
                              --
                            </option>
                            {this.state.zipcode &&
                              this.state.zipcode.map((item, index) => (
                                <option value={index}>{item.ZIPCODE}</option>
                              ))}
                          </select>
                        </div>

                        <div className="col-12 pb-3">
                          <label>{global[this.language]["username"]}:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_username}
                            readOnly
                            onChange={(event) => {
                              this.setState({
                                teacher_username: event.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className="col-12 pb-3">
                          <label>{global[this.language]["password"]}:</label>

                          <div
                            className="card w-100 bg-light p-4"
                            onClick={() => {
                              if (this.state.teacher_password === "") {
                                var text = "";
                                var possible =
                                  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                                for (var i = 0; i < 6; i++)
                                  text += possible.charAt(
                                    Math.floor(Math.random() * possible.length)
                                  );
                                this.setState({ teacher_password: text });
                                const selBox =
                                  document.createElement("textarea");
                                selBox.style.position = "fixed";
                                selBox.style.left = "0";
                                selBox.style.top = "0";
                                selBox.style.opacity = "0";
                                selBox.value = text;
                                document.body.appendChild(selBox);
                                selBox.focus();
                                selBox.select();
                                document.execCommand("copy");
                                document.body.removeChild(selBox);
                                var x = document.getElementById("snackbar");
                                x.className = "show";
                                setTimeout(function () {
                                  x.className = x.className.replace("show", "");
                                }, 3000);
                                // if (navigator.clipboard) {
                                //   navigator.clipboard.writeText(text);
                                //   this.setState({ copy: true });
                                // }
                                // setTimeout(() => {
                                //   this.setState({ copy: false });
                                // }, 2000);
                              }
                            }}
                          >
                            {this.state.teacher_password === "" ? (
                              <div
                                className="w-100 text-center"
                                style={{ cursor: "pointer" }}
                              >
                                <h4 className="header-password">
                                  <b className="text-primary">
                                    {global[this.language]["header_password"]}
                                  </b>
                                </h4>
                                {/* <small>
                              <label className="text-secondary">
                                {global[this.language]["detail_password"]}
                              </label>
                            </small> */}
                              </div>
                            ) : (
                              <div className="w-100 text-center">
                                <h4>{this.state.teacher_password}</h4>
                              </div>
                            )}
                          </div>
                        </div>

                        {/* <div className="col-6 pb-3">
                          <button
                            className="btn btn-secondary w-100"
                            onClick={() => {
                              this.Submit_add_teacher(2);
                            }}
                          >
                            {global[this.language]["back"]}
                          </button>
                        </div> */}
                        {/* 
                          <div className="col-6 pb-3">
                            <button
                              className="btn btn-primary w-100"
                              onClick={() => {
                                this.Submit_add_teacher("success");
                              }}
                            >
                              {global[this.language]["add"]}
                            </button>
                          </div>
                         */}
                      </div>
                    </div>
                    <div className="col-6 pb-3 mt-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3 mt-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_add_teacher("success");
                        }}
                      >
                        {global[this.language]["add"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* เพิ่มข้อมูลอาจารย์ผู้สอน 2 */}
              <Modal
                show={this.state.modal_add_teacher_2}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["add_teacher"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["address"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["address_hint"]}
                        value={this.state.teacher_address}
                        onChange={(event) => {
                          this.setState({
                            teacher_address: event.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["province"]}:</label>
                      <select
                        className="form-control"
                        value={this.state.teacher_province}
                        onChange={(event) => {
                          this.setState({
                            teacher_province: event.target.value,
                          });
                          console.log(event.target.value);
                          this.GetDistrict(event.target.value);
                        }}
                      >
                        <option value="">
                          --{global[this.language]["select_province"]}
                          --
                        </option>
                        {this.state.province &&
                          this.state.province.map((item, index) => (
                            <option value={index}>{item.PROVINCE_NAME}</option>
                          ))}
                      </select>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["district"]}:</label>
                      <select
                        className="form-control"
                        value={this.state.teacher_district}
                        onChange={(event) => {
                          this.setState({
                            teacher_district: event.target.value,
                          });
                          this.GetSubDistrict(event.target.value);
                        }}
                      >
                        <option value="">
                          --{global[this.language]["select_district"]}
                          --
                        </option>
                        {this.state.district &&
                          this.state.district.map((item, index) => (
                            <option value={index}>{item.DISTRICT_NAME}</option>
                          ))}
                      </select>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["subdistrict"]}:</label>
                      <select
                        className="form-control"
                        value={this.state.teacher_subdistrict}
                        onChange={(event) => {
                          this.setState({
                            teacher_subdistrict: event.target.value,
                          });
                          this.GetZipcode(event.target.value);
                        }}
                      >
                        <option value="">
                          --{global[this.language]["select_subdistrict"]}
                          --
                        </option>
                        {this.state.sub_district &&
                          this.state.sub_district.map((item, index) => (
                            <option value={index}>
                              {item.SUB_DISTRICT_NAME}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["zipcode"]}:</label>
                      <select
                        className="form-control"
                        value={this.state.teacher_zipcode}
                        onChange={(event) => {
                          this.setState({
                            teacher_zipcode: event.target.value,
                          });
                        }}
                      >
                        <option value="">
                          --{global[this.language]["select_zipcode"]}
                          --
                        </option>
                        {this.state.zipcode &&
                          this.state.zipcode.map((item, index) => (
                            <option value={index}>{item.ZIPCODE}</option>
                          ))}
                      </select>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Submit_add_teacher(1);
                        }}
                      >
                        {global[this.language]["back"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_add_teacher("userpass");
                        }}
                      >
                        {global[this.language]["continue"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* ดูข้อมูลทั้งหมด */}
              <Modal
                // size="lg"
                show={this.state.modal_detail}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <div className="row">
                    {/* <div className="col-6 p-3 right-solid"> */}
                    <div className="col-12 p-3 ">
                      <h3 className="mb-3">
                        <b>{global[this.language]["teacher_detail"]}</b>
                      </h3>

                      {/* ชื่อ-นามสกุล */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["fullname"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_name}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* ชื่อเล่น */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["nickname"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_nickname}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* หมายเลขโทรศัพท์ */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["phone"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            maxLength={10}
                            value={this.state.teacher_phone}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* เพศ */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["gender"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_gender}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* หมายเลขบัตรประชาชน */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["ID_card"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            maxLength={13}
                            value={this.state.teacher_idcard}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* Facebook */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>Facebook:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_facebook}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* หมายเลขบัตรประชาชน */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>Line:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_line}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* ประเภทอัตราจ้าง */}
                      {/* <div className="row">
                        <div className="col-4 pb-3">
                          <label>ประเภทอัตราจ้าง:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <div className="toggle-bar">
                            <button
                              onClick={() => {
                                this.setState({
                                  teacher_type: "รายชั่วโมง",
                                });
                              }}
                              className={
                                this.state.teacher_type === "รายชั่วโมง"
                                  ? "toggle-left-active"
                                  : "toggle-left"
                              }
                            >
                              รายชั่วโมง
                            </button>
                            <button
                              onClick={() => {
                                this.setState({
                                  teacher_type: "รายเดือน",
                                });
                              }}
                              className={
                                this.state.teacher_type === "รายเดือน"
                                  ? "toggle-right-active"
                                  : "toggle-right"
                              }
                            >
                              รายเดือน
                            </button>
                          </div>
                        </div>
                      </div> */}
                      {/* ค่าจ้าง */}
                      {/* <div className="row">
                        <div className="col-4 pb-3">
                          <label>ค่าจ้าง:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_id}
                            onChange={(event) => {
                              this.setState({ teacher_id: event.target.value });
                            }}
                          />
                        </div>
                      </div> */}
                      {/* สีประจำตัว */}
                      {/* <div className="row">
                        <div className="col-4 pb-3">
                          <label>สีประจำตัว:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="color"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_id}
                            onChange={(event) => {
                              this.setState({ teacher_id: event.target.value });
                            }}
                          />
                        </div>
                      </div> */}
                      {/* เลขที่ผู้เสียภาษี */}
                      {/* <div className="row">
                        <div className="col-4 pb-3">
                          <label>เลขที่ผู้เสียภาษี:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_id}
                            onChange={(event) => {
                              this.setState({ teacher_id: event.target.value });
                            }}
                          />
                        </div>
                      </div> */}
                      {/* ที่อยู่ปัจจุบัน */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["address"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_address}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* อำเภอ */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["subdistrict"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_subdistrict}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* อำเภอ */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["district"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_district}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* จังหวัด */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["province"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_province}
                            readOnly
                          />
                        </div>
                      </div>

                      {/* รหัสไปรษณีย์ */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["zipcode"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_zipcode}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* รายวิชาที่สอน */}
                      {/* <div className="row">
                        <div className="col-4 pb-3">
                          <label>รายวิชาที่สอน:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_id}
                            readOnly
                          />
                        </div>
                      </div> */}
                      {/* Zoom API Key */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>Zoom API Key:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_apikey}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* Zoom API Secret */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>Zoom API Secret:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_apisecret}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* Username */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["username"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_username}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-6 p-3">
                      <h3 className="mb-3">
                        <b>{global[this.language]["subject_detail"]}</b>
                      </h3>
                      <dav className="card border-0 bg-light p-3">
                        <div className="d-flex justify-content-between align-items-center pt-2">
                          <label>จำนวนการสอน (ชั่วโมง)</label>
                          <label className="text-secondary">40</label>
                        </div>
                        <div className="d-flex justify-content-between align-items-center top-dash pt-3">
                          <label>ค่าจ้างทั้งหมดเดือนนี้ (เมษายน)</label>
                          <h4>
                            <b className="text-primary">1500.-</b>
                          </h4>
                        </div>
                        <div className="d-flex justify-content-between align-items-center pt-2">
                          <label>ค่าจ้างทั้งหมดปีนี้ (2563)</label>
                          <h4>
                            <b className="text-primary">7500.-</b>
                          </h4>
                        </div>
                      </dav>
                      <div className="col-12 p-3">
                        <div className="top-dash " />
                      </div>
                      <dav className="card border-0 bg-light p-3">
                        <div className="d-flex justify-content-between align-items-start top-dash pt-3">
                          <div>
                            <label>ภาษาจีนพื้นฐาน</label>
                            <Days day={this.state.days} />
                          </div>
                          <button
                            className="btn btn-primary text-light pt-0 pb-0"
                            onClick={() => {}}
                          >
                            <small>กำลังเรียน</small>
                          </button>
                        </div>
                      </dav>
                    </div> */}
                  </div>
                  <div className="row">
                    {/* <div className="col-6 right-solid"></div> */}
                    <div className="col-12 ">
                      <div className="row">
                        <div className="col-6 pb-3"></div>
                        <div className="col-6 pb-3">
                          <button
                            className="btn btn-primary w-100"
                            onClick={() => {
                              this.setState({ modal_detail: false });
                            }}
                          >
                            {global[this.language]["close"]}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* แก้ไขข้อมูลอาจารย์ทั้งหมด */}
              <Modal
                show={this.state.modal_edit_teacher}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <div className="row">
                    <div className="col-12 p-3 ">
                      <h3 className="mb-3">
                        <b>{global[this.language]["teacher_edit"]}</b>
                      </h3>

                      {/* ชื่อ-นามสกุล */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["fullname"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_name}
                            onChange={(event) => {
                              this.setState({
                                teacher_name: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* ชื่อเล่น */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["nickname"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_nickname}
                            onChange={(event) => {
                              this.setState({
                                teacher_nickname: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* หมายเลขโทรศัพท์ */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["phone"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            maxLength={10}
                            value={this.state.teacher_phone}
                            onChange={(event) => {
                              this.setState({
                                teacher_phone: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* เพศ */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["gender"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <select
                            className="form-control"
                            value={this.state.teacher_gender}
                            onChange={(event) => {
                              this.setState({
                                teacher_gender: event.target.value,
                              });
                            }}
                          >
                            <option value="">
                              --
                              {global[this.language]["selected_gender"]}
                              --
                            </option>
                            <option value="ชาย">
                              {global[this.language]["male"]}
                            </option>
                            <option value="หญิง">
                              {global[this.language]["female"]}
                            </option>
                            <option value="ไม่ระบุ">
                              {global[this.language]["other"]}
                            </option>
                          </select>
                        </div>
                      </div>
                      {/* หมายเลขบัตรประชาชน */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["ID_card"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_idcard}
                            maxLength={13}
                            onChange={(event) => {
                              this.setState({
                                teacher_idcard: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* facebook */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>Facebook:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_facebook}
                            onChange={(event) => {
                              this.setState({
                                teacher_facebook: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* Line */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>Line:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_line}
                            onChange={(event) => {
                              this.setState({
                                teacher_line: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* ประเภทอัตราจ้าง */}
                      {/* <div className="row">
                        <div className="col-4 pb-3">
                          <label>ประเภทอัตราจ้าง:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <div className="toggle-bar">
                            <button
                              onClick={() => {
                                this.setState({
                                  teacher_type: "รายชั่วโมง",
                                });
                              }}
                              className={
                                this.state.teacher_type === "รายชั่วโมง"
                                  ? "toggle-left-active"
                                  : "toggle-left"
                              }
                            >
                              รายชั่วโมง
                            </button>
                            <button
                              onClick={() => {
                                this.setState({
                                  teacher_type: "รายเดือน",
                                });
                              }}
                              className={
                                this.state.teacher_type === "รายเดือน"
                                  ? "toggle-right-active"
                                  : "toggle-right"
                              }
                            >
                              รายเดือน
                            </button>
                          </div>
                        </div>
                      </div> */}
                      {/* ค่าจ้าง */}
                      {/* <div className="row">
                        <div className="col-4 pb-3">
                          <label>ค่าจ้าง:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_id}
                            onChange={(event) => {
                              this.setState({ teacher_id: event.target.value });
                            }}
                          />
                        </div>
                      </div> */}
                      {/* สีประจำตัว */}
                      {/* <div className="row">
                        <div className="col-4 pb-3">
                          <label>สีประจำตัว:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="color"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_id}
                            onChange={(event) => {
                              this.setState({ teacher_id: event.target.value });
                            }}
                          />
                        </div>
                      </div> */}
                      {/* เลขที่ผู้เสียภาษี */}
                      {/* <div className="row">
                        <div className="col-4 pb-3">
                          <label>เลขที่ผู้เสียภาษี:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_id}
                            onChange={(event) => {
                              this.setState({ teacher_id: event.target.value });
                            }}
                          />
                        </div>
                      </div> */}
                      {/* ที่อยู่ปัจจุบัน */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["address"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_address}
                            onChange={(event) => {
                              this.setState({
                                teacher_address: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* จังหวัด */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["province"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <select
                            className="form-control"
                            value={this.state.teacher_province}
                            onChange={(event) => {
                              this.setState({
                                teacher_province: event.target.value,
                              });
                              console.log(event.target.value);
                              if (event.target.value !== "") {
                                this.GetDistrict(event.target.value);
                              }
                            }}
                          >
                            <option value={""}>
                              --{global[this.language]["select_province"]}--
                            </option>
                            {this.state.province &&
                              this.state.province.map((item, index) => (
                                <option value={index}>
                                  {item.PROVINCE_NAME}
                                </option>
                              ))}
                            {this.state.teacher_province.length >= 3 && (
                              <option value={this.state.teacher_province}>
                                {this.state.teacher_province}
                              </option>
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["district"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <select
                            className="form-control"
                            value={this.state.teacher_district}
                            onChange={(event) => {
                              this.setState({
                                teacher_district: event.target.value,
                              });
                              if (event.target.value !== "") {
                                this.GetSubDistrict(event.target.value);
                              }
                            }}
                          >
                            <option value={""}>
                              --{global[this.language]["select_district"]}--
                            </option>
                            {this.state.district &&
                              this.state.district.map((item, index) => (
                                <option value={index}>
                                  {item.DISTRICT_NAME}
                                </option>
                              ))}
                            {this.state.teacher_district !== "" &&
                              typeof this.state.teacher_district !==
                                "number" && (
                                <option value={this.state.teacher_district}>
                                  {this.state.teacher_district}
                                </option>
                              )}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["subdistrict"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <select
                            className="form-control"
                            value={this.state.teacher_subdistrict}
                            onChange={(event) => {
                              this.setState({
                                teacher_subdistrict: event.target.value,
                              });
                              if (event.target.value !== "") {
                                this.GetZipcode(event.target.value);
                              }
                            }}
                          >
                            <option value={""}>
                              --{global[this.language]["select_subdistrict"]}--
                            </option>
                            {this.state.sub_district &&
                              this.state.sub_district.map((item, index) => (
                                <option value={index}>
                                  {item.SUB_DISTRICT_NAME}
                                </option>
                              ))}{" "}
                            {this.state.teacher_subdistrict !== "" &&
                              typeof this.state.teacher_subdistrict !==
                                "number" && (
                                <option value={this.state.teacher_subdistrict}>
                                  {this.state.teacher_subdistrict}
                                </option>
                              )}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["zipcode"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <select
                            className="form-control"
                            value={this.state.teacher_zipcode}
                            onChange={(event) => {
                              this.setState({
                                teacher_zipcode: event.target.value,
                              });
                            }}
                          >
                            <option value={""}>
                              --{global[this.language]["select_zipcode"]}--
                            </option>
                            {this.state.zipcode &&
                              this.state.zipcode.map((item, index) => (
                                <option value={index}>{item.ZIPCODE}</option>
                              ))}
                            {this.state.teacher_zipcode !== "" &&
                              typeof this.state.teacher_zipcode !==
                                "number" && (
                                <option value={this.state.teacher_zipcode}>
                                  {this.state.teacher_zipcode}
                                </option>
                              )}
                          </select>
                        </div>
                      </div>
                      {/* รายวิชาที่สอน */}
                      {/* <div className="row">
                        <div className="col-4 pb-3">
                          <label>รายวิชาที่สอน:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_id}
                            onChange={(event) => {
                              this.setState({ teacher_id: event.target.value });
                            }}
                          />
                        </div>
                      </div> */}
                      {/* Zoom API Key */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>Zoom API Key:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_apikey}
                            onChange={(event) => {
                              this.setState({
                                teacher_apikey: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* Zoom API Secret */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>Zoom API Secret:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_apisecret}
                            onChange={(event) => {
                              this.setState({
                                teacher_apisecret: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* Username */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["username"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_username}
                            readOnly
                            onChange={(event) => {
                              this.setState({
                                teacher_username: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* รหัสผ่าน */}
                      <div className="row">
                        <div className="col-12 pb-3">
                          <label>{global[this.language]["password"]}:</label>

                          <div
                            className="card w-100 bg-light p-4"
                            onClick={() => {
                              if (this.state.teacher_password === "") {
                                var text = "";
                                var possible =
                                  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                                for (var i = 0; i < 6; i++)
                                  text += possible.charAt(
                                    Math.floor(Math.random() * possible.length)
                                  );
                                this.setState({ teacher_password: text });
                                const selBox =
                                  document.createElement("textarea");
                                selBox.style.position = "fixed";
                                selBox.style.left = "0";
                                selBox.style.top = "0";
                                selBox.style.opacity = "0";
                                selBox.value = text;
                                document.body.appendChild(selBox);
                                selBox.focus();
                                selBox.select();
                                document.execCommand("copy");
                                document.body.removeChild(selBox);
                                var x = document.getElementById("snackbar");
                                x.className = "show";
                                setTimeout(function () {
                                  x.className = x.className.replace("show", "");
                                }, 3000);
                                // if (navigator.clipboard) {
                                //   navigator.clipboard.writeText(text);
                                //   this.setState({ copy: true });
                                // }
                                // setTimeout(() => {
                                //   this.setState({ copy: false });
                                // }, 2000);
                              }
                            }}
                          >
                            {this.state.teacher_password === "" ? (
                              <div
                                className="w-100 text-center"
                                style={{ cursor: "pointer" }}
                              >
                                <h4 className="header-password">
                                  <b className="text-primary">
                                    {global[this.language]["header_password"]}
                                  </b>
                                </h4>
                                {/* <small>
                                  <label className="text-secondary">
                                    {global[this.language]["detail_password"]}
                                  </label>
                                </small> */}
                              </div>
                            ) : (
                              <div className="w-100 text-center">
                                <h4>{this.state.teacher_password}</h4>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_edit_teacher();
                        }}
                      >
                        {global[this.language]["save_edit"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* เพิ่มข้อมูลอาจารย์ userpass */}
              <Modal
                show={this.state.modal_add_teacher_userpass}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["add_teacher"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["username"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.teacher_username}
                        readOnly
                        onChange={(event) => {
                          this.setState({
                            teacher_username: event.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="col-12 pb-3">
                      <label>{global[this.language]["password"]}:</label>

                      <div
                        className="card w-100 bg-light p-4"
                        onClick={() => {
                          if (this.state.teacher_password === "") {
                            var text = "";
                            var possible =
                              "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                            for (var i = 0; i < 6; i++)
                              text += possible.charAt(
                                Math.floor(Math.random() * possible.length)
                              );
                            this.setState({ teacher_password: text });
                            const selBox = document.createElement("textarea");
                            selBox.style.position = "fixed";
                            selBox.style.left = "0";
                            selBox.style.top = "0";
                            selBox.style.opacity = "0";
                            selBox.value = text;
                            document.body.appendChild(selBox);
                            selBox.focus();
                            selBox.select();
                            document.execCommand("copy");
                            document.body.removeChild(selBox);
                            var x = document.getElementById("snackbar");
                            x.className = "show";
                            setTimeout(function () {
                              x.className = x.className.replace("show", "");
                            }, 3000);
                            // if (navigator.clipboard) {
                            //   navigator.clipboard.writeText(text);
                            //   this.setState({ copy: true });
                            // }
                            // setTimeout(() => {
                            //   this.setState({ copy: false });
                            // }, 2000);
                          }
                        }}
                      >
                        {this.state.teacher_password === "" ? (
                          <div
                            className="w-100 text-center"
                            style={{ cursor: "pointer" }}
                          >
                            <h4 className="header-password">
                              <b className="text-primary">
                                {global[this.language]["header_password"]}
                              </b>
                            </h4>
                            {/* <small>
                              <label className="text-secondary">
                                {global[this.language]["detail_password"]}
                              </label>
                            </small> */}
                          </div>
                        ) : (
                          <div className="w-100 text-center">
                            <h4>{this.state.teacher_password}</h4>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Submit_add_teacher(2);
                        }}
                      >
                        {global[this.language]["back"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_add_teacher("success");
                        }}
                      >
                        {global[this.language]["add"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
        <div id="snackbar">{global[this.language]["copy_password"]}</div>
      </div>
    );
  }
}
