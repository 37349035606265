import "../../../bootstrap.css";
import React, { Component } from "react";
import SideMenu from "../../Content/SideMenu/SideMenu";
import Header from "../../Content/Header/Header";
import Days from "../../Content/Days/Days";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import visa from "./cc-visa-brands.svg";
import mastercard from "./cc-mastercard-brands.svg";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import Cookies from "universal-cookie";
import * as XLSX from "xlsx";
import { toDate } from "date-fns";
const cookies = new Cookies();
export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      activePage: 1,
      page_count: 1,
      step: 1,
      loading: true,
      search: "",
      student_code: "",
      student_name: "",
      student_nickname: "",
      student_phone: "",
      student_gender: "",
      student_school: "",
      student_facebook: "",
      student_line: "",
      student_course_type: "",
      student_address: "",
      student_province: "",
      student_district: "",
      student_subdistrict: "",
      student_zipcode: "",
      student_username: "",
      student_password: "",
      parents_name: "",
      parents_phone: "",
      student_payment: "",
      student_deposit_date: "",
      student_deposit: "",
      student_deposit_photo: "",
      student_deposit_card_id: "",
      student_deposit_card_name: "",
      student_deposit_card_expire: "",
      student_deposit_card_cvv: "",
      bank_select: "",
      copy: false,
      modal_add_student_1: false,
      modal_add_student_2: false,
      modal_add_student_3: false,
      modal_add_student_4: false,
      modal_edit_student: false,
      modal_add_student_userpass: false,
      modal_import: false,
      modal_detail: false,
      detail: "",

      data: [],
      bank: [
        {
          bank_name: "กสิกรไทย",
          name: "น.ส.นิตยา สนิทปัญญาวุโธ",
          bank_no: "088-3-15229-8",
          photo:
            "https://rismep.dip.go.th/uploads/bds/bds_461b859acede266e1ac8e0964676042d.jpg",
        },
      ],
      days: [
        { day: "จันทร์", select: false, time: "" },
        { day: "อังคาร", select: true, time: "" },
        { day: "พุธ", select: true, time: "" },
        { day: "พฤหัสบดี", select: false, time: "" },
        { day: "ศุกร์", select: false, time: "" },
        { day: "เสาร์", select: true, time: "" },
        { day: "อาทิตย์", select: false, time: "" },
      ],
      sort: [0, 0, 0],
      spinner: false,
      spinner_import: false,
      province: [],
      district: [],
      sub_district: [],
      zipcode: [],
      file: "",
      fileError: "",
    };
  }
  language = cookies.get("language") ? cookies.get("language") : "TH";
  componentDidMount() {
    if (cookies.get("user")) {
      if (cookies.get("token")) {
        this.GetStudent();
        this.GetProvince();
      } else {
        Swal.fire({
          title: global[this.language]["alert"],
          text: global[this.language]["retrieve_data_fail"],
          icon: "warning",
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonText: global[this.language]["ok"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.Logout();
          }
        });
      }
    } else {
      Swal.fire({
        title: global[this.language]["alert"],
        text: global[this.language]["retrieve_data_fail"],
        icon: "warning",
        showCancelButton: false,
        allowOutsideClick: false,
        confirmButtonText: global[this.language]["ok"],
      }).then((result) => {
        if (result.isConfirmed) {
          cookies.remove("token", { path: "/" });
          cookies.remove("user", { path: "/" });

          window.location.href = "/";
        }
      });
    }
  }
  Logout = () => {
    fetch(global.httpURL + "/api/logout", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "token " + cookies.get("token"),
      },
      // mode: "no-cors",
    })
      .then((response) => response.json())
      .then((response) => {
        cookies.remove("token", { path: "/" });
        cookies.remove("user", { path: "/" });

        window.location.href = "/";
      })
      .catch((err) => {
        //window.location.href = "/dashboard";
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_logout"],
          "warning"
        );
      });
  };
  Cancel = () => {
    this.setState({
      student_code: "",
      student_name: "",
      student_nickname: "",
      student_phone: "",
      student_gender: "",
      student_school: "",
      student_facebook: "",
      student_line: "",
      student_course_type: "",
      student_address: "",
      student_province: "",
      student_district: "",
      student_subdistrict: "",
      student_zipcode: "",
      parents_name: "",
      parents_phone: "",
      student_payment: "",
      student_deposit_date: "",
      student_deposit: "",
      student_deposit_photo: "",
      student_deposit_card_id: "",
      student_deposit_card_name: "",
      student_deposit_card_expire: "",
      student_deposit_card_cvv: "",
      student_username: "",
      student_password: "",
      bank_select: "",
      modal_add_student_1: false,
      modal_add_student_2: false,
      modal_add_student_3: false,
      modal_add_student_4: false,
      modal_edit_student: false,
      modal_add_student_userpass: false,
      modal_import: false,
      modal_detail: false,
      district: [],
      sub_district: [],
      zipcode: [],
      file: "",
      fileError: "",
    });
  };
  GetStudent = () => {
    this.setState({ loading: true });
    fetch(global.httpURL + "/api/student/page/" + this.state.activePage, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "token " + cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (
          response.message === "Auth Failed" ||
          response.message === "Token is null"
        ) {
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["unavailable"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.Logout();
            }
          });
        } else if (response.message === "Connot find page") {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            data: response.data,
            loading: false,
            page_count: response.page_count,
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        Swal.fire({
          title: global[this.language]["alert"],
          text: global[this.language]["retrieve_data_fail"],
          icon: "warning",
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonText: global[this.language]["ok"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.Logout();
          }
        });
      });
  };
  Submit_add_student = (step) => {
    if (step === 1) {
      this.setState({
        modal_add_student_1: true,
        modal_add_student_2: false,
        modal_add_student_3: false,
        modal_add_student_4: false,
        modal_add_student_userpass: false,
        step: 1,
      });
    }
    if (step === 2) {
      this.setState({
        modal_add_student_1: false,
        modal_add_student_2: true,
        modal_add_student_3: false,
        modal_add_student_4: false,
        modal_add_student_userpass: false,
        step: 2,
      });
    }
    if (step === 3) {
      this.setState({
        modal_add_student_1: false,
        modal_add_student_2: false,
        modal_add_student_3: true,
        modal_add_student_4: false,
        modal_add_student_userpass: false,
        step: 3,
      });
    }
    if (step === 4) {
      this.setState({
        modal_add_student_1: false,
        modal_add_student_2: false,
        modal_add_student_3: false,
        modal_add_student_4: true,
        modal_add_student_userpass: false,
      });
    }
    if (step === "userpass") {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
      let num = /^^[0][0-9]*$/;
      if (this.state.student_name === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_first_last_name"],
          "warning"
        );
        document.getElementById("add_student_name").className += " error";
      } else if (this.state.student_nickname === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_nickname"],
          "warning"
        );
        document.getElementById("add_student_nickname").className += " error";
      } else if (this.state.student_phone === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_phone"],
          "warning"
        );
        document.getElementById("add_student_phone").className += " error";
      } else if (
        this.state.student_phone.trim().length < 10 ||
        !num.test(this.state.student_phone)
      ) {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_phone_true"],
          "warning"
        );
        document.getElementById("add_student_phone").className += " error";
      } else if (this.state.student_gender === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["select_gender"],
          "warning"
        );
        document.getElementById("add_student_gender").className += " error";
      } else if (this.state.student_school === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_school"],
          "warning"
        );
        document.getElementById("add_student_school").className += " error";
      }
      // else if (this.state.student_facebook === "") {
      //   Swal.fire(
      //     global[this.language]["alert"],
      //     global[this.language]["enter_your_facebook"],
      //     "warning"
      //   );
      // } else if (this.state.student_line === "") {
      //   Swal.fire(
      //     global[this.language]["alert"],
      //     global[this.language]["enter_your_line"],
      //     "warning"
      //   );
      // }
      // else if (this.state.student_course_type === "") {
      //   Swal.fire(global[this.language]["alert"], "กรุณาเลือกประเภทคอร์สเรียน", "warning");
      // }
      else if (this.state.student_address === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_address"],
          "warning"
        );
        document.getElementById("add_student_address").className += " error";
      } else if (this.state.student_province === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_province"],
          "warning"
        );
        document.getElementById("add_student_province").className += " error";
      } else if (this.state.student_district === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_district"],
          "warning"
        );
        document.getElementById("add_student_district").className += " error";
      } else if (this.state.student_subdistrict === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_subdistrict"],
          "warning"
        );
        document.getElementById("add_student_subdistrict").className +=
          " error";
      } else if (this.state.student_zipcode === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_zipcode"],
          "warning"
        );
        document.getElementById("add_student_zipcode").className += " error";
      } else if (this.state.parents_name === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_parentname"],
          "warning"
        );
        document.getElementById("add_parents_name").className += " error";
      } else if (this.state.parents_phone === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_parentphone"],
          "warning"
        );
        document.getElementById("add_parents_phone").className += " error";
      } else if (
        this.state.parents_phone.trim().length < 10 ||
        !num.test(this.state.parents_phone)
      ) {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_parent_phone_true"],
          "warning"
        );
        document.getElementById("add_parents_phone").className += " error";
      } else {
        this.setState({
          modal_add_student_1: false,
          modal_add_student_2: false,
          modal_add_student_3: false,
          modal_add_student_4: false,
          modal_add_student_userpass: true,
        });
      }
    }
    if (step === "success") {
      if (this.state.student_username === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_username"],
          "warning"
        );
        document.getElementById("add_student_username").className += " error";
      } else if (this.state.student_password === "") {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["enter_your_password"],
          "warning"
        );
      } else {
        this.setState({ spinner: true });
        fetch(global.httpURL + "/api/student", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
          body: JSON.stringify({
            name: this.state.student_name,
            nickname: this.state.student_nickname,
            phone: this.state.student_phone,
            gender: this.state.student_gender,
            school: this.state.student_school,
            facebook: this.state.student_facebook,
            line: this.state.student_line,
            address: this.state.student_address,
            district:
              this.state.district[this.state.student_district].DISTRICT_NAME,
            sub_district:
              this.state.sub_district[this.state.student_subdistrict]
                .SUB_DISTRICT_NAME,
            province:
              this.state.province[this.state.student_province].PROVINCE_NAME,
            zip_code: this.state.zipcode[this.state.student_zipcode].ZIPCODE,
            parents_name: this.state.parents_name,
            parents_phone: this.state.parents_phone,
            username: this.state.student_username,
            password: this.state.student_password,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            this.setState({ spinner: false });
            if (response.message === "This username is existed") {
              Swal.fire(
                global[this.language]["alert"],
                "Username นี้มีในระบบแล้ว กรุณาลองใหม่อีกครั้ง",
                "warning"
              );
            } else {
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["add_success"],
                "success"
              );
              this.Cancel();
              this.GetStudent();
            }
          })
          .catch((err) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["add_not_success"],
              "warning"
            );
          });
      }
    }
  };
  Submit_edit_student = () => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
    let num = /^^[0][0-9]*$/;
    if (this.state.student_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_first_last_name"],
        "warning"
      );
      document.getElementById("edit_student_name").className += " error";
    } else if (this.state.student_nickname === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_nickname"],
        "warning"
      );
      document.getElementById("edit_student_nickname").className += " error";
    } else if (this.state.student_phone === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_phone"],
        "warning"
      );
      document.getElementById("edit_student_phone").className += " error";
    } else if (
      this.state.student_phone.trim().length < 10 ||
      !num.test(this.state.student_phone)
    ) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_phone_true"],
        "warning"
      );
      document.getElementById("edit_student_phone").className += " error";
    } else if (this.state.student_gender === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["select_gender"],
        "warning"
      );
      document.getElementById("edit_student_gender").className += " error";
    } else if (this.state.student_school === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_school"],
        "warning"
      );
      document.getElementById("edit_student_school").className += " error";
    }
    // else if (this.state.student_facebook === "") {
    //   Swal.fire(
    //     global[this.language]["alert"],
    //     global[this.language]["enter_your_facebook"],
    //     "warning"
    //   );
    // } else if (this.state.student_line === "") {
    //   Swal.fire(
    //     global[this.language]["alert"],
    //     global[this.language]["enter_your_line"],
    //     "warning"
    //   );
    // }
    // else if (this.state.student_course_type === "") {
    //   Swal.fire(global[this.language]["alert"], "กรุณาเลือกประเภทคอร์สเรียน", "warning");
    // }
    else if (this.state.student_address === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_address"],
        "warning"
      );
      document.getElementById("edit_student_address").className += " error";
    } else if (this.state.student_province === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_province"],
        "warning"
      );
      document.getElementById("edit_student_province").className += " error";
    } else if (this.state.student_district === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_district"],
        "warning"
      );
      document.getElementById("edit_student_district").className += " error";
    } else if (this.state.student_subdistrict === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_subdistrict"],
        "warning"
      );
      document.getElementById("edit_student_subdistrict").className += " error";
    } else if (this.state.student_zipcode === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_zipcode"],
        "warning"
      );
      document.getElementById("edit_student_zipcode").className += " error";
    } else if (this.state.parents_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_parentname"],
        "warning"
      );
      document.getElementById("edit_parents_name").className += " error";
    } else if (this.state.parents_phone === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_parentphone"],
        "warning"
      );
      document.getElementById("edit_parents_phone").className += " error";
    } else if (
      this.state.parents_phone.trim().length < 10 ||
      !num.test(this.state.parents_phone)
    ) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_parent_phone_true"],
        "warning"
      );
      document.getElementById("edit_parents_phone").className += " error";
    } else {
      this.setState({ spinner: true });
      let data = "";
      let district = !isNaN(this.state.student_district)
        ? this.state.district[this.state.student_district].DISTRICT_NAME
        : this.state.student_district;
      let subdistrict = !isNaN(this.state.student_subdistrict)
        ? this.state.sub_district[this.state.student_subdistrict]
            .SUB_DISTRICT_NAME
        : this.state.student_subdistrict;
      let province = !isNaN(this.state.student_province)
        ? this.state.province[this.state.student_province].PROVINCE_NAME
        : this.state.student_province;
      let zipcode =
        this.state.student_zipcode.length !== 5
          ? this.state.zipcode[this.state.student_zipcode].ZIPCODE
          : this.state.student_zipcode;
      if (this.state.password !== "") {
        data = {
          name: this.state.student_name,
          nickname: this.state.student_nickname,
          phone: this.state.student_phone,
          gender: this.state.student_gender,
          school: this.state.student_school,
          facebook: this.state.student_facebook,
          line: this.state.student_line,
          address: this.state.student_address,
          district: district,
          sub_district: subdistrict,
          province: province,
          zip_code: zipcode,
          parents_name: this.state.parents_name,
          parents_phone: this.state.parents_phone,
          username: this.state.student_username,
          password: this.state.student_password,
        };
      } else {
        data = {
          name: this.state.student_name,
          nickname: this.state.student_nickname,
          phone: this.state.student_phone,
          gender: this.state.student_gender,
          school: this.state.student_school,
          facebook: this.state.student_facebook,
          line: this.state.student_line,
          address: this.state.student_address,
          district: district,
          sub_district: subdistrict,
          province: province,
          zip_code: zipcode,
          parents_name: this.state.parents_name,
          parents_phone: this.state.parents_phone,
          username: this.state.student_username,
        };
      }
      fetch(
        global.httpURL +
          "/api/student/" +
          this.state.data[this.state.detail]._id,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.message === "This name is existed") {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["haved_name"],
              "warning"
            );
          } else {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["edit_success"],
              "success"
            );
            this.setState({ spinner: false });
            this.Cancel();
            this.GetStudent();
          }
        })
        .catch((err) => {
          this.setState({ spinner: false });
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["edit_not_success"],
            "warning"
          );
        });
    }
  };
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber, loading: true });
    setTimeout(() => {
      this.GetStudent();
    }, 1);
  }
  Delete_student = (index) => {
    Swal.fire({
      title: global[this.language]["confirm_delete"],
      showCancelButton: true,
      confirmButtonText: global[this.language]["confirm"],
      cancelButtonText: global[this.language]["cancel"],
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ spinner: true });
        fetch(global.httpURL + "/api/student/" + this.state.data[index]._id, {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
        })
          .then((response) => response.json())
          .then((response) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_success"],
              "success"
            );
            this.GetStudent();
          })
          .catch((err) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_not_success"],
              "warning"
            );
          });
      }
    });
  };
  Sort = (key) => {
    let list = this.state.data;
    let sorted = this.state.sort;
    if (key === "id") {
      if (sorted[0] === 0) {
        list.sort((a, b) => (a.user.user_code < b.user.user_code ? 1 : -1));
        sorted[0] = 1;
      } else {
        list.sort((a, b) => (a.user.user_code > b.user.user_code ? 1 : -1));
        sorted[0] = 0;
      }
    } else if (key === "name") {
      if (sorted[1] === 0) {
        list.sort((a, b) => (a.student_name < b.student_name ? 1 : -1));
        sorted[1] = 1;
      } else {
        list.sort((a, b) => (a.student_name > b.student_name ? 1 : -1));
        sorted[1] = 0;
      }
    } else if (key === "phone") {
      if (sorted[2] === 0) {
        list.sort((a, b) => (a.user.phone < b.user.phone ? 1 : -1));
        sorted[2] = 1;
      } else {
        list.sort((a, b) => (a.user.phone > b.user.phone ? 1 : -1));
        sorted[2] = 0;
      }
    }

    this.setState({ data: list, sort: sorted });
  };
  GenUser = () => {
    let year = new Date().getFullYear() + 543;
    let month = new Date().getMonth() + 1;
    let day = new Date().getDate();
    let time =
      new Date().getHours() * 60 * 60 +
      new Date().getMinutes() * 60 +
      new Date().getSeconds();
    year = year.toString().slice(2, 4);

    let gen = new Date().getTime();
    if (month.toString().length === 1) {
      month = "0" + month;
    }
    if (day.toString().length === 1) {
      day = "0" + day;
    }
    gen = gen.toString().slice(4, 10);
    this.setState({
      student_username: "ST" + year + month + gen,
    });
  };
  GetProvince = () => {
    fetch(global.httpURL + "/api/address/provinces", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          province: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  GetDistrict = (index) => {
    var id = this.state.province[index].PROVINCE_ID;

    fetch(global.httpURL + "/api/address/districts", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ provinces_id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          district: response.data,
          student_district: "",
          student_subdistrict: "",
          student_zipcode: "",
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  GetSubDistrict = (index) => {
    var id = this.state.district[index].DISTRICT_ID;
    fetch(global.httpURL + "/api/address/subDistricts", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ districts_id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          sub_district: response.data,
          student_subdistrict: "",
          student_zipcode: "",
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  GetZipcode = (index) => {
    var id = this.state.sub_district[index].SUB_DISTRICT_ID;

    fetch(global.httpURL + "/api/address/zipcodes", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ subDistricts_id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          zipcode: response.data,
          student_zipcode: "",
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  search = () => {
    fetch(global.httpURL + "/api/search/sd/page/1", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({
        data_search: this.state.search,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ spinner: false });
        if (
          response.message === "Auth Failed" ||
          response.message === "Token is null"
        ) {
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["unavailable"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.Logout();
            }
          });
        } else if (
          response.message === "Connot find page" ||
          response.message === "No information found"
        ) {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            data: response.data,
            loading: false,
            page_count: response.page_count,
          });
        }
      })
      .catch((err) => {
        this.setState({ spinner: false });
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_logout"],
          "warning"
        );
      });
  };

  handleClick(e) {
    this.refs.fileUploader.click();
  }

  filePathset(e) {
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];
    this.setState({ file });
  }

  readFile() {
    var f = this.state.file;
    var name = f.name;
    console.log(name);
    if (name.includes("csv") || name.includes("xls") || name.includes("xlsx")) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        // evt = on_file_select event
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        /* Update state */
        let dataAll = this.convertToJson(data);
        if (dataAll) {
          this.ImportFileAPI(dataAll);
        }
      };
      reader.readAsBinaryString(f);
    } else {
      setTimeout(() => {
        document.getElementById("file").value = "";
      }, 1);
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["error_file"],
        "warning"
      );
      return false;
    }
  }

  convertToJson(csv) {
    var lines = csv.split("\n");

    var result = [];
    var check = [
      "NAME",
      "NICKNAME",
      "PHONE",
      "GENDER",
      "SCHOOL",
      "FACEBOOK",
      "LINE",
      "ADDRESS",
      "PROVINCE",
      "DISTRICT",
      "SUB_DISTRICT",
      "ZIP_CODE",
      "PARENTS_NAME",
      "PARENTS_PHONE",
    ];
    var headers = lines[5].split(",");
    for (let he of headers) {
      let count = 0;
      check.find((e) => {
        if (e === he) {
          count += 1;
        }
      });
      if (count === 0) {
        setTimeout(() => {
          document.getElementById("file").value = "";
        }, 1);
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_format"],
          "warning"
        );
        return false;
      }
    }

    for (var i = 5; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");
      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }
    result.splice(0, 1);
    result.splice(result.length - 1, 1);
    //return result; //JavaScript object
    return result; //JSON
  }
  ImportFileAPI = (data) => {
    this.setState({ spinner_import: true });
    fetch(global.httpURL + "/api/import/sd/create", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({
        student_data: data,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ spinner_import: false });
        console.log(response);
        setTimeout(() => {
          document.getElementById("file").value = "";
        }, 1);
        if (response.data) {
          this.setState({ fileError: response.data });
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["fileError"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {});
        } else {
          this.setState({ fileError: "" });
          this.Cancel();
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["add_success"],
            "success"
          );

          this.GetStudent();
        }
      })
      .catch((err) => {
        this.setState({ spinner_import: false });
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["add_not_success"],
          "warning"
        );
      });
  };
  render() {
    return (
      <div className="w-100 h-auto minh-100">
        {this.state.spinner && (
          <div className="spinner">
            <div className="loader" />
          </div>
        )}
        <div className="d-flex h-auto minh-100">
          <div className="h-auto minh-100">
            <SideMenu page="student" />
          </div>
          <div className="w-100-body">
            <Header />
            <div className="container-fluid pt-3">
              {/* TABLE */}
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="card border-0 p-4">
                    <div className="flex-row justify-content-between align-items-center row p-4">
                      <div>
                        <h3>
                          <b>{global[this.language]["student"]}</b>
                        </h3>
                      </div>
                      {/* BUTTON XL */}
                      <div className="text-right d-none d-xl-block">
                        {/* <select className="filter-box mr-2  ml-2">
                          <option>{global[this.language]["all_status"]}</option>
                          <option>ชำระแล้ว</option>
                          <option>รอชำระ</option>
                          <option>{global[this.language]["cancel"]}</option>
                        </select> */}
                        <div className="search-box-div mr-2 ml-2">
                          <input
                            type="text"
                            className="search-box"
                            placeholder={
                              global[this.language]["id"] +
                              "," +
                              global[this.language]["fullname"]
                            }
                            value={this.state.search}
                            onChange={(event) => {
                              this.setState({
                                search: event.target.value,
                                activePage: 1,
                              });
                              setTimeout(() => {
                                if (this.state.search === "") {
                                  this.search();
                                }
                              }, 1);
                            }}
                            onKeyUp={(event) => {
                              if (event.key === "Enter") {
                                this.search();
                              }
                            }}
                          />
                          <button
                            className="icon-absolute"
                            onClick={() => {
                              this.search();
                            }}
                          >
                            {"\uf002"}
                          </button>
                        </div>
                        {/* <button className="btn btn-outline-secondary mr-2 ml-2 text-primary">
                          <label className="icon mr-1">{"\uf56d"}</label>
                          Export Report
                        </button> */}
                        <button
                          className="btn btn-outline-secondary mr-2 ml-2 text-primary"
                          onClick={() => {
                            this.setState({ modal_import: true });
                          }}
                        >
                          <label className="icon mr-1">{"\uf56d"}</label>
                          Import Report
                        </button>
                        <button
                          className="btn btn-primary mr-2 ml-2"
                          onClick={() => {
                            this.Cancel();
                            this.setState({ modal_add_student_1: true });
                            this.GenUser();
                          }}
                        >
                          <label className="icon mr-1">{"\uf055"}</label>
                          {global[this.language]["enroll"]}
                        </button>
                      </div>
                      {/* BUTTON NOT XL */}
                      <div className="text-right d-block d-xl-none flex-row justify-content-between align-items-center">
                        <div className="search-box-div mr-2 ml-2">
                          <input
                            type="text"
                            className="search-box mt-2"
                            placeholder={
                              global[this.language]["id"] +
                              "," +
                              global[this.language]["fullname"]
                            }
                            value={this.state.search}
                            onChange={(event) => {
                              this.setState({
                                search: event.target.value,
                                activePage: 1,
                              });
                              setTimeout(() => {
                                if (this.state.search === "") {
                                  this.search();
                                }
                              }, 1);
                            }}
                            onKeyUp={(event) => {
                              if (event.key === "Enter") {
                                this.search();
                              }
                            }}
                          />
                          <button
                            className="icon-absolute"
                            onClick={() => {
                              this.search();
                            }}
                          >
                            {"\uf002"}
                          </button>
                        </div>
                        <Menu
                          menuButton={
                            <button className="btn btn-primary mr-2 ml-2 mt-2">
                              <label className="icon">{"\uf0c9"}</label>
                            </button>
                          }
                        >
                          {/* <MenuItem>
                            <label className="icon mr-1">{"\uf56d"}</label>
                            Export Report
                          </MenuItem> */}
                          <MenuItem
                            onClick={() => {
                              this.setState({ modal_import: true });
                            }}
                          >
                            <label className="icon mr-1">{"\uf56d"}</label>
                            Import Report
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              this.Cancel();
                              this.setState({ modal_add_student_1: true });
                              this.GenUser();
                            }}
                          >
                            <label className="icon mr-1">{"\uf055"}</label>
                            {global[this.language]["enroll"]}
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {!this.state.loading ? (
                        <table className="table table-borderless table-striped table-w-1 table-hover ">
                          <thead className="thead-dark">
                            <tr>
                              <th className="text-center">
                                {global[this.language]["order"]}
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("id");
                                }}
                              >
                                {global[this.language]["student_ID"]}
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("name");
                                }}
                                style={{ minWidth: 200 }}
                              >
                                {global[this.language]["fullname"]}
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("phone");
                                }}
                              >
                                {global[this.language]["phone"]}
                              </th>

                              <th className="text-center">
                                {global[this.language]["enroll"]}
                              </th>
                              {/* <th className="text-center">
                                {global[this.language]["all_info"]}
                              </th> */}
                              <th className="text-center">
                                {global[this.language]["edit"]}
                              </th>
                              <th className="text-center">
                                {global[this.language]["delete"]}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* DEPARTMENT */}
                            {this.state.data &&
                              this.state.data.map((student, student_index) => (
                                <tr>
                                  <td className="text-center">
                                    {student_index +
                                      1 +
                                      (this.state.activePage - 1) * 10}
                                  </td>
                                  <td>{student.user.user_code}</td>
                                  <td>{student.user.name}</td>
                                  <td>{student.user.phone}</td>
                                  {/* <td className="text-center">
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary pt-1 pb-0"
                                    >
                                      <label className="icon mr-1">
                                        {"\uf5db"}
                                      </label>
                                      {global[this.language]["enroll"]}
                                    </button>
                                  </td> */}
                                  <td className="text-center">
                                    <button
                                      className="btn btn-link pt-0 pb-0"
                                      onClick={() => {
                                        this.Cancel();
                                        this.setState({
                                          modal_detail: true,
                                          detail: student_index,
                                          student_code: student.user.user_code,
                                          student_name: student.user.name,
                                          student_nickname: student.nickname,
                                          student_phone: student.user.phone,
                                          student_gender: student.gender,
                                          student_school: student.school,
                                          student_facebook: student.facebook,
                                          student_line: student.line,
                                          student_address: student.address,
                                          student_province: student.province,
                                          student_district: student.district,
                                          student_subdistrict:
                                            student.sub_district,
                                          student_zipcode: student.zip_code,
                                          student_username:
                                            student.user.username,
                                          student_password: "",
                                          parents_name: student.parents_name,
                                          parents_phone: student.parents_phone,
                                        });
                                      }}
                                    >
                                      {global[this.language]["info"]}
                                    </button>
                                  </td>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-link pt-0 pb-0"
                                      onClick={() => {
                                        this.Cancel();
                                        this.setState({
                                          modal_edit_student: true,
                                          detail: student_index,
                                          student_code: student.user.user_code,
                                          student_name: student.user.name,
                                          student_nickname: student.nickname,
                                          student_phone: student.user.phone,
                                          student_gender: student.gender,
                                          student_school: student.school,
                                          student_facebook: student.facebook,
                                          student_line: student.line,
                                          // student_course_type: "",
                                          student_address: student.address,
                                          student_province: student.province,
                                          student_district: student.district,
                                          student_subdistrict:
                                            student.sub_district,
                                          student_zipcode: student.zip_code,
                                          student_username:
                                            student.user.username,
                                          student_password: "",
                                          parents_name: student.parents_name,
                                          parents_phone: student.parents_phone,
                                        });
                                      }}
                                    >
                                      <label className="icon ">
                                        {"\uf304"}
                                      </label>
                                    </button>
                                  </td>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-link pt-0 pb-0"
                                      onClick={() => {
                                        this.Delete_student(student_index);
                                      }}
                                    >
                                      <label className="icon ">
                                        {"\uf2ed"}
                                      </label>
                                    </button>
                                  </td>
                                </tr>
                              ))}{" "}
                            {this.state.data && this.state.data.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={9}>
                                  {global[this.language]["no_data"]}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <div className="w-100 d-flex justify-content-center">
                          <div className="loader" />
                        </div>
                      )}
                    </div>
                    <div className="d-flex flex-row-reverse mt-3">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.page_count * 10}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        prevPageText="Previous"
                        nextPageText="Next"
                        hideFirstLastPages={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* MODAL */}
              {/* เพิ่มข้อมูลนักเรียน 1 */}
              <Modal
                show={this.state.modal_add_student_1}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["add_student"]}</b>
                  </h3>
                  <div className="row">
                    {/* <div className="col-12 pb-3">
                      <label>{global[this.language]["student_ID"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.student_code}
                        onChange={(event) => {
                          this.setState({ student_code: event.target.value });
                        }}
                      />
                    </div> */}
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["fullname"]}:</label>
                      <input
                        id="add_student_name"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.student_name}
                        onChange={(event) => {
                          this.setState({ student_name: event.target.value });
                          document.getElementById(
                            "add_student_name"
                          ).className = "form-control";
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["nickname"]}:</label>
                      <input
                        id="add_student_nickname"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.student_nickname}
                        onChange={(event) => {
                          this.setState({
                            student_nickname: event.target.value,
                          });
                          document.getElementById(
                            "add_student_nickname"
                          ).className = "form-control";
                        }}
                      />
                    </div>

                    <div className="col-6 pb-3">
                      <label>{global[this.language]["phone"]}:</label>
                      <input
                        id="add_student_phone"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.student_phone}
                        maxLength={10}
                        onChange={(event) => {
                          this.setState({
                            student_phone: event.target.value,
                          });
                          document.getElementById(
                            "add_student_phone"
                          ).className = "form-control";
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["gender"]}:</label>
                      <select
                        id="add_student_gender"
                        className="form-control"
                        value={this.state.student_gender}
                        onChange={(event) => {
                          this.setState({
                            student_gender: event.target.value,
                          });
                          document.getElementById(
                            "add_student_gender"
                          ).className = "form-control";
                        }}
                      >
                        <option value="">
                          --{global[this.language]["selected_gender"]}
                          --
                        </option>
                        <option value="ชาย">
                          {global[this.language]["male"]}
                        </option>
                        <option value="หญิง">
                          {global[this.language]["female"]}
                        </option>
                        <option value="ไม่ระบุ">
                          {global[this.language]["other"]}
                        </option>
                      </select>
                    </div>
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["school"]}:</label>
                      <input
                        id="add_student_school"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.student_school}
                        onChange={(event) => {
                          this.setState({
                            student_school: event.target.value,
                          });
                          document.getElementById(
                            "add_student_school"
                          ).className = "form-control";
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>Facebook:</label>
                      <input
                        id="add_student_facebook"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.student_facebook}
                        onChange={(event) => {
                          this.setState({
                            student_facebook: event.target.value,
                          });
                          document.getElementById(
                            "add_student_facebook"
                          ).className = "form-control";
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>LINE ID:</label>
                      <input
                        id="add_student_line"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.student_line}
                        onChange={(event) => {
                          this.setState({
                            student_line: event.target.value,
                          });
                          document.getElementById(
                            "add_student_line"
                          ).className = "form-control";
                        }}
                      />
                    </div>
                    {/* <div className="col-6 pb-3">
                      <label>{global[this.language]["Course_type"]}:</label>
                      <div className="toggle-bar">
                        <button
                          onClick={() => {
                            this.setState({
                              student_course_type: "เรียนเดี่ยว",
                            });
                          }}
                          className={
                            this.state.student_course_type === "เรียนเดี่ยว"
                              ? "toggle-left-active"
                              : "toggle-left"
                          }
                        >
                          {global[this.language]["solo_study"]}
                        </button>
                        <button
                          onClick={() => {
                            this.setState({
                              student_course_type: "เรียนกลุ่ม",
                            });
                          }}
                          className={
                            this.state.student_course_type === "เรียนกลุ่ม"
                              ? "toggle-right-active"
                              : "toggle-right"
                          }
                        >
                          {global[this.language]["group_classes"]}
                        </button>
                      </div>
                    </div> 

                    <div className="col-6 pb-3" />*/}
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["address"]}:</label>
                      <input
                        id="add_student_address"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["address_hint"]}
                        value={this.state.student_address}
                        onChange={(event) => {
                          this.setState({
                            student_address: event.target.value,
                          });

                          document.getElementById(
                            "add_student_address"
                          ).className = "form-control";
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["province"]}:</label>
                      <select
                        id="add_student_province"
                        className="form-control"
                        value={this.state.student_province}
                        onChange={(event) => {
                          this.setState({
                            student_province: event.target.value,
                          });
                          this.GetDistrict(event.target.value);

                          document.getElementById(
                            "add_student_province"
                          ).className = "form-control";
                        }}
                      >
                        <option value="">
                          --{global[this.language]["select_province"]}
                          --
                        </option>
                        {this.state.province &&
                          this.state.province.map((item, index) => (
                            <option value={index}>{item.PROVINCE_NAME}</option>
                          ))}
                      </select>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["district"]}:</label>
                      <select
                        id="add_student_district"
                        className="form-control"
                        value={this.state.student_district}
                        onChange={(event) => {
                          this.setState({
                            student_district: event.target.value,
                          });
                          this.GetSubDistrict(event.target.value);

                          document.getElementById(
                            "add_student_district"
                          ).className = "form-control";
                        }}
                      >
                        <option value="">
                          --{global[this.language]["select_district"]}
                          --
                        </option>
                        {this.state.district &&
                          this.state.district.map((item, index) => (
                            <option value={index}>{item.DISTRICT_NAME}</option>
                          ))}
                      </select>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["subdistrict"]}:</label>
                      <select
                        id="add_student_subdistrict"
                        className="form-control"
                        value={this.state.student_subdistrict}
                        onChange={(event) => {
                          this.setState({
                            student_subdistrict: event.target.value,
                          });
                          this.GetZipcode(event.target.value);
                          document.getElementById(
                            "add_student_subdistrict"
                          ).className = "form-control";
                        }}
                      >
                        <option value="">
                          --{global[this.language]["select_subdistrict"]}
                          --
                        </option>
                        {this.state.sub_district &&
                          this.state.sub_district.map((item, index) => (
                            <option value={index}>
                              {item.SUB_DISTRICT_NAME}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["zipcode"]}:</label>
                      <select
                        id="add_student_zipcode"
                        className="form-control"
                        value={this.state.student_zipcode}
                        onChange={(event) => {
                          this.setState({
                            student_zipcode: event.target.value,
                          });
                          document.getElementById(
                            "add_student_zipcode"
                          ).className = "form-control";
                        }}
                      >
                        <option value="">
                          --{global[this.language]["select_zipcode"]}
                          --
                        </option>
                        {this.state.zipcode &&
                          this.state.zipcode.map((item, index) => (
                            <option value={index}>{item.ZIPCODE}</option>
                          ))}
                      </select>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["fullname_parent"]}:</label>
                      <input
                        id="add_parents_name"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.parents_name}
                        onChange={(event) => {
                          this.setState({
                            parents_name: event.target.value,
                          });
                          document.getElementById(
                            "add_parents_name"
                          ).className = "form-control";
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["Phone_Parents"]}:</label>
                      <input
                        id="add_parents_phone"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        maxLength={10}
                        value={this.state.parents_phone}
                        onChange={(event) => {
                          this.setState({
                            parents_phone: event.target.value,
                          });
                          document.getElementById(
                            "add_parents_phone"
                          ).className = "form-control";
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_add_student("userpass");
                        }}
                      >
                        {global[this.language]["continue"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* เพิ่มข้อมูลนักเรียน 2 */}
              <Modal
                size="lg"
                show={this.state.modal_add_student_2}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <div className="row">
                    <div className="col-6 p-3 right-solid">
                      <h3 className="mb-3">
                        <b>{global[this.language]["addcourse"]}</b>
                        <p>5555</p>
                      </h3>
                    </div>
                    <div className="col-6 p-3">
                      <h3 className="mb-3">
                        <b>วิชาที่คุณเลือก</b>
                      </h3>
                      <dav className="card border-0 bg-light p-3">
                        <div className="d-flex justify-content-between align-items-center top-dash pt-3">
                          <label>รวม</label>
                          <h4>
                            <b className="text-primary">1500.-</b>
                          </h4>
                        </div>
                      </dav>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 right-solid"></div>
                    <div className="col-6 ">
                      <div className="row">
                        <div className="col-6 pb-3">
                          <button
                            className="btn btn-secondary w-100"
                            onClick={() => {
                              this.Submit_add_student(1);
                            }}
                          >
                            {global[this.language]["back"]}
                          </button>
                        </div>
                        <div className="col-6 pb-3">
                          <button
                            className="btn btn-primary w-100"
                            onClick={() => {
                              this.Submit_add_student(3);
                            }}
                          >
                            {global[this.language]["continue"]}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* เพิ่มข้อมูลนักเรียน 3 */}
              <Modal
                show={this.state.modal_add_student_3}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["add_student"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <dav className="card border-0 bg-light p-3">
                        <div className="d-flex justify-content-between align-items-center top-dash pt-3">
                          <label>รวม</label>
                          <h4>
                            <b className="text-primary">1500.-</b>
                          </h4>
                        </div>
                      </dav>
                    </div>
                    <div className="col-12 pb-3">
                      <label>วิธีชำระเงิน:</label>
                      <select
                        className="form-control"
                        value={this.state.student_payment}
                        onChange={(event) => {
                          this.setState({
                            student_payment: event.target.value,
                            student_deposit_date: "",
                            student_deposit: "",
                            student_deposit_photo: "",
                            student_deposit_card_id: "",
                            student_deposit_card_name: "",
                            student_deposit_card_expire: "",
                            student_deposit_card_cvv: "",
                            bank_select: "",
                          });
                        }}
                      >
                        <option value="">ชำระผ่านบัตร Visa/Master Card</option>
                        <option value="โอนผ่านบัญชี">โอนผ่านบัญชี</option>
                        <option value="Visa">Visa</option>
                        <option value="Master Card">Master Card</option>
                      </select>
                    </div>
                    {this.state.student_payment === "โอนผ่านบัญชี" && (
                      <>
                        <div className="col-12 pb-3">
                          <label>บัญชีธนาคาร:</label>
                          <select
                            className="form-control"
                            value={this.state.bank_select}
                            onChange={(event) => {
                              this.setState({
                                bank_select: event.target.value,
                              });
                            }}
                          >
                            <option value="">-- เลือกธนาคารที่โอน --</option>
                            {this.state.bank &&
                              this.state.bank.map((item, index) => (
                                <option value={index}>
                                  {item.bank_name} {item.bank_no} {item.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-12 pb-3">
                          <label>วันที่โอน:</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_deposit_date}
                            onChange={(event) => {
                              this.setState({
                                student_deposit_date: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-12 pb-3">
                          <label>ยอดที่โอน:</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_deposit}
                            onChange={(event) => {
                              this.setState({
                                student_deposit: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-12 pb-3">
                          <label>ภาพหลักฐาน:</label>
                          <input
                            type="file"
                            className="form-control mb-3"
                            placeholder={global[this.language]["text_hint"]}
                            onChange={(event) => {
                              this.setState({
                                student_deposit_photo: URL.createObjectURL(
                                  event.target.files[0]
                                ),
                              });
                            }}
                          />
                          {this.state.student_deposit_photo !== "" && (
                            <div className="w-100 text-center">
                              <img
                                width="200px"
                                height="200px"
                                className="img-contain"
                                src={this.state.student_deposit_photo}
                              ></img>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {(this.state.student_payment === "Visa" ||
                      this.state.student_payment === "Master Card") && (
                      <>
                        <div className="col-12 pb-3">
                          <label>เลขที่บนบัตร:</label>
                          <div className="w-100 text-right">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="XXXX XXXX XXXX XXXX"
                              value={this.state.student_deposit_card_id}
                              onChange={(event) => {
                                this.setState({
                                  student_deposit_card_id: event.target.value,
                                });
                              }}
                            />
                            <img
                              src={
                                this.state.student_payment === "Visa"
                                  ? visa
                                  : mastercard
                              }
                              className="card-icon"
                              alt="logo"
                            />
                            {/* <label className="icon-brand mr-1">
                              {"\uf1f1"}
                            </label> */}
                          </div>
                        </div>
                        <div className="col-12 pb-3">
                          <label>ชื่อบนบัตร:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_deposit_card_name}
                            onChange={(event) => {
                              this.setState({
                                student_deposit_card_name: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-6 pb-3">
                          <label>วันหมดอายุ:</label>
                          <input
                            type="month"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_deposit_card_expire}
                            onChange={(event) => {
                              this.setState({
                                student_deposit_card_expire: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-6 pb-3">
                          <label>เลข CVV:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_deposit_card_cvv}
                            onChange={(event) => {
                              this.setState({
                                student_deposit_card_cvv: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </>
                    )}
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Submit_add_student(2);
                        }}
                      >
                        {global[this.language]["back"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_add_student(4);
                        }}
                      >
                        ชำระเงิน
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* เพิ่มข้อมูลนักเรียน 4 */}
              <Modal
                show={this.state.modal_add_student_4}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <div className="row">
                    <div className="w-100 text-center mb-3">
                      <h3 className="icon mr-1 big-icon">
                        <b>{"\uf058"}</b>
                      </h3>
                      <h3>
                        <b>ชำระเงินเสร็จสิ้น</b>
                      </h3>
                    </div>
                    <div className="col-12 pb-3">
                      <dav className="card border-0 bg-light p-3">
                        <div className="d-flex justify-content-between align-items-center top-dash pt-3">
                          <label>รวม</label>
                          <h4>
                            <b className="text-primary">1500.-</b>
                          </h4>
                        </div>
                      </dav>
                    </div>

                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-outline-secondary w-100 text-primary"
                        onClick={() => {}}
                      >
                        <label className="icon mr-1">{"\uf56d"}</label>
                        ดาวโหลดใบเสร็จ
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_add_student("success");
                        }}
                      >
                        เสร็จสิ้น
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* เพิ่มข้อมูลนักเรียน userpass */}
              <Modal
                show={this.state.modal_add_student_userpass}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["add_student"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["username"]}:</label>
                      <input
                        id="add_student_username"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.student_username}
                        readOnly
                        onChange={(event) => {
                          this.setState({
                            student_username: event.target.value,
                          });
                          document.getElementById(
                            "add_student_username"
                          ).className = "form-control";
                        }}
                      />
                    </div>

                    <div className="col-12 pb-3">
                      <label>{global[this.language]["password"]}:</label>

                      <div
                        className="card w-100 bg-light p-4"
                        onClick={() => {
                          if (this.state.student_password === "") {
                            var text = "";
                            var possible =
                              "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                            for (var i = 0; i < 6; i++)
                              text += possible.charAt(
                                Math.floor(Math.random() * possible.length)
                              );
                            this.setState({ student_password: text });
                            const selBox = document.createElement("textarea");
                            selBox.style.position = "fixed";
                            selBox.style.left = "0";
                            selBox.style.top = "0";
                            selBox.style.opacity = "0";
                            selBox.value = text;
                            document.body.appendChild(selBox);
                            selBox.focus();
                            selBox.select();
                            document.execCommand("copy");
                            document.body.removeChild(selBox);
                            var x = document.getElementById("snackbar");
                            x.className = "show";
                            setTimeout(function () {
                              x.className = x.className.replace("show", "");
                            }, 3000);
                            // if (navigator.clipboard) {
                            //   navigator.clipboard.writeText(text);
                            //   this.setState({ copy: true });
                            // }
                            // setTimeout(() => {
                            //   this.setState({ copy: false });
                            // }, 2000);
                          }
                        }}
                      >
                        {this.state.student_password === "" ? (
                          <div
                            className="w-100 text-center"
                            style={{ cursor: "pointer" }}
                          >
                            <h4 className="header-password">
                              <b className="text-primary">
                                {global[this.language]["header_password"]}
                              </b>
                            </h4>
                            {/* <small>
                              <label className="text-secondary">
                                {global[this.language]["detail_password"]}
                              </label>
                            </small> */}
                          </div>
                        ) : (
                          <div className="w-100 text-center">
                            <h4>{this.state.student_password}</h4>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Submit_add_student(1);
                        }}
                      >
                        {global[this.language]["back"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_add_student("success");
                        }}
                      >
                        {global[this.language]["add"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* ดูข้อมูลทั้งหมด */}
              <Modal
                size="lg"
                show={this.state.modal_detail}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <div className="row">
                    <div className="col-6 p-3 right-solid">
                      <h3 className="mb-3">
                        <b>{global[this.language]["detail_student"]}</b>
                      </h3>
                      {/* รหัสนักเรียน */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["student_ID"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_code}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* ชื่อ-นามสกุล */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["fullname"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_name}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* ชื่อเล่น */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["nickname"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_nickname}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* หมายเลขโทรศัพท์ */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["phone"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            maxLength={10}
                            value={this.state.student_phone}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* เพศ */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["gender"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_gender}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* โรงเรียน */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["school"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_school}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* Facebook */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>Facebook:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.student_facebook}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* LINE ID */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>LINE ID:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.student_line}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6 p-3">
                      <h3 className="mb-3 text-white">
                        {/* <b>{global[this.language]["subject_detail"]}</b> */}
                        {" ."}
                      </h3>
                      {/* <dav className="card border-0 bg-light p-3">
                        <div className="d-flex justify-content-between align-items-center pt-2 w-100">
                          <label>รายการ 1,05-12-2563</label>
                          <button
                            className="btn btn-outline-secondary text-primary pt-0 pb-0"
                            onClick={() => {}}
                          >
                            <label className="icon mr-1">{"\uf56d"}</label>
                            ดาวโหลดใบเสร็จ
                          </button>
                        </div>
                      </dav>
                      <div className="col-12 p-3">
                        <div className="top-dash " />
                      </div>
                      <dav className="card border-0 bg-light p-3">
                        <div className="d-flex justify-content-between align-items-start top-dash pt-3">
                          <div>
                            <label>ภาษาจีนพื้นฐาน</label>
                            <Days day={this.state.days} />
                          </div>
                          <button
                            className="btn btn-primary text-light pt-0 pb-0"
                            onClick={() => {}}
                          >
                            <small>กำลังเรียน</small>
                          </button>
                        </div>
                      </dav> */}
                      {/* ที่อยู่ปัจจุบัน */}
                      <div className="row">
                        {/* <div className="col-12 p-3">
                          <div className="top-dash " />
                        </div> */}
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["address"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <textarea className="form-control" readOnly>
                            {this.state.student_address +
                              " ต." +
                              this.state.student_subdistrict +
                              " อ." +
                              this.state.student_district +
                              " จ." +
                              this.state.student_province +
                              " " +
                              this.state.student_zipcode}
                          </textarea>
                        </div>
                      </div>
                      {/* ชื่อผู้ปกครอง */}
                      <div className="row">
                        <div className="col-12 p-3">
                          <div className="top-dash " />
                        </div>
                        <div className="col-4 pb-3">
                          <label>
                            {global[this.language]["fullname_parent"]}:
                          </label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.parents_name}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* เบอร์โทรผู้ปกครอง */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>
                            {global[this.language]["Phone_Parents"]}:
                          </label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.parents_phone}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* Username */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["username"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_username}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 right-solid"></div>
                    <div className="col-6 ">
                      <div className="row">
                        <div className="col-6 pb-3"></div>
                        <div className="col-6 pb-3">
                          <button
                            className="btn btn-primary w-100"
                            onClick={() => {
                              this.setState({ modal_detail: false });
                            }}
                          >
                            {global[this.language]["close"]}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* แก้ไขข้อมูลนักเรียน */}
              <Modal
                show={this.state.modal_edit_student}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["edit_student"]}</b>
                  </h3>
                  <div className="row">
                    {/* <div className="col-12 pb-3">
                      <label>{global[this.language]["student_ID"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.student_code}
                        onChange={(event) => {
                          this.setState({ student_code: event.target.value });
                        }}
                      />
                    </div> */}
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["fullname"]}:</label>
                      <input
                        id="edit_student_name"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.student_name}
                        onChange={(event) => {
                          this.setState({ student_name: event.target.value });

                          document.getElementById(
                            "edit_student_name"
                          ).className = "form-control";
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["nickname"]}:</label>
                      <input
                        id="edit_student_nickname"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.student_nickname}
                        onChange={(event) => {
                          this.setState({
                            student_nickname: event.target.value,
                          });
                          document.getElementById(
                            "edit_student_nickname"
                          ).className = "form-control";
                        }}
                      />
                    </div>

                    <div className="col-6 pb-3">
                      <label>{global[this.language]["phone"]}:</label>
                      <input
                        id="edit_student_phone"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        maxLength={10}
                        value={this.state.student_phone}
                        onChange={(event) => {
                          this.setState({
                            student_phone: event.target.value,
                          });
                          document.getElementById(
                            "edit_student_phone"
                          ).className = "form-control";
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["gender"]}:</label>
                      <select
                        id="edit_student_gender"
                        className="form-control"
                        value={this.state.student_gender}
                        onChange={(event) => {
                          this.setState({
                            student_gender: event.target.value,
                          });
                          document.getElementById(
                            "edit_student_gender"
                          ).className = "form-control";
                        }}
                      >
                        <option value="">
                          --{global[this.language]["selected_gender"]}
                          --
                        </option>
                        <option value="ชาย">
                          {global[this.language]["male"]}
                        </option>
                        <option value="หญิง">
                          {global[this.language]["female"]}
                        </option>
                        <option value="ไม่ระบุ">
                          {global[this.language]["other"]}
                        </option>
                      </select>
                    </div>
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["school"]}:</label>
                      <input
                        id="edit_student_school"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.student_school}
                        onChange={(event) => {
                          this.setState({
                            student_school: event.target.value,
                          });
                          document.getElementById(
                            "edit_student_school"
                          ).className = "form-control";
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>Facebook:</label>
                      <input
                        id="edit_student_facebook"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.student_facebook}
                        onChange={(event) => {
                          this.setState({
                            student_facebook: event.target.value,
                          });
                          document.getElementById(
                            "edit_student_facebook"
                          ).className = "form-control";
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>LINE ID:</label>
                      <input
                        id="edit_student_line"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.student_line}
                        onChange={(event) => {
                          this.setState({
                            student_line: event.target.value,
                          });
                          document.getElementById(
                            "edit_student_line"
                          ).className = "form-control";
                        }}
                      />
                    </div>
                    {/* <div className="col-6 pb-3">
                      <label>{global[this.language]["Course_type"]}:</label>
                      <div className="toggle-bar">
                        <button
                          onClick={() => {
                            this.setState({
                              student_course_type: "เรียนเดี่ยว",
                            });
                          }}
                          className={
                            this.state.student_course_type === "เรียนเดี่ยว"
                              ? "toggle-left-active"
                              : "toggle-left"
                          }
                        >
                          {global[this.language]["solo_study"]}
                        </button>
                        <button
                          onClick={() => {
                            this.setState({
                              student_course_type: "เรียนกลุ่ม",
                            });
                          }}
                          className={
                            this.state.student_course_type === "เรียนกลุ่ม"
                              ? "toggle-right-active"
                              : "toggle-right"
                          }
                        >
                          {global[this.language]["group_classes"]}
                        </button>
                      </div>
                    </div>

                    <div className="col-6 pb-3" /> */}
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["address"]}:</label>
                      <input
                        id="edit_student_address"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["address_hint"]}
                        value={this.state.student_address}
                        onChange={(event) => {
                          this.setState({
                            student_address: event.target.value,
                          });
                          document.getElementById(
                            "edit_student_address"
                          ).className = "form-control";
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["province"]}:</label>
                      <select
                        id="edit_student_province"
                        className="form-control"
                        value={this.state.student_province}
                        onChange={(event) => {
                          this.setState({
                            student_province: event.target.value,
                          });
                          if (event.target.value !== "") {
                            this.GetDistrict(event.target.value);
                          }
                          document.getElementById(
                            "edit_student_province"
                          ).className = "form-control";
                        }}
                      >
                        <option value={""}>
                          --{global[this.language]["select_province"]}--
                        </option>
                        {this.state.province &&
                          this.state.province.map((item, index) => (
                            <option value={index}>{item.PROVINCE_NAME}</option>
                          ))}
                        {typeof this.state.student_province !== "number" && (
                          <option value={this.state.student_province}>
                            {this.state.student_province}
                          </option>
                        )}
                      </select>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["district"]}:</label>
                      <select
                        id="edit_student_district"
                        className="form-control"
                        value={this.state.student_district}
                        onChange={(event) => {
                          this.setState({
                            student_district: event.target.value,
                          });
                          if (event.target.value !== "") {
                            this.GetSubDistrict(event.target.value);
                          }

                          document.getElementById(
                            "edit_student_district"
                          ).className = "form-control";
                        }}
                      >
                        <option value={""}>
                          --{global[this.language]["select_district"]}--
                        </option>
                        {this.state.district &&
                          this.state.district.map((item, index) => (
                            <option value={index}>{item.DISTRICT_NAME}</option>
                          ))}
                        {this.state.student_district !== "" &&
                          typeof this.state.student_district !== "number" && (
                            <option value={this.state.student_district}>
                              {this.state.student_district}
                            </option>
                          )}
                      </select>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["subdistrict"]}:</label>
                      <select
                        id="edit_student_subdistrict"
                        className="form-control"
                        value={this.state.student_subdistrict}
                        onChange={(event) => {
                          this.setState({
                            student_subdistrict: event.target.value,
                          });
                          if (event.target.value !== "") {
                            this.GetZipcode(event.target.value);
                          }

                          document.getElementById(
                            "edit_student_subdistrict"
                          ).className = "form-control";
                        }}
                      >
                        {" "}
                        <option value={""}>
                          --{global[this.language]["select_subdistrict"]}--
                        </option>
                        {this.state.sub_district &&
                          this.state.sub_district.map((item, index) => (
                            <option value={index}>
                              {item.SUB_DISTRICT_NAME}
                            </option>
                          ))}{" "}
                        {this.state.student_subdistrict !== "" &&
                          typeof this.state.student_subdistrict !==
                            "number" && (
                            <option value={this.state.student_subdistrict}>
                              {this.state.student_subdistrict}
                            </option>
                          )}
                      </select>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["zipcode"]}:</label>
                      <select
                        id="edit_student_zipcode"
                        className="form-control"
                        value={this.state.student_zipcode}
                        onChange={(event) => {
                          this.setState({
                            student_zipcode: event.target.value,
                          });
                          document.getElementById(
                            "edit_student_zipcode"
                          ).className = "form-control";
                        }}
                      >
                        {" "}
                        <option value={""}>
                          --{global[this.language]["select_zipcode"]}--
                        </option>
                        {this.state.zipcode &&
                          this.state.zipcode.map((item, index) => (
                            <option value={index}>{item.ZIPCODE}</option>
                          ))}
                        {this.state.student_zipcode !== "" &&
                          typeof this.state.student_zipcode !== "number" && (
                            <option value={this.state.student_zipcode}>
                              {this.state.student_zipcode}
                            </option>
                          )}
                      </select>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["fullname_parent"]}:</label>
                      <input
                        id="edit_parents_name"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.parents_name}
                        onChange={(event) => {
                          this.setState({
                            parents_name: event.target.value,
                          });
                          document.getElementById(
                            "edit_parents_name"
                          ).className = "form-control";
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["Phone_Parents"]}:</label>
                      <input
                        id="edit_parents_phone"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.parents_phone}
                        maxLength={10}
                        onChange={(event) => {
                          this.setState({
                            parents_phone: event.target.value,
                          });
                          document.getElementById(
                            "edit_parents_phone"
                          ).className = "form-control";
                        }}
                      />
                    </div>
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["username"]}:</label>
                      <input
                        id="edit_student_username"
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.student_username}
                        readOnly
                        onChange={(event) => {
                          this.setState({
                            student_username: event.target.value,
                          });
                          document.getElementById(
                            "edit_student_username"
                          ).className = "form-control";
                        }}
                      />
                    </div>

                    <div className="col-12 pb-3">
                      <label>{global[this.language]["password"]}:</label>

                      <div
                        className="card w-100 bg-light p-4"
                        onClick={() => {
                          if (this.state.student_password === "") {
                            var text = "";
                            var possible =
                              "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                            for (var i = 0; i < 6; i++)
                              text += possible.charAt(
                                Math.floor(Math.random() * possible.length)
                              );
                            this.setState({ student_password: text });
                            const selBox = document.createElement("textarea");
                            selBox.style.position = "fixed";
                            selBox.style.left = "0";
                            selBox.style.top = "0";
                            selBox.style.opacity = "0";
                            selBox.value = text;
                            document.body.appendChild(selBox);
                            selBox.focus();
                            selBox.select();
                            document.execCommand("copy");
                            document.body.removeChild(selBox);
                            var x = document.getElementById("snackbar");
                            x.className = "show";
                            setTimeout(function () {
                              x.className = x.className.replace("show", "");
                            }, 3000);

                            // navigator.clipboard.writeText(text);
                            // this.setState({ copy: true });
                            // setTimeout(() => {
                            //   this.setState({ copy: false });
                            // }, 2000);
                          }
                        }}
                      >
                        {this.state.student_password === "" ? (
                          <div
                            className="w-100 text-center"
                            style={{ cursor: "pointer" }}
                          >
                            <h4 className="header-password">
                              <b className="text-primary">
                                {global[this.language]["header_password"]}
                              </b>
                            </h4>
                            {/* <small>
                              <label className="text-secondary">
                                {global[this.language]["detail_password"]}
                              </label>
                            </small> */}
                          </div>
                        ) : (
                          <div className="w-100 text-center">
                            <h4>{this.state.student_password}</h4>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_edit_student();
                        }}
                      >
                        {global[this.language]["save_edit"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* ดูข้อมูลทั้งหมด */}
              <Modal
                show={this.state.modal_import}
                onHide={() => {
                  this.Cancel();
                }}
                size={"xl"}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-custom-modal-styling-title">
                    Import Report
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-12 p-3">
                      <div>
                        <input
                          className="form-control"
                          type="file"
                          id="file"
                          ref="fileUploader"
                          // value={
                          //   this.state.file !== "" ? this.state.file.name : ""
                          // }
                          onChange={this.filePathset.bind(this)}
                        />
                        <div>{global[this.language]["file_format"]}</div>
                        <div class="row">
                          <button
                            className={
                              this.state.spinner_import
                                ? "btn btn-secondary pr-3 pl-3 mt-3"
                                : "btn btn-primary pr-3 pl-3 mt-3"
                            }
                            disabled={this.state.spinner_import}
                            onClick={() => {
                              if (this.state.file === "") {
                                Swal.fire({
                                  title: global[this.language]["alert"],
                                  text: global[this.language]["upload_alert"],
                                  icon: "warning",
                                  showCancelButton: false,
                                  allowOutsideClick: false,
                                  confirmButtonText:
                                    global[this.language]["ok"],
                                }).then((result) => {});
                              } else {
                                this.readFile();
                              }
                            }}
                          >
                            {global[this.language]["upload"]}
                          </button>
                          {this.state.spinner_import && (
                            <div class="ml-3 mt-4">
                              <div className="loader" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-12 p-3">
                      <div className="table-responsive">
                        {this.state.fileError !== "" && (
                          <table className="table table-borderless table-striped table-w-1 table-hover ">
                            <thead className="thead-dark">
                              <tr>
                                <th className="text-center">
                                  {global[this.language]["order"]}
                                </th>
                                <th style={{ minWidth: 180 }}>
                                  {global[this.language]["fullname"]}
                                </th>
                                <th className="text-center">
                                  {global[this.language]["nickname"]}
                                </th>
                                <th style={{ minWidth: 130 }}>
                                  {global[this.language]["phone"]}
                                </th>
                                <th className="text-center">
                                  {global[this.language]["gender"]}
                                </th>
                                <th>{global[this.language]["school"]}</th>
                                <th>Facebook</th>
                                <th>Line</th>
                                <th
                                  className="text-center"
                                  style={{ minWidth: 200 }}
                                >
                                  {global[this.language]["address"]}
                                </th>
                                <th style={{ minWidth: 180 }}>
                                  {global[this.language]["fullname_parent"]}
                                </th>
                                <th style={{ minWidth: 130 }}>
                                  {global[this.language]["Phone_Parents"]}
                                </th>
                                <th
                                  className="text-center"
                                  style={{ minWidth: 100 }}
                                >
                                  {global[this.language]["status"]}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* DEPARTMENT */}
                              {this.state.fileError !== "" &&
                                this.state.fileError.map((data, index) => (
                                  <tr
                                    style={{
                                      backgroundColor:
                                        data.STATUS === "Data Existed File"
                                          ? "#FF000020"
                                          : "Data Deficient"
                                          ? "#FFFF0020"
                                          : "",
                                    }}
                                  >
                                    <td className="text-center">
                                      {Number(data.NO) + 6}
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor:
                                          data.NAME.trim() === ""
                                            ? "#FFAA0050"
                                            : "",
                                      }}
                                    >
                                      {data.NAME}
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor:
                                          data.NICKNAME.trim() === ""
                                            ? "#FFAA0050"
                                            : "",
                                      }}
                                    >
                                      {data.NICKNAME}
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor:
                                          data.PHONE.trim() === "" ||
                                          data.PHONE.length !== 10
                                            ? "#FFAA0050"
                                            : "",
                                      }}
                                    >
                                      {data.PHONE}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{
                                        backgroundColor:
                                          data.GENDER.trim() === ""
                                            ? "#FFAA0050"
                                            : "",
                                      }}
                                    >
                                      {data.GENDER}
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor:
                                          data.SCHOOL.trim() === ""
                                            ? "#FFAA0050"
                                            : "",
                                      }}
                                    >
                                      {data.SCHOOL}
                                    </td>
                                    <td>{data.FACEBOOK}</td>
                                    <td>{data.LINE}</td>
                                    <td
                                      style={{
                                        backgroundColor:
                                          data.ADDRESS.trim() === ""
                                            ? "#FFAA0050"
                                            : "",
                                      }}
                                    >
                                      {data.ADDRESS +
                                        " ต." +
                                        data.SUB_DISTRICT +
                                        " อ." +
                                        data.DISTRICT +
                                        " จ." +
                                        data.PROVINCE +
                                        " " +
                                        data.ZIP_CODE}
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor:
                                          data.PARENTS_NAME.trim() === ""
                                            ? "#FFAA0050"
                                            : "",
                                      }}
                                    >
                                      {data.PARENTS_NAME}
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor:
                                          data.PARENTS_PHONE.trim() === ""
                                            ? "#FFAA0050"
                                            : "",
                                      }}
                                    >
                                      {data.PARENTS_PHONE}
                                    </td>
                                    <td className="text-center">
                                      {data.STATUS === "Data Existed File"
                                        ? "ข้อมูลซ้ำ"
                                        : data.STATUS === "Data Deficient"
                                        ? "ข้อมูลไม่ถูกต้อง"
                                        : data.STATUS ===
                                          "Data Existed Database"
                                        ? "มีข้อมูลในฐานข้อมูลแล้ว"
                                        : "-"}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
        <div id="snackbar">{global[this.language]["copy_password"]}</div>
      </div>
    );
  }
}
