import React from "react";
import { Router, Route } from "react-router";
import App from "./components/page/App/index";
import Login from "./components/page/Login/Login";
import Dashboard from "./components/page/Dashboard/Dashboard";
import NotFound from "./components/page/NotFound/Notfound";
import Admin from "./components/page/Admin/Admin";
import Branch from "./components/page/Branch/Branch";
import Finance from "./components/page/Finance/Finance";
import History from "./components/page/History/History";
import Holiday from "./components/page/Holiday/Holiday";
import Teacher from "./components/page/Teacher/Teacher";
import Student from "./components/page/Student/Student";
import Subject from "./components/page/Subject/Subject";
import Editprofile from "./components/page/Editprofile/Editprofile";
import Editpassword from "./components/page/Editpassword/Editpassword";
import Live from "./components/page/Live/Live";
import LiveClass from "./components/page/LiveClass/LiveClass";
import LiveClassHistory from "./components/page/LiveClassHistory/LiveClassHistory";
import ManageLiveClass from "./components/page/ManageLiveClass/ManageLiveClass";
import Ninja1 from "./components/Content/Ninja1";
import Ninja2 from "./components/Content/Ninja2";
import Ninja3 from "./components/Content/Ninja3";
import Ninja4 from "./components/Content/Ninja4";
import Ninja5 from "./components/Content/Ninja5";
import Ninja6 from "./components/Content/Ninja6";
import Ninja7 from "./components/Content/Ninja7";
import Ninja8 from "./components/Content/Ninja8";
import "./global.js";

const Routes = (props) => (
  <Router {...props}>
    <Route path="/index" component={App} />
    <Route path="/" component={Login} />
    <Route path="/dashboard" component={Dashboard} />
    <Route path="/admin" component={Admin} />
    <Route path="/branch" component={Branch} />
    <Route path="/finance" component={Finance} />
    <Route path="/history" component={History} />
    <Route path="/holiday" component={Holiday} />
    <Route path="/teacher" component={Teacher} />
    <Route path="/student" component={Student} />
    <Route path="/subject" component={Subject} />
    <Route path="/editprofile" component={Editprofile} />
    <Route path="/editpassword" component={Editpassword} />
    <Route path="/live" component={Live} />
    <Route path="/liveclass" component={LiveClass} />
    <Route path="/liveclasshistory" component={LiveClassHistory} />
    <Route path="/manageliveclass" component={ManageLiveClass} />
    <Route path="/ninja1" component={Ninja1} />
    <Route path="/ninja2" component={Ninja2} />
    <Route path="/ninja3" component={Ninja3} />
    <Route path="/ninja4" component={Ninja4} />
    <Route path="/ninja5" component={Ninja5} />
    <Route path="/ninja6" component={Ninja6} />
    <Route path="/ninja7" component={Ninja7} />
    <Route path="/ninja8" component={Ninja8} />
    <Route path="*" component={NotFound} />
  </Router>
);

export default Routes;
