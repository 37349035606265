import React, { Component } from "react";
import { Link } from "react-router";
import ReactDOM from "react-dom";
import LOGO from "./Logo.png";
import SideMenu from "../../Content/SideMenu/SideMenu";
import SideMenuUser from "../../Content/SideMenuUser/SideMenuUser";
import Header from "../../Content/Header/Header";
import Days from "../../Content/Days/Days";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import HTML5Backend from "react-dnd-html5-backend";
// import DragDropContext from "react-dnd";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import tinycolor from "tinycolor2";

import Swal from "sweetalert2";
import jwt from "jsonwebtoken";
// import Pagination from "react-js-pagination";

import { Modal } from "react-bootstrap";

import { format, subHours, startOfMonth, setDate } from "date-fns";
import {
  MonthlyBody,
  MonthlyCalendar,
  MonthlyNav,
  DefaultMonthlyEventItem,
  MonthlyDay,
} from "@zach.codes/react-calendar/react-calendar";

import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import Cookies from "universal-cookie";
import { closestIndexTo } from "date-fns/esm";
import "@zach.codes/react-calendar/react-calendar/dist/calendar-tailwind.css";
import "@szhsin/react-menu/dist/index.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../../bootstrap.css";
const cookies = new Cookies();
const localizer = momentLocalizer(moment);
var DnDCalendar = withDragAndDrop(Calendar);
export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiKey: "lEGFVofIRwKXYYMV4f1rzw",
      apiSecret: "O6NLwUK7U79VvlOnGHnpd84Iz7NiVo9ZVWpW",

      loading: true,
      currentMonth: startOfMonth(new Date()),
      modal_detail_teacher: false,
      modal_detail_subject: false,
      modal_detail_student_all: false,
      modal_add_subject: false,
      modal_edit_liveclass: false,
      modal_add_teacher_1: false,
      modal_add_teacher_2: false,
      modal_edit_teacher: false,
      modal_add_teacher_userpass: false,
      modal_detail_student: false,
      x: 0,
      y: 0,
      context_menu_Teacher: false,
      context_menu_Day: false,
      context_menu_Class: false,
      context_menu_Holiday: false,
      holiday: [{ date: "1/5/2021", name: "วันหยุด" }],
      teacher: [],
      student: [],
      events: [
        // {
        //   id: 0,
        //   title: "All Day Event very long title",
        //   start: new Date(2021, 7, 8, 10, 0, 0),
        //   end: new Date(2021, 7, 8, 13, 0, 0),
        //   color: "#fdaaaa",
        // },
        // {
        //   id: 100001,
        //   title: "วันกอบกู้เอกราช",
        //   start: new Date(2021, 4, 9, 16, 0, 0),
        //   end: new Date(2021, 4, 9, 18, 0, 0),
        //   color: "#eeda9d90",
        // },
      ],
      days: [
        { name: "จ.", day: "จันทร์", select: false, time: "" },
        { name: "อ.", day: "อังคาร", select: true, time: "" },
        { name: "พ.", day: "พุธ", select: true, time: "" },
        { name: "พฤ.", day: "พฤหัสบดี", select: false, time: "" },
        { name: "ศ.", day: "ศุกร์", select: false, time: "" },
        { name: "ส.", day: "เสาร์", select: true, time: "" },
        { name: "อา.", day: "อาทิตย์", select: false, time: "" },
      ],

      subject_id: "",
      subject_name: "",
      subject_department: "",
      subject_course: "",
      subject_teacher: "",
      subject_startdate: "",
      subject_enddate: "",
      subject_study_time: "",
      subject_total_hour: "",
      subject_hour: "",
      subject_course_type: "เรียนเดี่ยว",
      subject_study_type: "เวลาเหมือนกัน",
      detail: [0, 0, 0],
      de_id: "",
      subject_days: [
        { day: "จันทร์", select: false, time: "" },
        { day: "อังคาร", select: false, time: "" },
        { day: "พุธ", select: false, time: "" },
        { day: "พฤหัสบดี", select: false, time: "" },
        { day: "ศุกร์", select: false, time: "" },
        { day: "เสาร์", select: false, time: "" },
        { day: "อาทิตย์", select: false, time: "" },
      ],
      subject_price: "",
      subject_student: [],
      day: ["จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส.", "อา."],

      teacher_id: "",
      teacher_name: "",
      teacher_nickname: "",
      teacher_phone: "",
      teacher_gender: "",
      teacher_idcard: "",
      teacher_type: "",
      teacher_color: "",
      teacher_tax: "",
      teacher_salary: "",
      teacher_facebook: "",
      teacher_line: "",
      teacher_address: "",
      teacher_province: "",
      teacher_district: "",
      teacher_subdistrict: "",
      teacher_zipcode: "",
      teacher_username: "",
      teacher_password: "",
      teacher_apikey: "",
      teacher_apisecret: "",

      step: 0,

      student_id: "",
      student_name: "",
      student_nickname: "",
      student_phone: "",
      student_gender: "",
      student_school: "",
      student_facebook: "",
      student_line: "",
      student_course_type: "",
      student_address: "",
      student_province: "",
      student_district: "",
      student_zipcode: "",
      parents_name: "",
      parents_phone: "",

      modal_add_liveclass: false,
      modal_edit_liveclass: false,
      modal_detail_live: false,
      live_id: "",
      batch_name: "",
      zoom_api_key: "",
      zoom_api_secret: "",
      meeting_number: "",
      meeting_password: "",
      live_date: "",
      live_startime: "",
      live_endtime: "",
      liveclass_teacher: "",
      liveclass_student: [],
      search_teacher: "",
      search_student: "",
      menu_Teacher: false,
      menu_Student: false,
      defaultDate: "",
      defaultDateShow: "",
      calendar: [],
      calendar_month: "",
      calendar_year: "",

      holiday: "",
      holiday_name: "",
      holiday_date: "",
      modal_add_holiday: false,
      modal_edit_holiday: false,
      index: 0,
      modal_teacher_list: false,
    };
  }
  language = cookies.get("language") ? cookies.get("language") : "TH";
  componentDidMount() {
    let header = document.getElementsByClassName("rbc-header");

    if (this.language === "TH") {
      let array = ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"];
      for (let i = 0; i < header.length; i++) {
        header[i].children[0].innerHTML = array[i];
      }
    }
    let newDate = new Date();
    if (this.props.location.query.month) {
      let month = this.props.location.query.month;
      let year = this.props.location.query.year;
      newDate.setMonth(Number(month) - 1);
      newDate.setFullYear(year);
      this.setState({
        defaultDateShow: year + "-" + month,
      });
    }
    this.setState({
      calendar_month: newDate.getMonth(),
      calendar_year: newDate.getFullYear(),
    });
    this.getCalendar(newDate);
    if (!this.language) {
      cookies.set("language", "TH", { path: "/" });
    }

    if (cookies.get("user")) {
      this.setState({ loading: false });
      this.GetProvince();
      setTimeout(() => {
        this.getAllData();
      }, 1);
    } else {
      Swal.fire({
        title: global[this.language]["alert"],
        text: global[this.language]["retrieve_data_fail"],
        icon: "warning",
        showCancelButton: false,
        allowOutsideClick: false,
        confirmButtonText: global[this.language]["ok"],
      }).then((result) => {
        if (result.isConfirmed) {
          cookies.remove("token", { path: "/" });
          cookies.remove("user", { path: "/" });

          window.location.href = "/";
        }
      });
    }
  }
  getCalendar(date) {
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var start = firstDay.getDate();
    var end = lastDay.getDate();
    let month = [];
    for (var i = 0; i < 6; i++) {
      let week = [];
      for (var j = 0; j < 7; j++) {
        if (i === 0) {
          if (j < firstDay.getDay()) {
            week.push(0);
          } else {
            week.push(start);
            start++;
          }
        } else if (i === 5) {
          if (start > end) {
            week.push(0);
          } else {
            week.push(start);
            start++;
          }
        } else {
          if (start > end) {
            week.push(0);
          } else {
            week.push(start);
            start++;
          }
        }
      }
      if (week[0] === 0 && week[6] === 0) {
      } else {
        month.push(week);
      }
    }
    this.setState({
      calendar: month,
      calendar_month: firstDay.getMonth(),
      calendar_year: firstDay.getFullYear(),
    });
  }
  moveEvent = (data) => {
    console.log(data.event);
    let index_data = 0;
    this.state.events.find((element, index) => {
      if (element.id === data.event.id) {
        index_data = index;
      }
    });
    let day =
      ("00" + data.start.getDate()).slice(-2) +
      "/" +
      ("00" + Number(data.start.getMonth() + 1)).slice(-2) +
      "/" +
      data.event.start.getFullYear();
    let d = data.event.data;
    this.setState({
      live_id: d._id,
      batch_name: d.batch_name,
      zoom_api_key: d.zoom_api_key,
      zoom_api_secret: d.zoom_api_secret,
      meeting_number: d.meeting_number,
      meeting_password: d.meeting_password,
      live_date: day,
      live_startime: d.live_startime,
      live_endtime: d.live_endtime,
      liveclass_teacher: d.teacher,
      liveclass_student: d.student,
      search_teacher: "",
      search_student: "",
    });
    this.setState((state) => {
      state.events[index_data].start = data.start;
      state.events[index_data].end = data.end;
      return { events: [...state.events] };
    });
    setTimeout(() => {
      this.Submit_edit_liveclass();
      console.log(this.state.events);
    }, 1);
  };
  getAllData = () => {
    let date =
      ("00" + (this.state.calendar_month + 1)).slice(-2) +
      "/" +
      this.state.calendar_year;

    fetch(global.httpURL + "/api/dashbord", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({
        date: date,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (
          response.message === "Auth Failed" ||
          response.message === "Token is null"
        ) {
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["unavailable"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.Logout();
            }
          });
        } else {
          let data = response.data;
          let array = [];
          let array1 = [];
          let same1 = "";
          let index1 = 0;
          for (let liveclass of data.live_class) {
            array1.find((element) => {
              if (element === liveclass.batch_name) {
                index1 += 1;
              }
            });
            for (let i = 0; i < index1; i++) {
              same1 += "!";
            }
            array.push({
              id: liveclass._id,
              title: liveclass.batch_name + same1,
              start: new Date(
                liveclass.live_date.split("/")[2],
                Number(liveclass.live_date.split("/")[1]) - 1,
                liveclass.live_date.split("/")[0],
                liveclass.live_startime.split(":")[0],
                liveclass.live_startime.split(":")[1],
                0
              ),
              end: new Date(
                liveclass.live_date.split("/")[2],
                Number(liveclass.live_date.split("/")[1]) - 1,
                liveclass.live_date.split("/")[0],
                liveclass.live_endtime.split(":")[0],
                liveclass.live_endtime.split(":")[1],
                0
              ),
              color: liveclass.teacher.color,
              data: liveclass,
            });
            same1 = "";
            array1.push(liveclass.batch_name);
          }
          let arrays = [];
          let same = "";
          let index = 0;
          for (let holiday of data.holiday) {
            arrays.find((element) => {
              if (element === holiday.holiday_name) {
                index += 1;
              }
            });
            for (let i = 0; i < index; i++) {
              same += "#";
            }
            array.push({
              id: holiday._id,
              title: holiday.holiday_name + same,
              start: new Date(
                holiday.holiday_date.split("/")[2],
                Number(holiday.holiday_date.split("/")[1]) - 1,
                holiday.holiday_date.split("/")[0],
                0,
                0,
                0
              ),
              end: new Date(
                holiday.holiday_date.split("/")[2],
                Number(holiday.holiday_date.split("/")[1]) - 1,
                holiday.holiday_date.split("/")[0],
                0,
                0,
                0
              ),
              color: "#eeda9d90",
              data: holiday,
            });
            same = "";
            arrays.push(holiday.holiday_name);
          }
          this.setState({
            events: array,
            teacher: data.teacher,
            student: data.student,
          });
          setTimeout(() => {
            let event_data =
              document.getElementsByClassName("rbc-event-content");

            for (let data of event_data) {
              data.innerHTML = data.innerHTML.replaceAll("!", "");
              data.innerHTML = data.innerHTML.replaceAll("#", "");
            }
          }, 10);
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        Swal.fire({
          title: global[this.language]["alert"],
          text: global[this.language]["retrieve_data_fail"],
          icon: "warning",
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonText: global[this.language]["ok"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.Logout();
          }
        });
      });
  };
  Logout = () => {
    fetch(global.httpURL + "/api/logout", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "token " + cookies.get("token"),
      },
      // mode: "no-cors",
    })
      .then((response) => response.json())
      .then((response) => {
        cookies.remove("token", { path: "/" });
        cookies.remove("user", { path: "/" });

        window.location.href = "/";
      })
      .catch((err) => {
        //window.location.href = "/dashboard";
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_logout"],
          "warning"
        );
      });
  };
  Submit_add_teacher = () => {
    // DONE
    let num = /^^[0][0-9]*$/;
    let numid = /^[0-9]*$/;
    if (this.state.teacher_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_first_last_name"],
        "warning"
      );
    } else if (this.state.teacher_nickname === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_nickname"],
        "warning"
      );
    } else if (this.state.teacher_phone === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_phone_number"],
        "warning"
      );
    } else if (
      this.state.teacher_phone.trim().length < 10 ||
      !num.test(this.state.teacher_phone)
    ) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_phone_true"],
        "warning"
      );
    } else if (this.state.teacher_gender === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["select_gender"],
        "warning"
      );
    } else if (this.state.teacher_idcard === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_ID_card"],
        "warning"
      );
    } else if (
      this.state.teacher_idcard.length < 13 ||
      !numid.test(this.state.teacher_idcard)
    ) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_ID_card_true"],
        "warning"
      );
    } else if (this.state.teacher_apikey === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_apikey"],
        "warning"
      );
    } else if (this.state.teacher_apisecret === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_apisecret"],
        "warning"
      );
    } else if (this.state.teacher_address === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_address"],
        "warning"
      );
    } else if (this.state.teacher_province === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_province"],
        "warning"
      );
    } else if (this.state.teacher_district === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_district"],
        "warning"
      );
    } else if (this.state.teacher_subdistrict === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_subdistrict"],
        "warning"
      );
    } else if (this.state.teacher_zipcode === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_zipcode"],
        "warning"
      );
    } else if (this.state.teacher_username === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_username"],
        "warning"
      );
    } else if (this.state.teacher_password === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_password"],
        "warning"
      );
    } else {
      let district = !isNaN(this.state.teacher_district)
        ? this.state.district[this.state.teacher_district].DISTRICT_NAME
        : this.state.teacher_district;
      let subdistrict = !isNaN(this.state.teacher_subdistrict)
        ? this.state.sub_district[this.state.teacher_subdistrict]
            .SUB_DISTRICT_NAME
        : this.state.teacher_subdistrict;
      let province = !isNaN(this.state.teacher_province)
        ? this.state.province[this.state.teacher_province].PROVINCE_NAME
        : this.state.teacher_province;
      let zipcode =
        this.state.teacher_zipcode.length !== 5
          ? this.state.zipcode[this.state.teacher_zipcode].ZIPCODE
          : this.state.teacher_zipcode;
      this.setState({ spinner: true });
      fetch(global.httpURL + "/api/teacher", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + cookies.get("token"),
        },
        body: JSON.stringify({
          name: this.state.teacher_name,
          nickname: this.state.teacher_nickname,
          phone: this.state.teacher_phone,
          gender: this.state.teacher_gender,
          id_card_number: this.state.teacher_idcard,
          facebook: this.state.teacher_facebook,
          line: this.state.teacher_line,
          address: this.state.teacher_address,
          district: district,
          sub_district: subdistrict,
          province: province,
          zip_code: zipcode,
          zoom_api_key: this.state.teacher_apikey,
          zoom_api_secret: this.state.teacher_apisecret,
          username: this.state.teacher_username,
          password: this.state.teacher_password,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          this.setState({ spinner: false });
          if (response.message === "This zoom api key is existed") {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["zoom_api_key_error"],
              "warning"
            );
          } else if (response.message === "This zoom api secret is existed") {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["zoom_api_secret_error"],
              "warning"
            );
          } else {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["add_success"],
              "success"
            );
            this.Cancel();
            this.getAllData();
          }
        })
        .catch((err) => {
          this.setState({ spinner: false });
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["add_not_success"],
            "warning"
          );
        });
    }
  };
  Submit_edit_teacher = () => {
    // DONE
    this.setState({ spinner: true });
    let num = /^^[0][0-9]*$/;
    if (this.state.teacher_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_first_last_name"],
        "warning"
      );
    } else if (this.state.teacher_nickname === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_nickname"],
        "warning"
      );
    } else if (this.state.teacher_phone === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_phone_number"],
        "warning"
      );
    } else if (
      this.state.teacher_phone.trim().length < 10 ||
      !num.test(this.state.teacher_phone)
    ) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_phone_true"],
        "warning"
      );
    } else if (this.state.teacher_gender === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["select_gender"],
        "warning"
      );
    } else if (this.state.teacher_idcard === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_ID_card"],
        "warning"
      );
    } else if (this.state.teacher_address === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_address"],
        "warning"
      );
    } else if (this.state.teacher_province === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_province"],
        "warning"
      );
    } else if (this.state.teacher_district === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_district"],
        "warning"
      );
    } else if (this.state.teacher_subdistrict === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_subdistrict"],
        "warning"
      );
    } else if (this.state.teacher_zipcode === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_zipcode"],
        "warning"
      );
    } else if (this.state.teacher_apikey === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_apikey"],
        "warning"
      );
    } else if (this.state.teacher_apisecret === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_apisecret"],
        "warning"
      );
    } else {
      let data = "";
      if (this.state.teacher_password === "") {
        data = {
          name: this.state.teacher_name,
          nickname: this.state.teacher_nickname,
          phone: this.state.teacher_phone,
          gender: this.state.teacher_gender,
          id_card_number: this.state.teacher_idcard,
          facebook: this.state.teacher_facebook,
          line: this.state.teacher_line,
          address: this.state.teacher_address,
          district:
            this.state.district[this.state.teacher_district].DISTRICT_NAME,
          sub_district:
            this.state.sub_district[this.state.teacher_subdistrict]
              .SUB_DISTRICT_NAME,
          province:
            this.state.province[this.state.teacher_province].PROVINCE_NAME,
          zip_code: this.state.zipcode[this.state.teacher_zipcode].ZIPCODE,
          zoom_api_key: this.state.teacher_apikey,
          zoom_api_secret: this.state.teacher_apisecret,
          username: this.state.teacher_username,
        };
      } else {
        data = {
          name: this.state.teacher_name,
          nickname: this.state.teacher_nickname,
          phone: this.state.teacher_phone,
          gender: this.state.teacher_gender,
          id_card_number: this.state.teacher_idcard,
          address: this.state.teacher_address,
          district:
            this.state.district[this.state.teacher_district].DISTRICT_NAME,
          sub_district:
            this.state.sub_district[this.state.teacher_subdistrict]
              .SUB_DISTRICT_NAME,
          province:
            this.state.province[this.state.teacher_province].PROVINCE_NAME,
          zip_code: this.state.zipcode[this.state.teacher_zipcode].ZIPCODE,
          zoom_api_key: this.state.teacher_apikey,
          zoom_api_secret: this.state.teacher_apisecret,
          username: this.state.teacher_username,
          password: this.state.teacher_password,
        };
      }
      fetch(global.httpURL + "/api/teacher/" + this.state.teacher_id, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + cookies.get("token"),
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({ spinner: false });
          if (response.message === "This zoom api key is existed") {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["zoom_api_key_error"],
              "warning"
            );
          } else if (response.message === "This zoom api secret is existed") {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["zoom_api_secret_error"],
              "warning"
            );
          } else {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["edit_success"],
              "success"
            );
            this.Cancel();
            this.getAllData();
          }
        })
        .catch((err) => {
          this.setState({ spinner: false });
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["edit_not_success"],
            "warning"
          );
        });
    }
  };
  Delete_teacher = (user_id) => {
    // DONE
    this.setState({ spinner: true });
    Swal.fire({
      title: global[this.language]["confirm_delete"],
      showCancelButton: true,
      confirmButtonText: global[this.language]["confirm"],
      cancelButtonText: global[this.language]["cancel"],
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(global.httpURL + "/api/teacher/" + user_id, {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
        })
          .then((response) => response.json())
          .then((response) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_success"],
              "success"
            );
            this.getAllData();
          })
          .catch((err) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_not_success"],
              "warning"
            );
          });
      }
    });
  };
  Submit_add_holiday = () => {
    if (this.state.holiday_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_holiday"],
        "warning"
      );
    } else if (this.state.holiday_date === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_date"],
        "warning"
      );
    } else {
      let date =
        this.state.holiday_date.getDate().toString().length === 1
          ? "0" + this.state.holiday_date.getDate()
          : this.state.holiday_date.getDate();
      let month =
        this.state.holiday_date.getMonth().toString().length === 1
          ? "0" + (this.state.holiday_date.getMonth() + 1)
          : this.state.holiday_date.getMonth() + 1;
      let fulldate =
        date + "/" + month + "/" + this.state.holiday_date.getFullYear();
      setTimeout(() => {
        fetch(global.httpURL + "/api/holiday", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
          body: JSON.stringify({
            holiday_name: this.state.holiday_name,
            holiday_date: fulldate,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            console.log(response);
            this.setState({ spinner: false });
            if (!response.auth) {
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["add_not_success"],
                "warning"
              );
            } else {
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["add_success"],
                "success"
              );
              this.Cancel();
              this.getAllData();
            }
          })
          .catch((err) => {
            this.setState({ spinner: false });
          });
      }, 10);
    }
  };
  Submit_edit_holiday = () => {
    if (this.state.holiday_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_holiday"],
        "warning"
      );
    } else if (this.state.holiday_date === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_date"],
        "warning"
      );
    } else {
      if (typeof this.state.holiday_date === "number") {
        this.setState({
          holiday_date: new Date(this.state.holiday_date),
        });
      }
      setTimeout(() => {
        let date =
          this.state.holiday_date.getDate().toString().length === 1
            ? "0" + this.state.holiday_date.getDate()
            : this.state.holiday_date.getDate();
        let month =
          this.state.holiday_date.getMonth().toString().length === 1
            ? "0" + (this.state.holiday_date.getMonth() + 1)
            : this.state.holiday_date.getMonth() + 1;
        let fulldate =
          date + "/" + month + "/" + this.state.holiday_date.getFullYear();

        setTimeout(() => {
          fetch(
            global.httpURL +
              "/api/holiday/" +
              this.state.events[this.state.index].id,
            {
              method: "PUT",
              headers: {
                "content-type": "application/json",
                accept: "application/json",
                Authorization: "Bearer " + cookies.get("token"),
              },
              body: JSON.stringify({
                holiday_name: this.state.holiday_name,
                holiday_date: fulldate,
              }),
            }
          )
            .then((response) => response.json())
            .then((response) => {
              console.log(response);
              this.setState({ spinner: false });
              if (!response.auth) {
                Swal.fire(
                  global[this.language]["alert"],
                  global[this.language]["edit_not_success"],
                  "warning"
                );
              } else {
                Swal.fire(
                  global[this.language]["alert"],
                  global[this.language]["edit_success"],
                  "success"
                );
                this.Cancel();
                this.getAllData();
              }
            })
            .catch((err) => {
              console.error(err);
              this.setState({ spinner: false });
            });
        }, 10);
      }, 1);
    }
  };
  Delete_holiday = () => {
    Swal.fire({
      title: global[this.language]["confirm_delete"],
      showCancelButton: true,
      confirmButtonText: global[this.language]["confirm"],
      cancelButtonText: global[this.language]["cancel"],
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ spinner: true });
        fetch(
          global.httpURL +
            "/api/holiday/" +
            this.state.events[this.state.index].id,
          {
            method: "DELETE",
            headers: {
              "content-type": "application/json",
              accept: "application/json",
              Authorization: "Bearer " + cookies.get("token"),
            },
          }
        )
          .then((response) => response.json())
          .then((response) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_success"],
              "success"
            );
            this.Cancel();
            this.getAllData();
          })
          .catch((err) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_not_success"],
              "warning"
            );
          });
      }
    });
  };
  Submit_add_liveclass = () => {
    if (this.state.batch_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_subject"],
        "warning"
      );
    } else if (this.state.live_date === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_date"],
        "warning"
      );
    } else if (this.state.live_startime === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_startdate"],
        "warning"
      );
    } else if (this.state.live_endtime === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_enddate"],
        "warning"
      );
    } else if (this.state.liveclass_teacher === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_teacher"],
        "warning"
      );
    } else if (this.state.liveclass_student.length === 0) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_student"],
        "warning"
      );
    } else {
      this.setState({ spinner: true });
      let stu = [];
      let date =
        this.state.live_date.getDate().toString().length === 1
          ? "0" + this.state.live_date.getDate()
          : this.state.live_date.getDate();
      let month =
        this.state.live_date.getMonth().toString().length === 1
          ? "0" + (this.state.live_date.getMonth() + 1)
          : this.state.live_date.getMonth() + 1;
      let fulldate =
        date + "/" + month + "/" + this.state.live_date.getFullYear();
      for (let s of this.state.liveclass_student) {
        stu.push(s._id);
      }

      var zoom_api_key = this.state.liveclass_teacher.zoom_api_key;
      var zoom_api_secret = this.state.liveclass_teacher.zoom_api_secret;
      const payload = {
        iss: zoom_api_key,
        exp: new Date().getTime() + 15000,
      };
      const token = jwt.sign(payload, zoom_api_secret);
      const meeting_password = Math.random().toString(36).slice(-8);
      const start_time = new Date(this.state.live_date);
      start_time.toLocaleString("en-US", { timeZone: "Asia/Bangkok" });
      start_time.setHours(Number(this.state.live_startime.split(":")[0]));
      start_time.setMinutes(Number(this.state.live_startime.split(":")[1]));
      var setting = {
        topic: this.state.batch_name,
        type: 2,
        start_time: start_time,
        duration: 50,
        timezone: "Asia/Bangkok",
        password: meeting_password,
        agenda:
          "วิชา : " +
          this.state.batch_name +
          "\nวันที่ Live : " +
          fulldate +
          "\nเวลาเริ่มต้น : " +
          this.state.live_startime +
          "\nเวลาสิ้นสุด : " +
          this.state.live_endtime,
        settings: {
          host_video: true,
          participant_video: true,
          cn_meeting: false,
          in_meeting: false,
          join_before_host: true,
          mute_upon_entry: true,
          watermark: false,
          approval_type: 2,
          audio: "both",
          auto_recording: "cloud",
          alternative_hosts: "",
          close_registration: true,
          waiting_room: false,
          contact_name: "DevGrit",
          contact_email: "developer@grit.technology",
          registrants_email_notification: false,
          registrants_confirmation_email: false,
          meeting_authentication: false,
          authentication_domains: "",
        },
      };

      setTimeout(() => {
        fetch(global.httpURL + "/api/liveclass", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
          body: JSON.stringify({
            batch_name: this.state.batch_name,
            zoom_api_key: this.state.zoom_api_key,
            zoom_api_secret: this.state.zoom_api_secret,
            token_zoom: token,
            live_date: fulldate,
            live_startime: this.state.live_startime,
            live_endtime: this.state.live_endtime,
            setting_zoom: JSON.stringify(setting),
            teacher: this.state.liveclass_teacher._id,
            student: stu,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            console.log(response);
            this.setState({ spinner: false });
            if (!response.auth) {
              if (response.message === "meeting number already exists") {
                Swal.fire(
                  global[this.language]["alert"],
                  global[this.language]["enter_your_meeting_similar"],
                  "warning"
                );
              } else {
                Swal.fire(
                  global[this.language]["alert"],
                  global[this.language]["add_not_success"],
                  "warning"
                );
              }
            } else {
              if (response.data.code) {
                Swal.fire(
                  global[this.language]["alert"],
                  global[this.language]["add_not_success_api"],
                  "warning"
                );
              } else {
                Swal.fire(
                  global[this.language]["alert"],
                  global[this.language]["add_success"],
                  "success"
                );
                this.Cancel();
                this.getAllData();
              }
            }
          })
          .catch((err) => {
            this.setState({ spinner: false });
          });
      }, 10);
    }
  };
  Submit_edit_liveclass = () => {
    if (this.state.batch_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_subject"],
        "warning"
      );
    } else if (this.state.live_date === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_date"],
        "warning"
      );
    } else if (this.state.live_startime === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_startdate"],
        "warning"
      );
    } else if (this.state.live_endtime === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_enddate"],
        "warning"
      );
    } else if (this.state.liveclass_teacher === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_teacher"],
        "warning"
      );
    } else if (this.state.liveclass_student.length === 0) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_student"],
        "warning"
      );
    } else {
      this.setState({ spinner: true });
      let stu = [];
      for (let s of this.state.liveclass_student) {
        stu.push(s._id);
      }
      console.log(
        JSON.stringify({
          batch_name: this.state.batch_name,
          zoom_api_key: this.state.zoom_api_key,
          zoom_api_secret: this.state.zoom_api_secret,
          meeting_number: this.state.meeting_number.replace(/ /g, ""),
          meeting_password: this.state.meeting_password,
          live_date: this.state.live_date,
          live_startime: this.state.live_startime,
          live_endtime: this.state.live_endtime,
          teacher: this.state.liveclass_teacher._id,
          student: stu,
          status: "active",
        })
      );
      fetch(global.httpURL + "/api/liveclass/" + this.state.live_id, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + cookies.get("token"),
        },
        body: JSON.stringify({
          batch_name: this.state.batch_name,
          zoom_api_key: this.state.zoom_api_key,
          zoom_api_secret: this.state.zoom_api_secret,
          meeting_number: this.state.meeting_number.replace(/ /g, ""),
          meeting_password: this.state.meeting_password,
          live_date: this.state.live_date,
          live_startime: this.state.live_startime,
          live_endtime: this.state.live_endtime,
          teacher: this.state.liveclass_teacher._id,
          student: stu,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          this.setState({ spinner: false });
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["edit_success"],
            "success"
          );
          this.Cancel();
          this.getAllData();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ spinner: false });
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["edit_not_success"],
            "warning"
          );
        });
    }
  };
  Delete_Live = (index) => {
    Swal.fire({
      title: global[this.language]["confirm_delete"],
      showCancelButton: true,
      confirmButtonText: global[this.language]["confirm"],
      cancelButtonText: global[this.language]["cancel"],
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ spinner: true });
        const payload = {
          iss: "lEGFVofIRwKXYYMV4f1rzw",
          exp: new Date().getTime() + 5000,
        };
        const token = jwt.sign(payload, "mFp4xsulVfZN7vDDVpqdAmdjZJ9OphfiGuXN");
        fetch(global.httpURL + "/api/liveclass/" + index, {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
          body: JSON.stringify({
            token_zoom: token,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_success"],
              "success"
            );
            this.getAllData();
          })
          .catch((err) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_not_success"],
              "warning"
            );
          });
      }
    });
  };
  Cancel = () => {
    this.setState({
      modal_detail_teacher: false,
      modal_detail_subject: false,
      modal_detail_student: false,
      modal_add_subject: false,
      modal_edit_liveclass: false,
      modal_add_teacher_1: false,
      modal_add_teacher_2: false,
      modal_edit_teacher: false,
      modal_add_teacher_userpass: false,

      holiday_name: "",
      holiday_date: "",
      modal_add_holiday: false,
      modal_edit_holiday: false,
      subject_id: "",
      subject_name: "",
      subject_department: "",
      subject_course: "",
      subject_teacher: "",
      subject_startdate: "",
      subject_enddate: "",
      subject_study_time: "",
      subject_total_hour: "",
      subject_hour: "",
      subject_course_type: "เรียนเดี่ยว",
      subject_study_type: "เวลาเหมือนกัน",
      detail: [0, 0, 0],
      de_id: "",
      subject_days: [
        { day: "จันทร์", select: false, time: "" },
        { day: "อังคาร", select: false, time: "" },
        { day: "พุธ", select: false, time: "" },
        { day: "พฤหัสบดี", select: false, time: "" },
        { day: "ศุกร์", select: false, time: "" },
        { day: "เสาร์", select: false, time: "" },
        { day: "อาทิตย์", select: false, time: "" },
      ],
      subject_price: "",
      subject_student: [],

      day: ["จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส.", "อา."],

      teacher_name: "",
      teacher_nickname: "",
      teacher_phone: "",
      teacher_gender: "",
      teacher_idcard: "",
      teacher_type: "",
      teacher_color: "",
      teacher_tax: "",
      teacher_salary: "",
      teacher_address: "",
      teacher_province: "",
      teacher_district: "",
      teacher_zipcode: "",
      teacher_apikey: "",
      teacher_apisecret: "",
      step: 0,

      modal_add_liveclass: false,
      modal_edit_liveclass: false,
      modal_detail_live: false,
      student_add: [],
      live_id: "",
      batch_name: "",
      zoom_api_key: "",
      zoom_api_secret: "",
      meeting_number: "",
      meeting_password: "",
      live_date: "",
      live_startime: "",
      live_endtime: "",
      liveclass_teacher: "",
      liveclass_student: [],
      search_teacher: "",
      search_student: "",
      menu_Teacher: false,
      menu_Student: false,

      district: [],
      sub_district: [],
      zipcode: [],
      modal_teacher_list: false,
    });
  };
  GetProvince = () => {
    fetch(global.httpURL + "/api/address/provinces", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          province: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  GetDistrict = (index) => {
    var id = this.state.province[index].PROVINCE_ID;

    fetch(global.httpURL + "/api/address/districts", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ provinces_id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          district: response.data,
          teacher_district: "",
          teacher_subdistrict: "",
          teacher_zipcode: "",
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  GetSubDistrict = (index) => {
    var id = this.state.district[index].DISTRICT_ID;
    fetch(global.httpURL + "/api/address/subDistricts", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ districts_id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          sub_district: response.data,
          teacher_subdistrict: "",
          teacher_zipcode: "",
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  GetZipcode = (index) => {
    var id = this.state.sub_district[index].SUB_DISTRICT_ID;

    fetch(global.httpURL + "/api/address/zipcodes", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ subDistricts_id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          zipcode: response.data,
          teacher_zipcode: "",
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handleContextMenu_Teacher(e) {
    e.preventDefault();
    let detail = this.state.detail;
    detail[0] = Number(e.target.value);
    this.setState({
      x: e.nativeEvent.pageX,
      y: e.nativeEvent.pageY,
      context_menu_Teacher: true,
      context_menu_Day: false,
      context_menu_Class: false,
      context_menu_Holiday: false,
      detail: detail,
    });
  }
  handleContextMenu_Day(e) {
    e.preventDefault();
    this.setState({
      x: e.nativeEvent.pageX,
      y: e.nativeEvent.pageY - 80,
      context_menu_Teacher: false,
      context_menu_Day: true,
      context_menu_Class: false,
      context_menu_Holiday: false,
    });
  }
  handleContextMenu_Class(e) {
    e.preventDefault();
    let detail = this.state.detail;

    let live = this.state.class.find((o) => o.title === e.target.value);
    this.setState({
      x: e.nativeEvent.pageX,
      y: e.nativeEvent.pageY - 80,
      context_menu_Teacher: false,
      context_menu_Day: false,
      context_menu_Class: true,
      context_menu_Holiday: false,
      detail: detail,
    });
    if (live) {
      this.setState({
        live_id: live.data._id,
        batch_name: live.data.batch_name,
        zoom_api_key: live.data.zoom_api_key,
        zoom_api_secret: live.data.zoom_api_secret,
        meeting_number: live.data.meeting_number,
        meeting_password: live.data.meeting_password,
        live_date: live.data.live_date.slice(0, 10),
        live_startime: live.data.live_startime.slice(11, 16),
        live_endtime: live.data.live_endtime.slice(11, 16),
        liveclass_teacher: live.data.teacher,
        liveclass_student: live.data.student,
      });
    }
  }
  handleClick(e) {
    console.log(e);
    if (cookies.get("user").user.status === "admin") {
      if (e.target.id === "context-menu" && e._reactName === "onContextMenu") {
      } else if (
        (e.target.className === "rbc-event" ||
          e.target.className === "rbc-event-content" ||
          e.target.className === "rbc-event rbc-selected") &&
        e._reactName === "onContextMenu"
      ) {
        e.preventDefault();
        if (e.target.style.backgroundColor === "rgba(238, 218, 157, 0.565)") {
          console.log(e.target.children[0].attributes.title.value);
          this.state.events.find((element, i) => {
            if (element.title === e.target.children[0].attributes.title.value) {
              this.setState({ index: i });
            }
          });

          this.setState({
            x: e.nativeEvent.pageX,
            y: e.nativeEvent.pageY - 80,
            context_menu_Teacher: false,
            context_menu_Day: false,
            context_menu_Class: false,
            context_menu_Holiday: true,
          });
        } else {
          console.log(e.target.title);
          this.state.events.find((element, i) => {
            if (element.title === e.target.title) {
              this.setState({ index: i });
            }
          });
          if (e.target.title.includes("#")) {
            this.setState({
              x: e.nativeEvent.pageX,
              y: e.nativeEvent.pageY - 80,
              context_menu_Teacher: false,
              context_menu_Day: false,
              context_menu_Class: false,
              context_menu_Holiday: true,
            });
          } else {
            this.setState({
              x: e.nativeEvent.pageX,
              y: e.nativeEvent.pageY,
              context_menu_Teacher: false,
              context_menu_Day: false,
              context_menu_Class: true,
              context_menu_Holiday: false,
            });
          }
        }
      } else if (
        (e.target.className === "rbc-day-bg" ||
          e.target.className === "rbc-date-cell" ||
          e.target.className === "rbc-row" ||
          e.target.className === "rbc-row-segment" ||
          e.target.className === "rbc-date-cell rbc-current" ||
          e.target.className.includes("rbc-today")) &&
        e._reactName === "onContextMenu"
      ) {
        e.preventDefault();

        this.setState({
          x: e.nativeEvent.pageX,
          y: e.nativeEvent.pageY - 80,
          context_menu_Teacher: false,
          context_menu_Day: true,
          context_menu_Class: false,
          context_menu_Holiday: false,
        });
      } else {
        if (e.target.id === "teacher-menu") {
          this.setState({
            menu_Teacher: true,
            menu_Student: false,
          });
        } else if (e.target.id === "student-menu") {
          this.setState({
            menu_Student: true,
            menu_Teacher: false,
          });
        } else {
          this.setState({
            menu_Teacher: false,
            menu_Student: false,
          });
        }
        this.setState({
          x: 0,
          y: 0,
          context_menu_Teacher: false,
          context_menu_Day: false,
          context_menu_Class: false,
          context_menu_Holiday: false,
        });
      }
    } else {
      if (
        (e.target.className === "rbc-event" ||
          e.target.className === "rbc-event-content" ||
          e.target.className === "rbc-event rbc-selected") &&
        e._reactName === "onClick"
      ) {
        e.preventDefault();
        if (e.target.style.backgroundColor === "rgba(238, 218, 157, 0.565)") {
        } else {
          console.log(e.target);
          this.state.events.find((element, i) => {
            if (element.title === e.target.title) {
              this.setState({ index: i });
            }
          });
          setTimeout(() => {
            if (e.target.title.includes("#")) {
            } else {
              let data = this.state.events[this.state.index].data;
              this.setState({
                modal_detail_live: true,
                live_id: data._id,
                batch_name: data.batch_name,
                zoom_api_key: data.zoom_api_key,
                zoom_api_secret: data.zoom_api_secret,
                meeting_number: data.meeting_number,
                meeting_password: data.meeting_password,
                live_date: data.live_date,
                live_startime: data.live_startime,
                live_endtime: data.live_endtime,
                liveclass_teacher: data.teacher,
                liveclass_student: data.student,
                search_teacher: "",
                search_student: "",
              });
              // this.setState({
              //   x: e.nativeEvent.pageX,
              //   y: e.nativeEvent.pageY,
              //   context_menu_Teacher: false,
              //   context_menu_Day: false,
              //   context_menu_Class: true,
              //   context_menu_Holiday: false,
              // });
            }
          }, 10);
        }
      }
    }
  }
  GenUser = () => {
    let year = new Date().getFullYear() + 543;
    let month = new Date().getMonth() + 1;
    let day = new Date().getDate();
    let time =
      new Date().getHours() * 60 * 60 +
      new Date().getMinutes() * 60 +
      new Date().getSeconds();

    let gen = new Date().getTime();
    year = year.toString().slice(2, 4);
    if (month.toString().length === 1) {
      month = "0" + month;
    }
    if (day.toString().length === 1) {
      day = "0" + day;
    }
    gen = gen.toString().slice(4, 10);
    this.setState({
      teacher_username: "TC" + year + month + gen,
    });
  };
  render() {
    return (
      <div
        className="w-100 h-auto minh-100"
        onClick={this.handleClick.bind(this)}
        onContextMenu={this.handleClick.bind(this)}
      >
        <div className="d-flex h-auto minh-100">
          <div className="h-auto minh-100">
            {!this.state.zoom && cookies.get("user") && (
              <>
                {cookies.get("user").user.status === "teacher" ||
                cookies.get("user").user.status === "student" ? (
                  <SideMenuUser page="dashboard" />
                ) : (
                  <SideMenu page="dashboard" />
                )}
              </>
            )}
          </div>
          <div className="w-100-body">
            <Header />
            <div className="container-fluid pt-3">
              <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-3">
                  <div className="card border-0 p-4 flex-row justify-content-between align-items-center w-100">
                    <b className="icon mr-1 text-primary big-icon">
                      {"\uf63d"}
                    </b>
                    <div className="text-right">
                      <b className="text-secondary">
                        {global[this.language]["total_learner"]}
                      </b>
                      <br />
                      <h4 className="text-primary">
                        <b>{this.state.student.length}</b>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-3">
                  <div className="card border-0 p-4 flex-row justify-content-between align-items-center">
                    <b className="icon mr-1 text-primary big-icon">
                      {"\uf5db"}
                    </b>
                    <div className="text-right">
                      <b className="text-secondary">
                        {global[this.language]["total_subject"]}
                      </b>
                      <br />
                      <h4 className="text-primary">
                        <b>16</b>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 mb-3">
                  <div className="card border-0 p-4 flex-row justify-content-between align-items-center">
                    <b className="icon mr-1 text-primary big-icon">
                      {"\uf51c"}
                    </b>
                    <div className="text-right">
                      <b className="text-secondary">
                        {global[this.language]["total_teacher"]}
                      </b>
                      <br />
                      <h4 className="text-primary">
                        <b>{this.state.teacher.length}</b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* context_menu_Teacher */}
              <div
                className={
                  this.state.context_menu_Teacher
                    ? "menu-context-box active"
                    : "menu-context-box hidden"
                }
                style={{ left: this.state.x, top: this.state.y }}
              >
                <button
                  className="menu-context-button "
                  onClick={() => {
                    this.setState({ modal_detail_teacher: true });
                  }}
                >
                  <label>{global[this.language]["teacher_see"]}</label>
                </button>
                <button
                  className="menu-context-button "
                  onClick={() => {
                    this.Cancel();
                    this.setState({ modal_add_teacher_1: true });
                    this.GenUser();
                  }}
                >
                  <label>{global[this.language]["teacher_add"]}</label>
                </button>
                <button
                  className="menu-context-button "
                  onClick={() => {
                    let teacher =
                      this.state.teacher &&
                      this.state.teacher[this.state.detail[0]];
                    this.setState({
                      modal_edit_teacher: true,
                      teacher_id: teacher._id,
                      teacher_name: teacher.user.name,
                      teacher_nickname: teacher.nickname,
                      teacher_phone: teacher.user.phone,
                      teacher_gender: teacher.gender,
                      teacher_idcard: teacher.id_card_number,
                      teacher_facebook: teacher.facebook,
                      teacher_line: teacher.line,
                      teacher_address: teacher.address,
                      teacher_province: teacher.province,
                      teacher_district: teacher.district,
                      teacher_subdistrict: teacher.sub_district,
                      teacher_zipcode: teacher.zip_code,
                      teacher_username: teacher.user.username,
                      teacher_apikey: teacher.zoom_api_key,
                      teacher_apisecret: teacher.zoom_api_secret,
                    });
                  }}
                >
                  <label>{global[this.language]["teacher_edit"]}</label>
                </button>
                <button
                  className="menu-context-button "
                  onClick={() => {
                    let teacher =
                      this.state.teacher &&
                      this.state.teacher[this.state.detail[0]];
                    this.Delete_teacher(teacher._id);
                  }}
                >
                  <label>{global[this.language]["teacher_delete"]}</label>
                </button>
              </div>
              {/* context_menu_Day */}
              <div
                className={
                  this.state.context_menu_Day
                    ? "menu-context-box active"
                    : "menu-context-box hidden"
                }
                style={{ left: this.state.x, top: this.state.y + 80 }}
              >
                <button
                  className="menu-context-button "
                  onClick={() => {
                    this.setState({ modal_add_holiday: true });
                  }}
                >
                  <label>{global[this.language]["add_holiday"]}</label>
                </button>

                <button
                  className="menu-context-button "
                  onClick={() => {
                    this.setState({
                      modal_add_liveclass: true,
                      student_add: this.state.student,
                    });
                  }}
                >
                  <label>{global[this.language]["add_course"]}</label>
                </button>
              </div>
              {/* context_menu_Holiday */}
              <div
                className={
                  this.state.context_menu_Holiday
                    ? "menu-context-box active"
                    : "menu-context-box hidden"
                }
                style={{ left: this.state.x, top: this.state.y + 80 }}
              >
                <button
                  className="menu-context-button "
                  onClick={() => {
                    this.setState({ modal_add_holiday: true });
                  }}
                >
                  <label>{global[this.language]["add_holiday"]}</label>
                </button>
                <button
                  className="menu-context-button "
                  onClick={() => {
                    this.setState({
                      modal_edit_holiday: true,
                      holiday_name:
                        this.state.events[this.state.index].data.holiday_name,
                      holiday_date: new Date(
                        this.state.events[
                          this.state.index
                        ].data.holiday_date.split("/")[2],
                        Number(
                          this.state.events[
                            this.state.index
                          ].data.holiday_date.split("/")[1]
                        ) - 1,
                        this.state.events[
                          this.state.index
                        ].data.holiday_date.split("/")[0]
                      ),
                    });
                  }}
                >
                  <label>{global[this.language]["edit_holiday"]}</label>
                </button>
                <button
                  className="menu-context-button "
                  onClick={() => {
                    this.Delete_holiday();
                  }}
                >
                  <label>{global[this.language]["delete_holiday"]}</label>
                </button>
                {/* <button
                  className="menu-context-button "
                  onClick={() => {
                    this.setState({ modal_add_subject: true });
                  }}
                >
                  <label>{global[this.language]["add_course"]}</label>
                </button> */}
              </div>
              {/* context_menu_Class */}
              <div
                className={
                  this.state.context_menu_Class
                    ? "menu-context-box active"
                    : "menu-context-box hidden"
                }
                style={{ left: this.state.x, top: this.state.y }}
              >
                <button
                  className="menu-context-button "
                  onClick={() => {
                    let data = this.state.events[this.state.index].data;
                    this.setState({
                      modal_detail_live: true,
                      live_id: data._id,
                      batch_name: data.batch_name,
                      zoom_api_key: data.zoom_api_key,
                      zoom_api_secret: data.zoom_api_secret,
                      meeting_number: data.meeting_number,
                      meeting_password: data.meeting_password,
                      live_date: data.live_date,
                      live_startime: data.live_startime,
                      live_endtime: data.live_endtime,
                      liveclass_teacher: data.teacher,
                      liveclass_student: data.student,
                      search_teacher: "",
                      search_student: "",
                    });
                  }}
                >
                  <label>{global[this.language]["see_subject"]}</label>
                </button>
                {/* <button
                  className="menu-context-button "
                  onClick={() => {
                    this.setState({
                      modal_add_liveclass: true,
                      live_id: "",
                      batch_name: "",
                      zoom_api_key: "",
                      zoom_api_secret: "",
                      meeting_number: "",
                      meeting_password: "",
                      live_date: "",
                      live_startime: "",
                      live_endtime: "",
                      liveclass_teacher: "",
                      liveclass_student: [],
                      search_teacher: "",
                      search_student: "",
                      menu_Teacher: false,
                      menu_Student: false,
                    });
                  }}
                >
                  <label>{global[this.language]["add_course"]}</label>
                </button> */}
                <button
                  className="menu-context-button "
                  onClick={() => {
                    let data = this.state.events[this.state.index].data;
                    let student = this.state.student;
                    for (let i = 0; i < this.state.student.length; i++) {
                      for (let s of data.student) {
                        if (student[i].nickname === s.nickname) {
                          student[i].select = true;
                        }
                      }
                    }
                    this.setState({
                      modal_edit_liveclass: true,
                      live_id: data._id,
                      batch_name: data.batch_name,
                      zoom_api_key: data.zoom_api_key,
                      zoom_api_secret: data.zoom_api_secret,
                      meeting_number: data.meeting_number,
                      meeting_password: data.meeting_password,
                      live_date: data.live_date,
                      live_startime: data.live_startime,
                      live_endtime: data.live_endtime,
                      liveclass_teacher: data.teacher,
                      liveclass_student: data.student,
                      student_add: student,
                      search_teacher: "",
                      search_student: "",
                    });
                  }}
                >
                  <label>{global[this.language]["edit_subject"]}</label>
                </button>
                <button
                  className="menu-context-button "
                  onClick={() => {
                    this.Delete_Live(
                      this.state.events[this.state.index].data._id
                    );
                  }}
                >
                  <label>{global[this.language]["delete_subject"]}</label>
                </button>
              </div>
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="card border-0 p-4">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-xl-6">
                        <div className="row">
                          <h3 className="text-primary mr-2 mb-2">
                            <b>
                              {global[this.language]["month"][
                                this.state.calendar_month
                              ] +
                                " " +
                                this.state.calendar_year}
                            </b>
                          </h3>

                          <input
                            type="month"
                            className="form-control month mb-2"
                            value={this.state.defaultDateShow}
                            onChange={(event) => {
                              window.location.href =
                                "/dashboard?month=" +
                                event.target.value.split("-")[1] +
                                "&year=" +
                                event.target.value.split("-")[0];
                            }}
                          />
                        </div>
                      </div>
                      {cookies.get("user").user.status === "admin" && (
                        <div className="col-12 col-sm-12 col-md-12 col-xl-6">
                          <div className="d-flex justify-content-end">
                            {this.state.teacher &&
                              this.state.teacher.map((item, index) => (
                                <>
                                  {index < 4 && (
                                    <button
                                      className="card border-0 pb-1 pl-2 pr-2 ml-2 mt-2"
                                      style={{
                                        backgroundColor: item.color,
                                        paddingTop: 6,
                                        color: tinycolor(item.color).isLight()
                                          ? "#000000"
                                          : "#FFFFFF",
                                      }}
                                      onContextMenu={this.handleContextMenu_Teacher.bind(
                                        this
                                      )}
                                      value={index}
                                      id="context-menu"
                                    >
                                      ครู{item.nickname}
                                    </button>
                                  )}
                                  {index === 4 && (
                                    <button
                                      className="card border-0 pb-1 pl-2 pr-2 ml-3 mt-2"
                                      style={{
                                        backgroundColor: "#162d4f",
                                        color: "#FFFFFF",
                                        paddingTop: 8,
                                        alignItems: "center",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          modal_teacher_list: true,
                                        });
                                      }}
                                    >
                                      <label className="icon mr-1">
                                        {"\uf39b"}
                                      </label>
                                    </button>
                                  )}
                                </>
                              ))}

                            {this.state.teacher.length === 0 && (
                              <button
                                className="btn btn-primary mr-2 ml-2 mt-2 p-1"
                                onClick={() => {
                                  this.Cancel();
                                  this.setState({ modal_add_teacher_1: true });
                                  this.GenUser();
                                }}
                              >
                                <label className="icon mr-1">{"\uf055"}</label>
                                {global[this.language]["add_teacher"]}
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    <DnDCalendar
                      defaultDate={
                        this.props.location.query.month
                          ? new Date(
                              this.props.location.query.year,
                              Number(this.props.location.query.month) - 1,
                              1
                            )
                          : new Date()
                      }
                      views={["month"]}
                      popup={true}
                      onShowMore={(events, date) => {
                        setTimeout(() => {
                          let event_data =
                            document.getElementsByClassName(
                              "rbc-event-content"
                            );

                          for (let data of event_data) {
                            data.innerHTML = data.innerHTML.replaceAll("!", "");
                            data.innerHTML = data.innerHTML.replaceAll("#", "");
                          }
                        }, 10);
                      }}
                      localizer={localizer}
                      events={this.state.events}
                      onEventDrop={this.moveEvent}
                      eventPropGetter={(event) => {
                        const backgroundColor = event.color;
                        if (event.color === "#eeda9d90") {
                          return {
                            style: {
                              backgroundColor,
                              borderRadius: 0,
                              height: 120,
                              marginTop: -30,
                              color: "#162D4F",
                              textAlign: "center",
                              paddingTop: 40,
                            },
                          };
                        } else {
                          return { style: { backgroundColor } };
                        }
                      }}
                      resizable={false}
                      draggableAccessor={(event) => {
                        let now = new Date().getTime();
                        let date = event.start.getTime();
                        if (event.color === "#eeda9d90") {
                          return false;
                        } else if (date < now) {
                          return false;
                        } else {
                          if (cookies.get("user").user.status === "admin") {
                            return true;
                          } else {
                            return false;
                          }
                        }
                      }}
                      startAccessor="start"
                      endAccessor="end"
                      style={{ minHeight: 600, marginTop: 30 }}
                    />
                  </div>
                </div>
              </div>

              {/* MODAL */}

              {/* อาจารย์ */}
              {/* ดูข้อมูลอาจารย์ทั้งหมด */}
              <Modal
                show={this.state.modal_detail_teacher}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <div className="row">
                    <div className="col-12 p-3">
                      <h3 className="mb-3">
                        <b>{global[this.language]["teacher_detail"]}</b>
                      </h3>

                      {/* ชื่อ-นามสกุล */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["fullname"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={
                              this.state.teacher &&
                              this.state.teacher[this.state.detail[0]] &&
                              this.state.teacher[this.state.detail[0]].user.name
                            }
                            readOnly
                          />
                        </div>
                      </div>
                      {/* หมายเลขโทรศัพท์ */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["phone"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={
                              this.state.teacher &&
                              this.state.teacher[this.state.detail[0]] &&
                              this.state.teacher[this.state.detail[0]].user
                                .phone
                            }
                            readOnly
                          />
                        </div>
                      </div>
                      {/* เพศ */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["gender"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={
                              this.state.teacher &&
                              this.state.teacher[this.state.detail[0]] &&
                              this.state.teacher[this.state.detail[0]].gender
                            }
                            readOnly
                          />
                        </div>
                      </div>
                      {/* หมายเลขบัตรประชาชน */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["ID_card"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={
                              this.state.teacher &&
                              this.state.teacher[this.state.detail[0]] &&
                              this.state.teacher[this.state.detail[0]]
                                .id_card_number
                            }
                            readOnly
                          />
                        </div>
                      </div>
                      {/* facebook */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>Facebook:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={
                              this.state.teacher &&
                              this.state.teacher[this.state.detail[0]] &&
                              this.state.teacher[this.state.detail[0]].facebook
                            }
                            readOnly
                          />
                        </div>
                      </div>
                      {/* line */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>Line:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={
                              this.state.teacher &&
                              this.state.teacher[this.state.detail[0]] &&
                              this.state.teacher[this.state.detail[0]].line
                            }
                            readOnly
                          />
                        </div>
                      </div>

                      {/* ที่อยู่ปัจจุบัน */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["address"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={
                              this.state.teacher &&
                              this.state.teacher[this.state.detail[0]] &&
                              this.state.teacher[this.state.detail[0]].address
                            }
                            readOnly
                          />
                        </div>
                      </div>
                      {/* จังหวัด */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["province"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={
                              this.state.teacher &&
                              this.state.teacher[this.state.detail[0]] &&
                              this.state.teacher[this.state.detail[0]].province
                            }
                            readOnly
                          />
                        </div>
                      </div>
                      {/* อำเภอ */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["district"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={
                              this.state.teacher &&
                              this.state.teacher[this.state.detail[0]] &&
                              this.state.teacher[this.state.detail[0]].district
                            }
                            readOnly
                          />
                        </div>
                      </div>
                      {/* ตำบล */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["subdistrict"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={
                              this.state.teacher &&
                              this.state.teacher[this.state.detail[0]] &&
                              this.state.teacher[this.state.detail[0]]
                                .sub_district
                            }
                            readOnly
                          />
                        </div>
                      </div>
                      {/* รหัสไปรษณีย์ */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["zipcode"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={
                              this.state.teacher &&
                              this.state.teacher[this.state.detail[0]] &&
                              this.state.teacher[this.state.detail[0]].zip_code
                            }
                            readOnly
                          />
                        </div>
                      </div>
                      {/* Zoom API Key */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>Zoom API Key:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={
                              this.state.teacher &&
                              this.state.teacher[this.state.detail[0]] &&
                              this.state.teacher[this.state.detail[0]]
                                .zoom_api_key
                            }
                            readOnly
                          />
                        </div>
                      </div>
                      {/* Zoom API Secret */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>Zoom API Secret:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={
                              this.state.teacher &&
                              this.state.teacher[this.state.detail[0]] &&
                              this.state.teacher[this.state.detail[0]]
                                .zoom_api_secret
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 pb-3"></div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.setState({ modal_detail_teacher: false });
                        }}
                      >
                        {global[this.language]["close"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* เพิ่มข้อมูลอาจารย์ */}
              <Modal
                size="lg"
                show={this.state.modal_add_teacher_1}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <div className="row">
                    <div
                      className="col-xl-6 col-lg-6 col-12 "
                      style={{
                        borderRightColor: "#DFDFDF",
                        borderRightWidth: 1,
                      }}
                    >
                      <h3 className="mb-3">
                        <b>{global[this.language]["add_teacher"]}</b>
                      </h3>
                      <div className="row">
                        <div className="col-6 pb-3">
                          <label>{global[this.language]["fullname"]}:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_name}
                            onChange={(event) => {
                              this.setState({
                                teacher_name: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-6 pb-3">
                          <label>{global[this.language]["nickname"]}:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_nickname}
                            onChange={(event) => {
                              this.setState({
                                teacher_nickname: event.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className="col-6 pb-3">
                          <label>{global[this.language]["phone"]}:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            maxLength={10}
                            value={this.state.teacher_phone}
                            onChange={(event) => {
                              this.setState({
                                teacher_phone: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-6 pb-3">
                          <label>{global[this.language]["gender"]}:</label>
                          <select
                            className="form-control"
                            value={this.state.teacher_gender}
                            onChange={(event) => {
                              this.setState({
                                teacher_gender: event.target.value,
                              });
                            }}
                          >
                            <option value="">
                              --{global[this.language]["selected_gender"]}
                              --
                            </option>
                            <option value="ชาย">
                              {global[this.language]["male"]}
                            </option>
                            <option value="หญิง">
                              {global[this.language]["female"]}
                            </option>
                            <option value="ไม่ระบุ">
                              {global[this.language]["other"]}
                            </option>
                          </select>
                        </div>
                        <div className="col-12 pb-3">
                          <label>{global[this.language]["ID_card"]}:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_idcard}
                            maxLength={13}
                            onChange={(event) => {
                              this.setState({
                                teacher_idcard: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-6 pb-3">
                          <label>Facebook:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_facebook}
                            onChange={(event) => {
                              this.setState({
                                teacher_facebook: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-6 pb-3">
                          <label>Line:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_line}
                            onChange={(event) => {
                              this.setState({
                                teacher_line: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div
                          style={{
                            width: "100%",
                            borderBottomColor: "#ccc",
                            borderBottomWidth: 1,
                            margin: 16,
                            marginTop: 20,
                            marginBottom: 30,
                          }}
                        />
                        <div className="col-12 pb-3 d-flex align-items-center">
                          <a
                            className="btn btn-link bg-zoom mr-2 text-white"
                            href="https://marketplace.zoom.us"
                            target="_blank"
                          >
                            <label className="icon mr-1">{"\uf03d"}</label>
                            ZOOM MARKETPLACE
                          </a>
                          <a
                            href="https://drive.google.com/file/d/1JBNK-34NoyiPABenovAMScas0rE-cHKs/view?usp=sharing"
                            target="_blank"
                          >
                            {global[this.language]["howto"]}
                            <label className="icon mr-1">{"\uf05a"}</label>
                          </a>
                        </div>
                        <div className="col-12 pb-3">
                          <label>Zoom API Key:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_apikey}
                            onChange={(event) => {
                              this.setState({
                                teacher_apikey: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-12 pb-3">
                          <label>Zoom API Secret:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_apisecret}
                            onChange={(event) => {
                              this.setState({
                                teacher_apisecret: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-12 ">
                      <h3 className="mb-3 text-light">
                        <b>. </b>
                      </h3>
                      <div className="row">
                        <div className="col-12 pb-3">
                          <label>{global[this.language]["address"]}:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["address_hint"]}
                            value={this.state.teacher_address}
                            onChange={(event) => {
                              this.setState({
                                teacher_address: event.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-6 pb-3">
                          <label>{global[this.language]["province"]}:</label>
                          <select
                            className="form-control"
                            value={this.state.teacher_province}
                            onChange={(event) => {
                              this.setState({
                                teacher_province: event.target.value,
                              });
                              this.GetDistrict(event.target.value);
                            }}
                          >
                            <option value="">
                              --{global[this.language]["select_province"]}
                              --
                            </option>
                            {this.state.province &&
                              this.state.province.map((item, index) => (
                                <option value={index}>
                                  {item.PROVINCE_NAME}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-6 pb-3">
                          <label>{global[this.language]["district"]}:</label>
                          <select
                            className="form-control"
                            value={this.state.teacher_district}
                            onChange={(event) => {
                              this.setState({
                                teacher_district: event.target.value,
                              });
                              this.GetSubDistrict(event.target.value);
                            }}
                          >
                            <option value="">
                              --{global[this.language]["select_district"]}
                              --
                            </option>
                            {this.state.district &&
                              this.state.district.map((item, index) => (
                                <option value={index}>
                                  {item.DISTRICT_NAME}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-6 pb-3">
                          <label>{global[this.language]["subdistrict"]}:</label>
                          <select
                            className="form-control"
                            value={this.state.teacher_subdistrict}
                            onChange={(event) => {
                              this.setState({
                                teacher_subdistrict: event.target.value,
                              });
                              this.GetZipcode(event.target.value);
                            }}
                          >
                            <option value="">
                              --{global[this.language]["select_subdistrict"]}
                              --
                            </option>
                            {this.state.sub_district &&
                              this.state.sub_district.map((item, index) => (
                                <option value={index}>
                                  {item.SUB_DISTRICT_NAME}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-6 pb-3">
                          <label>{global[this.language]["zipcode"]}:</label>
                          <select
                            className="form-control"
                            value={this.state.teacher_zipcode}
                            onChange={(event) => {
                              this.setState({
                                teacher_zipcode: event.target.value,
                              });
                            }}
                          >
                            <option value="">
                              --{global[this.language]["select_zipcode"]}
                              --
                            </option>
                            {this.state.zipcode &&
                              this.state.zipcode.map((item, index) => (
                                <option value={index}>{item.ZIPCODE}</option>
                              ))}
                          </select>
                        </div>

                        <div className="col-12 pb-3">
                          <label>{global[this.language]["username"]}:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_username}
                            readOnly
                            onChange={(event) => {
                              this.setState({
                                teacher_username: event.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className="col-12 pb-3">
                          <label>{global[this.language]["password"]}:</label>

                          <div
                            className="card w-100 bg-light p-4"
                            onClick={() => {
                              if (this.state.teacher_password === "") {
                                var text = "";
                                var possible =
                                  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                                for (var i = 0; i < 6; i++)
                                  text += possible.charAt(
                                    Math.floor(Math.random() * possible.length)
                                  );
                                this.setState({ teacher_password: text });
                                const selBox =
                                  document.createElement("textarea");
                                selBox.style.position = "fixed";
                                selBox.style.left = "0";
                                selBox.style.top = "0";
                                selBox.style.opacity = "0";
                                selBox.value = text;
                                document.body.appendChild(selBox);
                                selBox.focus();
                                selBox.select();
                                document.execCommand("copy");
                                document.body.removeChild(selBox);
                                var x = document.getElementById("snackbar");
                                x.className = "show";
                                setTimeout(function () {
                                  x.className = x.className.replace("show", "");
                                }, 3000);
                              }
                            }}
                          >
                            {this.state.teacher_password === "" ? (
                              <div
                                className="w-100 text-center"
                                style={{ cursor: "pointer" }}
                              >
                                <h4 className="header-password">
                                  <b className="text-primary">
                                    {global[this.language]["header_password"]}
                                  </b>
                                </h4>
                              </div>
                            ) : (
                              <div className="w-100 text-center">
                                <h4>{this.state.teacher_password}</h4>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 pb-3 mt-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3 mt-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_add_teacher();
                        }}
                      >
                        {global[this.language]["add"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* แก้ไขข้อมูลอาจารย์ทั้งหมด */}
              <Modal
                show={this.state.modal_edit_teacher}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <div className="row">
                    <div className="col-12 p-3 ">
                      <h3 className="mb-3">
                        <b>{global[this.language]["teacher_edit"]}</b>
                      </h3>

                      {/* ชื่อ-นามสกุล */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["fullname"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_name}
                            onChange={(event) => {
                              this.setState({
                                teacher_name: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* หมายเลขโทรศัพท์ */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["phone"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            maxLength={10}
                            value={this.state.teacher_phone}
                            onChange={(event) => {
                              this.setState({
                                teacher_phone: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* เพศ */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["gender"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <select
                            className="form-control"
                            value={this.state.teacher_gender}
                            onChange={(event) => {
                              this.setState({
                                teacher_gender: event.target.value,
                              });
                            }}
                          >
                            <option value="">
                              --
                              {global[this.language]["selected_gender"]}
                              --
                            </option>
                            <option value="ชาย">
                              {global[this.language]["male"]}
                            </option>
                            <option value="หญิง">
                              {global[this.language]["female"]}
                            </option>
                            <option value="ไม่ระบุ">
                              {global[this.language]["other"]}
                            </option>
                          </select>
                        </div>
                      </div>
                      {/* หมายเลขบัตรประชาชน */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["ID_card"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_idcard}
                            maxLength={13}
                            onChange={(event) => {
                              this.setState({
                                teacher_idcard: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* ประเภทอัตราจ้าง */}
                      {/* <div className="row">
                        <div className="col-4 pb-3">
                          <label>ประเภทอัตราจ้าง:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <div className="toggle-bar">
                            <button
                              onClick={() => {
                                this.setState({
                                  teacher_type: "รายชั่วโมง",
                                });
                              }}
                              className={
                                this.state.teacher_type === "รายชั่วโมง"
                                  ? "toggle-left-active"
                                  : "toggle-left"
                              }
                            >
                              รายชั่วโมง
                            </button>
                            <button
                              onClick={() => {
                                this.setState({
                                  teacher_type: "รายเดือน",
                                });
                              }}
                              className={
                                this.state.teacher_type === "รายเดือน"
                                  ? "toggle-right-active"
                                  : "toggle-right"
                              }
                            >
                              รายเดือน
                            </button>
                          </div>
                        </div>
                      </div> */}
                      {/* ค่าจ้าง */}
                      {/* <div className="row">
                        <div className="col-4 pb-3">
                          <label>ค่าจ้าง:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_id}
                            onChange={(event) => {
                              this.setState({ teacher_id: event.target.value });
                            }}
                          />
                        </div>
                      </div> */}
                      {/* สีประจำตัว */}
                      {/* <div className="row">
                        <div className="col-4 pb-3">
                          <label>สีประจำตัว:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="color"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_id}
                            onChange={(event) => {
                              this.setState({ teacher_id: event.target.value });
                            }}
                          />
                        </div>
                      </div> */}
                      {/* เลขที่ผู้เสียภาษี */}
                      {/* <div className="row">
                        <div className="col-4 pb-3">
                          <label>เลขที่ผู้เสียภาษี:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_id}
                            onChange={(event) => {
                              this.setState({ teacher_id: event.target.value });
                            }}
                          />
                        </div>
                      </div> */}
                      {/* ที่อยู่ปัจจุบัน */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["address"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_address}
                            onChange={(event) => {
                              this.setState({
                                teacher_address: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* จังหวัด */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["province"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <select
                            className="form-control"
                            value={this.state.teacher_province}
                            onChange={(event) => {
                              this.setState({
                                teacher_province: event.target.value,
                              });
                              this.GetDistrict(event.target.value);
                            }}
                          >
                            <option value="">
                              --{global[this.language]["select_province"]}
                              --
                            </option>
                            {this.state.province &&
                              this.state.province.map((item, index) => (
                                <option value={index}>
                                  {item.PROVINCE_NAME}
                                </option>
                              ))}
                            {typeof this.state.teacher_province !==
                              "number" && (
                              <option value={this.state.teacher_province}>
                                {this.state.teacher_province}
                              </option>
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["district"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <select
                            className="form-control"
                            value={this.state.teacher_district}
                            onChange={(event) => {
                              this.setState({
                                teacher_district: event.target.value,
                              });
                              this.GetSubDistrict(event.target.value);
                            }}
                          >
                            <option value="">
                              --{global[this.language]["select_district"]}
                              --
                            </option>
                            {this.state.district &&
                              this.state.district.map((item, index) => (
                                <option value={index}>
                                  {item.DISTRICT_NAME}
                                </option>
                              ))}
                            {this.state.teacher_district !== "" &&
                              typeof this.state.teacher_district !==
                                "number" && (
                                <option value={this.state.teacher_district}>
                                  {this.state.teacher_district}
                                </option>
                              )}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["subdistrict"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <select
                            className="form-control"
                            value={this.state.teacher_subdistrict}
                            onChange={(event) => {
                              this.setState({
                                teacher_subdistrict: event.target.value,
                              });
                              this.GetZipcode(event.target.value);
                            }}
                          >
                            <option value="">
                              --{global[this.language]["select_subdistrict"]}
                              --
                            </option>
                            {this.state.sub_district &&
                              this.state.sub_district.map((item, index) => (
                                <option value={index}>
                                  {item.SUB_DISTRICT_NAME}
                                </option>
                              ))}{" "}
                            {this.state.teacher_subdistrict !== "" &&
                              typeof this.state.teacher_subdistrict !==
                                "number" && (
                                <option value={this.state.teacher_subdistrict}>
                                  {this.state.teacher_subdistrict}
                                </option>
                              )}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["zipcode"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <select
                            className="form-control"
                            value={this.state.teacher_zipcode}
                            onChange={(event) => {
                              this.setState({
                                teacher_zipcode: event.target.value,
                              });
                            }}
                          >
                            <option value="">
                              --{global[this.language]["select_zipcode"]}
                              --
                            </option>
                            {this.state.zipcode &&
                              this.state.zipcode.map((item, index) => (
                                <option value={index}>{item.ZIPCODE}</option>
                              ))}
                            {this.state.teacher_zipcode !== "" &&
                              typeof this.state.teacher_zipcode !==
                                "number" && (
                                <option value={this.state.teacher_zipcode}>
                                  {this.state.teacher_zipcode}
                                </option>
                              )}
                          </select>
                        </div>
                      </div>
                      {/* รายวิชาที่สอน */}
                      {/* <div className="row">
                        <div className="col-4 pb-3">
                          <label>รายวิชาที่สอน:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_id}
                            onChange={(event) => {
                              this.setState({ teacher_id: event.target.value });
                            }}
                          />
                        </div>
                      </div> */}
                      {/* Zoom API Key */}
                      {/* <div className="row">
                        <div className="col-4 pb-3">
                          <label>Zoom API Key:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_apikey}
                            onChange={(event) => {
                              this.setState({
                                teacher_apikey: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div> */}
                      {/* Zoom API Secret */}
                      {/* <div className="row">
                        <div className="col-4 pb-3">
                          <label>Zoom API Secret:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_apisecret}
                            onChange={(event) => {
                              this.setState({
                                teacher_apisecret: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div> */}
                      {/* Zoom API Key */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>Zoom API Key:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_apikey}
                            onChange={(event) => {
                              this.setState({
                                teacher_apikey: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* Zoom API Secret */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>Zoom API Secret:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_apisecret}
                            onChange={(event) => {
                              this.setState({
                                teacher_apisecret: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* Username */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["username"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.teacher_username}
                            readOnly
                            onChange={(event) => {
                              this.setState({
                                teacher_username: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* รหัสผ่าน */}
                      <div className="row">
                        <div className="col-12 pb-3">
                          <label>{global[this.language]["password"]}:</label>

                          <div
                            className="card w-100 bg-light p-4"
                            onClick={() => {
                              if (this.state.teacher_password === "") {
                                var text = "";
                                var possible =
                                  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                                for (var i = 0; i < 6; i++)
                                  text += possible.charAt(
                                    Math.floor(Math.random() * possible.length)
                                  );
                                this.setState({ teacher_password: text });
                                const selBox =
                                  document.createElement("textarea");
                                selBox.style.position = "fixed";
                                selBox.style.left = "0";
                                selBox.style.top = "0";
                                selBox.style.opacity = "0";
                                selBox.value = text;
                                document.body.appendChild(selBox);
                                selBox.focus();
                                selBox.select();
                                document.execCommand("copy");
                                document.body.removeChild(selBox);
                                var x = document.getElementById("snackbar");
                                x.className = "show";
                                setTimeout(function () {
                                  x.className = x.className.replace("show", "");
                                }, 3000);
                                // if (navigator.clipboard) {
                                //   navigator.clipboard.writeText(text);
                                //   this.setState({ copy: true });
                                // }
                                // setTimeout(() => {
                                //   this.setState({ copy: false });
                                // }, 2000);
                              }
                            }}
                          >
                            {this.state.teacher_password === "" ? (
                              <div
                                className="w-100 text-center"
                                style={{ cursor: "pointer" }}
                              >
                                <h4 className="header-password">
                                  <b className="text-primary">
                                    {global[this.language]["header_password"]}
                                  </b>
                                </h4>
                                {/* <small>
                                  <label className="text-secondary">
                                    {global[this.language]["detail_password"]}
                                  </label>
                                </small> */}
                              </div>
                            ) : (
                              <div className="w-100 text-center">
                                <h4>{this.state.teacher_password}</h4>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_edit_teacher();
                        }}
                      >
                        {global[this.language]["save_edit"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* ดู list อาจารย์ */}
              <Modal
                show={this.state.modal_teacher_list}
                onHide={() => {
                  this.Cancel();
                }}
                size={"xl"}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-custom-modal-styling-title">
                    {global[this.language]["teacher"]}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="row p-2 pt-0 pb-3">
                      {/* BUTTON XL */}

                      <div
                        className="text-right d-none d-xl-block"
                        style={{ right: 0 }}
                      >
                        <div className="search-box-div mr-2 ml-2">
                          <input
                            type="text"
                            className="search-box"
                            placeholder={
                              global[this.language]["id"] +
                              "," +
                              global[this.language]["fullname"]
                            }
                            value={this.state.search}
                            onChange={(event) => {
                              this.setState({
                                search: event.target.value,
                                activePage: 1,
                              });
                            }}
                          />
                          <button className="icon-absolute" onClick={() => {}}>
                            {"\uf002"}
                          </button>
                        </div>
                        <button
                          className="btn btn-primary mr-2 ml-2"
                          onClick={() => {
                            this.Cancel();
                            this.setState({ modal_add_teacher_1: true });
                            this.GenUser();
                          }}
                        >
                          <label className="icon mr-1">{"\uf055"}</label>
                          {global[this.language]["add_teacher"]}
                        </button>
                      </div>

                      {/* BUTTON NOT XL */}
                      <div className="text-right d-block d-xl-none flex-row justify-content-between align-items-center">
                        <div className="search-box-div mr-2 ml-2">
                          <input
                            type="text"
                            className="search-box"
                            placeholder={
                              global[this.language]["id"] +
                              "," +
                              global[this.language]["fullname"]
                            }
                            value={this.state.search}
                            onChange={(event) => {
                              this.setState({
                                search: event.target.value,
                              });
                            }}
                          />
                          <button className="icon-absolute" onClick={() => {}}>
                            {"\uf002"}
                          </button>
                        </div>
                        <Menu
                          menuButton={
                            <button className="btn btn-primary mr-2 ml-2">
                              <label className="icon">{"\uf0c9"}</label>
                            </button>
                          }
                        >
                          <MenuItem
                            onClick={() => {
                              this.Cancel();
                              this.setState({ modal_add_teacher_1: true });
                              this.GenUser();
                            }}
                          >
                            <label className="icon mr-1">{"\uf055"}</label>
                            {global[this.language]["add_teacher"]}
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-borderless table-striped table-w-9 table-hover ">
                        <thead className="thead-dark">
                          <tr>
                            <th className="text-center">
                              {global[this.language]["order"]}
                            </th>
                            <th
                              className="point"
                              onClick={() => {
                                this.Sort("id");
                              }}
                            >
                              {global[this.language]["teacher_id"]}
                            </th>
                            <th
                              className="point"
                              onClick={() => {
                                this.Sort("name");
                              }}
                              style={{ minWidth: 200 }}
                            >
                              {global[this.language]["fullname"]}
                            </th>
                            <th
                              className="point text-center"
                              onClick={() => {
                                this.Sort("phone");
                              }}
                            >
                              {global[this.language]["phone"]}
                            </th>
                            <th className="text-center">
                              {global[this.language]["all_info"]}
                            </th>
                            <th className="text-center">
                              {global[this.language]["color"]}
                            </th>
                            <th className="text-center">
                              {global[this.language]["edit"]}
                            </th>
                            <th className="text-center">
                              {global[this.language]["delete"]}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* TEACHER */}
                          {this.state.teacher &&
                            this.state.teacher.map((teacher, index) => (
                              <tr>
                                <td className="text-center">{index + 1}</td>
                                <td>{teacher.user.user_code}</td>
                                <td>{teacher.user.name}</td>
                                <td className="text-center">
                                  {teacher.user.phone}
                                </td>
                                <td className="text-center">
                                  <button
                                    className="btn btn-link pt-0 pb-0"
                                    onClick={() => {
                                      this.Cancel();
                                      let detail = this.state.detail;
                                      detail[0] = index;
                                      this.setState({
                                        modal_detail_teacher: true,
                                        detail: detail,
                                      });
                                    }}
                                  >
                                    {global[this.language]["info"]}
                                  </button>
                                </td>

                                <td className="align-items-center justify-content-center">
                                  <div className="w-100 d-flex align-items-center justify-content-center">
                                    <div
                                      style={{
                                        width: 36,
                                        height: 30,
                                        backgroundColor: teacher.color,
                                      }}
                                    />
                                  </div>
                                </td>
                                <td className="text-center">
                                  <a
                                    className="btn btn-link p-0 d-flex align-items-center justify-content-center"
                                    onClick={() => {
                                      this.Cancel();
                                      this.setState({
                                        teacher_id: teacher._id,
                                        teacher_name: teacher.user.name,
                                        teacher_nickname: teacher.nickname,
                                        teacher_phone: teacher.user.phone,
                                        teacher_gender: teacher.gender,
                                        teacher_idcard: teacher.id_card_number,
                                        teacher_facebook: teacher.facebook,
                                        teacher_line: teacher.line,
                                        teacher_address: teacher.address,
                                        teacher_province: teacher.province,
                                        teacher_district: teacher.district,
                                        teacher_subdistrict:
                                          teacher.sub_district,
                                        teacher_zipcode: teacher.zip_code,
                                        teacher_apikey: teacher.zoom_api_key,
                                        teacher_apisecret:
                                          teacher.zoom_api_secret,
                                        teacher_username: teacher.user.username,
                                        modal_edit_teacher: true,
                                      });
                                    }}
                                  >
                                    <label className="icon">{"\uf304"}</label>
                                  </a>
                                </td>

                                <td className="text-center">
                                  <a
                                    className="btn btn-link p-0 d-flex align-items-center justify-content-center"
                                    onClick={() => {
                                      this.Delete_teacher(teacher._id);
                                    }}
                                  >
                                    <label className="icon">{"\uf2ed"}</label>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          {this.state.teacher &&
                            this.state.teacher.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={8}>
                                  {global[this.language]["no_data"]}
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* นักเรียน */}
              {/* ดูข้อมูลทั้งหมด */}
              <Modal
                size="lg"
                show={this.state.modal_detail_student}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <div className="row">
                    <div className="col-6 p-3 right-solid">
                      <h3 className="mb-3">
                        <b>{global[this.language]["student_detail"]}</b>
                      </h3>
                      {/* รหัสนักเรียน */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["student_ID"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_id}
                            onChange={(event) => {
                              this.setState({ student_id: event.target.value });
                            }}
                          />
                        </div>
                      </div>
                      {/* ชื่อ-นามสกุล */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["fullname"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_name}
                            onChange={(event) => {
                              this.setState({
                                student_name: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* ชื่อเล่น */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["nickname"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_id}
                            onChange={(event) => {
                              this.setState({ student_id: event.target.value });
                            }}
                          />
                        </div>
                      </div>
                      {/* หมายเลขโทรศัพท์ */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["phone"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_id}
                            onChange={(event) => {
                              this.setState({ student_id: event.target.value });
                            }}
                          />
                        </div>
                      </div>
                      {/* เพศ */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["gender"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_id}
                            onChange={(event) => {
                              this.setState({ student_id: event.target.value });
                            }}
                          />
                        </div>
                      </div>
                      {/* โรงเรียน */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["school"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_id}
                            onChange={(event) => {
                              this.setState({ student_id: event.target.value });
                            }}
                          />
                        </div>
                      </div>
                      {/* Facebook */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>Facebook:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_id}
                            onChange={(event) => {
                              this.setState({ student_id: event.target.value });
                            }}
                          />
                        </div>
                      </div>
                      {/* LINE ID */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>LINE ID:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_id}
                            onChange={(event) => {
                              this.setState({ student_id: event.target.value });
                            }}
                          />
                        </div>
                      </div>
                      {/* ที่อยู่ปัจจุบัน */}
                      <div className="row">
                        <div className="col-12 p-3">
                          <div className="top-dash " />
                        </div>
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["address"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_id}
                            onChange={(event) => {
                              this.setState({ student_id: event.target.value });
                            }}
                          />
                        </div>
                      </div>
                      {/* ชื่อผู้ปกครอง */}
                      <div className="row">
                        <div className="col-12 p-3">
                          <div className="top-dash " />
                        </div>
                        <div className="col-4 pb-3">
                          <label>
                            {global[this.language]["fullname_parent"]}:
                          </label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_id}
                            onChange={(event) => {
                              this.setState({ student_id: event.target.value });
                            }}
                          />
                        </div>
                      </div>
                      {/* เบอร์โทรผู้ปกครอง */}
                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>
                            {global[this.language]["Phone_Parents"]}:
                          </label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.student_id}
                            onChange={(event) => {
                              this.setState({ student_id: event.target.value });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6 p-3">
                      <h3 className="mb-3">
                        <b>{global[this.language]["subject_detail"]}</b>
                      </h3>
                      <dav className="card border-0 bg-light p-3">
                        <div className="d-flex justify-content-between align-items-center pt-2 w-100">
                          <label>รายการ 1,05-12-2563</label>
                          <button
                            className="btn btn-outline-secondary text-primary pt-0 pb-0"
                            onClick={() => {}}
                          >
                            <label className="icon mr-1">{"\uf56d"}</label>
                            ดาวโหลดใบเสร็จ
                          </button>
                        </div>
                      </dav>
                      <div className="col-12 p-3">
                        <div className="top-dash " />
                      </div>
                      <dav className="card border-0 bg-light p-3">
                        <div className="d-flex justify-content-between align-items-start top-dash pt-3">
                          <div>
                            <label>ภาษาจีนพื้นฐาน</label>
                            <Days day={this.state.days} />
                          </div>
                          <button
                            className="btn btn-primary text-light pt-0 pb-0"
                            onClick={() => {}}
                          >
                            <small>กำลังเรียน</small>
                          </button>
                        </div>
                      </dav>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 right-solid"></div>
                    <div className="col-6 ">
                      <div className="row">
                        <div className="col-6 pb-3"></div>
                        <div className="col-6 pb-3">
                          <button
                            className="btn btn-primary w-100"
                            onClick={() => {
                              this.setState({ modal_detail_student: false });
                            }}
                          >
                            {global[this.language]["close"]}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* ดูข้อมูลนักเรียนทั้งหมด */}
              <Modal
                size="lg"
                show={this.state.modal_detail_student_all}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <div className="row">
                    <div className="col-6 p-3 right-solid">
                      <h3 className="mb-3">
                        <b>{global[this.language]["detail_Department"]}</b>
                      </h3>

                      <div className="row">
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["Department"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={
                              global[this.language]["Type_the_course_name"]
                            }
                            value={""}
                            disabled={true}
                          />
                        </div>
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["course"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            value={""}
                            disabled={true}
                          />
                        </div>
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["course_name"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={
                              global[this.language]["Type_the_course_name"]
                            }
                            value={""}
                            disabled={true}
                          />
                        </div>
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["start_date"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            value={""}
                            disabled={true}
                          />
                        </div>
                        {true !== true ? (
                          <>
                            <div className="col-4 pb-3">
                              <label>
                                {global[this.language]["end_date"]}:
                              </label>
                            </div>
                            <div className="col-8 pb-3">
                              <input
                                type="text"
                                className="form-control"
                                value={""}
                                disabled={true}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-4 pb-3">
                              <label></label>
                            </div>
                            <div className="col-8 pb-3">
                              <div className="text-secondary">
                                <input
                                  type="checkbox"
                                  className="mr-2"
                                  checked
                                  disabled={true}
                                />
                                {global[this.language]["End_date_not"]}
                              </div>
                            </div>
                          </>
                        )}

                        <div className="col-4 pb-3">
                          <label>{global[this.language]["study_time"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={
                              global[this.language]["Type_the_course_name"]
                            }
                            value={""}
                            disabled={true}
                          />
                        </div>
                        <div className="col-4 pb-3">
                          <label>
                            {global[this.language]["number_of_class_hours"]}:
                          </label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={
                              global[this.language]["Type_the_course_name"]
                            }
                            value={""}
                            disabled={true}
                          />
                        </div>
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["Study_hours"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={
                              global[this.language]["Type_the_course_name"]
                            }
                            value={""}
                            disabled={true}
                          />
                        </div>

                        <div className="col-4 pb-3">
                          <label>{global[this.language]["Course_type"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <div className="toggle-bar">
                            <div className={"toggle-left-active"}>
                              {global[this.language]["solo_study"]}
                            </div>
                            <div className={"toggle-right"}>
                              {global[this.language]["group_classes"]}
                            </div>
                          </div>
                        </div>

                        <div className="col-4 pb-3">
                          <label>{global[this.language]["study_time"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          {"เวลาเหมือนกัน" === "เวลาเหมือนกัน" ? (
                            <div className="toggle-bar">
                              {this.state.days.map((day, index) => (
                                <div
                                  className={
                                    index === 0
                                      ? day.select
                                        ? "day-big-left-active"
                                        : "day-big-left"
                                      : index === 6
                                      ? day.select
                                        ? "day-big-right-active"
                                        : "day-big-right"
                                      : day.select
                                      ? "day-big-center-active"
                                      : "day-big-center"
                                  }
                                >
                                  <label>{day.name}</label>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="w-100">
                              {this.state.days.map((day, index) => (
                                <div className="d-flex justify-content-around align-items-center mb-3">
                                  <input
                                    type="checkbox"
                                    checked={day.select}
                                    value={day.select}
                                    className="mr-3"
                                    disabled={true}
                                  />
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled={true}
                                    value={"วัน" + day.day}
                                  />
                                  <input
                                    type="text"
                                    disabled={true}
                                    className="form-control ml-3"
                                    value={day.time}
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["price"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="00"
                            value={""}
                            disabled={true}
                          />
                        </div>
                        <div className="dash m-3 w-100" />
                        <div className="col-4 pb-3">
                          <label>
                            {global[this.language]["teacher_name"]}:
                          </label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="00"
                            value={""}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6 p-3">
                      <h3 className="mb-3">
                        <b>{global[this.language]["student_in_course"]}</b>
                      </h3>
                      <dav className="card border-0 bg-light p-3">
                        <button
                          className="btn btn-link text-left"
                          onClick={() => {
                            this.setState({ modal_detail_student: true });
                          }}
                        >
                          นุติกุล อินทะโนรัตน์
                        </button>
                        <button
                          className="btn btn-link text-left"
                          onClick={() => {
                            this.setState({ modal_detail_student: true });
                          }}
                        >
                          นุติกุล อินทะโนรัตน์
                        </button>
                        <button
                          className="btn btn-link text-left"
                          onClick={() => {
                            this.setState({ modal_detail_student: true });
                          }}
                        >
                          นุติกุล อินทะโนรัตน์
                        </button>
                        <button
                          className="btn btn-link text-left"
                          onClick={() => {
                            this.setState({ modal_detail_student: true });
                          }}
                        >
                          นุติกุล อินทะโนรัตน์
                        </button>
                        <button
                          className="btn btn-link text-left"
                          onClick={() => {
                            this.setState({ modal_detail_student: true });
                          }}
                        >
                          นุติกุล อินทะโนรัตน์
                        </button>
                        <button
                          className="btn btn-link text-left"
                          onClick={() => {
                            this.setState({ modal_detail_student: true });
                          }}
                        >
                          นุติกุล อินทะโนรัตน์
                        </button>
                      </dav>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 right-solid"></div>
                    <div className="col-6 ">
                      <div className="row">
                        <div className="col-6 pb-3"></div>
                        <div className="col-6 pb-3">
                          <button
                            className="btn btn-primary w-100"
                            onClick={() => {
                              this.setState({
                                modal_detail_student_all: false,
                              });
                            }}
                          >
                            {global[this.language]["close"]}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* liveclass */}
              {/* เพิ่ม live */}
              <Modal
                show={this.state.modal_add_liveclass}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["add_liveclass"]}</b>
                  </h3>
                  <div className="row">
                    {/* course_name */}
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["course_name"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.batch_name}
                        onChange={(event) => {
                          this.setState({
                            batch_name: event.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* live_date */}
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["live_date"]}:</label>

                      <br />
                      <DatePicker
                        className="form-control w-100"
                        selected={this.state.live_date}
                        minDate={new Date()}
                        locale="th"
                        onChange={(date) => {
                          this.setState({ live_date: date });
                        }}
                      />
                    </div>
                    {/* start_time */}
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["start_time"]}:</label>
                      <input
                        type="time"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.live_startime}
                        onChange={(event) => {
                          this.setState({
                            live_startime: event.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* end_time */}
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["end_time"]}:</label>
                      <input
                        type="time"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.live_endtime}
                        onChange={(event) => {
                          this.setState({
                            live_endtime: event.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* Meeting Number */}
                    {/* <div className="col-12 pb-3">
                      <label>Meeting Number:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.meeting_number}
                        onChange={(event) => {
                          this.setState({
                            meeting_number: event.target.value,
                          });
                        }}
                      />
                    </div> */}
                    {/* Password */}
                    {/* <div className="col-12 pb-3">
                      <label>Password:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.meeting_password}
                        onChange={(event) => {
                          this.setState({
                            meeting_password: event.target.value,
                          });
                        }}
                      />
                    </div> */}
                    {/* teacher */}
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["teacher_name"]}:</label>
                      <div
                        className="form-control"
                        onClick={this.handleClick.bind(this)}
                        id="teacher-menu"
                      >
                        <label
                          className={
                            this.state.liveclass_teacher == ""
                              ? "text-secondary"
                              : ""
                          }
                          onClick={this.handleClick.bind(this)}
                          id="teacher-menu"
                        >
                          {this.state.liveclass_teacher == ""
                            ? "--" +
                              global[this.language]["select_teacher"] +
                              "--"
                            : this.state.liveclass_teacher.user.name}
                        </label>
                      </div>
                      <div
                        className={
                          this.state.menu_Teacher
                            ? "menu-context-box-xl active"
                            : "menu-context-box-xl hidden"
                        }
                        style={{ left: this.state.x, top: this.state.y }}
                      >
                        <div className="p-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="ค้นหา"
                            value={this.state.search_teacher}
                            onChange={(event) => {
                              this.setState({
                                search_teacher: event.target.value,
                              });
                            }}
                            onClick={this.handleClick.bind(this)}
                            id="teacher-menu"
                          />
                        </div>
                        <div className="search-select-max-height overflow-y">
                          {this.state.teacher &&
                            this.state.teacher.map((item, index) => (
                              <>
                                {(item.user.name.includes(
                                  this.state.search_teacher
                                ) ||
                                  this.state.search_teacher === "") && (
                                  <button
                                    className="menu-context-button "
                                    onClick={() => {
                                      this.setState({
                                        liveclass_teacher: item,
                                        zoom_api_key: item.zoom_api_key,
                                        zoom_api_secret: item.zoom_api_secret,
                                      });
                                    }}
                                  >
                                    <label>{item.user.name}</label>
                                  </button>
                                )}
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                    {/* students */}
                    <div className="col-12 pb-3">
                      <label>
                        {global[this.language]["students"]}:
                        {this.state.liveclass_student.length !== 0 &&
                          " ( " +
                            this.state.liveclass_student.length +
                            global[this.language]["person"] +
                            "  )"}
                      </label>
                      <div
                        className="form-control-style"
                        onClick={this.handleClick.bind(this)}
                        id="student-menu"
                      >
                        {this.state.liveclass_student.length === 0 ? (
                          <p
                            className={"text-secondary pb-0"}
                            onClick={this.handleClick.bind(this)}
                            id="student-menu"
                          >
                            --
                            {global[this.language]["select_student"]}
                            --
                          </p>
                        ) : (
                          <div
                            className="row"
                            onClick={this.handleClick.bind(this)}
                            id="student-menu"
                          >
                            {this.state.liveclass_student &&
                              this.state.liveclass_student.map(
                                (item, index) => (
                                  <div className="selected-box bg-primary text-white m-1">
                                    <label>{item.nickname}</label>
                                    <button
                                      className="btn-link p-0 text-white icon ml-1 mt-1"
                                      onClick={() => {
                                        let data = this.state.liveclass_student,
                                          array = this.state.student_add;
                                        for (let a of array) {
                                          if (a.nickname === item.nickname) {
                                            a.select = false;
                                          }
                                        }
                                        data.splice(index, 1);
                                        this.setState({
                                          liveclass_student: data,
                                          student_add: array,
                                        });
                                      }}
                                    >
                                      {"\uf00d"}
                                    </button>
                                  </div>
                                )
                              )}
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          this.state.menu_Student
                            ? "menu-context-box-xl active"
                            : "menu-context-box-xl hidden"
                        }
                        style={{ left: this.state.x, top: this.state.y }}
                      >
                        <div className="p-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="ค้นหา"
                            value={this.state.search_student}
                            onChange={(event) => {
                              this.setState({
                                search_student: event.target.value,
                              });
                            }}
                            onClick={this.handleClick.bind(this)}
                            id="student-menu"
                          />
                        </div>
                        <div className="search-select-max-height overflow-y">
                          {this.state.student_add &&
                            this.state.student_add.map((item, index) => (
                              <>
                                {(item.nickname.includes(
                                  this.state.search_student
                                ) ||
                                  this.state.search_student === "") && (
                                  <button
                                    className="d-flex align-items-center p-2 w-100 text-left search-button-select"
                                    id="student-menu"
                                    onClick={() => {
                                      let student = this.state.student_add,
                                        data = [];
                                      student[index].select =
                                        !student[index].select;

                                      for (let st of student) {
                                        if (st.select) {
                                          data.push(st);
                                        }
                                        this.setState({
                                          student_add: student,
                                          liveclass_student: data,
                                        });
                                      }
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={item.select}
                                      id="student-menu"
                                      onChange={(e) => {
                                        let student = this.state.student_add,
                                          data = [];
                                        student[index].select =
                                          e.target.checked;

                                        for (let st of student) {
                                          if (st.select) {
                                            data.push(st);
                                          }
                                          this.setState({
                                            student_add: student,
                                            liveclass_student: data,
                                          });
                                        }
                                      }}
                                    ></input>
                                    <div
                                      className="w-100 pl-2 pr-2"
                                      id="student-menu"
                                      onClick={() => {
                                        // this.setState({
                                        //   liveclass_student: item,
                                        // });
                                      }}
                                    >
                                      <label id="student-menu">
                                        {item.nickname}
                                      </label>
                                    </div>
                                  </button>
                                )}
                              </>
                            ))}
                        </div>
                      </div>
                    </div>

                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_add_liveclass();
                        }}
                      >
                        {global[this.language]["add"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* แก้ไข live */}
              <Modal
                show={this.state.modal_edit_liveclass}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["edit_liveclass"]}</b>
                  </h3>
                  <div className="row">
                    {/* course_name */}
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["course_name"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.batch_name}
                        readOnly
                        onChange={(event) => {
                          this.setState({
                            batch_name: event.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* live_date */}
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["live_date"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        readOnly
                        value={this.state.live_date}
                        onChange={(event) => {
                          this.setState({
                            live_date: event.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* start_time */}
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["start_time"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        readOnly
                        value={this.state.live_startime}
                        onChange={(event) => {
                          this.setState({
                            live_startime: event.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* end_time */}
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["end_time"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        readOnly
                        value={this.state.live_endtime}
                        onChange={(event) => {
                          this.setState({
                            live_endtime: event.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* Meeting Number */}
                    <div className="col-12 pb-3">
                      <label>Meeting Number:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        readOnly
                        value={this.state.meeting_number}
                        onChange={(event) => {
                          this.setState({
                            meeting_number: event.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* Password */}
                    <div className="col-12 pb-3">
                      <label>Password:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        readOnly
                        value={this.state.meeting_password}
                        onChange={(event) => {
                          this.setState({
                            meeting_password: event.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* teacher */}
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["teacher_name"]}:</label>
                      <div
                        className="form-control"
                        onClick={this.handleClick.bind(this)}
                        id="teacher-menu"
                      >
                        <label
                          className={
                            this.state.liveclass_teacher === ""
                              ? "text-secondary"
                              : ""
                          }
                          onClick={this.handleClick.bind(this)}
                          id="teacher-menu"
                        >
                          {this.state.liveclass_teacher === ""
                            ? "--" +
                              global[this.language]["select_teacher"] +
                              "--"
                            : this.state.liveclass_teacher.user.name}
                        </label>
                      </div>
                      <div
                        className={
                          this.state.menu_Teacher
                            ? "menu-context-box-xl active"
                            : "menu-context-box-xl hidden"
                        }
                        style={{ left: this.state.x, top: this.state.y }}
                      >
                        <div className="p-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="ค้นหา"
                            value={this.state.search_teacher}
                            onChange={(event) => {
                              this.setState({
                                search_teacher: event.target.value,
                              });
                            }}
                            onClick={this.handleClick.bind(this)}
                            id="teacher-menu"
                          />
                        </div>
                        <div className="search-select-max-height overflow-y">
                          {this.state.teacher &&
                            this.state.teacher.map((item, index) => (
                              <>
                                {(item.user.name.includes(
                                  this.state.search_teacher
                                ) ||
                                  this.state.search_teacher === "") && (
                                  <button
                                    className="menu-context-button "
                                    onClick={() => {
                                      this.setState({
                                        liveclass_teacher: item,
                                        zoom_api_key: item.zoom_api_key,
                                        zoom_api_secret: item.zoom_api_secret,
                                      });
                                    }}
                                  >
                                    <label>{item.user.name}</label>
                                  </button>
                                )}
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                    {/* students */}
                    <div className="col-12 pb-3">
                      <label>
                        {global[this.language]["students"]}:
                        {this.state.liveclass_student.length !== 0 &&
                          " ( " +
                            this.state.liveclass_student.length +
                            global[this.language]["person"] +
                            " )"}
                      </label>
                      <div
                        className="form-control-style"
                        onClick={this.handleClick.bind(this)}
                        id="student-menu"
                      >
                        {this.state.liveclass_student.length === 0 ? (
                          <p
                            className={"text-secondary pb-0"}
                            onClick={this.handleClick.bind(this)}
                            id="student-menu"
                          >
                            --
                            {global[this.language]["select_student"]}
                            --
                          </p>
                        ) : (
                          <div
                            className="row"
                            onClick={this.handleClick.bind(this)}
                            id="student-menu"
                          >
                            {this.state.liveclass_student &&
                              this.state.liveclass_student.map(
                                (item, index) => (
                                  <div className="selected-box bg-primary text-white m-1">
                                    <label>{item.nickname}</label>
                                    <button
                                      className="btn-link p-0 text-white icon ml-1 mt-1"
                                      onClick={() => {
                                        let data = this.state.liveclass_student,
                                          array = this.state.student_add;
                                        for (let a of array) {
                                          if (a.nickname === item.nickname) {
                                            a.select = false;
                                          }
                                        }
                                        data.splice(index, 1);
                                        this.setState({
                                          liveclass_student: data,
                                          student_add: array,
                                        });
                                      }}
                                    >
                                      {"\uf00d"}
                                    </button>
                                  </div>
                                )
                              )}
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          this.state.menu_Student
                            ? "menu-context-box-xl active"
                            : "menu-context-box-xl hidden"
                        }
                        style={{ left: this.state.x, top: this.state.y }}
                      >
                        <div className="p-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="ค้นหา"
                            value={this.state.search_student}
                            onChange={(event) => {
                              this.setState({
                                search_student: event.target.value,
                              });
                            }}
                            onClick={this.handleClick.bind(this)}
                            id="student-menu"
                          />
                        </div>
                        <div className="search-select-max-height overflow-y">
                          {this.state.student_add &&
                            this.state.student_add.map((item, index) => (
                              <>
                                {(item.nickname.includes(
                                  this.state.search_student
                                ) ||
                                  this.state.search_student === "") && (
                                  <button
                                    className="d-flex align-items-center p-2 w-100 text-left search-button-select"
                                    id="student-menu"
                                    onClick={() => {
                                      let student = this.state.student_add,
                                        data = [];
                                      student[index].select =
                                        !student[index].select;

                                      for (let st of student) {
                                        if (st.select) {
                                          data.push(st);
                                        }
                                        this.setState({
                                          student_add: student,
                                          liveclass_student: data,
                                        });
                                      }
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={item.select}
                                      id="student-menu"
                                      onChange={(e) => {
                                        let student = this.state.student_add,
                                          data = [];
                                        student[index].select =
                                          e.target.checked;

                                        for (let st of student) {
                                          if (st.select) {
                                            data.push(st);
                                          }
                                          this.setState({
                                            student_add: student,
                                            liveclass_student: data,
                                          });
                                        }
                                      }}
                                    ></input>
                                    <div
                                      className="w-100 pl-2 pr-2"
                                      id="student-menu"
                                      onClick={() => {
                                        // this.setState({
                                        //   liveclass_student: item,
                                        // });
                                      }}
                                    >
                                      <label id="student-menu">
                                        {item.nickname}
                                      </label>
                                    </div>
                                  </button>
                                )}
                              </>
                            ))}
                        </div>
                      </div>
                    </div>

                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_edit_liveclass();
                        }}
                      >
                        {global[this.language]["save_edit"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* ดูรายละเอียด live */}
              <Modal
                show={this.state.modal_detail_live}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["liveclass_detail"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["course_name"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={this.state.batch_name}
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["live_date"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.live_date}
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["start_time"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={this.state.live_startime}
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["end_time"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.live_endtime}
                        disabled={true}
                      />
                    </div>

                    <div className="col-4 pb-3">
                      <label>Zoom API Key:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={this.state.zoom_api_key}
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>Zoom API Secret:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={this.state.zoom_api_secret}
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>Meeting Number:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={this.state.meeting_number}
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>Password:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={this.state.meeting_password}
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["teacher_name"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={
                          this.state.liveclass_teacher &&
                          this.state.liveclass_teacher.user.name
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["students"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <div
                        className="form-control-style"
                        onClick={this.handleClick.bind(this)}
                        id="student-menu"
                      >
                        {this.state.liveclass_student &&
                        this.state.liveclass_student.length === 0 ? (
                          <p className={"text-secondary pb-0"}>
                            {global[this.language]["no_student"]}
                          </p>
                        ) : (
                          <div className="row">
                            {this.state.liveclass_student &&
                              this.state.liveclass_student.map(
                                (item, index) => (
                                  <div className="selected-box bg-primary text-white m-1">
                                    <label>{item.nickname}</label>
                                  </div>
                                )
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-6 pb-3" />
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["close"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* วันหยุด */}
              {/* เพิ่มวันหยุด */}
              <Modal
                show={this.state.modal_add_holiday}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["add_holiday"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>ชื่อวันหยุด:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.holiday_name}
                        onChange={(event) => {
                          this.setState({ holiday_name: event.target.value });
                        }}
                      />
                    </div>
                    <div className="col-12 pb-3">
                      <label>วัน/เดือน/ปี:</label>
                      {/* <input
                        type="date"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.holiday_date}
                        onChange={(event) => {
                          this.setState({ holiday_date: event.target.value });
                        }}
                      /> */}
                      <DatePicker
                        className="form-control w-100"
                        selected={this.state.holiday_date}
                        minDate={new Date()}
                        locale="th"
                        onChange={(date) => {
                          this.setState({ holiday_date: date });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_add_holiday();
                        }}
                      >
                        {global[this.language]["add"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* แก้ไขวันหยุด */}
              <Modal
                show={this.state.modal_edit_holiday}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["edit_holiday"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>ชื่อวันหยุด:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.holiday_name}
                        onChange={(event) => {
                          this.setState({ holiday_name: event.target.value });
                        }}
                      />
                    </div>
                    <div className="col-12 pb-3">
                      <label>วัน/เดือน/ปี:</label>
                      <DatePicker
                        className="form-control w-100"
                        selected={this.state.holiday_date}
                        locale="th"
                        onChange={(date) => {
                          this.setState({ holiday_date: date });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_edit_holiday();
                        }}
                      >
                        {global[this.language]["save_edit"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>{" "}
        <div id="snackbar">{global[this.language]["copy_password"]}</div>
      </div>
    );
  }
}
