import "../../bootstrap.css";
import "./style.css";
import React, { Component } from "react";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { Link } from "react-router";
import ninja from "./ninja2.png";
import ninja2 from "./ninja3.png";

import axios from "axios";
const cookies = new Cookies();
export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <div style={{ background: "#E54045" }} className="py-4">
          <div className="container text-center">
            <h1 style={{ fontSize: 39, fontWeight: "bold", color: "#FFF" }}>
              บริการสั่งซื้อสินค้า
            </h1>
            <h1 style={{ fontSize: 29, fontWeight: "bold", color: "#FFF" }}>
              จากจีน
            </h1>
            <label style={{ fontSize: 20, color: "#FFF" }}>
              บริการสั่งซื้อสินค้าจากประเทศจีนมาจำหน่าย ในประเทศไทยทั้งแบบ
              ปลีกและแบบส่งโดยไม่ต้องผ่านคนกลางพร้อม จัดส่งสินค้าถึงมือคุณ
              มีขั้นตอนในการขนส่ง สินค้าดังนี้
            </label>
          </div>
          <div style={{ height: 150 }}></div>
        </div>
        <div
          className="container text-center pt-5 text-center card bg-white shadow-lg border-0 mb-5"
          style={{ borderRadius: 15, marginTop: -100 }}
        >
          <img
            src="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/cart-onyclo06f11rnj88qchf15mcaaiokytqifdi2i5i3s.png"
            title=""
            alt=""
            className="mx-auto"
          />
          <label
            className="mt-4"
            style={{ fontSize: 20, fontWeight: "bold", color: "#E54045" }}
          >
            คลังสินค้าจีนรับสินค้า
          </label>
          <br />
          <label className="mt-4" style={{ fontSize: 20 }}>
            เลือกซื้อสินค้าที่เว็บไซต์จีน คลังสินค้า เตรียมขนส่งมายังประเทศไทย
          </label>
          <br />
          <img
            src="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/ship-onyclo06f11rnj88qchf15mcaaiokytqifdi2i5i3s.png"
            title=""
            alt=""
            className="mx-auto"
          />
          <label
            className="mt-4"
            style={{ fontSize: 20, fontWeight: "bold", color: "#E54045" }}
          >
            ขนส่งจาก จีน-ไทย
          </label>
          <br />
          <label className="mt-4" style={{ fontSize: 20 }}>
            คลังสินค้าขนสินค้าจากจีน ส่งมายัง ประเทศไทย
          </label>
          <br />
          <img
            src="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/hand-truck-onyclo06f11rnj88qchf15mcaaiokytqifdi2i5i3s.png"
            title=""
            alt=""
            className="mx-auto"
          />
          <label
            className="mt-4"
            style={{ fontSize: 20, fontWeight: "bold", color: "#E54045" }}
          >
            คลังสินค้าไทยรับสินค้า
          </label>
          <br />
          <label className="mt-4" style={{ fontSize: 20 }}>
            สินค้ามาถึงไทย คลังสินค้าไทยจะรับ สินค้าไว้เพื่อตรวจสอบและแจ้งลูกค้า
          </label>
          <br />
          <img
            src="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/delivery-truck-onyclo06f11rnj88qchf15mcaaiokytqifdi2i5i3s.png"
            title=""
            alt=""
            className="mx-auto"
          />
          <label
            className="mt-4"
            style={{ fontSize: 20, fontWeight: "bold", color: "#E54045" }}
          >
            ชำระเงินพร้อมจัดส่งสินค้า
          </label>
          <br />
          <label className="mt-4" style={{ fontSize: 20 }}>
            เตรียมการส่งสินค้าไปถึงมือลูกค้า และ ชำระเงินค่าส่งสินค้า
          </label>
        </div>
        <div className="container text-center mt-5 pt-5">
          <h1 style={{ fontSize: 39, fontWeight: "bold", color: "#E54045" }}>
            ขั้นตอนการขนส่ง
          </h1>
          <h1 style={{ fontSize: 29, fontWeight: "bold", color: "#E54045" }}>
            รูปแบบต่างๆ
          </h1>
        </div>
        <div className="container mt-5 pt-5">
          <div className="card p-4 mb-3" style={{ backgroundColor: "#0000" }}>
            <h1 className="mb-3" style={{ fontSize: 20, fontWeight: "bold" }}>
              บริการขนส่งทางรถ
            </h1>
            <label>
              1. Ninja Cargo ออกรหัสสินค้าให้กับ ลูกค้า <br />
              2. ลูกค้าบอกทางร้านให้เขียนข้อมูลลงบน กล่องสินค้า
              (เขียนภาษาจีนหรือ อังกฤษ) <br />
              3. สินค้าถึงโกดังจีน <br />
              4. จัดส่งมาไทยใช้ระยะเวลาประมาณ 7วัน
              <br />
              5. จัดส่งสินค้าให้ลูกค้า
            </label>
          </div>
          <div className="card p-4 mb-3" style={{ backgroundColor: "#0000" }}>
            <h1 className="mb-3" style={{ fontSize: 20, fontWeight: "bold" }}>
              บริการขนส่งทางเรือ
            </h1>
            <label>
              1. Ninja Cargo ออกรหัสสินค้าให้กับ ลูกค้า
              <br />
              2. ลูกค้าบอกทางร้านให้เขียนข้อมูลลงบน กล่องสินค้า
              (เขียนภาษาจีนหรือ อังกฤษ)
              <br />
              3. สินค้าถึงโกดังจีน
              <br />
              4. จัดส่งมาไทยใช้ระยะเวลาประมาณ 10-14วัน
              <br />
              5. จัดส่งสินค้าให้ลูกค้า
              <br />
            </label>
          </div>
          <div className="card p-4 mb-3" style={{ backgroundColor: "#0000" }}>
            <h1 className="mb-3" style={{ fontSize: 20, fontWeight: "bold" }}>
              บริการขนส่งสินค้าจาก ไทย - จีน
            </h1>
            <label>
              1. ลูกค้าต้องให้รายละเอียดสินค้ากับ ทางบริษัท เพื่อความสะดวกในการ
              ผ่านศุลกากรหากสินค้าที่แจ้งมาไม่ ตรงตามสินค้าจริง แล้วก่อให้เกิด
              ความเสียหาย ทางบริษัทจะไม่รับผิด ชอบใดๆทั้งสิ้น
              <br />
              2. น้ำหนักสินค้าต้องไม่เกิน 50 กิโลกรัม ต่อลัง
              หากน้ำหนักเกินจะไม่สามารถ ขึ้นรถตู้ได้
              <br />
              3. ผู้ส่งสินค้าเป็นผู้รับผิดชอบค่าขนส่ง ไป-กลับ
              ระหว่างบริษัทกับท่าเรือ
              <br />
            </label>
          </div>
          <div style={{ height: 50 }}></div>
        </div>
        <div style={{ background: "#E54045" }} className="py-4">
          <div className="container">
            <img
              className="mx-auto mb-4"
              src="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/warning.png"
              title=""
              alt=""
            />
            <div className="text-center mb-4">
              <h1 style={{ fontSize: 20, fontWeight: "bold", color: "#FFF" }}>
                กรณีของหาย
              </h1>
            </div>
            <label style={{ fontSize: 20, color: "#FFF" }}>
              1. ทางบริษัทหยุดขายประกันสินค้าชั่วคราว หากสินค้าสูญหาย
              จะชดใช้เต็มจำนวนของ ค่าสินค้า (เฉพาะสินค้าที่สูญหาย )<br />
              2. สินค้าแตกง่าย ลูกค้าต้องแพ็คสินค้าเป็น อย่างดี
              หากสินค้าแตกทางบริษัทไม่มีการ รับผิดชอบใดๆ
              บริษัทรับผิดชอบสินค้าที่ หายเท่านั้น
              <br />
              3. เมื่อสินค้ามาถึงหากลูกค้ายังไม่ไปรับสินค้า ภายใน 30วัน
              ทางบริษัทถือว่าสินค้าตกเป็น ทรัพย์สินของบริษัททันที
              <br />
            </label>
          </div>
          <div style={{ height: 150 }}></div>
        </div>
      </div>
    );
  }
}
