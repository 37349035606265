import LOGO from "./Logo.png";
import "../../../index.css";
import "../../../bootstrap.css";
import React, { Component } from "react";
import { Link } from "react-router";

import Swal from "sweetalert2";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class MenuComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { toggle: false, page: this.props.page, type: "" };
  }
  language = cookies.get("language") ? cookies.get("language") : "TH";
  UNSAFE_componentWillReceiveProps() {
    if (!this.language) {
      cookies.set("language", "TH", { path: "/" });
    }
    if (cookies.get("user")) {
      if (cookies.get("user").user.status === undefined) {
        Swal.fire({
          title: "เกิดข้อผิดพลาดในการโหลดข้อมูล กรุณาล็อกอินใหม่อีกครั้ง",
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonText: global[this.language]["confirm"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.Logout();
          }
        });
      } else {
        let type = cookies.get("user").user.status;
        this.setState({ type: type });
      }
    }
  }

  Logout = () => {
    fetch(global.httpURL + "/api/logout", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "token " + cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        cookies.remove("token", { path: "/" });
        cookies.remove("user", { path: "/" });

        window.location.href = "/";
      })
      .catch((err) => {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_logout"],
          "warning"
        );
      });
  };
  updateDimensions = () => {
    if (window.innerWidth >= 768) {
      this.setState({ toggle: false });
    }
  };
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  render() {
    return (
      <div className="h-100">
        <div
          className={
            this.state.toggle
              ? "h-100 bg-white side-menu "
              : "h-100 bg-white side-menu-hidden "
          }
        >
          <div className="w-100 d-flex align-items-center justify-content-center bg-primary side-logo-height">
            <img className="logo-sidemenu" src={LOGO} alt="LOGO" />
          </div>
          <div className="container-fluid">
            <div className="mt-4 row">
              {cookies.get("user") &&
                cookies.get("user").user.role.role_detail.dashboard &&
                cookies.get("user").user.role.role_detail.dashboard ===
                  "True" && (
                  <Link to="/dashboard" className="button w-100">
                    <a
                      href="#"
                      className={
                        this.state.page === "dashboard"
                          ? "btn btn-white w-100 text-left border-r-0 text-white bg-primary side-menu-tab"
                          : "btn btn-white w-100 text-left border-r-0 text-secondary side-menu-tab"
                      }
                    >
                      <label className="icon mr-1">{"\uf200"}</label>
                      {global[this.language]["dashboard"]}
                    </a>
                  </Link>
                )}
              {cookies.get("user") &&
                cookies.get("user").user.role.role_detail.subject &&
                cookies.get("user").user.role.role_detail.subject ===
                  "True" && (
                  <Link to="/subject" className="button w-100">
                    <a
                      href="#"
                      className={
                        this.state.page === "subject"
                          ? "btn btn-white w-100 text-left border-r-0 text-white bg-primary side-menu-tab"
                          : "btn btn-white w-100 text-left border-r-0 text-secondary side-menu-tab"
                      }
                    >
                      <label className="icon mr-1">{"\uf5db"}</label>

                      {global[this.language]["subject"]}
                    </a>
                  </Link>
                )}
              {cookies.get("user") &&
                cookies.get("user").user.role.role_detail.student &&
                cookies.get("user").user.role.role_detail.student ===
                  "True" && (
                  <Link to="/student" className="button w-100">
                    <a
                      href="#"
                      className={
                        this.state.page === "student"
                          ? "btn btn-white w-100 text-left border-r-0 text-white bg-primary side-menu-tab"
                          : "btn btn-white w-100 text-left border-r-0 text-secondary side-menu-tab"
                      }
                    >
                      <label className="icon mr-1">{"\uf63d"}</label>

                      {global[this.language]["student"]}
                    </a>
                  </Link>
                )}
              {cookies.get("user") &&
                cookies.get("user").user.role.role_detail.teacher &&
                cookies.get("user").user.role.role_detail.teacher ===
                  "True" && (
                  <Link to="/teacher" className="button w-100">
                    <a
                      href="#"
                      className={
                        this.state.page === "teacher"
                          ? "btn btn-white w-100 text-left border-r-0 text-white bg-primary side-menu-tab"
                          : "btn btn-white w-100 text-left border-r-0 text-secondary side-menu-tab"
                      }
                    >
                      <label className="icon mr-1">{"\uf51c"}</label>

                      {global[this.language]["teacher"]}
                    </a>
                  </Link>
                )}
              {cookies.get("user") &&
                cookies.get("user").user.role.role_detail.finance &&
                cookies.get("user").user.role.role_detail.finance ===
                  "True" && (
                  <Link to="/finance" className="button w-100">
                    <a
                      href="#"
                      className={
                        this.state.page === "finance"
                          ? "btn btn-white w-100 text-left border-r-0 text-white bg-primary side-menu-tab"
                          : "btn btn-white w-100 text-left border-r-0 text-secondary side-menu-tab"
                      }
                    >
                      <label className="icon mr-1">{"\uf53d"}</label>

                      {global[this.language]["finance"]}
                    </a>
                  </Link>
                )}
              {cookies.get("user") &&
                cookies.get("user").user.role.role_detail.history &&
                cookies.get("user").user.role.role_detail.history ===
                  "True" && (
                  <Link to="/history" className="button w-100">
                    <a
                      href="#"
                      className={
                        this.state.page === "history"
                          ? "btn btn-white w-100 text-left border-r-0 text-white bg-primary side-menu-tab"
                          : "btn btn-white w-100 text-left border-r-0 text-secondary side-menu-tab"
                      }
                    >
                      <label className="icon mr-1">{"\uf15c"}</label>

                      {global[this.language]["history"]}
                    </a>
                  </Link>
                )}
              {cookies.get("user") &&
                cookies.get("user").user.role.role_detail["institution"] &&
                cookies.get("user").user.role.role_detail["institution"] ===
                  "True" && (
                  <Link to="/holiday" className="button w-100">
                    <a
                      href="#"
                      className={
                        this.state.page === "holiday"
                          ? "btn btn-white w-100 text-left border-r-0 text-white bg-primary side-menu-tab"
                          : "btn btn-white w-100 text-left border-r-0 text-secondary side-menu-tab"
                      }
                    >
                      <label className="icon mr-1">{"\uf013"}</label>
                      {global[this.language]["institution"]}&
                      {global[this.language]["holiday"]}
                    </a>
                  </Link>
                )}
              {cookies.get("user") &&
                cookies.get("user").user.role.role_detail["admin"] &&
                cookies.get("user").user.role.role_detail["admin"] ===
                  "True" && (
                  <Link to="/admin" className="button w-100">
                    <a
                      href="#"
                      className={
                        this.state.page === "admin"
                          ? "btn btn-white w-100 text-left border-r-0 text-white bg-primary side-menu-tab"
                          : "btn btn-white w-100 text-left border-r-0 text-secondary side-menu-tab"
                      }
                    >
                      <label className="icon mr-1">{"\uf505"}</label>
                      {global[this.language]["admin"]}&
                      {global[this.language]["role"]}
                    </a>
                  </Link>
                )}
              {/* <Link to="/branch" className="button w-100">
                <a
                  href="#"
                  className={
                    this.state.page === "branch"
                      ? "btn btn-white w-100 text-left border-r-0 text-white bg-primary side-menu-tab"
                      : "btn btn-white w-100 text-left border-r-0 text-secondary side-menu-tab"
                  }
                >
                  <label className="icon mr-1">{"\uf126"}</label>
                  จัดการสาขา
                </a>
              </Link> */}

              {cookies.get("user") &&
                cookies.get("user").user.role.role_detail.manageliveclass &&
                cookies.get("user").user.role.role_detail.manageliveclass ===
                  "True" && (
                  <Link to="/manageliveclass" className="button w-100">
                    <a
                      href="#"
                      className={
                        this.state.page === "manageliveclass"
                          ? "btn btn-white w-100 text-left border-r-0 text-white bg-primary side-menu-tab"
                          : "btn btn-white w-100 text-left border-r-0 text-secondary side-menu-tab"
                      }
                    >
                      <label className="icon mr-1">{"\uf828"}</label>
                      {global[this.language]["manage_liveclass"]}
                    </a>
                  </Link>
                )}
              {cookies.get("user") &&
                cookies.get("user").user.role.role_detail.liveclass &&
                cookies.get("user").user.role.role_detail.liveclass ===
                  "True" && (
                  <Link to="/liveclass" className="button w-100">
                    <a
                      href="#"
                      className={
                        this.state.page === "liveclass"
                          ? "btn btn-white w-100 text-left border-r-0 text-white bg-primary side-menu-tab"
                          : "btn btn-white w-100 text-left border-r-0 text-secondary side-menu-tab"
                      }
                    >
                      <label className="icon mr-1">{"\uf03d"}</label>

                      {global[this.language]["liveclass"]}
                    </a>
                  </Link>
                )}
              {cookies.get("user") &&
                cookies.get("user").user.role.role_detail.liveclasshistory &&
                cookies.get("user").user.role.role_detail.liveclasshistory ===
                  "True" && (
                  <Link to="/liveclasshistory" className="button w-100">
                    <a
                      href="#"
                      className={
                        this.state.page === "liveclasshistory"
                          ? "btn btn-white w-100 text-left border-r-0 text-white bg-primary side-menu-tab"
                          : "btn btn-white w-100 text-left border-r-0 text-secondary side-menu-tab"
                      }
                    >
                      <label className="icon mr-1">{"\uf5a0"}</label>

                      {global[this.language]["liveclasshistory"]}
                    </a>
                  </Link>
                )}
            </div>
          </div>
        </div>
        {this.state.toggle && (
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#00000030",
              position: "fixed",
              zIndex: 5,
            }}
            className="blur"
            onClick={() => {
              this.setState({ toggle: !this.state.toggle });
            }}
          ></div>
        )}
        <button
          className={
            this.state.toggle
              ? "btn-toggle-right d-block d-lg-none d-md-none d-lg-none d-xl-none"
              : "btn-toggle-left d-block d-lg-none d-md-none d-lg-none d-xl-none"
          }
          onClick={() => {
            this.setState({ toggle: !this.state.toggle });
          }}
          style={{
            backgroundColor: this.state.toggle ? "#00000040" : "#00000000",
            marginLeft: 8,
            borderRadius: "50%",
            paddingLeft: 4,
            paddingTop: 4,
            position: this.state.toggle ? "fixed" : "",
            outline: "none",
          }}
        >
          <label className="icon-solid mr-1 text-secondary">{"\uf0c9"}</label>
        </button>
      </div>
    );
  }
}
