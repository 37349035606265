import "../../../bootstrap.css";
import React, { Component } from "react";
import SideMenu from "../../Content/SideMenu/SideMenu";
import SideMenuUser from "../../Content/SideMenuUser/SideMenuUser";
import Header from "../../Content/Header/Header";

import Swal from "sweetalert2";
import "@szhsin/react-menu/dist/index.css";

import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class LiveClassScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      new_password: "",
      new_confirmpassword: "",
      new_security: "password",
      confirm_security: "password",
      _id: "",
    };
  }
  language = cookies.get("language") ? cookies.get("language") : "TH";
  componentDidMount() {
    if (cookies.get("user")) {
      let data = cookies.get("user");

      this.setState({ username: data.username });
      if (data.status === "admin") {
        this.setState({
          _id: data._id,
        });
      } else if (data.status === "teacher") {
        this.setState({
          _id: data._id,
        });
      } else if (data.status === "student") {
        this.setState({
          _id: data._id,
        });
      }
    } else {
      Swal.fire({
        title: global[this.language]["alert"],
        text: global[this.language]["retrieve_data_fail"],
        icon: "warning",
        showCancelButton: false,
        allowOutsideClick: false,
        confirmButtonText: global[this.language]["ok"],
      }).then((result) => {
        if (result.isConfirmed) {
          cookies.remove("token", { path: "/" });
          cookies.remove("user", { path: "/" });

          window.location.href = "/";
        }
      });
    }
  }

  Cancel = () => {
    this.setState({
      username: "",
      new_password: "",
      new_confirmpassword: "",
      new_security: "password",
      confirm_security: "password",
    });
  };
  Submit_edit_password = () => {
    if (this.state.new_password === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_newpassword"],
        "warning"
      );
    } else if (this.state.new_confirmpassword === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_confirmpassword"],
        "warning"
      );
    } else if (this.state.new_password !== this.state.new_confirmpassword) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["password_notmatch"],
        "warning"
      );
    } else {
      if (cookies.get("user").user.status === "student") {
        fetch(global.httpURL + "/api/student/password/" + this.state._id, {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
          body: JSON.stringify({
            password: this.state.new_password,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.message === "Success") {
              cookies.set("token", response.token, { path: "/" });
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["edit_success"],
                ""
              );
              setTimeout(() => {
                window.location.href = "/editpassword";
              }, 1000);
            }
          })
          .catch((err) => {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["add_not_success"],
              "warning"
            );
          });
      } else if (cookies.get("user").user.status === "teacher") {
        fetch(global.httpURL + "/api/teacher/password/" + this.state._id, {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
          body: JSON.stringify({
            password: this.state.new_password,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.message === "Success") {
              cookies.set("token", response.token, { path: "/" });
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["edit_success"],
                "success"
              );
              setTimeout(() => {
                window.location.href = "/editpassword";
              }, 500);
            }
          })
          .catch((err) => {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["add_not_success"],
              "warning"
            );
          });
      } else {
        fetch(global.httpURL + "/api/user/password/" + this.state._id, {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
          body: JSON.stringify({
            password: this.state.new_password,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.message === "Success") {
              cookies.set("token", response.token, { path: "/" });
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["edit_success"],
                "success"
              );
              setTimeout(() => {
                window.location.href = "/editpassword";
              }, 500);
            }
          })
          .catch((err) => {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["add_not_success"],
              "warning"
            );
          });
      }
    }
  };

  render() {
    return (
      <div className="w-100 h-auto minh-100">
        <div className="d-flex h-auto minh-100">
          <div className="h-auto minh-100">
            {cookies.get("user") && (
              <>
                {cookies.get("user").user.status === "teacher" ||
                cookies.get("user").user.status === "student" ? (
                  <>
                    <SideMenuUser page="" />
                  </>
                ) : (
                  <SideMenu page="" />
                )}
              </>
            )}
          </div>
          <div className="w-100-body">
            <Header />
            <div className="container-fluid pt-3">
              <div className="row">
                <div className="col-3" />
                <div className="col-6 mb-3">
                  {/* STUDENT */}
                  <div className="card border-0 p-4 mb-3">
                    <div className="flex-row justify-content-between align-items-center row p-4">
                      <div>
                        <h3>
                          <b>{global[this.language]["editpassword"]}</b>
                        </h3>
                      </div>
                    </div>
                    <div>
                      <div className="row">
                        <div className="col-12 pb-3">
                          <label>{global[this.language]["username"]}:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["text_hint"]}
                            value={this.state.username}
                            readOnly
                            onChange={(event) => {
                              this.setState({
                                username: event.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className="col-12 pb-3">
                          <label>{global[this.language]["newpassword"]}:</label>
                          <div className="text-right">
                            <input
                              type={this.state.new_security}
                              placeholder={global[this.language]["password"]}
                              className="form-control"
                              value={this.state.new_password}
                              onChange={(event) => {
                                this.setState({
                                  new_password: event.target.value,
                                });
                              }}
                            />
                            <a
                              className="btn btn-link m-t-input"
                              onClick={() => {
                                this.state.new_security === "text"
                                  ? this.setState({ new_security: "password" })
                                  : this.setState({ new_security: "text" });
                              }}
                            >
                              <b className="icon mr-1 text-primary">
                                {this.state.new_security === "text"
                                  ? "\uf06e"
                                  : "\uf070"}
                              </b>
                            </a>
                          </div>
                        </div>
                        <div className="col-12 pb-3 mt--2">
                          <label>
                            {global[this.language]["confirmnewpassword"]}:
                          </label>
                          <div className="text-right">
                            <input
                              type={this.state.confirm_security}
                              placeholder={global[this.language]["password"]}
                              className="form-control"
                              value={this.state.new_confirmpassword}
                              onChange={(event) => {
                                this.setState({
                                  new_confirmpassword: event.target.value,
                                });
                              }}
                            />
                            <a
                              className="btn btn-link m-t-input"
                              onClick={() => {
                                this.state.confirm_security === "text"
                                  ? this.setState({
                                      confirm_security: "password",
                                    })
                                  : this.setState({ confirm_security: "text" });
                              }}
                            >
                              <b className="icon mr-1 text-primary">
                                {this.state.confirm_security === "text"
                                  ? "\uf06e"
                                  : "\uf070"}
                              </b>
                            </a>
                          </div>
                        </div>
                        <div className="col-6 pb-3">
                          <button
                            className="btn btn-secondary w-100"
                            onClick={() => {
                              this.Cancel();
                            }}
                          >
                            {global[this.language]["cancel"]}
                          </button>
                        </div>
                        <div className="col-6 pb-3">
                          <button
                            className="btn btn-primary w-100"
                            onClick={() => {
                              this.Submit_edit_password();
                            }}
                          >
                            {global[this.language]["save_edit"]}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-3" />
              </div>
              {/* MODAL */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
