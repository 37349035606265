import LOGO from "./Logo.png";
import "../../../index.css";
import "../../../bootstrap.css";
import React, { Component } from "react";
import { Link } from "react-router";

import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { ZoomMtg } from "@zoomus/websdk";
import crypto from "crypto";
const cookies = new Cookies();

export default class Live extends Component {
  constructor(props) {
    super(props);
    this.state = {
      now_date: "",
      now_time: "",
      signatureEndpoint: global.leaveUrl,
      apiKey: "",
      apiSecret: "",
      meetingNumber: "",
      leaveUrl: global.leaveUrl + "liveclass",
      zoomstart: false,
    };
  }

  language = cookies.get("language") ? cookies.get("language") : "TH";
  componentDidMount() {
    setTimeout(() => {
      var data = {
        zoom_api_key: this.props.location.query.zoom_api_key.replace('"', ""),
        zoom_api_secret: this.props.location.query.zoom_api_secret.replace(
          '"',
          ""
        ),
        meeting_number: this.props.location.query.meeting_number.replace(
          '"',
          ""
        ),
        meeting_password: this.props.location.query.meeting_password.replace(
          '"',
          ""
        ),
        role: this.props.location.query.role.replace('"', ""),
      };
      console.log(data);
      ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.5/lib", "/av");
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareJssdk();
      ZoomMtg.i18n.load("en-US");
      ZoomMtg.i18n.reload("en-US");
      this.getSignature(data);
    }, 10);
  }
  getSignature = (data) => {
    this.setState({
      zoomstart: false,
    });
    var zoom_api_key = data.zoom_api_key;
    var zoom_api_secret = data.zoom_api_secret;
    const timestamp = new Date().getTime() - 50000;
    // var zoom_api_secret = this.state.apisecret;
    console.log("role", data.role);
    const msg = Buffer.from(
      zoom_api_key + data.meeting_number + timestamp + data.role
    ).toString("base64");
    const hash = crypto
      .createHmac("sha256", zoom_api_secret)
      .update(msg)
      .digest("base64");
    const signature = Buffer.from(
      `${zoom_api_key}.${data.meeting_number}.${timestamp}.${data.role}.${hash}`
    ).toString("base64");
    this.startMeeting(signature, data);
  };
  startMeeting = (signature, data) => {
    document.getElementById("zmmtg-root").style.display = "block";
    this.setState({ zoom: true });
    ZoomMtg.init({
      leaveUrl: this.state.leaveUrl,
      isSupportAV: true,
      isSupportChat: true,
      isSupportQA: true,
      showMeetingHeader: false,
      videoHeader: false,
      disableInvite: true,
      videoDrag: true,
      debug: true,
      inviteUrlFormat: "hide",
      screenShare: true,
      sharingMode: "both",
      success: (success) => {
        console.log("1", success);
        ZoomMtg.join({
          signature: signature,
          meetingNumber: data.meeting_number,
          userName: cookies.get("user").user.username,
          apiKey: data.zoom_api_key,
          userEmail: cookies.get("user").user.username + "@jmschool.com",
          passWord: data.meeting_password,
          success: (success) => {
            console.log("2", success);
            this.setState({
              zoomstart: true,
            });
          },
          error: (error) => {
            if (error.result && error.result.includes("not started")) {
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["not_start_class"],
                "warning"
              );
            } else {
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["can_not_join"],
                "warning"
              );
            }
            document.getElementById("zmmtg-root").style.display = "none";
          },
        });
      },
      error: (error) => {
        console.error("1", error);
        this.setState({
          zoomstart: true,
        });
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["can_not_join"],
          "warning"
        );
        this.setState({ zoom: false });
        document.getElementById("zmmtg-root").style.display = "none";
      },
    });
  };
  render() {
    return <div></div>;
  }
}
