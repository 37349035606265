import "../../bootstrap.css";
import "./style.css";
import React, { Component } from "react";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { Link } from "react-router";
import ninja from "./ninja.png";

import axios from "axios";
const cookies = new Cookies();
export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <div style={{ background: "#E54045" }} className="py-4">
          <div className="container text-center">
            <h1 style={{ fontSize: 39, fontWeight: "bold", color: "#FFF" }}>
              วีดีโอสาธิต
            </h1>
            <h1 style={{ fontSize: 29, fontWeight: "bold", color: "#FFF" }}>
              วิธีใช้งาน
            </h1>
          </div>
        </div>
        <div className="container text-center pt-5 text-center">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/NSpoxPXosw4"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <label className="mt-4" style={{ fontSize: 20, fontWeight: "bold" }}>
            สมัครสมาชิกกับ Ninja Cargo
          </label>
        </div>
      </div>
    );
  }
}
