import "../../../bootstrap.css";
import React, { Component } from "react";
import SideMenu from "../../Content/SideMenu/SideMenu";
import Header from "../../Content/Header/Header";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import th from "date-fns/locale/th";
import Cookies from "universal-cookie";
import { roundToNearestMinutes } from "date-fns";
import jwt from "jsonwebtoken";

import axios from "axios";
const cookies = new Cookies();
registerLocale("th", th);
export default class LiveClassScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiKey: "",
      apiSecret: "",

      activePage: 1,
      page_count: 1,
      loading: true,
      modal_add_liveclass: false,
      modal_edit_liveclass: false,
      modal_detail_live: false,
      live_id: "",
      batch_name: "",
      zoom_api_key: "",
      zoom_api_secret: "",
      meeting_number: "",
      meeting_password: "",
      live_date: "",
      live_startime: "",
      live_endtime: "",
      liveclass_teacher: "",
      liveclass_student: [],
      teacher: [],
      student: [],
      student_add: [],
      search_teacher: "",
      search_student: "",
      menu_Teacher: false,
      menu_Student: false,
      detail: 0,
      data: [],
      sort: [0, 0, 0, 0, 0, 0],
      spinner: false,
      search: "",
    };
  }
  language = cookies.get("language") ? cookies.get("language") : "TH";
  componentDidMount() {
    if (cookies.get("user")) {
      if (cookies.get("token")) {
        this.GetLiveClass();
      } else {
        Swal.fire({
          title: global[this.language]["alert"],
          text: global[this.language]["retrieve_data_fail"],
          icon: "warning",
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonText: global[this.language]["ok"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.Logout();
          }
        });
      }
    } else {
      Swal.fire({
        title: global[this.language]["alert"],
        text: global[this.language]["retrieve_data_fail"],
        icon: "warning",
        showCancelButton: false,
        allowOutsideClick: false,
        confirmButtonText: global[this.language]["ok"],
      }).then((result) => {
        if (result.isConfirmed) {
          cookies.remove("token", { path: "/" });
          cookies.remove("user", { path: "/" });

          window.location.href = "/";
        }
      });
    }
  }
  Logout = () => {
    fetch(global.httpURL + "/api/logout", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "token " + cookies.get("token"),
      },
      // mode: "no-cors",
    })
      .then((response) => response.json())
      .then((response) => {
        cookies.remove("token", { path: "/" });
        cookies.remove("user", { path: "/" });

        window.location.href = "/";
      })
      .catch((err) => {
        //window.location.href = "/dashboard";
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_logout"],
          "warning"
        );
      });
  };

  GetLiveClass = () => {
    this.setState({ loading: true });
    fetch(global.httpURL + "/api/liveclass/page/" + this.state.activePage, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (
          response.message === "Auth Failed" ||
          response.message === "Token is null"
        ) {
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["unavailable"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.Logout();
            }
          });
        } else if (response.message === "Connot find page") {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          if (response.data) {
            this.setState({
              data: response.data.live_class,
              teacher: response.data.teacher,
              student: response.data.student,
              loading: false,
              page_count: response.page_count,
            });
          } else {
            this.setState({
              loading: false,
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loading: false });
        Swal.fire({
          title: global[this.language]["alert"],
          text: global[this.language]["retrieve_data_fail"],
          icon: "warning",
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonText: global[this.language]["ok"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.Logout();
          }
        });
      });
  };
  Cancel = () => {
    this.setState({
      modal_add_liveclass: false,
      modal_edit_liveclass: false,
      modal_detail_live: false,
      batch_name: "",
      zoom_api_key: "",
      zoom_api_secret: "",
      meeting_number: "",
      meeting_password: "",
      live_date: "",
      live_startime: "",
      live_endtime: "",
      liveclass_teacher: "",
      liveclass_student: [],
      student_add: [],
      search_teacher: "",
      search_student: "",
      menu_Teacher: false,
      menu_Student: false,
    });
  };
  Submit_add_liveclass = () => {
    if (this.state.batch_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_subject"],
        "warning"
      );
    } else if (this.state.live_date === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_date"],
        "warning"
      );
    } else if (this.state.live_startime === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_startdate"],
        "warning"
      );
    } else if (this.state.live_endtime === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_enddate"],
        "warning"
      );
    }
    // else if (this.state.meeting_number === "") {
    //   Swal.fire(
    //     global[this.language]["alert"],
    //     global[this.language]["enter_your_meetingnumber"],
    //     "warning"
    //   );
    // } else if (this.state.meeting_password === "") {
    //   Swal.fire(
    //     global[this.language]["alert"],
    //     global[this.language]["enter_your_meetingpassword"],
    //     "warning"
    //   );
    // }
    else if (this.state.liveclass_teacher === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_teacher"],
        "warning"
      );
    } else if (this.state.liveclass_student.length === 0) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_student"],
        "warning"
      );
    } else {
      this.setState({ spinner: true });
      let stu = [];
      let date =
        this.state.live_date.getDate().toString().length === 1
          ? "0" + this.state.live_date.getDate()
          : this.state.live_date.getDate();
      let month =
        this.state.live_date.getMonth().toString().length === 1
          ? "0" + (this.state.live_date.getMonth() + 1)
          : this.state.live_date.getMonth() + 1;
      let fulldate =
        date + "/" + month + "/" + this.state.live_date.getFullYear();
      for (let s of this.state.liveclass_student) {
        stu.push(s._id);
      }

      var zoom_api_key = this.state.liveclass_teacher.zoom_api_key;
      var zoom_api_secret = this.state.liveclass_teacher.zoom_api_secret;
      const payload = {
        iss: zoom_api_key,
        exp: new Date().getTime() + 15000,
      };
      const token = jwt.sign(payload, zoom_api_secret);
      const meeting_password = Math.random().toString(36).slice(-8);
      const start_time = new Date(this.state.live_date);
      start_time.toLocaleString("en-US", { timeZone: "Asia/Bangkok" });
      start_time.setHours(Number(this.state.live_startime.split(":")[0]));
      start_time.setMinutes(Number(this.state.live_startime.split(":")[1]));
      console.log(start_time);
      console.log(Number(this.state.live_startime.split(":")[0]));
      console.log(Number(this.state.live_startime.split(":")[1]));
      var setting = {
        topic: this.state.batch_name,
        type: 2,
        start_time: start_time,
        duration: 50,
        timezone: "Asia/Bangkok",
        password: meeting_password,
        agenda:
          "วิชา : " +
          this.state.batch_name +
          "\nวันที่ Live : " +
          fulldate +
          "\nเวลาเริ่มต้น : " +
          this.state.live_startime +
          "\nเวลาสิ้นสุด : " +
          this.state.live_endtime,
        settings: {
          host_video: true,
          participant_video: true,
          cn_meeting: false,
          in_meeting: false,
          join_before_host: true,
          mute_upon_entry: true,
          watermark: false,
          approval_type: 2,
          audio: "both",
          auto_recording: "cloud",
          alternative_hosts: "",
          close_registration: true,
          waiting_room: false,
          contact_name: "DevGrit",
          contact_email: "developer@grit.technology",
          registrants_email_notification: false,
          registrants_confirmation_email: false,
          meeting_authentication: false,
          authentication_domains: "",
        },
      };
      console.log(
        JSON.stringify({
          batch_name: this.state.batch_name,
          zoom_api_key: this.state.zoom_api_key,
          zoom_api_secret: this.state.zoom_api_secret,
          token_zoom: token,
          live_date: fulldate,
          live_startime: this.state.live_startime,
          live_endtime: this.state.live_endtime,
          setting_zoom: JSON.stringify(setting),
          teacher: this.state.liveclass_teacher._id,
          student: stu,
        })
      );
      console.log(fulldate);
      setTimeout(() => {
        fetch(global.httpURL + "/api/liveclass", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
          body: JSON.stringify({
            batch_name: this.state.batch_name,
            zoom_api_key: this.state.zoom_api_key,
            zoom_api_secret: this.state.zoom_api_secret,
            token_zoom: token,
            live_date: fulldate,
            live_startime: this.state.live_startime,
            live_endtime: this.state.live_endtime,
            setting_zoom: JSON.stringify(setting),
            teacher: this.state.liveclass_teacher._id,
            student: stu,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            console.log(response);
            this.setState({ spinner: false });
            if (!response.auth) {
              if (response.message === "meeting number already exists") {
                Swal.fire(
                  global[this.language]["alert"],
                  global[this.language]["enter_your_meeting_similar"],
                  "warning"
                );
              } else {
                Swal.fire(
                  global[this.language]["alert"],
                  global[this.language]["add_not_success"],
                  "warning"
                );
              }
            } else {
              if (response.data.code) {
                Swal.fire(
                  global[this.language]["alert"],
                  global[this.language]["add_not_success_api"],
                  "warning"
                );
              } else {
                Swal.fire(
                  global[this.language]["alert"],
                  global[this.language]["add_success"],
                  "success"
                );
                this.Cancel();
                this.GetLiveClass();
              }
            }
          })
          .catch((err) => {
            this.setState({ spinner: false });
          });
      }, 10);
    }
  };
  Submit_edit_liveclass = () => {
    if (this.state.batch_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_subject"],
        "warning"
      );
    } else if (this.state.live_date === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_date"],
        "warning"
      );
    } else if (this.state.live_startime === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_startdate"],
        "warning"
      );
    } else if (this.state.live_endtime === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_enddate"],
        "warning"
      );
    } else if (this.state.meeting_number === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_meetingnumber"],
        "warning"
      );
    } else if (this.state.meeting_password === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_meetingpassword"],
        "warning"
      );
    } else if (this.state.liveclass_teacher === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_teacher"],
        "warning"
      );
    } else if (this.state.liveclass_student.length === 0) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_student"],
        "warning"
      );
    } else {
      this.setState({ spinner: true });
      let stu = [];
      for (let s of this.state.liveclass_student) {
        stu.push(s._id);
      }

      fetch(global.httpURL + "/api/liveclass/" + this.state.live_id, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + cookies.get("token"),
        },
        body: JSON.stringify({
          batch_name: this.state.batch_name,
          zoom_api_key: this.state.zoom_api_key,
          zoom_api_secret: this.state.zoom_api_secret,
          meeting_number: this.state.meeting_number.replace(/ /g, ""),
          meeting_password: this.state.meeting_password,
          live_date: this.state.live_date,
          live_startime: this.state.live_startime,
          live_endtime: this.state.live_endtime,
          teacher: this.state.liveclass_teacher._id,
          student: stu,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({ spinner: false });
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["add_success"],
            "success"
          );
          this.Cancel();
          this.GetLiveClass();
        })
        .catch((err) => {
          this.setState({ spinner: false });
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["add_not_success"],
            "warning"
          );
        });
    }
  };
  Delete_Live = (index) => {
    Swal.fire({
      title: global[this.language]["confirm_delete"],
      showCancelButton: true,
      confirmButtonText: global[this.language]["confirm"],
      cancelButtonText: global[this.language]["cancel"],
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ spinner: true });
        const payload = {
          iss: this.state.data[index].zoom_api_key,
          exp: new Date().getTime() + 5000,
        };
        const token = jwt.sign(payload, this.state.data[index].zoom_api_secret);
        fetch(global.httpURL + "/api/liveclass/" + this.state.data[index]._id, {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + cookies.get("token"),
          },
          body: JSON.stringify({
            token_zoom: token,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_success"],
              "success"
            );
            this.GetLiveClass();
          })
          .catch((err) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_not_success"],
              "warning"
            );
          });
      }
    });
  };
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber, loading: true });
    setTimeout(() => {
      this.GetLiveClass();
    }, 1);
  }
  handleClick(e) {
    if (e.target.id === "teacher-menu") {
      this.setState({
        menu_Teacher: true,
        menu_Student: false,
      });
    } else if (e.target.id === "student-menu") {
      this.setState({
        menu_Student: true,
        menu_Teacher: false,
      });
    } else {
      this.setState({
        menu_Teacher: false,
        menu_Student: false,
      });
    }
  }
  Sort = (key) => {
    let list = this.state.data;
    let sorted = this.state.sort;
    if (key === "subject") {
      if (sorted[0] === 0) {
        list.sort((a, b) => (a.batch_name < b.batch_name ? 1 : -1));
        sorted[0] = 1;
      } else {
        list.sort((a, b) => (a.batch_name > b.batch_name ? 1 : -1));
        sorted[0] = 0;
      }
    } else if (key === "teacher") {
      if (sorted[1] === 0) {
        list.sort((a, b) => (a.teacher.name < b.teacher.name ? 1 : -1));
        sorted[1] = 1;
      } else {
        list.sort((a, b) => (a.teacher.name > b.teacher.name ? 1 : -1));
        sorted[1] = 0;
      }
    } else if (key === "date") {
      if (sorted[2] === 0) {
        list.sort((a, b) => (a.live_date < b.live_date ? 1 : -1));
        sorted[2] = 1;
      } else {
        list.sort((a, b) => (a.live_date > b.live_date ? 1 : -1));
        sorted[2] = 0;
      }
    } else if (key === "time") {
      if (sorted[3] === 0) {
        list.sort((a, b) => (a.live_startime < b.live_startime ? 1 : -1));
        sorted[3] = 1;
      } else {
        list.sort((a, b) => (a.live_startime > b.live_startime ? 1 : -1));
        sorted[3] = 0;
      }
    } else if (key === "number") {
      if (sorted[4] === 0) {
        list.sort((a, b) => (a.meeting_number < b.meeting_number ? 1 : -1));
        sorted[4] = 1;
      } else {
        list.sort((a, b) => (a.meeting_number > b.meeting_number ? 1 : -1));
        sorted[4] = 0;
      }
    } else if (key === "password") {
      if (sorted[5] === 0) {
        list.sort((a, b) => (a.meeting_password < b.meeting_password ? 1 : -1));
        sorted[5] = 1;
      } else {
        list.sort((a, b) => (a.meeting_password > b.meeting_password ? 1 : -1));
        sorted[5] = 0;
      }
    }

    this.setState({ data: list, sort: sorted });
  };
  search = () => {
    fetch(global.httpURL + "/api/search/lc/page/1", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ data_search: this.state.search }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({ spinner: false });
        if (
          response.message === "Auth Failed" ||
          response.message === "Token is null"
        ) {
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["unavailable"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.Logout();
            }
          });
        } else if (
          response.message === "Connot find page" ||
          response.message === "No information found"
        ) {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            data: response.data.live_class,
            loading: false,
            page_count: response.page_count,
          });
        }
      })
      .catch((err) => {
        this.setState({ spinner: false });
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_logout"],
          "warning"
        );
      });
  };
  render() {
    return (
      <div
        className="w-100 h-auto minh-100"
        onClick={this.handleClick.bind(this)}
      >
        {this.state.spinner && (
          <div className="spinner">
            <div className="loader" />
          </div>
        )}
        <div className="d-flex h-auto minh-100">
          <div className="h-auto minh-100">
            <SideMenu page="manageliveclass" />
          </div>
          <div className="w-100-body">
            <Header />
            <div className="container-fluid pt-3">
              {/* TABLE */}
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="card border-0 p-4">
                    <div className="flex-row justify-content-between align-items-center row p-4">
                      <div>
                        <h3>
                          <b>{global[this.language]["manage_liveclass"]}</b>
                        </h3>
                      </div>
                      {/* BUTTON XL */}
                      <div className="text-right">
                        {/* <a
                          className="btn btn-link bg-zoom mr-2 ml-2 text-white"
                          href="https://us05web.zoom.us/meeting/schedule"
                          target="_blank"
                        >
                          <label className="icon mr-1">{"\uf03d"}</label>
                          ZOOM
                        </a> */}
                        <div className="search-box-div mr-2 ml-2">
                          <input
                            type="text"
                            className="search-box mt-2"
                            placeholder={
                              global[this.language]["course_name"] +
                              "," +
                              global[this.language]["teacher_name"]
                            }
                            value={this.state.search}
                            onChange={(event) => {
                              this.setState({
                                search: event.target.value,
                                activePage: 1,
                              });
                              setTimeout(() => {
                                if (this.state.search === "") {
                                  this.search();
                                }
                              }, 1);
                            }}
                            onKeyUp={(event) => {
                              console.log(event.key);
                              if (event.key === "Enter") {
                                this.search();
                              }
                            }}
                          />
                          <button
                            className="icon-absolute"
                            onClick={() => {
                              this.search();
                            }}
                          >
                            {"\uf002"}
                          </button>
                        </div>
                        <button
                          className="btn btn-primary ml-2 mt-2"
                          onClick={() => {
                            this.setState({
                              modal_add_liveclass: true,
                              student_add: this.state.student,
                            });
                          }}
                        >
                          <label className="icon mr-1">{"\uf055"}</label>
                          {global[this.language]["add_liveclass"]}
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {!this.state.loading ? (
                        <table className="table table-borderless table-striped table-w-1 table-hover ">
                          <thead className="thead-dark">
                            <tr>
                              <th className="text-center">
                                {global[this.language]["order"]}
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("subject");
                                }}
                              >
                                {global[this.language]["course_name"]}
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("teacher");
                                }}
                              >
                                {global[this.language]["teacher_name"]}
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("date");
                                }}
                              >
                                {global[this.language]["open_date"]}
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("time");
                                }}
                              >
                                {global[this.language]["open_time"]}
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("number");
                                }}
                              >
                                Meeting Number
                              </th>
                              <th
                                className="point"
                                onClick={() => {
                                  this.Sort("password");
                                }}
                              >
                                Password
                              </th>
                              <th className="text-center">
                                {global[this.language]["all_info"]}
                              </th>
                              <th className="text-center">
                                {global[this.language]["edit"]}
                              </th>
                              <th className="text-center">
                                {global[this.language]["delete"]}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* DEPARTMENT */}
                            {this.state.data &&
                              this.state.data.map(
                                (liveclass, liveclass_index) => (
                                  <tr>
                                    <td className="text-center">
                                      {liveclass_index +
                                        1 +
                                        (this.state.activePage - 1) * 10}
                                    </td>
                                    <td>{liveclass.batch_name}</td>
                                    <td>{liveclass.teacher.user.name}</td>
                                    <td>{liveclass.live_date}</td>
                                    <td>
                                      {liveclass.live_startime} -{" "}
                                      {liveclass.live_endtime}
                                    </td>
                                    <td>{liveclass.meeting_number}</td>
                                    <td>{liveclass.meeting_password}</td>
                                    <td className="text-center">
                                      <button
                                        className="btn btn-link pt-0 pb-0"
                                        onClick={() => {
                                          this.setState({
                                            modal_detail_live: true,
                                            detail: liveclass_index,
                                          });
                                        }}
                                      >
                                        {global[this.language]["info"]}
                                      </button>
                                    </td>

                                    <td className="text-center">
                                      <a
                                        className="btn btn-link p-0"
                                        onClick={() => {
                                          let student = this.state.student;
                                          for (
                                            let i = 0;
                                            i < this.state.student.length;
                                            i++
                                          ) {
                                            for (let s of liveclass.student) {
                                              if (
                                                student[i].nickname ===
                                                s.nickname
                                              ) {
                                                student[i].select = true;
                                              }
                                            }
                                          }
                                          this.setState({
                                            student_add: student,
                                            modal_edit_liveclass: true,
                                            live_id: liveclass._id,
                                            batch_name: liveclass.batch_name,
                                            zoom_api_key:
                                              liveclass.zoom_api_key,
                                            zoom_api_secret:
                                              liveclass.zoom_api_secret,
                                            meeting_number:
                                              liveclass.meeting_number,
                                            meeting_password:
                                              liveclass.meeting_password,
                                            live_date: liveclass.live_date,
                                            live_startime:
                                              liveclass.live_startime,
                                            live_endtime:
                                              liveclass.live_endtime,
                                            liveclass_teacher:
                                              liveclass.teacher,
                                            liveclass_student:
                                              liveclass.student,
                                          });
                                        }}
                                      >
                                        <label className="icon">
                                          {"\uf304"}
                                        </label>
                                      </a>
                                    </td>
                                    <td className="text-center">
                                      <a
                                        className="btn btn-link p-0"
                                        onClick={() => {
                                          this.Delete_Live(liveclass_index);
                                        }}
                                      >
                                        <label className="icon">
                                          {"\uf2ed"}
                                        </label>
                                      </a>
                                    </td>
                                  </tr>
                                )
                              )}
                            {this.state.data && this.state.data.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={10}>
                                  {global[this.language]["no_data"]}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <div className="w-100 d-flex justify-content-center">
                          <div className="loader" />
                        </div>
                      )}
                    </div>
                    <div className="d-flex flex-row-reverse mt-3">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.page_count * 10}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        prevPageText="Previous"
                        nextPageText="Next"
                        hideFirstLastPages={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* MODAL */}
              {/* เพิ่ม live */}
              <Modal
                show={this.state.modal_add_liveclass}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["add_liveclass"]}</b>
                  </h3>
                  <div className="row">
                    {/* course_name */}
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["course_name"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.batch_name}
                        onChange={(event) => {
                          this.setState({
                            batch_name: event.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* live_date */}
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["live_date"]}:</label>

                      <br />
                      <DatePicker
                        className="form-control w-100"
                        selected={this.state.live_date}
                        minDate={new Date()}
                        locale="th"
                        onChange={(date) => {
                          this.setState({ live_date: date });
                        }}
                      />
                    </div>
                    {/* start_time */}
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["start_time"]}:</label>
                      <input
                        type="time"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.live_startime}
                        onChange={(event) => {
                          this.setState({
                            live_startime: event.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* end_time */}
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["end_time"]}:</label>
                      <input
                        type="time"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.live_endtime}
                        onChange={(event) => {
                          this.setState({
                            live_endtime: event.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* Meeting Number */}
                    {/* <div className="col-12 pb-3">
                      <label>Meeting Number:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.meeting_number}
                        onChange={(event) => {
                          this.setState({
                            meeting_number: event.target.value,
                          });
                        }}
                      />
                    </div> */}
                    {/* Password */}
                    {/* <div className="col-12 pb-3">
                      <label>Password:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.meeting_password}
                        onChange={(event) => {
                          this.setState({
                            meeting_password: event.target.value,
                          });
                        }}
                      />
                    </div> */}
                    {/* teacher */}
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["teacher_name"]}:</label>
                      <div
                        className="form-control"
                        onClick={this.handleClick.bind(this)}
                        id="teacher-menu"
                      >
                        <label
                          className={
                            this.state.liveclass_teacher == ""
                              ? "text-secondary"
                              : ""
                          }
                          onClick={this.handleClick.bind(this)}
                          id="teacher-menu"
                        >
                          {this.state.liveclass_teacher == ""
                            ? "--" +
                              global[this.language]["select_teacher"] +
                              "--"
                            : this.state.liveclass_teacher.user.name}
                        </label>
                      </div>
                      <div
                        className={
                          this.state.menu_Teacher
                            ? "menu-context-box-xl active"
                            : "menu-context-box-xl hidden"
                        }
                        style={{ left: this.state.x, top: this.state.y }}
                      >
                        <div className="p-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="ค้นหา"
                            value={this.state.search_teacher}
                            onChange={(event) => {
                              this.setState({
                                search_teacher: event.target.value,
                              });
                            }}
                            onClick={this.handleClick.bind(this)}
                            id="teacher-menu"
                          />
                        </div>
                        <div className="search-select-max-height overflow-y">
                          {this.state.teacher &&
                            this.state.teacher.map((item, index) => (
                              <>
                                {(item.user.name.includes(
                                  this.state.search_teacher
                                ) ||
                                  this.state.search_teacher === "") && (
                                  <button
                                    className="menu-context-button "
                                    onClick={() => {
                                      this.setState({
                                        liveclass_teacher: item,
                                        zoom_api_key: item.zoom_api_key,
                                        zoom_api_secret: item.zoom_api_secret,
                                      });
                                    }}
                                  >
                                    <label>{item.user.name}</label>
                                  </button>
                                )}
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                    {/* students */}
                    <div className="col-12 pb-3">
                      <label>
                        {global[this.language]["students"]}:
                        {this.state.liveclass_student.length !== 0 &&
                          " ( " +
                            this.state.liveclass_student.length +
                            global[this.language]["person"] +
                            " )"}
                      </label>
                      <div
                        className="form-control-style"
                        onClick={this.handleClick.bind(this)}
                        id="student-menu"
                      >
                        {this.state.liveclass_student.length === 0 ? (
                          <p
                            className={"text-secondary pb-0"}
                            onClick={this.handleClick.bind(this)}
                            id="student-menu"
                          >
                            --
                            {global[this.language]["select_student"]}
                            --
                          </p>
                        ) : (
                          <div
                            className="row"
                            onClick={this.handleClick.bind(this)}
                            id="student-menu"
                          >
                            {this.state.liveclass_student &&
                              this.state.liveclass_student.map(
                                (item, index) => (
                                  <div className="selected-box bg-primary text-white m-1">
                                    <label>{item.nickname}</label>
                                    <button
                                      className="btn-link p-0 text-white icon ml-1 mt-1"
                                      onClick={() => {
                                        let data = this.state.liveclass_student,
                                          array = this.state.student_add;
                                        for (let a of array) {
                                          if (a.nickname === item.nickname) {
                                            a.select = false;
                                          }
                                        }
                                        data.splice(index, 1);
                                        this.setState({
                                          liveclass_student: data,
                                          student_add: array,
                                        });
                                      }}
                                    >
                                      {"\uf00d"}
                                    </button>
                                  </div>
                                )
                              )}
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          this.state.menu_Student
                            ? "menu-context-box-xl active"
                            : "menu-context-box-xl hidden"
                        }
                        style={{ left: this.state.x, top: this.state.y }}
                      >
                        <div className="p-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="ค้นหา"
                            value={this.state.search_student}
                            onChange={(event) => {
                              this.setState({
                                search_student: event.target.value,
                              });
                            }}
                            onClick={this.handleClick.bind(this)}
                            id="student-menu"
                          />
                        </div>
                        <div className="search-select-max-height overflow-y">
                          {this.state.student_add &&
                            this.state.student_add.map((item, index) => (
                              <>
                                {(item.nickname.includes(
                                  this.state.search_student
                                ) ||
                                  this.state.search_student === "") && (
                                  <button
                                    className="d-flex align-items-center p-2 w-100 text-left search-button-select"
                                    id="student-menu"
                                    onClick={() => {
                                      let student = this.state.student_add,
                                        data = [];
                                      student[index].select =
                                        !student[index].select;

                                      for (let st of student) {
                                        if (st.select) {
                                          data.push(st);
                                        }
                                        this.setState({
                                          student_add: student,
                                          liveclass_student: data,
                                        });
                                      }
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={item.select}
                                      id="student-menu"
                                      onChange={(e) => {
                                        let student = this.state.student_add,
                                          data = [];
                                        student[index].select =
                                          e.target.checked;

                                        for (let st of student) {
                                          if (st.select) {
                                            data.push(st);
                                          }
                                          this.setState({
                                            student_add: student,
                                            liveclass_student: data,
                                          });
                                        }
                                      }}
                                    ></input>
                                    <div
                                      className="w-100 pl-2 pr-2"
                                      id="student-menu"
                                      onClick={() => {
                                        // this.setState({
                                        //   liveclass_student: item,
                                        // });
                                      }}
                                    >
                                      <label id="student-menu">
                                        {item.nickname}
                                      </label>
                                    </div>
                                  </button>
                                )}
                              </>
                            ))}
                        </div>
                      </div>
                    </div>

                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_add_liveclass();
                        }}
                      >
                        {global[this.language]["add"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* แก้ไข live */}
              <Modal
                show={this.state.modal_edit_liveclass}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["edit_liveclass"]}</b>
                  </h3>
                  <div className="row">
                    {/* course_name */}
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["course_name"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.batch_name}
                        readOnly
                        onChange={(event) => {
                          this.setState({
                            batch_name: event.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* live_date */}
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["live_date"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        readOnly
                        value={this.state.live_date}
                        onChange={(event) => {
                          this.setState({
                            live_date: event.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* start_time */}
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["start_time"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        readOnly
                        value={this.state.live_startime}
                        onChange={(event) => {
                          this.setState({
                            live_startime: event.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* end_time */}
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["end_time"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        readOnly
                        value={this.state.live_endtime}
                        onChange={(event) => {
                          this.setState({
                            live_endtime: event.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* Meeting Number */}
                    <div className="col-12 pb-3">
                      <label>Meeting Number:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        readOnly
                        value={this.state.meeting_number}
                        onChange={(event) => {
                          this.setState({
                            meeting_number: event.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* Password */}
                    <div className="col-12 pb-3">
                      <label>Password:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        readOnly
                        value={this.state.meeting_password}
                        onChange={(event) => {
                          this.setState({
                            meeting_password: event.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* teacher */}
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["teacher_name"]}:</label>
                      <div
                        className="form-control"
                        onClick={this.handleClick.bind(this)}
                        id="teacher-menu"
                      >
                        <label
                          className={
                            this.state.liveclass_teacher === ""
                              ? "text-secondary"
                              : ""
                          }
                          onClick={this.handleClick.bind(this)}
                          id="teacher-menu"
                        >
                          {this.state.liveclass_teacher === ""
                            ? "--" +
                              global[this.language]["select_teacher"] +
                              "--"
                            : this.state.liveclass_teacher.user.name}
                        </label>
                      </div>
                      <div
                        className={
                          this.state.menu_Teacher
                            ? "menu-context-box-xl active"
                            : "menu-context-box-xl hidden"
                        }
                        style={{ left: this.state.x, top: this.state.y }}
                      >
                        <div className="p-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="ค้นหา"
                            value={this.state.search_teacher}
                            onChange={(event) => {
                              this.setState({
                                search_teacher: event.target.value,
                              });
                            }}
                            onClick={this.handleClick.bind(this)}
                            id="teacher-menu"
                          />
                        </div>
                        <div className="search-select-max-height overflow-y">
                          {this.state.teacher &&
                            this.state.teacher.map((item, index) => (
                              <>
                                {(item.user.name.includes(
                                  this.state.search_teacher
                                ) ||
                                  this.state.search_teacher === "") && (
                                  <button
                                    className="menu-context-button "
                                    onClick={() => {
                                      this.setState({
                                        liveclass_teacher: item,
                                        zoom_api_key: item.zoom_api_key,
                                        zoom_api_secret: item.zoom_api_secret,
                                      });
                                    }}
                                  >
                                    <label>{item.user.name}</label>
                                  </button>
                                )}
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                    {/* students */}
                    <div className="col-12 pb-3">
                      <label>
                        {global[this.language]["students"]}:
                        {this.state.liveclass_student.length !== 0 &&
                          " ( " +
                            this.state.liveclass_student.length +
                            global[this.language]["person"] +
                            " )"}
                      </label>
                      <div
                        className="form-control-style"
                        onClick={this.handleClick.bind(this)}
                        id="student-menu"
                      >
                        {this.state.liveclass_student.length === 0 ? (
                          <p
                            className={"text-secondary pb-0"}
                            onClick={this.handleClick.bind(this)}
                            id="student-menu"
                          >
                            --
                            {global[this.language]["select_student"]}
                            --
                          </p>
                        ) : (
                          <div
                            className="row"
                            onClick={this.handleClick.bind(this)}
                            id="student-menu"
                          >
                            {this.state.liveclass_student &&
                              this.state.liveclass_student.map(
                                (item, index) => (
                                  <div className="selected-box bg-primary text-white m-1">
                                    <label>{item.nickname}</label>
                                    <button
                                      className="btn-link p-0 text-white icon ml-1 mt-1"
                                      onClick={() => {
                                        let data = this.state.liveclass_student,
                                          array = this.state.student_add;
                                        for (let a of array) {
                                          if (a.nickname === item.nickname) {
                                            a.select = false;
                                          }
                                        }
                                        data.splice(index, 1);
                                        this.setState({
                                          liveclass_student: data,
                                          student_add: array,
                                        });
                                      }}
                                    >
                                      {"\uf00d"}
                                    </button>
                                  </div>
                                )
                              )}
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          this.state.menu_Student
                            ? "menu-context-box-xl active"
                            : "menu-context-box-xl hidden"
                        }
                        style={{ left: this.state.x, top: this.state.y }}
                      >
                        <div className="p-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="ค้นหา"
                            value={this.state.search_student}
                            onChange={(event) => {
                              this.setState({
                                search_student: event.target.value,
                              });
                            }}
                            onClick={this.handleClick.bind(this)}
                            id="student-menu"
                          />
                        </div>
                        <div className="search-select-max-height overflow-y">
                          {this.state.student_add &&
                            this.state.student_add.map((item, index) => (
                              <>
                                {(item.nickname.includes(
                                  this.state.search_student
                                ) ||
                                  this.state.search_student === "") && (
                                  <button
                                    className="d-flex align-items-center p-2 w-100 text-left search-button-select"
                                    id="student-menu"
                                    onClick={() => {
                                      let student = this.state.student_add,
                                        data = [];
                                      student[index].select =
                                        !student[index].select;

                                      for (let st of student) {
                                        if (st.select) {
                                          data.push(st);
                                        }
                                        this.setState({
                                          student_add: student,
                                          liveclass_student: data,
                                        });
                                      }
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={item.select}
                                      id="student-menu"
                                      onChange={(e) => {
                                        let student = this.state.student_add,
                                          data = [];
                                        student[index].select =
                                          e.target.checked;

                                        for (let st of student) {
                                          if (st.select) {
                                            data.push(st);
                                          }
                                          this.setState({
                                            student_add: student,
                                            liveclass_student: data,
                                          });
                                        }
                                      }}
                                    ></input>
                                    <div
                                      className="w-100 pl-2 pr-2"
                                      id="student-menu"
                                      onClick={() => {
                                        // this.setState({
                                        //   liveclass_student: item,
                                        // });
                                      }}
                                    >
                                      <label id="student-menu">
                                        {item.nickname}
                                      </label>
                                    </div>
                                  </button>
                                )}
                              </>
                            ))}
                        </div>
                      </div>
                    </div>

                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_edit_liveclass();
                        }}
                      >
                        {global[this.language]["save_edit"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* ดูรายละเอียด live */}
              <Modal
                show={this.state.modal_detail_live}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["liveclass_detail"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["course_name"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].batch_name
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["live_date"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].live_date
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["start_time"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].live_startime
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["end_time"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].live_endtime
                        }
                        disabled={true}
                      />
                    </div>

                    <div className="col-4 pb-3">
                      <label>Zoom API Key:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].zoom_api_key
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>Zoom API Secret:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].zoom_api_secret
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>Meeting Number:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].meeting_number
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>Password:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].meeting_password
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["teacher_name"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={
                          this.state.data &&
                          this.state.data[this.state.detail] &&
                          this.state.data[this.state.detail].teacher &&
                          this.state.data[this.state.detail].teacher.user.name
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["students"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <div
                        className="form-control-style"
                        onClick={this.handleClick.bind(this)}
                        id="student-menu"
                      >
                        {this.state.data &&
                        this.state.data[this.state.detail] &&
                        this.state.data[this.state.detail].student.length ===
                          0 ? (
                          <p className={"text-secondary pb-0"}>
                            {global[this.language]["no_student"]}
                          </p>
                        ) : (
                          <div className="row">
                            {this.state.data &&
                              this.state.data[this.state.detail] &&
                              this.state.data[this.state.detail].student.map(
                                (item, index) => (
                                  <div className="selected-box bg-primary text-white m-1">
                                    <label>{item.nickname}</label>
                                  </div>
                                )
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-6 pb-3" />
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["close"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
