import "../../../index.css";
import "../../../bootstrap.css";
import React, { Component } from "react";
import "@szhsin/react-menu/dist/index.css";

import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class MenuComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      day: this.props.day,
      text: ["จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส.", "อา."],
      texten: ["M.", "T.", "W.", "Th.", "F.", "S.", "Su."],
    };
  }

  updateDimensions = () => {
    if (window.innerWidth >= 768) {
      this.setState({ toggle: false });
    }
  };
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  render() {
    return (
      <div className="days-bar">
        {this.state.day.map((day, index) => (
          <div
            className={
              index === 0
                ? day.select
                  ? "day-left-active"
                  : "day-left"
                : index === 6
                ? day.select
                  ? "day-right-active"
                  : "day-right"
                : day.select
                ? "day-center-active"
                : "day-center"
            }
          >
            <label>
              {cookies.get("language") === "TH"
                ? this.state.text[index]
                : this.state.texten[index]}
            </label>
          </div>
        ))}
      </div>
    );
  }
}
