import "../../../index.css";
import "../../../bootstrap.css";
import React, { Component } from "react";
import blank from "./blank.png";
import studentm from "./graduate_m.png";
import studentwm from "./graduate_wm.png";
import teacherm from "./teacher_m.png";
import teacherwm from "./teacher_wm.png";
import admin from "./worker.png";

import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";

import Swal from "sweetalert2";
import Cookies from "universal-cookie";

const cookies = new Cookies();
export default class MenuComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { toggle: true, status: "", image: "" };
  }

  updateDimensions = () => {
    if (window.innerWidth >= 768) {
      this.setState({ toggle: false });
    }
  };
  componentDidMount() {
    if (cookies.get("user")) {
      if (cookies.get("user").user.status === "student") {
        this.setState({ status: "STUDENT" });
      } else if (cookies.get("user").user.status === "teacher") {
        this.setState({ status: "TEACHER" });
      } else {
        this.setState({
          status: cookies.get("user").user.role
            ? cookies.get("user").user.role.role_name.toUpperCase()
            : "SUPER ADMIN",
        });
      }
    }

    window.addEventListener("resize", this.updateDimensions);
  }
  language = cookies.get("language") ? cookies.get("language") : "TH";
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  Logout = () => {
    Swal.fire({
      title: global[this.language]["logout_title"],
      showCancelButton: true,
      confirmButtonText: global[this.language]["logout"],
      cancelButtonText: global[this.language]["cancel"],
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(global.httpURL + "/api/logout", {
          method: "GET",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "token " + cookies.get("token"),
          },
        })
          .then((response) => response.json())
          .then((response) => {
            cookies.remove("token", { path: "/" });
            cookies.remove("user", { path: "/" });

            window.location.href = "/";
          })
          .catch((err) => {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["error_logout"],
              "warning"
            );
          });
      }
    });
  };
  render() {
    return (
      <div className="header-bar">
        <Menu
          menuButton={
            <div className="row align-items-center">
              <div className="text-right mr-3">
                <label className="profile-text">
                  {cookies.get("user") && cookies.get("user").user.name}
                </label>
                <br />
                <label className="role-text">{this.state.status}</label>
              </div>
              {cookies.get("user") && (
                <img
                  src={
                    cookies.get("user").user.status === "student"
                      ? cookies.get("user").gender === "หญิง"
                        ? studentwm
                        : studentm
                      : cookies.get("user").user.status === "teacher"
                      ? cookies.get("user").gender === "หญิง"
                        ? teacherwm
                        : teacherm
                      : cookies.get("user").user.status === "admin" && admin
                  }
                  alt=""
                  width="56"
                  height="56"
                  className="profile-image"
                />
              )}
              <label className="icon-solid ml-3 text-secondary">
                {"\uf0d7"}
              </label>
            </div>
          }
        >
          <MenuItem
            className="button text-secondary"
            onClick={() => {
              window.location.href = "/editprofile";
            }}
          >
            <label className="icon ml-3">{"\uf4ff"}</label>
            {global[this.language]["edit_profile"]}
          </MenuItem>

          {/* <MenuItem
            className="button text-secondary"
            onClick={() => {
              window.location.href = "/editpassword";
            }}
          >
            <label className="icon ml-3">{"\uf044"}</label>
            {global[this.language]["editpassword"]}
          </MenuItem> */}

          <MenuItem
            className="button text-secondary"
            onClick={() => {
              this.Logout();
            }}
          >
            <b className="text-danger">
              <label className="icon ml-3">{"\uf08b"}</label>
              {global[this.language]["logout"]}
            </b>
          </MenuItem>
        </Menu>
      </div>
    );
  }
}
