import "../../../bootstrap.css";
import React, { Component } from "react";
import SideMenu from "../../Content/SideMenu/SideMenu";
import Header from "../../Content/Header/Header";
import Days from "../../Content/Days/Days";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { sortableContainer, sortableElement } from "react-sortable-hoc";

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import arrayMove from "array-move";
import Cookies from "universal-cookie";
import "@szhsin/react-menu/dist/index.css";
const cookies = new Cookies();
var main = 0;
var sub = 0;
const SortableItem = sortableElement(({ value, child }) => <li>{child}</li>);

const SortableContainer = sortableContainer(({ children, value }) => {
  return (
    <ul className="pl-4" id={value}>
      {children}
    </ul>
  );
});

export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      loading: true,
      subject_id: "",
      subject_name: "",
      subject_department: "",
      subject_course: "",
      subject_teacher: "",
      subject_startdate: "",
      subject_enddate: "",
      subject_study_time: "",
      subject_total_hour: "",
      subject_hour: "",
      subject_course_type: "เรียนเดี่ยว",
      subject_course_place: "สถาบัน",
      subject_study_type: "เวลาเหมือนกัน",
      course_main_type: "",
      detail: [0, 0, 0],
      de_id: "",
      subject_days: [
        { day: "จันทร์", select: false, time: "" },
        { day: "อังคาร", select: false, time: "" },
        { day: "พุธ", select: false, time: "" },
        { day: "พฤหัสบดี", select: false, time: "" },
        { day: "ศุกร์", select: false, time: "" },
        { day: "เสาร์", select: false, time: "" },
        { day: "อาทิตย์", select: false, time: "" },
      ],
      subject_price: "",
      subject_student: [],

      modalTypeInfoClass: false,
      modalAddInfoClass: false,
      modalEditInfoClass: false,
      modalDetailInfoClass: false,

      modalAddMainClass: false,
      modalEditMainClass: false,

      modalAddSubClass: false,
      modalEditSubClass: false,
      day: ["จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส.", "อา."],
      dayen: ["M.", "T.", "W.", "Th.", "F.", "S.", "Su."],
      data: [],
      subject: [],
      items: [],
      listDay: [],
      department: "",
      subdepartment: [],
      search: "",
      subdepartmenttext: "",
      MainClass: [],
      count: [0, 0, 0],
    };
  }
  language = cookies.get("language") ? cookies.get("language") : "TH";
  componentDidMount() {
    if (!this.language) {
      cookies.set("language", "TH", { path: "/" });
    }
    if (cookies.get("user")) {
      this.GetClass();
    } else {
      Swal.fire({
        title: global[this.language]["alert"],
        text: global[this.language]["retrieve_data_fail"],
        icon: "warning",
        showCancelButton: false,
        allowOutsideClick: false,
        confirmButtonText: global[this.language]["ok"],
      }).then((result) => {
        if (result.isConfirmed) {
          cookies.remove("token", { path: "/" });
          cookies.remove("user", { path: "/" });

          window.location.href = "/";
        }
      });
    }
  }
  Logout = () => {
    fetch(global.httpURL + "/api/logout", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "token " + cookies.get("token"),
      },
      // mode: "no-cors",
    })
      .then((response) => response.json())
      .then((response) => {
        cookies.remove("token", { path: "/" });
        cookies.remove("user", { path: "/" });

        window.location.href = "/";
      })
      .catch((err) => {
        //window.location.href = "/dashboard";
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_logout"],
          "warning"
        );
      });
  };
  Cancel = () => {
    this.setState({
      subject_id: "",
      subject_name: "",
      subject_department: "",
      subject_course: "",
      subject_teacher: "",
      subject_startdate: "",
      subject_enddate: "",
      subject_study_time: "",
      subject_total_hour: "",
      subject_hour: "",
      subject_course_type: "เรียนเดี่ยว",
      subject_course_place: "สถาบัน",
      subject_study_type: "เวลาเหมือนกัน",
      course_main_type: "",
      subject_days: [
        { day: "จันทร์", select: false, time: "" },
        { day: "อังคาร", select: false, time: "" },
        { day: "พุธ", select: false, time: "" },
        { day: "พฤหัสบดี", select: false, time: "" },
        { day: "ศุกร์", select: false, time: "" },
        { day: "เสาร์", select: false, time: "" },
        { day: "อาทิตย์", select: false, time: "" },
      ],
      subject_price: "",
      subject_student: [],
      modalEditMainClass: false,
      modalEditSubClass: false,
      modalTypeInfoClass: false,
      modalAddInfoClass: false,
      modalEditInfoClass: false,
      modalDetailInfoClass: false,
      modalAddMainClass: false,
      modalAddMainClass: false,
      modalAddSubClass: false,
      department: "",
      subdepartment: [],
      subdepartmenttext: "",
    });
  };
  GetClass = () => {
    fetch(global.httpURL + "/api/course/page/" + this.state.activePage, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (
          response.message === "Auth Failed" ||
          response.message === "Token is null" ||
          response.message === "Token is not found"
        ) {
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["unavailable"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.Logout();
            }
          });
        } else if (response.message === "Connot find page") {
          this.setState({
            subject: [],
            loading: false,
          });
        } else {
          this.setState({
            count: [
              response.data.count_info,
              response.data.count_sub,
              response.data.count_main,
            ],
            data: response.data.course_data,
            loading: false,
            page_count: response.page_count,
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        Swal.fire({
          title: global[this.language]["alert"],
          text: global[this.language]["retrieve_data_fail"],
          icon: "warning",
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonText: global[this.language]["ok"],
        }).then((result) => {
          if (result.isConfirmed) {
            this.Logout();
          }
        });
      });
    fetch(global.httpURL + "/api/subject/class/selecter", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ MainClass: response.data });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  // MainClass
  AddMainClass = () => {
    this.setState({ spinner: true });
    setTimeout(() => {
      fetch(global.httpURL + "/api/subject/main_class", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + cookies.get("token"),
        },
        body: JSON.stringify({
          main_class_name: this.state.department,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({ spinner: false });
          if (!response.auth) {
            if (response.message === "This main class name is existed") {
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["error_department_same"],
                "warning"
              );
            } else {
              Swal.fire(
                global[this.language]["alert"],
                global[this.language]["add_not_success"],
                "warning"
              );
            }
          } else {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["add_success"],
              "success"
            );
            this.Cancel();
            this.GetClass();
          }
        })
        .catch((err) => {
          this.setState({ spinner: false });
        });
    }, 10);
  };
  EditMainClass = () => {
    fetch(
      global.httpURL +
        "/api/subject/main_class/" +
        this.state.data[this.state.detail[0]]._id,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + cookies.get("token"),
        },
        body: JSON.stringify({ main_class_name: this.state.department }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        this.setState({ spinner: false });
        if (response.data) {
          this.setState({ spinner: false });
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["edit_not_success"],
            "warning"
          );
        } else {
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["edit_success"],
            "success"
          );
          this.Cancel();
          this.GetClass();
        }
      })
      .catch((err) => {
        this.setState({ spinner: false });
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["edit_not_success"],
          "warning"
        );
      });
  };
  DeleteMainClass = () => {
    Swal.fire({
      title: global[this.language]["confirm_delete"],
      showCancelButton: true,
      confirmButtonText: global[this.language]["confirm"],
      cancelButtonText: global[this.language]["cancel"],
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ spinner: true });
        fetch(
          global.httpURL +
            "/api/subject/main_class/del/" +
            this.state.data[this.state.detail[0]]._id,
          {
            method: "DELETE",
            headers: {
              "content-type": "application/json",
              accept: "application/json",
              Authorization: "Bearer " + cookies.get("token"),
            },
          }
        )
          .then((response) => response.json())
          .then((response) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_success"],
              "success"
            );
            this.GetClass();
          })
          .catch((err) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_not_success"],
              "warning"
            );
          });
      }
    });
  };
  // SubClass
  AddSubClass = () => {
    if (this.state.department === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["fill_department"],
        "warning"
      );
    } else if (this.state.subdepartment.length === 0) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["fill_subdepartment"],
        "warning"
      );
    } else {
      let subdepartment_check = true;
      let same = 0;
      for (let data of this.state.subdepartment) {
        if (data === "") {
          subdepartment_check = false;
        }
      }
      for (let d of this.state.subdepartment) {
        var newArray = this.state.subdepartment.filter((x) => {
          if (x.toString() === d) {
            same += 1;
          }
        });
      }
      if (!subdepartment_check) {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_subdepartment"],
          "warning"
        );
      } else if (same !== this.state.subdepartment.length) {
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_subdepartment_same"],
          "warning"
        );
      } else {
        this.setState({ spinner: true });
        console.log(
          JSON.stringify({
            main_class_id: this.state.data[this.state.department],
            sub_class_name: this.state.subdepartment,
          })
        );
        setTimeout(() => {
          fetch(global.httpURL + "/api/subject/sub_class", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              accept: "application/json",
              Authorization: "Bearer " + cookies.get("token"),
            },
            body: JSON.stringify({
              main_class_id: this.state.data[this.state.department]._id,
              sub_class_name: this.state.subdepartment,
            }),
          })
            .then((response) => response.json())
            .then((response) => {
              console.log(response);
              this.setState({ spinner: false });
              if (!response.auth) {
                if (response.message === "This sub class is existed") {
                  Swal.fire(
                    global[this.language]["alert"],
                    global[this.language]["error_subdepartment_same"],
                    "warning"
                  );
                } else {
                  Swal.fire(
                    global[this.language]["alert"],
                    global[this.language]["add_not_success"],
                    "warning"
                  );
                }
              } else {
                Swal.fire(
                  global[this.language]["alert"],
                  global[this.language]["add_success"],
                  "success"
                );
                this.Cancel();
                this.GetClass();
              }
            })
            .catch((err) => {
              this.setState({ spinner: false });
            });
        }, 10);
      }
    }
  };
  EditSubClass = () => {
    fetch(
      global.httpURL +
        "/api/subject/sub_class/" +
        this.state.data[this.state.detail[0]].sub_class[this.state.detail[1]]
          ._id,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + cookies.get("token"),
        },
        body: JSON.stringify({ sub_class_name: this.state.subdepartmenttext }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        this.setState({ spinner: false });
        if (response.data) {
          this.setState({ spinner: false });
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["edit_not_success"],
            "warning"
          );
        } else {
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["edit_success"],
            "success"
          );
          this.Cancel();
          this.GetClass();
        }
      })
      .catch((err) => {
        this.setState({ spinner: false });
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["edit_not_success"],
          "warning"
        );
      });
  };
  DeleteSubClass = () => {
    Swal.fire({
      title: global[this.language]["confirm_delete"],
      showCancelButton: true,
      confirmButtonText: global[this.language]["confirm"],
      cancelButtonText: global[this.language]["cancel"],
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ spinner: true });
        fetch(
          global.httpURL +
            "/api/subject/sub_class/del/" +
            this.state.data[this.state.detail[0]].sub_class[
              this.state.detail[1]
            ]._id,
          {
            method: "DELETE",
            headers: {
              "content-type": "application/json",
              accept: "application/json",
              Authorization: "Bearer " + cookies.get("token"),
            },
          }
        )
          .then((response) => response.json())
          .then((response) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_success"],
              "success"
            );
            this.GetClass();
          })
          .catch((err) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_not_success"],
              "warning"
            );
          });
      }
    });
  };
  // InfoClass
  AddInfoClass = () => {
    this.setState({ spinner: true });
    let start_date = ("00" + this.state.subject_startdate.getDate()).slice(-2);
    let start_month = (
      "00" +
      (this.state.subject_startdate.getMonth() + 1)
    ).slice(-2);
    let fullstart_date =
      start_date +
      "/" +
      start_month +
      "/" +
      this.state.subject_startdate.getFullYear();
    let end_date = ("00" + this.state.subject_enddate.getDate()).slice(-2);
    let end_month = ("00" + (this.state.subject_enddate.getMonth() + 1)).slice(
      -2
    );
    let fullend_date =
      end_date +
      "/" +
      end_month +
      "/" +
      this.state.subject_enddate.getFullYear();
    let data = {
      subject_name: this.state.subject_name,
      main_class_id: this.state.data[this.state.subject_department]._id,
      sub_class_id:
        this.state.data[this.state.subject_department].sub_class[
          this.state.subject_course
        ]._id,
      teacher_id: "",
      start_date: fullstart_date,
      end_date: fullend_date,
      start_time: this.state.subject_study_time,
      study_hours_all: Number(this.state.subject_total_hour),
      study_hours_class: Number(this.state.subject_hour),
      max_student: 15,
      course_main_type: {
        free: this.state.course_main_type === "free" ? "True" : "False",
        register: this.state.course_main_type === "register" ? "True" : "False",
      },
      course_type: {
        single:
          this.state.subject_course_type === "เรียนเดี่ยว" ? "True" : "False",
        group:
          this.state.subject_course_type === "เรียนกลุ่ม" ? "True" : "False",
      },
      course_place: {
        school: this.state.subject_course_place === "สถาบัน" ? "True" : "False",
        online:
          this.state.subject_course_place === "ออนไลน์" ? "True" : "False",
      },
      select_time: {
        fix_time:
          this.state.subject_study_type === "เวลาเหมือนกัน" ? "True" : "False",
        custom_time:
          this.state.subject_study_type === "กำหนดเอง" ? "True" : "False",
      },
      select_day:
        this.state.subject_study_type === "เวลาเหมือนกัน"
          ? this.state.subject_days
          : [],
      custom_day:
        this.state.subject_study_type === "กำหนดเอง" ? this.state.listDay : [],
      price_class: Number(this.state.subject_price),
    };
    setTimeout(() => {
      fetch(global.httpURL + "/api/course", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + cookies.get("token"),
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          this.setState({ spinner: false });
          if (!response.auth) {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["add_not_success"],
              "warning"
            );
          } else {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["add_success"],
              "success"
            );
            this.Cancel();
            // this.GetLiveClass();
          }
        })
        .catch((err) => {
          this.setState({ spinner: false });
        });
    }, 10);
  };
  EditInfoClass = () => {
    this.setState({ spinner: true });
    let start_date = ("00" + this.state.subject_startdate.getDate()).slice(-2);
    let start_month = (
      "00" +
      (this.state.subject_startdate.getMonth() + 1)
    ).slice(-2);
    let fullstart_date =
      start_date +
      "/" +
      start_month +
      "/" +
      this.state.subject_startdate.getFullYear();
    let end_date = ("00" + this.state.subject_enddate.getDate()).slice(-2);
    let end_month = ("00" + (this.state.subject_enddate.getMonth() + 1)).slice(
      -2
    );
    let fullend_date =
      end_date +
      "/" +
      end_month +
      "/" +
      this.state.subject_enddate.getFullYear();
    let data = {
      subject_name: this.state.subject_name,
      main_class_id: this.state.data[this.state.subject_department]._id,
      sub_class_id:
        this.state.data[this.state.subject_department].sub_class[
          this.state.subject_course
        ]._id,
      teacher_id: "",
      start_date: fullstart_date,
      end_date: fullend_date,
      start_time: this.state.subject_study_time,
      study_hours_all: Number(this.state.subject_total_hour),
      study_hours_class: Number(this.state.subject_hour),
      max_student: 15,
      course_main_type: {
        free: this.state.course_main_type === "free" ? "True" : "False",
        register: this.state.course_main_type === "register" ? "True" : "False",
      },
      course_type: {
        single:
          this.state.subject_course_type === "เรียนเดี่ยว" ? "True" : "False",
        group:
          this.state.subject_course_type === "เรียนกลุ่ม" ? "True" : "False",
      },
      course_place: {
        school: this.state.subject_course_place === "สถาบัน" ? "True" : "False",
        online:
          this.state.subject_course_place === "ออนไลน์" ? "True" : "False",
      },
      select_time: {
        fix_time:
          this.state.subject_study_type === "เวลาเหมือนกัน" ? "True" : "False",
        custom_time:
          this.state.subject_study_type === "กำหนดเอง" ? "True" : "False",
      },
      select_day:
        this.state.subject_study_type === "เวลาเหมือนกัน"
          ? this.state.subject_days
          : [],
      custom_day:
        this.state.subject_study_type === "กำหนดเอง" ? this.state.listDay : [],
      price_class: Number(this.state.subject_price),
    };
    fetch(
      global.httpURL +
        "/api/course/" +
        this.state.data[this.state.detail[0]].sub_class[this.state.detail[1]]
          .info_class[this.state.detail[2]]._id,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + cookies.get("token"),
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        this.setState({ spinner: false });
        if (response.message !== "Success") {
          this.setState({ spinner: false });
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["edit_not_success"],
            "warning"
          );
        } else {
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["edit_success"],
            "success"
          );
          this.Cancel();
          this.GetClass();
        }
      })
      .catch((err) => {
        this.setState({ spinner: false });
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["edit_not_success"],
          "warning"
        );
      });
  };
  DeleteInfoClass = () => {
    Swal.fire({
      title: global[this.language]["confirm_delete"],
      showCancelButton: true,
      confirmButtonText: global[this.language]["confirm"],
      cancelButtonText: global[this.language]["cancel"],
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ spinner: true });
        fetch(
          global.httpURL +
            "/api/course/del/" +
            this.state.data[this.state.detail[0]].sub_class[
              this.state.detail[1]
            ].info_class[this.state.detail[2]]._id,
          {
            method: "DELETE",
            headers: {
              "content-type": "application/json",
              accept: "application/json",
              Authorization: "Bearer " + cookies.get("token"),
            },
          }
        )
          .then((response) => response.json())
          .then((response) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_success"],
              "success"
            );
            this.GetClass();
          })
          .catch((err) => {
            this.setState({ spinner: false });
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["delete_not_success"],
              "warning"
            );
          });
      }
    });
  };
  // Handle Click
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    setTimeout(() => {
      this.GetClass();
    }, 1);
  }
  search = () => {
    fetch(global.httpURL + "/api/search/th/page/1", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({
        data_search: this.state.search,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ spinner: false });
        if (
          response.message === "Auth Failed" ||
          response.message === "Token is null"
        ) {
          Swal.fire({
            title: global[this.language]["alert"],
            text: global[this.language]["unavailable"],
            icon: "warning",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonText: global[this.language]["ok"],
          }).then((result) => {
            if (result.isConfirmed) {
              this.Logout();
            }
          });
        } else if (
          response.message === "Connot find page" ||
          response.message === "No information found"
        ) {
          this.setState({
            data: [],
            loading: false,
          });
        } else {
          this.setState({
            data: response.data,
            loading: false,
            page_count: response.page_count,
          });
        }
      })
      .catch((err) => {
        this.setState({ spinner: false });
        Swal.fire(
          global[this.language]["alert"],
          global[this.language]["error_logout"],
          "warning"
        );
      });
  };
  render() {
    return (
      <div className="w-100 h-auto minh-100">
        <div className="d-flex h-auto minh-100 w-100">
          <div className="h-auto minh-100">
            <SideMenu page="subject" />
          </div>
          <div className="w-100-body">
            <Header />
            <div className="container-fluid pt-3">
              <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-3">
                  <div className="card border-0 p-4 flex-row justify-content-between align-items-center w-100">
                    <b className="icon mr-1 text-primary big-icon">
                      {"\uf200"}
                    </b>
                    <div className="text-right">
                      <b className="text-secondary">
                        {global[this.language]["total_number_of_departments"]}
                      </b>
                      <br />
                      <h4 className="text-primary">
                        <b>{this.state.count[2]}</b>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-3">
                  <div className="card border-0 p-4 flex-row justify-content-between align-items-center">
                    <b className="icon mr-1 text-primary big-icon">
                      {"\uf200"}
                    </b>
                    <div className="text-right">
                      <b className="text-secondary">
                        {global[this.language]["total_number_of_courses"]}
                      </b>
                      <br />
                      <h4 className="text-primary">
                        <b>{this.state.count[1]}</b>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 mb-3">
                  <div className="card border-0 p-4 flex-row justify-content-between align-items-center">
                    <b className="icon mr-1 text-primary big-icon">
                      {"\uf200"}
                    </b>
                    <div className="text-right">
                      <b className="text-secondary">
                        {global[this.language]["total_number_of_subjects"]}
                      </b>
                      <br />
                      <h4 className="text-primary">
                        <b>{this.state.count[0]}</b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* TABLE */}
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="card border-0 p-4">
                    <div className="flex-row justify-content-between align-items-center row p-4">
                      <div>
                        <h3>
                          <b>{global[this.language]["subject"]}</b>
                        </h3>
                      </div>
                      {/* BUTTON XL */}
                      <div className="text-right d-none d-xl-block">
                        {/* <button className="btn btn-outline-secondary mr-2 ml-2 text-primary">
                          <label className="icon mr-1">{"\uf56d"}</label>
                          Export Report
                        </button> */}
                        {/* <div className="search-box-div mr-2 ml-2">
                          <input
                            type="text"
                            className="search-box"
                            placeholder={global[this.language]["search"]}
                            value={this.state.search}
                            onChange={(event) => {
                              this.setState({
                                search: event.target.value,
                                activePage: 1,
                              });
                              setTimeout(() => {
                                if (this.state.search === "") {
                                  this.search();
                                }
                              }, 1);
                            }}
                            onKeyUp={(event) => {
                              if (event.key === "Enter") {
                                this.search();
                              }
                            }}
                          />
                          <button
                            className="icon-absolute"
                            onClick={() => {
                              this.search();
                            }}
                          >
                            {"\uf002"}
                          </button>
                        </div> */}

                        <button
                          onClick={() => {
                            this.setState({ modalAddMainClass: true });
                          }}
                          className="btn btn-outline-primary ml-3"
                        >
                          <label className="icon mr-1">{"\uf055"}</label>
                          {global[this.language]["add_department"]}
                        </button>
                        <button
                          onClick={() => {
                            this.setState({ modalAddSubClass: true });
                          }}
                          className="btn btn-outline-primary ml-3"
                        >
                          <label className="icon mr-1">{"\uf055"}</label>
                          {global[this.language]["add_subdepartment"]}
                        </button>
                        <button
                          className="btn btn-primary ml-3"
                          onClick={() => {
                            this.setState({ modalTypeInfoClass: true });
                          }}
                        >
                          <label className="icon mr-1">{"\uf055"}</label>
                          {global[this.language]["add_course"]}
                        </button>
                      </div>
                      {/* BUTTON NOT XL */}
                      <div className="text-right d-block d-xl-none flex-row justify-content-between align-items-center">
                        {/* <div className="search-box-div mr-2 ml-2">
                          <input
                            type="text"
                            className="search-box"
                            placeholder={global[this.language]["search"]}
                            value={this.state.search}
                            onChange={(event) => {
                              this.setState({
                                search: event.target.value,
                                activePage: 1,
                              });
                              setTimeout(() => {
                                if (this.state.search === "") {
                                  this.search();
                                }
                              }, 1);
                            }}
                            onKeyUp={(event) => {
                              if (event.key === "Enter") {
                                this.search();
                              }
                            }}
                          />
                          <button
                            className="icon-absolute"
                            onClick={() => {
                              this.search();
                            }}
                          >
                            {"\uf002"}
                          </button>
                        </div> */}
                        <Menu
                          menuButton={
                            <button className="btn btn-primary mr-2 ml-2">
                              <label className="icon">{"\uf0c9"}</label>
                            </button>
                          }
                        >
                          {/* <MenuItem>
                            <label className="icon mr-1">{"\uf56d"}</label>
                            Export Report
                          </MenuItem> */}

                          {/* <MenuItem
                            onClick={() => {
                              this.setState({ modalAddMainClass: true });
                            }}
                          >
                            <label className="icon mr-1">{"\uf5db"}</label>
                            {global[this.language]["manage_department"]}
                          </MenuItem> */}
                          <MenuItem
                            onClick={() => {
                              this.setState({ modalAddMainClass: true });
                            }}
                          >
                            <label className="icon mr-1">{"\uf055"}</label>
                            {global[this.language]["add_department"]}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              this.setState({ modalAddSubClass: true });
                            }}
                          >
                            <label className="icon mr-1">{"\uf055"}</label>
                            {global[this.language]["add_subdepartment"]}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              this.setState({ modalTypeInfoClass: true });
                            }}
                          >
                            <label className="icon mr-1">{"\uf055"}</label>
                            {global[this.language]["add_course"]}
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {!this.state.loading ? (
                        <table className="table table-borderless table-striped table-w-1-3 table-hover ">
                          <thead className="thead-dark">
                            <tr>
                              <th className="text-center">
                                {global[this.language]["course_code"]}
                              </th>
                              <th className="text-center">
                                {global[this.language]["course_name"]}
                              </th>
                              <th className="text-center">
                                {global[this.language]["Department"]}
                              </th>
                              <th className="text-center">
                                {global[this.language]["course"]}
                              </th>
                              <th>{global[this.language]["teacher_name"]}</th>
                              <th>{global[this.language]["start_date"]}</th>
                              <th className="text-center">
                                {global[this.language]["class_date"]}
                              </th>
                              <th className="text-center">
                                {global[this.language]["all_info"]}
                              </th>
                              <th className="text-center">
                                {global[this.language]["edit"]}
                              </th>
                              <th className="text-center">
                                {global[this.language]["delete"]}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* DEPARTMENT */}
                            {this.state.data &&
                              this.state.data.map(
                                (main_class, main_class_index) => (
                                  <>
                                    {main_class.sub_class.map(
                                      (sub_class, sub_class_index) => (
                                        <>
                                          {sub_class.info_class.map(
                                            (info_class, info_class_index) => (
                                              <tr>
                                                <td>
                                                  {info_class.subject_code}
                                                </td>
                                                <td>
                                                  {info_class.subject_name}
                                                </td>
                                                <td>
                                                  <div className="d-flex align-items-center">
                                                    <Menu
                                                      menuButton={
                                                        <button className="btn btn-link mt-2 pt-2 pr-1">
                                                          <label className="icon">
                                                            {"\uf39c"}
                                                          </label>
                                                        </button>
                                                      }
                                                    >
                                                      <MenuItem
                                                        onClick={() => {
                                                          this.setState({
                                                            modalEditMainClass: true,
                                                            department:
                                                              this.state.data[
                                                                main_class_index
                                                              ].main_class_name,
                                                            detail: [
                                                              main_class_index,
                                                              sub_class_index,
                                                              info_class_index,
                                                            ],
                                                          });
                                                        }}
                                                      >
                                                        <label className="icon mr-1">
                                                          {"\uf044"}
                                                        </label>
                                                        {
                                                          global[this.language][
                                                            "edit"
                                                          ]
                                                        }
                                                      </MenuItem>
                                                      <MenuItem
                                                        onClick={() => {
                                                          this.setState({
                                                            detail: [
                                                              main_class_index,
                                                              sub_class_index,
                                                              info_class_index,
                                                            ],
                                                          });
                                                          setTimeout(() => {
                                                            this.DeleteMainClass();
                                                          }, 1);
                                                        }}
                                                      >
                                                        <label
                                                          className="icon mr-1 "
                                                          style={{
                                                            color: "#FF0000",
                                                          }}
                                                        >
                                                          {"\uf2ed"}
                                                        </label>
                                                        <label
                                                          style={{
                                                            color: "#FF0000",
                                                          }}
                                                        >
                                                          {
                                                            global[
                                                              this.language
                                                            ]["delete"]
                                                          }
                                                        </label>
                                                      </MenuItem>
                                                    </Menu>
                                                    {main_class.main_class_name}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="d-flex align-items-center ">
                                                    <Menu
                                                      menuButton={
                                                        <button className="btn btn-link mt-2 pt-2 pr-1">
                                                          <label className="icon">
                                                            {"\uf39c"}
                                                          </label>
                                                        </button>
                                                      }
                                                    >
                                                      <MenuItem
                                                        onClick={() => {
                                                          this.setState({
                                                            modalEditSubClass: true,
                                                            department:
                                                              this.state.data[
                                                                main_class_index
                                                              ].main_class_name,
                                                            subdepartmenttext:
                                                              this.state.data[
                                                                main_class_index
                                                              ].sub_class[
                                                                sub_class_index
                                                              ].sub_class_name,
                                                            detail: [
                                                              main_class_index,
                                                              sub_class_index,
                                                              info_class_index,
                                                            ],
                                                          });
                                                        }}
                                                      >
                                                        <label className="icon mr-1">
                                                          {"\uf044"}
                                                        </label>
                                                        {
                                                          global[this.language][
                                                            "edit"
                                                          ]
                                                        }
                                                      </MenuItem>
                                                      <MenuItem
                                                        onClick={() => {
                                                          this.setState({
                                                            detail: [
                                                              main_class_index,
                                                              sub_class_index,
                                                              info_class_index,
                                                            ],
                                                          });
                                                          setTimeout(() => {
                                                            this.DeleteSubClass();
                                                          }, 1);
                                                        }}
                                                      >
                                                        <label
                                                          className="icon mr-1 "
                                                          style={{
                                                            color: "#FF0000",
                                                          }}
                                                        >
                                                          {"\uf2ed"}
                                                        </label>
                                                        <label
                                                          style={{
                                                            color: "#FF0000",
                                                          }}
                                                        >
                                                          {
                                                            global[
                                                              this.language
                                                            ]["delete"]
                                                          }
                                                        </label>
                                                      </MenuItem>
                                                    </Menu>
                                                    {sub_class.sub_class_name}
                                                  </div>
                                                </td>
                                                <td>
                                                  {info_class.teacher_name}
                                                </td>
                                                <td>{info_class.start_date}</td>
                                                <td>
                                                  <Days
                                                    day={info_class.select_day}
                                                  />
                                                </td>
                                                <td
                                                  className="text-center point"
                                                  onClick={() => {
                                                    this.setState({
                                                      modalDetailInfoClass: true,
                                                      detail: [
                                                        main_class_index,
                                                        sub_class_index,
                                                        info_class_index,
                                                      ],
                                                      subject_id:
                                                        info_class._id,
                                                      subject_name:
                                                        info_class.subject_name,
                                                      subject_department:
                                                        main_class.main_class_name,
                                                      subject_course:
                                                        sub_class.sub_class_name,
                                                      subject_teacher:
                                                        info_class.teacher_name,
                                                      subject_startdate:
                                                        info_class.start_date,
                                                      subject_enddate:
                                                        info_class.end_date,
                                                      subject_study_time:
                                                        info_class.start_time,
                                                      subject_total_hour:
                                                        info_class.study_hours_all,
                                                      subject_hour:
                                                        info_class.study_hours_class,
                                                      subject_course_type:
                                                        info_class.course_type
                                                          .single === "True"
                                                          ? "เรียนเดี่ยว"
                                                          : "เรียนกลุ่ม",
                                                      subject_course_place:
                                                        info_class.course_place
                                                          .school === "True"
                                                          ? "สถาบัน"
                                                          : "ออนไลน์",
                                                      subject_study_type:
                                                        info_class.select_time
                                                          .fix_time === "True"
                                                          ? "เวลาเหมือนกัน"
                                                          : "กำหนดเอง",
                                                      course_main_type:
                                                        info_class
                                                          .course_main_type
                                                          .free === "True"
                                                          ? "ทดลองเรียน"
                                                          : "เปิดสอนจริง",
                                                      subject_days:
                                                        info_class.select_day,
                                                      subject_price:
                                                        info_class.price_class,
                                                      listDay:
                                                        info_class.custom_day,
                                                    });
                                                  }}
                                                >
                                                  {
                                                    global[this.language][
                                                      "info"
                                                    ]
                                                  }
                                                </td>
                                                <td
                                                  className="text-center point"
                                                  onClick={() => {
                                                    this.setState({
                                                      modalEditInfoClass: true,
                                                      detail: [
                                                        main_class_index,
                                                        sub_class_index,
                                                        info_class_index,
                                                      ],
                                                      subject_id:
                                                        info_class._id,
                                                      subject_name:
                                                        info_class.subject_name,
                                                      subject_department:
                                                        main_class_index,
                                                      subject_course:
                                                        sub_class_index,
                                                      subject_teacher:
                                                        info_class.teacher_name,
                                                      subject_startdate:
                                                        new Date(
                                                          info_class.start_date.split(
                                                            "/"
                                                          )[2],
                                                          Number(
                                                            info_class.start_date.split(
                                                              "/"
                                                            )[1]
                                                          ) - 1,
                                                          info_class.start_date.split(
                                                            "/"
                                                          )[0]
                                                        ),
                                                      subject_enddate: new Date(
                                                        info_class.end_date.split(
                                                          "/"
                                                        )[2],
                                                        Number(
                                                          info_class.end_date.split(
                                                            "/"
                                                          )[1]
                                                        ) - 1,
                                                        info_class.end_date.split(
                                                          "/"
                                                        )[0]
                                                      ),
                                                      subject_study_time:
                                                        info_class.start_time,
                                                      subject_total_hour:
                                                        info_class.study_hours_all,
                                                      subject_hour:
                                                        info_class.study_hours_class,
                                                      subject_course_type:
                                                        info_class.course_type
                                                          .single === "True"
                                                          ? "เรียนเดี่ยว"
                                                          : "เรียนกลุ่ม",
                                                      subject_course_place:
                                                        info_class.course_place
                                                          .school === "True"
                                                          ? "สถาบัน"
                                                          : "ออนไลน์",
                                                      subject_study_type:
                                                        info_class.select_time
                                                          .fix_time === "True"
                                                          ? "เวลาเหมือนกัน"
                                                          : "กำหนดเอง",
                                                      course_main_type:
                                                        info_class
                                                          .course_main_type
                                                          .free === "True"
                                                          ? "ทดลองเรียน"
                                                          : "เปิดสอนจริง",
                                                      subject_days:
                                                        info_class.select_day,
                                                      subject_price:
                                                        info_class.price_class,
                                                      listDay:
                                                        info_class.custom_day,
                                                    });
                                                  }}
                                                >
                                                  <label className="icon point">
                                                    {"\uf304"}
                                                  </label>
                                                </td>
                                                <td
                                                  className="text-center point"
                                                  onClick={() => {
                                                    this.setState({
                                                      detail: [
                                                        main_class_index,
                                                        sub_class_index,
                                                        info_class_index,
                                                      ],
                                                    });
                                                    setTimeout(() => {
                                                      this.DeleteInfoClass();
                                                    }, 1);
                                                  }}
                                                >
                                                  <label className="icon point">
                                                    {"\uf2ed"}
                                                  </label>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </>
                                      )
                                    )}
                                  </>
                                )
                              )}
                            {this.state.data && this.state.data.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={10}>
                                  {global[this.language]["no_data"]}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <div className="w-100 d-flex justify-content-center">
                          <div className="loader" />
                        </div>
                      )}
                    </div>
                    <div className="d-flex flex-row-reverse mt-3">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.page_count * 10}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        prevPageText="Previous"
                        nextPageText="Next"
                        hideFirstLastPages={true}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* MODAL */}
              {/* ปุ่มเพิ่มวิชาเรียน */}
              <Modal
                show={this.state.modalTypeInfoClass}
                onHide={() => {
                  this.setState({ modalTypeInfoClass: false });
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["teaching_type"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-6">
                      <button
                        onClick={() => {
                          this.setState({
                            modalAddInfoClass: true,
                            modalTypeInfoClass: false,
                            course_main_type: "free",
                          });
                        }}
                        className=" btn-outline-primary card  d-flex align-items-center justify-content-center p-4 w-100"
                      >
                        <label className="icon big-icon mb-2">{"\uf02d"}</label>
                        <br />
                        <b>{global[this.language]["trial_lessons"]}</b>
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        onClick={() => {
                          this.setState({
                            modalAddInfoClass: true,
                            modalTypeInfoClass: false,
                            course_main_type: "register",
                          });
                        }}
                        className=" btn-outline-primary card d-flex align-items-center justify-content-center p-4 w-100"
                      >
                        <label className="icon big-icon mb-2">{"\uf5db"}</label>
                        <br />
                        <b>{global[this.language]["open_for_teaching"]}</b>
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* เพิ่มวิชาเรียน */}
              <Modal
                show={this.state.modalAddInfoClass}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["add_course_information"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["course_name"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={this.state.subject_name}
                        onChange={(event) => {
                          this.setState({ subject_name: event.target.value });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["Department"]}:</label>
                      <select
                        className="form-control"
                        value={this.state.subject_department}
                        onChange={(event) => {
                          this.setState({
                            subject_department: event.target.value,
                          });
                        }}
                      >
                        <option value="">
                          --
                          {global[this.language]["select_Department"]}
                          --
                        </option>
                        {this.state.MainClass.map((main_class, index) => (
                          <option value={index}>
                            {main_class.main_class_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["course"]}:</label>
                      <select
                        className="form-control"
                        value={this.state.subject_course}
                        onChange={(event) => {
                          this.setState({
                            subject_course: event.target.value,
                          });
                        }}
                      >
                        <option value="">
                          --{global[this.language]["select_course"]}--
                        </option>
                        {this.state.subject_department !== "" &&
                          this.state.MainClass[this.state.subject_department] &&
                          this.state.MainClass[
                            this.state.subject_department
                          ].sub_class.map((sub_class, index) => (
                            <option value={index}>{sub_class.sub_class}</option>
                          ))}
                      </select>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["start_date"]}:</label>

                      <DatePicker
                        className="form-control w-100"
                        selected={this.state.subject_startdate}
                        minDate={new Date()}
                        locale="th"
                        onChange={(date) => {
                          this.setState({ subject_startdate: date });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["end_date"]}:</label>

                      <DatePicker
                        className="form-control w-100"
                        selected={this.state.subject_enddate}
                        minDate={new Date()}
                        locale="th"
                        onChange={(date) => {
                          this.setState({ subject_enddate: date });
                        }}
                      />
                      <div className="text-secondary">
                        <input
                          type="checkbox"
                          className="mt-2 mr-2"
                          placeholder={
                            global[this.language]["Type_the_course_name"]
                          }
                          value={this.state.subject_enddate}
                          onChange={(event) => {
                            this.setState({
                              subject_enddate: event.target.checked,
                            });
                          }}
                        />
                        {global[this.language]["End_date_not"]}
                      </div>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["class_time"]}:</label>
                      <input
                        type="time"
                        className="form-control"
                        placeholder="00:00"
                        value={this.state.subject_study_time}
                        onChange={(event) => {
                          this.setState({
                            subject_study_time: event.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>
                        {global[this.language]["number_of_class_hours"]}:
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="00"
                        value={this.state.subject_total_hour}
                        onChange={(event) => {
                          this.setState({
                            subject_total_hour: event.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["Study_hours"]}:</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="00"
                        value={this.state.subject_hour}
                        onChange={(event) => {
                          this.setState({
                            subject_hour: event.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["Course_type"]}:</label>
                      <div className="toggle-bar">
                        <button
                          onClick={() => {
                            this.setState({
                              subject_course_type: "เรียนเดี่ยว",
                            });
                          }}
                          className={
                            this.state.subject_course_type === "เรียนเดี่ยว"
                              ? "toggle-left-active"
                              : "toggle-left"
                          }
                        >
                          {global[this.language]["solo_study"]}
                        </button>
                        <button
                          onClick={() => {
                            this.setState({
                              subject_course_type: "เรียนกลุ่ม",
                            });
                          }}
                          className={
                            this.state.subject_course_type === "เรียนกลุ่ม"
                              ? "toggle-right-active"
                              : "toggle-right"
                          }
                        >
                          {global[this.language]["group_classes"]}
                        </button>
                      </div>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["Course_place"]}:</label>
                      <div className="toggle-bar">
                        <button
                          onClick={() => {
                            this.setState({
                              subject_course_place: "สถาบัน",
                            });
                          }}
                          className={
                            this.state.subject_course_place === "สถาบัน"
                              ? "toggle-left-active"
                              : "toggle-left"
                          }
                        >
                          {global[this.language]["institution_1"]}
                        </button>
                        <button
                          onClick={() => {
                            this.setState({
                              subject_course_place: "ออนไลน์",
                            });
                          }}
                          className={
                            this.state.subject_course_place === "ออนไลน์"
                              ? "toggle-right-active"
                              : "toggle-right"
                          }
                        >
                          {global[this.language]["online"]}
                        </button>
                      </div>
                    </div>
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["Class_type"]}:</label>
                      <div className="toggle-bar">
                        <button
                          onClick={() => {
                            this.setState({
                              subject_study_type: "เวลาเหมือนกัน",
                              listDay: [],
                            });
                          }}
                          className={
                            this.state.subject_study_type === "เวลาเหมือนกัน"
                              ? "toggle-left-active"
                              : "toggle-left"
                          }
                        >
                          {global[this.language]["same_time"]}
                        </button>
                        <button
                          onClick={() => {
                            this.setState({
                              subject_study_type: "กำหนดเอง",
                            });
                          }}
                          className={
                            this.state.subject_study_type === "กำหนดเอง"
                              ? "toggle-right-active"
                              : "toggle-right"
                          }
                        >
                          {global[this.language]["different_time"]}
                        </button>
                      </div>
                    </div>

                    <div className="col-12 pb-3">
                      <label>{global[this.language]["study_time"]}:</label>
                      {this.state.subject_study_type === "เวลาเหมือนกัน" ? (
                        <div className="toggle-bar">
                          {this.state.subject_days.map((day, index) => (
                            <button
                              onClick={() => {
                                let days = this.state.subject_days;
                                days[index].select = !days[index].select;
                                this.setState({ subject_days: days });
                              }}
                              className={
                                index === 0
                                  ? day.select
                                    ? "day-big-left-active"
                                    : "day-big-left"
                                  : index === 6
                                  ? day.select
                                    ? "day-big-right-active"
                                    : "day-big-right"
                                  : day.select
                                  ? "day-big-center-active"
                                  : "day-big-center"
                              }
                            >
                              <label>
                                {this.language === "TH"
                                  ? this.state.day[index]
                                  : this.state.dayen[index]}
                              </label>
                            </button>
                          ))}
                        </div>
                      ) : (
                        <div className="w-100">
                          <div className=" bg-secondary rounded mb-3">
                            {this.state.listDay.map((item, index) => (
                              <div className="w-100 d-flex p-2 align-items-center">
                                <div>
                                  {index === 0 && <label>วันที่</label>}
                                  {/* <input
                                    type="date"
                                    className="form-control"
                                    value={item.date}
                                    onChange={(event) => {
                                      let data = this.state.listDay;
                                      data[index].date = event.target.value;
                                      this.setState({
                                        listDay: data,
                                      });
                                    }}
                                  /> */}
                                  <DatePicker
                                    className="form-control"
                                    selected={item.date}
                                    minDate={new Date()}
                                    locale="th"
                                    onChange={(date) => {
                                      let data = this.state.listDay;
                                      data[index].date = date;
                                      this.setState({
                                        listDay: data,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="ml-2 mr-2">
                                  {index === 0 && <label>เวลา</label>}
                                  <input
                                    type="time"
                                    className="form-control"
                                    value={item.time}
                                    onChange={(event) => {
                                      let data = this.state.listDay;
                                      data[index].time = event.target.value;
                                      this.setState({ listDay: data });
                                    }}
                                  />
                                </div>
                                <div className="mr-2">
                                  {index === 0 && <label>ชั่วโมง</label>}
                                  <input
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    maxLength={2}
                                    value={item.study_hours_class}
                                    onChange={(event) => {
                                      let data = this.state.listDay;
                                      data[index].study_hours_class =
                                        event.target.value;
                                      this.setState({ listDay: data });
                                    }}
                                  />
                                </div>
                                <div>
                                  {index === 0 && <label>ลบ</label>}
                                  <button
                                    className="icon btn btn-danger"
                                    onClick={() => {
                                      let data = this.state.listDay;
                                      data.splice(index, 1);
                                      this.setState({ listDay: data });
                                    }}
                                  >
                                    {"\uf2ed"}
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                          <button
                            className="btn btn-primary w-100"
                            onClick={() => {
                              if (this.state.subject_total_hour === "") {
                                Swal.fire(
                                  global[this.language]["alert"],
                                  global[this.language][
                                    "fill_subject_total_hour"
                                  ],
                                  "warning"
                                );
                              } else {
                                let data = this.state.listDay;
                                let t =
                                  this.state.subject_hour === ""
                                    ? 1
                                    : this.state.subject_hour;
                                data.push({
                                  date: "",
                                  time: t,
                                  study_hours_class: "",
                                });
                                this.setState({ listDay: data });
                              }
                            }}
                          >
                            + เพิ่มเวลาเรียน
                          </button>
                          {/* {this.state.subject_days.map((day, index) => (
                            <div className="d-flex justify-content-around align-items-center mb-3">
                              <input
                                type="checkbox"
                                checked={day.select}
                                value={day.select}
                                className="mr-3"
                                onChange={(event) => {
                                  let days = this.state.subject_days;
                                  days[index].select = event.target.checked;
                                  if (event.target.checked === false) {
                                    days[index].time = "";
                                  }
                                  this.setState({ subject_days: days });
                                }}
                              />
                              <input
                                type="text"
                                className="form-control"
                                disabled={true}
                                value={"วัน" + day.day}
                              />
                              <input
                                type="time"
                                disabled={!day.select}
                                className="form-control ml-3"
                                value={day.time}
                                onChange={(event) => {
                                  let days = this.state.subject_days;
                                  days[index].time = event.target.value;
                                  this.setState({ subject_days: days });
                                }}
                              />
                            </div>
                          ))} */}
                        </div>
                      )}
                    </div>
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["price"]}:</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="00"
                        value={this.state.subject_price}
                        onChange={(event) => {
                          this.setState({
                            subject_price: event.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.AddInfoClass();
                        }}
                      >
                        {global[this.language]["add"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* แก้ไขวิชาเรียน */}
              <Modal
                show={this.state.modalEditInfoClass}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["edit_subject"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["course_name"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={this.state.subject_name}
                        onChange={(event) => {
                          this.setState({ subject_name: event.target.value });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["Department"]}:</label>
                      <select
                        className="form-control"
                        value={this.state.subject_department}
                        onChange={(event) => {
                          this.setState({
                            subject_department: event.target.value,
                          });
                        }}
                      >
                        <option value="">
                          --
                          {global[this.language]["select_Department"]}
                          --
                        </option>
                        {this.state.MainClass.map((data, index) => (
                          <option value={index}>{data.main_class_name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["course"]}:</label>
                      <select
                        className="form-control"
                        value={this.state.subject_course}
                        onChange={(event) => {
                          this.setState({
                            subject_course: event.target.value,
                          });
                        }}
                      >
                        <option value="">
                          --{global[this.language]["select_course"]}--
                        </option>
                        {this.state.subject_department !== "" &&
                          this.state.MainClass[this.state.subject_department] &&
                          this.state.MainClass[
                            this.state.subject_department
                          ].sub_class.map((sub_class, index) => (
                            <option value={index}>{sub_class.sub_class}</option>
                          ))}
                      </select>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["start_date"]}:</label>
                      {/* <input
                        type="date"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={this.state.subject_startdate}
                        onChange={(event) => {
                          this.setState({
                            subject_startdate: event.target.value,
                          });
                        }}
                      /> */}
                      <DatePicker
                        className="form-control w-100"
                        selected={this.state.subject_startdate}
                        minDate={new Date()}
                        locale="th"
                        onChange={(date) => {
                          this.setState({ subject_startdate: date });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["end_date"]}:</label>
                      {/* <input
                        type="date"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={this.state.subject_enddate}
                        disabled={this.state.subject_enddate === true}
                        onChange={(event) => {
                          this.setState({
                            subject_enddate: event.target.value,
                          });
                        }}
                      /> */}
                      <DatePicker
                        className="form-control w-100"
                        selected={this.state.subject_enddate}
                        minDate={new Date()}
                        locale="th"
                        onChange={(date) => {
                          this.setState({ subject_enddate: date });
                        }}
                      />
                      <div className="text-secondary">
                        <input
                          type="checkbox"
                          className="mt-2 mr-2"
                          placeholder={
                            global[this.language]["Type_the_course_name"]
                          }
                          value={this.state.subject_enddate}
                          onChange={(event) => {
                            this.setState({
                              subject_enddate: event.target.checked,
                            });
                          }}
                        />
                        {global[this.language]["End_date_not"]}
                      </div>
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["study_time"]}:</label>
                      <input
                        type="time"
                        className="form-control"
                        placeholder="00:00"
                        value={this.state.subject_study_time}
                        onChange={(event) => {
                          this.setState({
                            subject_study_time: event.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>
                        {global[this.language]["number_of_class_hours"]}:
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="00"
                        value={this.state.subject_total_hour}
                        onChange={(event) => {
                          this.setState({
                            subject_total_hour: event.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["Study_hours"]}:</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="00"
                        value={this.state.subject_hour}
                        onChange={(event) => {
                          this.setState({
                            subject_hour: event.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>{global[this.language]["Course_type"]}:</label>
                      <div className="toggle-bar">
                        <button
                          onClick={() => {
                            this.setState({
                              subject_course_type: "เรียนเดี่ยว",
                            });
                          }}
                          className={
                            this.state.subject_course_type === "เรียนเดี่ยว"
                              ? "toggle-left-active"
                              : "toggle-left"
                          }
                        >
                          {global[this.language]["solo_study"]}
                        </button>
                        <button
                          onClick={() => {
                            this.setState({
                              subject_course_type: "เรียนกลุ่ม",
                            });
                          }}
                          className={
                            this.state.subject_course_type === "เรียนกลุ่ม"
                              ? "toggle-right-active"
                              : "toggle-right"
                          }
                        >
                          {global[this.language]["group_classes"]}
                        </button>
                      </div>
                    </div>
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["Class_type"]}:</label>
                      <div className="toggle-bar">
                        <button
                          onClick={() => {
                            this.setState({
                              subject_study_type: "เวลาเหมือนกัน",
                            });
                          }}
                          className={
                            this.state.subject_study_type === "เวลาเหมือนกัน"
                              ? "toggle-left-active"
                              : "toggle-left"
                          }
                        >
                          {global[this.language]["same_time"]}
                        </button>
                        <button
                          onClick={() => {
                            this.setState({
                              subject_study_type: "กำหนดเอง",
                            });
                          }}
                          className={
                            this.state.subject_study_type === "กำหนดเอง"
                              ? "toggle-right-active"
                              : "toggle-right"
                          }
                        >
                          {global[this.language]["different_time"]}
                        </button>
                      </div>
                    </div>

                    <div className="col-12 pb-3">
                      <label>{global[this.language]["study_time"]}:</label>
                      {this.state.subject_study_type === "เวลาเหมือนกัน" ? (
                        <div className="toggle-bar">
                          {this.state.subject_days.map((day, index) => (
                            <button
                              onClick={() => {
                                let days = this.state.subject_days;
                                days[index].select = !days[index].select;
                                this.setState({ subject_days: days });
                              }}
                              className={
                                index === 0
                                  ? day.select
                                    ? "day-big-left-active"
                                    : "day-big-left"
                                  : index === 6
                                  ? day.select
                                    ? "day-big-right-active"
                                    : "day-big-right"
                                  : day.select
                                  ? "day-big-center-active"
                                  : "day-big-center"
                              }
                            >
                              <label>
                                {this.language === "TH"
                                  ? this.state.day[index]
                                  : this.state.dayen[index]}
                              </label>
                            </button>
                          ))}
                        </div>
                      ) : (
                        <div className="w-100">
                          {this.state.subject_days.map((day, index) => (
                            <div className="d-flex justify-content-around align-items-center mb-3">
                              <input
                                type="checkbox"
                                checked={day.select}
                                value={day.select}
                                className="mr-3"
                                onChange={(event) => {
                                  let days = this.state.subject_days;
                                  days[index].select = event.target.checked;
                                  if (event.target.checked === false) {
                                    days[index].time = "";
                                  }
                                  this.setState({ subject_days: days });
                                }}
                              />
                              <input
                                type="text"
                                className="form-control"
                                disabled={true}
                                value={"วัน" + day.day}
                              />
                              <input
                                type="time"
                                disabled={!day.select}
                                className="form-control ml-3"
                                value={day.time}
                                onChange={(event) => {
                                  let days = this.state.subject_days;
                                  days[index].time = event.target.value;
                                  this.setState({ subject_days: days });
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["price"]}:</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="00"
                        value={this.state.subject_price}
                        onChange={(event) => {
                          this.setState({
                            subject_price: event.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.EditInfoClass();
                        }}
                      >
                        {global[this.language]["save"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* ดูรายละเอียดวิชาเรียน */}
              <Modal
                show={this.state.modalDetailInfoClass}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["detail_Department"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["Department"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={this.state.subject_department}
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["course"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.subject_course}
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["course_name"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={this.state.subject_name}
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["start_date"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.subject_startdate}
                        disabled={true}
                      />
                    </div>
                    {this.state.subject_enddate !== "" ? (
                      <>
                        <div className="col-4 pb-3">
                          <label>{global[this.language]["end_date"]}:</label>
                        </div>
                        <div className="col-8 pb-3">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.subject_enddate}
                            disabled={true}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-4 pb-3">
                          <label></label>
                        </div>
                        <div className="col-8 pb-3">
                          <div className="text-secondary">
                            <input
                              type="checkbox"
                              className="mr-2"
                              checked
                              disabled={true}
                            />
                            {global[this.language]["End_date_not"]}
                          </div>
                        </div>
                      </>
                    )}

                    <div className="col-4 pb-3">
                      <label>{global[this.language]["study_time"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={this.state.subject_study_time}
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>
                        {global[this.language]["number_of_class_hours"]}:
                      </label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={this.state.subject_total_hour}
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["Study_hours"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Type_the_course_name"]
                        }
                        value={this.state.subject_hour}
                        disabled={true}
                      />
                    </div>

                    <div className="col-4 pb-3">
                      <label>{global[this.language]["Course_type"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <div className="toggle-bar">
                        <div
                          className={
                            this.state.subject_course_type === "เรียนเดี่ยว"
                              ? "toggle-left-active"
                              : "toggle-left"
                          }
                        >
                          {global[this.language]["solo_study"]}
                        </div>
                        <div
                          className={
                            this.state.subject_course_type === "เรียนกลุ่ม"
                              ? "toggle-right-active"
                              : "toggle-right"
                          }
                        >
                          {global[this.language]["group_classes"]}
                        </div>
                      </div>
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["Course_place"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <div className="toggle-bar">
                        <div
                          className={
                            this.state.subject_course_place === "สถาบัน"
                              ? "toggle-left-active"
                              : "toggle-left"
                          }
                        >
                          {global[this.language]["institution_1"]}
                        </div>
                        <div
                          className={
                            this.state.subject_course_place === "ออนไลน์"
                              ? "toggle-right-active"
                              : "toggle-right"
                          }
                        >
                          {global[this.language]["online"]}
                        </div>
                      </div>
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["teaching_type_1"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <div className="toggle-bar">
                        <div
                          className={
                            this.state.course_main_type === "ทดลองเรียน"
                              ? "toggle-left-active"
                              : "toggle-left"
                          }
                        >
                          {global[this.language]["trial_lessons"]}
                        </div>
                        <div
                          className={
                            this.state.course_main_type === "เปิดสอนจริง"
                              ? "toggle-right-active"
                              : "toggle-right"
                          }
                        >
                          {global[this.language]["open_for_teaching"]}
                        </div>
                      </div>
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["study_time"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      {this.state.subject_study_type === "เวลาเหมือนกัน" ? (
                        <div className="toggle-bar">
                          {this.state.subject_days.map((day, index) => (
                            <div
                              className={
                                index === 0
                                  ? day.select
                                    ? "day-big-left-active"
                                    : "day-big-left"
                                  : index === 6
                                  ? day.select
                                    ? "day-big-right-active"
                                    : "day-big-right"
                                  : day.select
                                  ? "day-big-center-active"
                                  : "day-big-center"
                              }
                            >
                              <label>
                                {this.language === "TH"
                                  ? this.state.day[index]
                                  : this.state.dayen[index]}
                              </label>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className=" bg-secondary rounded mb-3">
                          {this.state.listDay.map((item, index) => (
                            <div className="w-100 d-flex p-2 align-items-center">
                              <div>
                                {index === 0 && <label>วันที่</label>}
                                {/* <input
                                    type="date"
                                    className="form-control"
                                    value={item.date}
                                    onChange={(event) => {
                                      let data = this.state.listDay;
                                      data[index].date = event.target.value;
                                      this.setState({
                                        listDay: data,
                                      });
                                    }}
                                  /> */}
                                <DatePicker
                                  className="form-control"
                                  selected={item.date}
                                  locale="th"
                                  readOnly
                                />
                              </div>
                              <div className="ml-2 mr-2">
                                {index === 0 && <label>เวลา</label>}
                                <input
                                  type="time"
                                  className="form-control"
                                  value={item.time}
                                  readOnly
                                />
                              </div>
                              <div className="mr-2">
                                {index === 0 && <label>ชั่วโมง</label>}
                                <input
                                  type="number"
                                  min={0}
                                  className="form-control"
                                  value={item.study_hours_class}
                                  readOnly
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["price"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="number"
                        className="form-control"
                        value={this.state.subject_price}
                        disabled={true}
                      />
                    </div>
                    <div className="dash m-3 w-100" />
                    <div className="col-4 pb-3">
                      <label>{global[this.language]["teacher_name"]}:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.subject_teacher}
                        disabled={true}
                      />
                    </div>
                    <div className="col-4 pb-3">
                      <label>
                        {global[this.language]["student_in_course"]}:
                      </label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        value={
                          ""
                          // this.state.data &&
                          // this.state.data[this.state.detail[0]] &&
                          // this.state.data[this.state.detail[0]].course &&
                          // this.state.data[this.state.detail[0]].course[
                          //   this.state.detail[1]
                          // ] &&
                          // this.state.data[this.state.detail[0]].course[
                          //   this.state.detail[1]
                          // ].subject[
                          //   this.state.detail[2]
                          // ].subject_student.toString()
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="col-6 pb-3" />

                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["close"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* เพิ่มภาควิชา */}
              <Modal
                show={this.state.modalAddMainClass}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["add_Department"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["Department"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["Department"]}
                        value={this.state.department}
                        onChange={(event) => {
                          this.setState({ department: event.target.value });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.AddMainClass();
                        }}
                      >
                        {global[this.language]["add"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* แก้ไขภาควิชา */}
              <Modal
                show={this.state.modalEditMainClass}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["edit_Department"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["Department"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["Department"]}
                        value={this.state.department}
                        onChange={(event) => {
                          this.setState({ department: event.target.value });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.EditMainClass();
                        }}
                      >
                        {global[this.language]["save"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* เพิ่มหลักสูตร */}
              <Modal
                show={this.state.modalAddSubClass}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["add_subDepartment"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["Department"]}:</label>
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder={
                          global[this.language]["Department"]
                        }
                        value={this.state.department}
                        onChange={(event) => {
                          this.setState({ department: event.target.value });
                        }}
                      /> */}
                      <select
                        className="form-control"
                        value={this.state.department}
                        onChange={(event) => {
                          this.setState({
                            department: event.target.value,
                          });
                        }}
                      >
                        <option value="">
                          --
                          {global[this.language]["select_Department"]}
                          --
                        </option>
                        {this.state.MainClass.map((main_class, index) => (
                          <option value={index}>
                            {main_class.main_class_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["course"]}:</label>
                      {this.state.subdepartment.map((item, index) => (
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={global[this.language]["course"]}
                            value={item}
                            onChange={(event) => {
                              let data = this.state.subdepartment;

                              data[index] = event.target.value;
                              this.setState({ subdepartment: data });
                            }}
                          />
                          <button
                            className="icon btn btn-danger ml-2"
                            onClick={() => {
                              let data = this.state.subdepartment;
                              data.splice(index, 1);
                              this.setState({ subdepartment: data });
                            }}
                          >
                            {"\uf2ed"}
                          </button>
                        </div>
                      ))}
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          let data = this.state.subdepartment;
                          data.push("");
                          this.setState({ subdepartment: data });
                        }}
                      >
                        + {global[this.language]["add_subDepartment"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.AddSubClass();
                        }}
                      >
                        {global[this.language]["add"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* แก้ไขหลักสูตร */}
              <Modal
                show={this.state.modalEditSubClass}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>{global[this.language]["edit_subDepartment"]}</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["Department"]}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["Department"]}
                        readOnly
                        value={this.state.department}
                        onChange={(event) => {
                          this.setState({ department: event.target.value });
                        }}
                      />
                    </div>
                    <div className="col-12 pb-3">
                      <label>{global[this.language]["course"]}:</label>

                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["course"]}
                        value={this.state.subdepartmenttext}
                        onChange={(event) => {
                          this.setState({
                            subdepartmenttext: event.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.EditSubClass();
                        }}
                      >
                        {global[this.language]["save"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
