import "../../../bootstrap.css";
import React, { Component } from "react";
import SideMenu from "../../Content/SideMenu/SideMenu";
import Header from "../../Content/Header/Header";

import Pagination from "react-js-pagination";

import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";

import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";

import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      search: "",
      data: [
        {
          id: "572110139",
          name: "Megan Roberts",
          total: "1500",
          status: "ชำระแล้ว",
        },
        {
          id: "572110139",
          name: "Megan Roberts",
          total: "1500",
          status: "รอชำระ",
        },
        {
          id: "572110139",
          name: "Megan Roberts",
          total: "1500",
          status: global[this.language]["cancel"],
        },
      ],
      data1: [
        {
          id: "572110139",
          list: "Megan Roberts",
          type: "การสอน",
          total: "1500",
        },

        {
          id: "572110139",
          list: "Megan Roberts",
          type: "ค่าน้ำ & ค่าไฟ",
          total: "1500",
        },
        {
          id: "572110139",
          list: "Megan Roberts",
          type: "การสอน",
          total: "1500",
        },
      ],
      data2: ["ค่าน้ำ & ค่าไฟ", "การสอน", "การสอน2"],
      modal_add_type_pay: false,
      modal_edit_type_pay: false,
      modal_add_pay: false,
      modal_edit_pay: false,
      type_pay: "",
      pay_name: "",
      pay_type: "",
      pay_total: "",
      sort: [0, 0, 0, 0, 0, 0, 0],
    };
  }
  language = cookies.get("language") ? cookies.get("language") : "TH";
  componentDidMount() {
    if (cookies.get("user")) {
    } else {
      Swal.fire({
        title: global[this.language]["alert"],
        text: global[this.language]["retrieve_data_fail"],
        icon: "warning",
        showCancelButton: false,
        allowOutsideClick: false,
        confirmButtonText: global[this.language]["ok"],
      }).then((result) => {
        if (result.isConfirmed) {
          cookies.remove("token", { path: "/" });
          cookies.remove("user", { path: "/" });

          window.location.href = "/";
        }
      });
    }
  }

  Cancel = () => {
    this.setState({
      modal_add_type_pay: false,
      modal_edit_type_pay: false,
      modal_add_pay: false,
      modal_edit_pay: false,
      type_pay: "",
      pay_name: "",
      pay_type: "",
      pay_total: "",
    });
  };

  Submit_add_pay = () => {
    Swal.fire(
      global[this.language]["alert"],
      global[this.language]["save_success"],
      "success"
    );
    this.Cancel();
  };
  Submit_add_type_pay = () => {
    Swal.fire(
      global[this.language]["alert"],
      global[this.language]["save_success"],
      "success"
    );
    this.Cancel();
  };
  Submit_edit_pay = () => {
    Swal.fire(
      global[this.language]["alert"],
      global[this.language]["save_success"],
      "success"
    );
    this.Cancel();
  };
  Submit_edit_type_pay = () => {
    Swal.fire(
      global[this.language]["alert"],
      global[this.language]["save_success"],
      "success"
    );
    this.Cancel();
  };
  Delete_pay = () => {
    Swal.fire(
      global[this.language]["alert"],
      global[this.language]["delete_success"],
      "success"
    );
  };
  Delete_type_pay = () => {
    Swal.fire(
      global[this.language]["alert"],
      global[this.language]["delete_success"],
      "success"
    );
  };
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
  }
  Sort = (key) => {
    let list1 = this.state.data;
    let list2 = this.state.data1;
    let list3 = this.state.data2;
    let sorted = this.state.sort;
    if (key === "name") {
      if (sorted[0] === 0) {
        list1.sort((a, b) => (a.name < b.name ? 1 : -1));
        sorted[0] = 1;
      } else {
        list1.sort((a, b) => (a.name > b.name ? 1 : -1));
        sorted[0] = 0;
      }
    } else if (key === "id") {
      if (sorted[1] === 0) {
        list1.sort((a, b) => (a.id < b.id ? 1 : -1));
        sorted[1] = 1;
      } else {
        list1.sort((a, b) => (a.id > b.id ? 1 : -1));
        sorted[1] = 0;
      }
    } else if (key === "total") {
      if (sorted[2] === 0) {
        list1.sort((a, b) => (a.total < b.total ? 1 : -1));
        sorted[2] = 1;
      } else {
        list1.sort((a, b) => (a.total > b.total ? 1 : -1));
        sorted[2] = 0;
      }
    } else if (key === "list") {
      if (sorted[3] === 0) {
        list2.sort((a, b) => (a.list < b.list ? 1 : -1));
        sorted[3] = 1;
      } else {
        list2.sort((a, b) => (a.list > b.list ? 1 : -1));
        sorted[3] = 0;
      }
    } else if (key === "type") {
      if (sorted[4] === 0) {
        list2.sort((a, b) => (a.type < b.type ? 1 : -1));
        sorted[4] = 1;
      } else {
        list2.sort((a, b) => (a.type > b.type ? 1 : -1));
        sorted[4] = 0;
      }
    } else if (key === "total2") {
      if (sorted[5] === 0) {
        list2.sort((a, b) => (a.total < b.total ? 1 : -1));
        sorted[5] = 1;
      } else {
        list2.sort((a, b) => (a.total > b.total ? 1 : -1));
        sorted[5] = 0;
      }
    } else if (key === "nametype") {
      if (sorted[1] === 0) {
        list3.sort((a, b) => (a < b ? 1 : -1));
        sorted[1] = 1;
      } else {
        list3.sort((a, b) => (a > b ? 1 : -1));
        sorted[1] = 0;
      }
    }

    this.setState({ data: list1, data1: list2, data2: list3, sort: sorted });
  };
  render() {
    return (
      <div className="w-100-body h-auto minh-100">
        <div className="d-flex h-auto minh-100">
          <div className="h-auto minh-100">
            <SideMenu page="finance" />
          </div>
          <div className="w-100-body">
            <Header />
            <div className="container-fluid pt-3">
              <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-3">
                  <div className="card border-0 p-3 flex-row justify-content-between align-items-center w-100">
                    <b className="icon mr-1 text-primary big-icon">
                      {"\uf200"}
                    </b>
                    <div className="text-right">
                      <b className="text-secondary">จำนวนภาควิชาทั้งหมด</b>
                      <br />
                      <h4 className="text-primary">
                        <b>5</b>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-3">
                  <div className="card border-0 p-3 flex-row justify-content-between align-items-center">
                    <b className="icon mr-1 text-primary big-icon">
                      {"\uf200"}
                    </b>
                    <div className="text-right">
                      <b className="text-secondary">จำนวนหลักสูตรทั้งหมด</b>
                      <br />
                      <h4 className="text-primary">
                        <b>16</b>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-3">
                  <div className="card border-0 p-3 flex-row justify-content-between align-items-center">
                    <b className="icon mr-1 text-primary big-icon">
                      {"\uf200"}
                    </b>
                    <div className="text-right">
                      <b className="text-secondary">จำนวนวิชาทั้งหมด</b>
                      <br />
                      <h4 className="text-primary">
                        <b>24</b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* TABLE */}
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-3">
                  <div className="card border-0 p-4">
                    <div className="flex-row justify-content-between align-items-center row p-4">
                      <div>
                        <h3>
                          <b>บัญชีรายรับ</b>
                        </h3>
                      </div>
                      {/* BUTTON XL */}
                      <div className="text-right d-none d-xl-block">
                        <button className="btn btn-outline-secondary mr-2 text-primary">
                          <label className="icon mr-1">{"\uf56d"}</label>
                          Export Report
                        </button>
                        <div className="search-box-div mr-2 ml-2">
                          <input
                            type="text"
                            className="search-box"
                            placeholder={global[this.language]["search"]}
                            value={this.state.search}
                            onChange={(event) => {
                              this.setState({
                                search: event.target.value,
                              });
                            }}
                          />
                          <button className="icon-absolute">{"\uf002"}</button>
                        </div>
                        <select className="filter-box ml-2">
                          <option>{global[this.language]["all_status"]}</option>
                          <option>ชำระแล้ว</option>
                          <option>รอชำระ</option>
                          <option>{global[this.language]["cancel"]}</option>
                        </select>
                      </div>
                      {/* BUTTON NOT XL */}
                      <div className="text-right d-block d-xl-none flex-row justify-content-between align-items-center">
                        <div className="search-box-div mr-2 ml-2">
                          <input
                            type="text"
                            className="search-box"
                            placeholder={global[this.language]["search"]}
                            value={this.state.search}
                            onChange={(event) => {
                              this.setState({
                                search: event.target.value,
                              });
                            }}
                          />
                          <button className="icon-absolute">{"\uf002"}</button>
                        </div>
                        <Menu
                          menuButton={
                            <button className="btn btn-primary mr-2 ml-2">
                              <label className="icon">{"\uf0c9"}</label>
                            </button>
                          }
                        >
                          <MenuItem>
                            <label className="icon mr-1">{"\uf56d"}</label>
                            Export Report
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-borderless table-striped table-w-2-1 table-hover ">
                        <thead className="thead-dark">
                          <tr>
                            <th className="text-center">
                              {global[this.language]["order"]}
                            </th>
                            <th
                              className="point"
                              onClick={() => {
                                this.Sort("name");
                              }}
                            >
                              {global[this.language]["fullname"]}
                            </th>
                            <th
                              className="point"
                              onClick={() => {
                                this.Sort("id");
                              }}
                            >
                              รหัสวิชา
                            </th>
                            <th
                              className="point"
                              onClick={() => {
                                this.Sort("total");
                              }}
                            >
                              จำนวน
                            </th>
                            <th className="text-center">ใบเสร็จ</th>
                            <th className="text-center">
                              {global[this.language]["status"]}
                            </th>
                            <th className="text-center">
                              {global[this.language]["cancel"]}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* DEPARTMENT */}
                          {this.state.data.map((recieve, recieve_index) => (
                            <tr>
                              <td className="text-center">
                                {recieve_index + 1}
                              </td>
                              <td>{recieve.name}</td>
                              <td>{recieve.id}</td>
                              <td>{recieve.total}</td>
                              <td className="text-center">
                                <label className="icon ">{"\uf0c6"}</label>
                              </td>
                              <td className="text-center">
                                <div
                                  className={
                                    recieve.status === "ชำระแล้ว"
                                      ? "btn btn-success pt-1 pb-0"
                                      : recieve.status === "รอชำระ"
                                      ? "btn btn-warning pt-1 pb-0"
                                      : "btn btn-danger pt-1 pb-0"
                                  }
                                >
                                  {recieve.status}
                                </div>
                              </td>

                              <td className="text-center">
                                <label className="icon ">{"\uf057"}</label>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex flex-row-reverse mt-3">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={450}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        prevPageText="Previous"
                        nextPageText="Next"
                        hideFirstLastPages={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-3">
                  <div className="card border-0 p-4 mb-3">
                    <div className="flex-row justify-content-between align-items-center row p-4">
                      <div>
                        <h3>
                          <b>บัญชีรายจ่าย</b>
                        </h3>
                      </div>
                      {/* BUTTON XL */}
                      <div className="text-right d-none d-xl-block">
                        <button
                          className="btn btn-primary mr-2 ml-2 text-warning"
                          onClick={() => {
                            this.setState({ modal_type_subject: true });
                          }}
                        >
                          เปรียบเทียบ
                        </button>
                        <button className="btn btn-outline-secondary mr-2 ml-2 text-primary">
                          <label className="icon mr-1">{"\uf56d"}</label>
                          Export Report
                        </button>
                        <button
                          className="btn btn-primary mr-2 ml-2"
                          onClick={() => {
                            this.setState({ modal_add_pay: true });
                          }}
                        >
                          <label className="icon mr-1">{"\uf055"}</label>
                          สร้างรายการจ่าย
                        </button>
                      </div>
                      {/* BUTTON NOT XL */}
                      <div className="text-right d-block d-xl-none flex-row justify-content-between align-items-center">
                        <Menu
                          menuButton={
                            <button className="btn btn-primary mr-2 ml-2">
                              <label className="icon">{"\uf0c9"}</label>
                            </button>
                          }
                        >
                          <MenuItem onClick={() => {}}>
                            <label className="icon mr-1">{"\uf24e"}</label>
                            เปรียบเทียบ
                          </MenuItem>
                          <MenuItem>
                            <label className="icon mr-1">{"\uf56d"}</label>
                            Export Report
                          </MenuItem>

                          <MenuItem
                            onClick={() => {
                              this.setState({ modal_add_pay: true });
                            }}
                          >
                            <label className="icon mr-1">{"\uf055"}</label>
                            สร้างรายการจ่าย
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-borderless table-striped table-w-2-2 table-hover ">
                        <thead className="thead-dark">
                          <tr>
                            <th className="text-center">
                              {global[this.language]["order"]}
                            </th>
                            <th
                              className="point"
                              onClick={() => {
                                this.Sort("list");
                              }}
                            >
                              รายการ
                            </th>
                            <th
                              className="point"
                              onClick={() => {
                                this.Sort("type");
                              }}
                            >
                              ประเภท
                            </th>
                            <th
                              className="point"
                              onClick={() => {
                                this.Sort("total2");
                              }}
                            >
                              จำนวน
                            </th>
                            <th className="text-center">
                              {global[this.language]["edit"]}
                            </th>
                            <th className="text-center">
                              {global[this.language]["delete"]}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* DEPARTMENT */}
                          {this.state.data1.map((pay, pay_index) => (
                            <tr>
                              <td className="text-center">{pay_index + 1}</td>
                              <td>{pay.list}</td>
                              <td>{pay.type}</td>
                              <td>{pay.total}</td>

                              <td className="text-center">
                                <button
                                  className="btn btn-link pt-0 pb-0"
                                  onClick={() => {
                                    this.setState({ modal_edit_pay: true });
                                  }}
                                >
                                  <label className="icon ">{"\uf304"}</label>
                                </button>
                              </td>
                              <td className="text-center">
                                <button
                                  className="btn btn-link pt-0 pb-0"
                                  onClick={() => {
                                    Swal.fire({
                                      title:
                                        global[this.language]["confirm_delete"],
                                      showCancelButton: true,
                                      confirmButtonText:
                                        global[this.language]["confirm"],
                                      cancelButtonText:
                                        global[this.language]["cancel"],
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        this.Delete_pay();
                                      }
                                    });
                                  }}
                                >
                                  <label className="icon ">{"\uf2ed"}</label>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex flex-row-reverse mt-3">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={450}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        prevPageText="Previous"
                        nextPageText="Next"
                        hideFirstLastPages={true}
                      />
                    </div>
                  </div>
                  <div className="card border-0 p-4">
                    <div className="flex-row justify-content-between align-items-center row p-4">
                      <div>
                        <h3>
                          <b>ประเภทรายจ่าย</b>
                        </h3>
                      </div>
                      {/* BUTTON XL */}
                      <div className="text-right">
                        <button
                          className="btn btn-primary mr-2 ml-2"
                          onClick={() => {
                            this.setState({ modal_add_type_pay: true });
                          }}
                        >
                          <label className="icon mr-1">{"\uf055"}</label>
                          เพิ่มประเภทการจ่าย
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-borderless table-striped table-w-2-3 table-hover ">
                        <thead className="thead-dark">
                          <tr>
                            <th className="text-center">
                              {global[this.language]["order"]}
                            </th>
                            <th
                              className="point"
                              onClick={() => {
                                this.Sort("nametype");
                              }}
                            >
                              ชื่อของประเภท
                            </th>
                            <th className="text-center">
                              {global[this.language]["edit"]}
                            </th>
                            <th className="text-center">
                              {global[this.language]["delete"]}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* DEPARTMENT */}
                          {this.state.data2.map((paytype, paytype_index) => (
                            <tr>
                              <td className="text-center">
                                {paytype_index + 1}
                              </td>
                              <td>{paytype}</td>
                              <td className="text-center">
                                <button
                                  className="btn btn-link pt-0 pb-0"
                                  onClick={() => {
                                    this.setState({
                                      modal_edit_type_pay: true,
                                    });
                                  }}
                                >
                                  <label className="icon ">{"\uf304"}</label>
                                </button>
                              </td>
                              <td className="text-center">
                                <button
                                  className="btn btn-link pt-0 pb-0"
                                  onClick={() => {
                                    Swal.fire({
                                      title:
                                        global[this.language]["confirm_delete"],
                                      showCancelButton: true,
                                      confirmButtonText:
                                        global[this.language]["confirm"],
                                      cancelButtonText:
                                        global[this.language]["cancel"],
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        this.Delete_pay();
                                      }
                                    });
                                  }}
                                >
                                  <label className="icon ">{"\uf2ed"}</label>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex flex-row-reverse mt-3">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={450}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        prevPageText="Previous"
                        nextPageText="Next"
                        hideFirstLastPages={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* MODAL */}
              {/* เพิ่มรายจ่าย */}
              <Modal
                show={this.state.modal_add_pay}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>สร้างรายการจ่าย</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>ชื่อรายการจ่าย:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.pay_name}
                        onChange={(event) => {
                          this.setState({ pay_name: event.target.value });
                        }}
                      />
                    </div>
                    <div className="col-12 pb-3">
                      <label>ประเภทรายการจ่าย:</label>
                      <select
                        className="form-control mat-4 mab-24"
                        value={this.state.pay_type}
                        onChange={(event) =>
                          this.setState({
                            pay_type: event.target.value,
                          })
                        }
                      >
                        <option value="">--เลือกประเภท--</option>
                        {this.state.data2.map((item, index) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 pb-3">
                      <label>จำนวนที่จ่าย:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.pay_total}
                        onChange={(event) => {
                          this.setState({ pay_total: event.target.value });
                        }}
                      />
                    </div>

                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_add_pay();
                        }}
                      >
                        {global[this.language]["add"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* แก้ไขรายจ่าย */}
              <Modal
                show={this.state.modal_edit_pay}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>แก้ไขรายจ่าย</b>
                  </h3>
                  <div className="row">
                    <div className="col-4 pb-3">
                      <label>ชื่อรายการจ่าย:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.pay_name}
                        onChange={(event) => {
                          this.setState({ pay_name: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 pb-3">
                      <label>ประเภทรายการจ่าย:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <select
                        className="form-control mat-4 mab-24"
                        value={this.state.pay_type}
                        onChange={(event) =>
                          this.setState({
                            pay_type: event.target.value,
                          })
                        }
                      >
                        <option value="">--เลือกประเภท--</option>
                        {this.state.data2.map((item, index) => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 pb-3">
                      <label>จำนวนที่จ่าย:</label>
                    </div>
                    <div className="col-8 pb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.pay_total}
                        onChange={(event) => {
                          this.setState({ pay_total: event.target.value });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_edit_pay();
                        }}
                      >
                        {global[this.language]["save_edit"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* เพิ่มประเภทรายจ่าย */}
              <Modal
                show={this.state.modal_add_type_pay}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>เพิ่มประเภทรายจ่าย</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>ชื่อของประเภท:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.type_pay}
                        onChange={(event) => {
                          this.setState({ type_pay: event.target.value });
                        }}
                      />
                    </div>

                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_add_type_pay();
                        }}
                      >
                        {global[this.language]["add"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* แก้ไขประเภทรายจ่าย */}
              <Modal
                show={this.state.modal_edit_type_pay}
                onHide={() => {
                  this.Cancel();
                }}
              >
                <Modal.Body>
                  <h3 className="mb-3">
                    <b>แก้ไขประเภทรายจ่าย</b>
                  </h3>
                  <div className="row">
                    <div className="col-12 pb-3">
                      <label>ชื่อของประเภท:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.type_pay}
                        onChange={(event) => {
                          this.setState({ type_pay: event.target.value });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => {
                          this.Cancel();
                        }}
                      >
                        {global[this.language]["cancel"]}
                      </button>
                    </div>
                    <div className="col-6 pb-3">
                      <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                          this.Submit_edit_type_pay();
                        }}
                      >
                        {global[this.language]["save_edit"]}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
