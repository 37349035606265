import "../../bootstrap.css";
import "./style.css";
import React, { Component } from "react";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { Link } from "react-router";
import ninja from "./ninja2.png";
import ninja2 from "./ninja3.png";

import axios from "axios";
const cookies = new Cookies();
export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="bg-white">
        <div style={{ background: "#E54045" }} className="py-4">
          <div className="container text-center">
            <h1 style={{ fontSize: 39, fontWeight: "bold", color: "#FFF" }}>
              บริการขนส่งสินค้า
            </h1>
            <h1 style={{ fontSize: 29, fontWeight: "bold", color: "#FFF" }}>
              จากประเทศจีน-ไทย
            </h1>
            <label style={{ fontSize: 20, color: "#FFF" }}>
              บริการสั่งซื้อสินค้าจากประเทศจีนมาจำหน่ายในประเทศไทยทั้งแบบ
              ปลีกและแบบส่งโดยไม่ต้องผ่านคนกลางพร้อม จัดส่งสินค้าถึงมือคุณ
            </label>
          </div>
          <div style={{ height: 150 }}></div>
        </div>
        <div
          className="container text-center pt-5 text-center card mb-4 p-3 shadow-lg border-0"
          style={{ marginTop: -100, borderRadius: 15 }}
        >
          <h1 style={{ fontSize: 50, fontWeight: "bold", color: "#E54045" }}>
            ขนส่งสินค้าทางรถ
          </h1>
          <img
            width="837"
            height="681"
            src="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/Mask-Group-3@3x.png"
            class="attachment-full size-full mx-auto"
            alt=""
            srcset="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/Mask-Group-3@3x.png 837w, https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/Mask-Group-3@3x-300x244.png 300w, https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/Mask-Group-3@3x-768x625.png 768w"
            sizes="(max-width: 837px) 100vw, 837px"
          />
        </div>
        <div
          className="container text-center pt-5 text-center card mb-4 p-3 shadow-lg border-0"
          style={{ borderRadius: 15 }}
        >
          <h1 style={{ fontSize: 50, fontWeight: "bold", color: "#E54045" }}>
            ขนส่งสินค้าทางเรือ
          </h1>
          <img
            width="837"
            height="570"
            src="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/Mask-Group-4@3x.png"
            class="attachment-full size-full mx-auto"
            alt=""
            srcset="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/Mask-Group-4@3x.png 837w, https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/Mask-Group-4@3x-300x204.png 300w, https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/Mask-Group-4@3x-768x523.png 768w"
            sizes="(max-width: 837px) 100vw, 837px"
          />
        </div>
        <div className="p-3 text-center">
          <h1 style={{ fontSize: 39, fontWeight: "bold", color: "#E54045" }}>
            การคำนวณ
          </h1>
          <h1 style={{ fontSize: 29, fontWeight: "bold", color: "#E54045" }}>
            ค่าขนส่ง
          </h1>
          <img
            width="925"
            height="1024"
            src="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/Mask-Group-5@3x-925x1024.png"
            class="attachment-large size-large mx-auto"
            alt=""
            srcset="https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/Mask-Group-5@3x-925x1024.png 925w, https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/Mask-Group-5@3x-271x300.png 271w, https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/Mask-Group-5@3x-768x850.png 768w, https://wp-app.ninjacargo.com/wp-content/uploads/2020/11/Mask-Group-5@3x.png 1125w"
            sizes="(max-width: 925px) 100vw, 925px"
          ></img>
        </div>
      </div>
    );
  }
}
