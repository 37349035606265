import "../../bootstrap.css";
import "./style.css";
import React, { Component } from "react";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { Link } from "react-router";
import ninja from "./ninja2.png";

import axios from "axios";
const cookies = new Cookies();
export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <div style={{ background: "#E54045" }} className="py-4">
          <div className="container text-center">
            <h1 style={{ fontSize: 39, fontWeight: "bold", color: "#FFF" }}>
              ขั้นตอนการ
            </h1>
            <h1 style={{ fontSize: 29, fontWeight: "bold", color: "#FFF" }}>
              ฝากจ่าย
            </h1>
          </div>
        </div>
        <div className="container text-center pt-5 text-center">
          <img
            width="192"
            height="410"
            src={ninja}
            class="attachment-full size-full mx-auto mt-5"
            alt=""
            sizes="(max-width: 192px) 100vw, 192px"
          />
          <label className="mt-4" style={{ fontSize: 20, fontWeight: "bold" }}>
            1.เข้าเมนู ธุรกรรม
          </label>
          <label className="mt-4" style={{ fontSize: 20, fontWeight: "bold" }}>
            2.กดเข้าไปยังฝากจ่ายและทำการชำระเงินที่ต้องการฝากจ่าย
          </label>
        </div>
      </div>
    );
  }
}
