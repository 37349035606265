import "../../../bootstrap.css";
import React, { Component } from "react";
import SideMenu from "../../Content/SideMenu/SideMenu";
import Header from "../../Content/Header/Header";

import Swal from "sweetalert2";
import Pagination from "react-js-pagination";

import { Modal } from "react-bootstrap";

import "@szhsin/react-menu/dist/index.css";

import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // let count = 0
    // let all = 0
    // for (let data of global.transaction) {
    //   let total = 0
    //   for(let found of data.forwarders){
    //       all+=1
    //       global.orders.find(element => {
    //         if(element.id === found){
    //           count+=1
    //           total =total+ element.total_cn + element.total_th
    //         }
    //       }
    //       )
    //   }
    //   if(total*100 !== data.amount ){
    //     global.data.push(data)
    //   }
     
    // }
    // console.log(global.data);
    // console.log("all:",all);
    // console.log("count:",count);
  }
  render() {
    return (
      <div className="w-100 h-auto minh-100">
        
      </div>
    );
  }
}
