import "../../../bootstrap.css";
import React, { Component } from "react";
import SideMenu from "../../Content/SideMenu/SideMenu";
import SideMenuUser from "../../Content/SideMenuUser/SideMenuUser";
import Header from "../../Content/Header/Header";

import Swal from "sweetalert2";
import "@szhsin/react-menu/dist/index.css";

import Cookies from "universal-cookie";
const cookies = new Cookies();
export default class LiveClassScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student_id: "",
      student_name: "",
      student_nickname: "",
      student_phone: "",
      student_gender: "",
      student_school: "",
      student_facebook: "",
      student_line: "",
      student_course_type: "",
      student_address: "",
      student_province: "",
      student_district: "",
      student_subdistrict: "",
      student_zipcode: "",
      student_username: "",
      parents_name: "",
      parents_phone: "",

      teacher_id: "",
      teacher_name: "",
      teacher_nickname: "",
      teacher_phone: "",
      teacher_gender: "",
      teacher_idcard: "",
      teacher_type: "",
      teacher_color: "",
      teacher_tax: "",
      teacher_salary: "",
      teacher_facebook: "",
      teacher_line: "",
      teacher_address: "",
      teacher_province: "",
      teacher_district: "",
      teacher_subdistrict: "",
      teacher_zipcode: "",
      teacher_zoom_api_key: "",
      teacher_zoom_api_secret: "",

      admin_id: "",
      admin_name: "",
      admin_role: "",
      admin_email: "",
      admin_phone: "",
      admin_username: "",
      role: [],
    };
  }
  language = cookies.get("language") ? cookies.get("language") : "TH";
  componentDidMount() {
    this.GetProvince();
    if (cookies.get("user")) {
      let data = cookies.get("user");
      console.log("user", data);
      if (data.user.status === "admin") {
        this.setState({
          admin_id: data.user._id,
          admin_name: data.user.name,
          admin_role: data.user.role.role_id,
          admin_email: data.user.email,
          admin_phone: data.user.phone,
          admin_username: data.user.username,
        });
      } else if (data.user.status === "teacher") {
        this.setState({
          teacher_id: data.user._id,
          teacher_name: data.user.name,
          teacher_nickname: data.nickname,
          teacher_phone: data.user.phone,
          teacher_gender: data.gender,
          teacher_idcard: data.id_card_number,
          teacher_address: data.address,
          teacher_province: data.province,
          teacher_district: data.district,
          teacher_subdistrict: data.sub_district,
          teacher_zipcode: data.zip_code,
          teacher_username: data.user.username,
          teacher_zoom_api_key: data.zoom_api_key,
          teacher_zoom_api_secret: data.zoom_api_secret,
          teacher_facebook: data.facebook,
          teacher_line: data.line,
        });
      } else if (data.user.status === "student") {
        this.setState({
          student_id: data.user._id,
          student_name: data.user.name,
          student_nickname: data.nickname,
          student_phone: data.user.phone,
          student_gender: data.gender,
          student_school: data.school,
          student_facebook: data.facebook,
          student_line: data.line,
          student_address: data.address,
          student_province: data.province,
          student_district: data.district,
          student_subdistrict: data.sub_district,
          student_zipcode: data.zip_code,
          student_username: data.user.username,
          parents_name: data.parents_name,
          parents_phone: data.parents_phone,
        });
      }
    } else {
      Swal.fire({
        title: global[this.language]["alert"],
        text: global[this.language]["retrieve_data_fail"],
        icon: "warning",
        showCancelButton: false,
        allowOutsideClick: false,
        confirmButtonText: global[this.language]["ok"],
      }).then((result) => {
        if (result.isConfirmed) {
          cookies.remove("token", { path: "/" });
          cookies.remove("user", { path: "/" });

          window.location.href = "/";
        }
      });
    }
  }

  Cancel = () => {
    this.setState({
      student_id: "",
      student_name: "",
      student_nickname: "",
      student_phone: "",
      student_gender: "",
      student_school: "",
      student_facebook: "",
      student_line: "",
      student_course_type: "",
      student_address: "",
      student_province: "",
      student_district: "",
      student_subdistrict: "",
      student_zipcode: "",
      student_username: "",
      student_password: "",
      parents_name: "",
      parents_phone: "",

      teacher_name: "",
      teacher_nickname: "",
      teacher_phone: "",
      teacher_gender: "",
      teacher_idcard: "",
      teacher_type: "",
      teacher_color: "",
      teacher_facebook: "",
      teacher_line: "",
      teacher_tax: "",
      teacher_salary: "",
      teacher_address: "",
      teacher_province: "",
      teacher_district: "",
      teacher_subdistrict: "",
      teacher_zipcode: "",
      teacher_zoom_api_key: "",
      teacher_zoom_api_secret: "",

      admin_id: "",
      admin_name: "",
      admin_role: "",
      admin_email: "",
      admin_phone: "",
      admin_password: "",
      role: [],
      province: [],
      district: [],
      sub_district: [],
      zipcode: [],
    });
  };
  Submit_edit_profile_teacher = () => {
    let num = /^^[0][0-9]*$/;
    if (this.state.teacher_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_first_last_name"],
        "warning"
      );
    } else if (this.state.teacher_nickname === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_nickname"],
        "warning"
      );
    } else if (this.state.teacher_phone === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_phone_number"],
        "warning"
      );
    } else if (
      this.state.teacher_phone.trim().length < 10 ||
      !num.test(this.state.teacher_phone)
    ) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_phone_true"],
        "warning"
      );
    } else if (this.state.teacher_gender === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["select_gender"],
        "warning"
      );
    } else if (this.state.teacher_idcard === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_ID_card"],
        "warning"
      );
    } else if (this.state.teacher_address === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_address"],
        "warning"
      );
    } else if (this.state.teacher_province === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_province"],
        "warning"
      );
    } else if (this.state.teacher_district === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_district"],
        "warning"
      );
    } else if (this.state.teacher_zipcode === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_zipcode"],
        "warning"
      );
    } else {
      console.log(
        JSON.stringify({
          name: this.state.teacher_name,
          nickname: this.state.teacher_nickname,
          phone: this.state.teacher_phone,
          gender: this.state.teacher_gender,
          id_card_number: this.state.teacher_idcard,
          facebook: this.state.teacher_facebook,
          line: this.state.teacher_line,
          address: this.state.teacher_address,
          sub_district: this.state.teacher_subdistrict,
          district: this.state.teacher_district,
          province: this.state.teacher_province,
          zip_code: this.state.teacher_zipcode,
          zoom_api_key: this.state.teacher_zoom_api_key,
          zoom_api_secret: this.state.teacher_zoom_api_secret,
          username: this.state.teacher_username,
          teacher_id: this.state.teacher_id,
        })
      );
      let district = !isNaN(this.state.teacher_district)
        ? this.state.district[this.state.teacher_district].DISTRICT_NAME
        : this.state.teacher_district;
      let subdistrict = !isNaN(this.state.teacher_subdistrict)
        ? this.state.sub_district[this.state.teacher_subdistrict]
            .SUB_DISTRICT_NAME
        : this.state.teacher_subdistrict;
      let province = !isNaN(this.state.teacher_province)
        ? this.state.province[this.state.teacher_province].PROVINCE_NAME
        : this.state.teacher_province;
      let zipcode =
        this.state.teacher_zipcode.length !== 5
          ? this.state.zipcode[this.state.teacher_zipcode].ZIPCODE
          : this.state.teacher_zipcode;
      fetch(global.httpURL + "/api/profile/" + this.state.teacher_id, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + cookies.get("token"),
        },
        body: JSON.stringify({
          name: this.state.teacher_name,
          nickname: this.state.teacher_nickname,
          phone: this.state.teacher_phone,
          gender: this.state.teacher_gender,
          id_card_number: this.state.teacher_idcard,
          facebook: this.state.teacher_facebook,
          line: this.state.teacher_line,
          address: this.state.teacher_address,
          sub_district: subdistrict,
          district: district,
          province: province,
          zip_code: zipcode,
          zoom_api_key: this.state.teacher_zoom_api_key,
          zoom_api_secret: this.state.teacher_zoom_api_secret,
          username: this.state.teacher_username,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          if (response.message === "Success") {
            // response.data.status = "teacher";
            cookies.set("user", response.data, { path: "/" });
            // cookies.set("token", response.data.token, { path: "/" });

            Swal.fire({
              title: global[this.language]["alert"],
              text: global[this.language]["edit_success"],
              icon: "success",
              showCancelButton: false,
              allowOutsideClick: false,
              confirmButtonText: global[this.language]["ok"],
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/editprofile";
              }
            });
          }
        })
        .catch((err) => {
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["add_not_success"],
            "warning"
          );
        });
    }
  };
  Submit_edit_profile_student = () => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
    let num = /^^[0][0-9]*$/;
    if (this.state.student_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_first_last_name"],
        "warning"
      );
    } else if (this.state.student_nickname === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_nickname"],
        "warning"
      );
    } else if (this.state.student_phone === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_phone"],
        "warning"
      );
    } else if (
      this.state.student_phone.trim().length < 10 ||
      !num.test(this.state.student_phone)
    ) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_phone_true"],
        "warning"
      );
    } else if (this.state.student_gender === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["select_gender"],
        "warning"
      );
    } else if (this.state.student_school === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_school"],
        "warning"
      );
    }
    // else if (this.state.student_facebook === "") {
    //   Swal.fire(
    //     global[this.language]["alert"],
    //     global[this.language]["enter_your_facebook"],
    //     "warning"
    //   );
    // } else if (this.state.student_line === "") {
    //   Swal.fire(
    //     global[this.language]["alert"],
    //     global[this.language]["enter_your_line"],
    //     "warning"
    //   );
    // }
    // else if (this.state.student_course_type === "") {
    //   Swal.fire(global[this.language]["alert"], "กรุณาเลือกประเภทคอร์สเรียน", "warning");
    // }
    else if (this.state.student_address === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_address"],
        "warning"
      );
    } else if (this.state.student_province === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_province"],
        "warning"
      );
    } else if (this.state.student_district === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_district"],
        "warning"
      );
    } else if (this.state.student_zipcode === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_zipcode"],
        "warning"
      );
    } else if (this.state.parents_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_parentname"],
        "warning"
      );
    } else if (this.state.parents_phone === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_parentphone"],
        "warning"
      );
    } else if (
      this.state.parents_phone.trim().length < 10 ||
      !num.test(this.state.parents_phone)
    ) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_phone_true"],
        "warning"
      );
    } else {
      let district = !isNaN(this.state.student_district)
        ? this.state.district[this.state.student_district].DISTRICT_NAME
        : this.state.student_district;
      let subdistrict = !isNaN(this.state.student_subdistrict)
        ? this.state.sub_district[this.state.student_subdistrict]
            .SUB_DISTRICT_NAME
        : this.state.student_subdistrict;
      let province = !isNaN(this.state.student_province)
        ? this.state.province[this.state.student_province].PROVINCE_NAME
        : this.state.student_province;
      let zipcode =
        this.state.student_zipcode.length !== 5
          ? this.state.zipcode[this.state.student_zipcode].ZIPCODE
          : this.state.student_zipcode;
      fetch(global.httpURL + "/api/profile/" + this.state.student_id, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + cookies.get("token"),
        },
        body: JSON.stringify({
          name: this.state.student_name,
          nickname: this.state.student_nickname,
          phone: this.state.student_phone,
          gender: this.state.student_gender,
          school: this.state.student_school,
          facebook: this.state.student_facebook,
          line: this.state.student_line,
          address: this.state.student_address,
          sub_district: subdistrict,
          district: district,
          province: province,
          zip_code: zipcode,
          parents_name: this.state.parents_name,
          parents_phone: this.state.parents_phone,
          username: this.state.student_username,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.message === "Success") {
            // response.data.status = "student";
            cookies.set("user", response.data, { path: "/" });
            // cookies.set("token", response.data.token, { path: "/" });
            Swal.fire({
              title: global[this.language]["alert"],
              text: global[this.language]["edit_success"],
              icon: "success",
              showCancelButton: false,
              allowOutsideClick: false,
              confirmButtonText: global[this.language]["ok"],
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/editprofile";
              }
            });
          }
        })
        .catch((err) => {
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["add_not_success"],
            "warning"
          );
        });
    }
  };
  Submit_edit_profile = () => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
    let num = /^^[0][0-9]*$/;
    if (this.state.admin_name === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_first_last_name"],
        "warning"
      );
    } else if (this.state.admin_email === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_email"],
        "warning"
      );
    } else if (!reg.test(this.state.admin_email)) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_email_true"],
        "warning"
      );
    } else if (this.state.admin_phone === "") {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_phone"],
        "warning"
      );
    } else if (
      this.state.admin_phone.trim().length < 10 ||
      !num.test(this.state.admin_phone)
    ) {
      Swal.fire(
        global[this.language]["alert"],
        global[this.language]["enter_your_phone_true"],
        "warning"
      );
    } else {
      console.log(
        JSON.stringify({
          name: this.state.admin_name,
          phone: this.state.admin_phone,
          email: this.state.admin_email,
          username: this.state.admin_username,
          role_id: this.state.admin_role,
        })
      );
      fetch(global.httpURL + "/api/profile/" + this.state.admin_id, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
          Authorization: "Bearer " + cookies.get("token"),
        },
        body: JSON.stringify({
          name: this.state.admin_name,
          phone: this.state.admin_phone,
          email: this.state.admin_email,
          username: this.state.admin_username,
          role_id: this.state.admin_role,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          if (response.message === "Email already exists") {
            Swal.fire(
              global[this.language]["alert"],
              global[this.language]["haved_email"],
              "warning"
            );
          } else {
            if (response.message === "Success") {
              cookies.set("user", response.data, { path: "/" });
              // cookies.set("token", response.data.token, { path: "/" });
              Swal.fire({
                title: global[this.language]["alert"],
                text: global[this.language]["edit_success"],
                icon: "success",
                showCancelButton: false,
                allowOutsideClick: false,
                confirmButtonText: global[this.language]["ok"],
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.href = "/editprofile";
                }
              });
              // window.location.href = "/editprofile";
            }
          }
        })
        .catch((err) => {
          Swal.fire(
            global[this.language]["alert"],
            global[this.language]["edit_not_success_2"],
            "warning"
          );
        });
    }
  };
  GetProvince = () => {
    fetch(global.httpURL + "/api/address/provinces", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({
          province: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  GetDistrict = (index) => {
    var id = this.state.province[index].PROVINCE_ID;

    fetch(global.httpURL + "/api/address/districts", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ provinces_id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({
          district: response.data,
          teacher_district: "",
          teacher_subdistrict: "",
          teacher_zipcode: "",
          student_district: "",
          student_subdistrict: "",
          student_zipcode: "",
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  GetSubDistrict = (index) => {
    var id = this.state.district[index].DISTRICT_ID;
    console.log(JSON.stringify({ districts_id: id }));
    fetch(global.httpURL + "/api/address/subDistricts", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ districts_id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({
          sub_district: response.data,
          teacher_subdistrict: "",
          teacher_zipcode: "",
          student_subdistrict: "",
          student_zipcode: "",
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  GetZipcode = (index) => {
    var id = this.state.sub_district[index].SUB_DISTRICT_ID;

    fetch(global.httpURL + "/api/address/zipcodes", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + cookies.get("token"),
      },
      body: JSON.stringify({ subDistricts_id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({
          zipcode: response.data,
          teacher_zipcode: "",
          student_zipcode: "",
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  render() {
    return (
      <div className="w-100 h-auto minh-100">
        <div className="d-flex h-auto minh-100">
          <div className="h-auto minh-100">
            {cookies.get("user") && (
              <>
                {cookies.get("user").user.status === "teacher" ||
                cookies.get("user").user.status === "student" ? (
                  <>
                    <SideMenuUser page="" />
                  </>
                ) : (
                  <SideMenu page="" />
                )}
              </>
            )}
          </div>
          <div className="w-100-body">
            <Header />
            <div className="container-fluid pt-3">
              <div className="row">
                <div className="col-2" />
                <div className="col-12 col-xs-12 col-sm-12  col-md-12 col-xl-8 mb-3 ">
                  {/* STUDENT */}
                  {cookies.get("user") &&
                    cookies.get("user").user.status === "student" && (
                      <div className="card border-0 p-4 mb-3">
                        <div className="flex-row justify-content-between align-items-center row p-4">
                          <div>
                            <h3>
                              <b>{global[this.language]["edit_user"]}</b>
                            </h3>
                          </div>
                        </div>
                        <div>
                          <div className="row">
                            <div className="col-6 pb-3">
                              <label>
                                {global[this.language]["fullname"]}:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={global[this.language]["text_hint"]}
                                value={this.state.student_name}
                                onChange={(event) => {
                                  this.setState({
                                    student_name: event.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-6 pb-3">
                              <label>
                                {global[this.language]["nickname"]}:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={global[this.language]["text_hint"]}
                                value={this.state.student_nickname}
                                onChange={(event) => {
                                  this.setState({
                                    student_nickname: event.target.value,
                                  });
                                }}
                              />
                            </div>

                            <div className="col-6 pb-3">
                              <label>{global[this.language]["phone"]}:</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={global[this.language]["text_hint"]}
                                maxLength={10}
                                value={this.state.student_phone}
                                onChange={(event) => {
                                  this.setState({
                                    student_phone: event.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-6 pb-3">
                              <label>{global[this.language]["gender"]}:</label>
                              <select
                                className="form-control"
                                value={this.state.student_gender}
                                onChange={(event) => {
                                  this.setState({
                                    student_gender: event.target.value,
                                  });
                                }}
                              >
                                <option value="">
                                  --
                                  {global[this.language]["selected_gender"]}
                                  --
                                </option>
                                <option value="ชาย">
                                  {global[this.language]["male"]}
                                </option>
                                <option value="หญิง">
                                  {global[this.language]["female"]}
                                </option>
                                <option value="ไม่ระบุ">
                                  {global[this.language]["other"]}
                                </option>
                              </select>
                            </div>
                            <div className="col-12 pb-3">
                              <label>{global[this.language]["school"]}:</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={global[this.language]["text_hint"]}
                                value={this.state.student_school}
                                onChange={(event) => {
                                  this.setState({
                                    student_school: event.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-6 pb-3">
                              <label>Facebook:</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={global[this.language]["text_hint"]}
                                value={this.state.student_facebook}
                                onChange={(event) => {
                                  this.setState({
                                    student_facebook: event.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-6 pb-3">
                              <label>LINE ID:</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={global[this.language]["text_hint"]}
                                value={this.state.student_line}
                                onChange={(event) => {
                                  this.setState({
                                    student_line: event.target.value,
                                  });
                                }}
                              />
                            </div>
                            {/* <div className="col-6 pb-3">
                            <label>{global[this.language]["Course_type"]}:</label>
                            <div className="toggle-bar">
                              <button
                                onClick={() => {
                                  this.setState({
                                    student_course_type: "เรียนเดี่ยว",
                                  });
                                }}
                                className={
                                  this.state.student_course_type ===
                                  "เรียนเดี่ยว"
                                    ? "toggle-left-active"
                                    : "toggle-left"
                                }
                              >
                                {global[this.language]["solo_study"]}
                              </button>
                              <button
                                onClick={() => {
                                  this.setState({
                                    student_course_type: "เรียนกลุ่ม",
                                  });
                                }}
                                className={
                                  this.state.student_course_type ===
                                  "เรียนกลุ่ม"
                                    ? "toggle-right-active"
                                    : "toggle-right"
                                }
                              >
                                {global[this.language]["group_classes"]}
                              </button>
                            </div>
                          </div> */}

                            {/* <div className="col-6 pb-3" /> */}
                            <div className="col-12 pb-3">
                              <label>{global[this.language]["address"]}:</label>
                              <input
                                id="edit_student_address"
                                type="text"
                                className="form-control"
                                placeholder={
                                  global[this.language]["address_hint"]
                                }
                                value={this.state.student_address}
                                onChange={(event) => {
                                  this.setState({
                                    student_address: event.target.value,
                                  });
                                  document.getElementById(
                                    "edit_student_address"
                                  ).className = "form-control";
                                }}
                              />
                            </div>
                            <div className="col-6 pb-3">
                              <label>
                                {global[this.language]["province"]}:
                              </label>
                              <select
                                id="edit_student_province"
                                className="form-control"
                                value={this.state.student_province}
                                onChange={(event) => {
                                  this.setState({
                                    student_province: event.target.value,
                                  });
                                  this.GetDistrict(event.target.value);
                                  document.getElementById(
                                    "edit_student_province"
                                  ).className = "form-control";
                                }}
                              >
                                {this.state.province &&
                                  this.state.province.map((item, index) => (
                                    <option value={index}>
                                      {item.PROVINCE_NAME}
                                    </option>
                                  ))}
                                {typeof this.state.student_province !==
                                  "number" && (
                                  <option value={this.state.student_province}>
                                    {this.state.student_province}
                                  </option>
                                )}
                              </select>
                            </div>
                            <div className="col-6 pb-3">
                              <label>
                                {global[this.language]["district"]}:
                              </label>
                              <select
                                id="edit_student_district"
                                className="form-control"
                                value={this.state.student_district}
                                onChange={(event) => {
                                  this.setState({
                                    student_district: event.target.value,
                                  });
                                  this.GetSubDistrict(event.target.value);
                                  document.getElementById(
                                    "edit_student_district"
                                  ).className = "form-control";
                                }}
                              >
                                {this.state.student_district === "" && (
                                  <option value={""}>
                                    --{global[this.language]["select_district"]}
                                    --
                                  </option>
                                )}
                                {this.state.district &&
                                  this.state.district.map((item, index) => (
                                    <option value={index}>
                                      {item.DISTRICT_NAME}
                                    </option>
                                  ))}
                                {this.state.student_district !== "" &&
                                  typeof this.state.student_district !==
                                    "number" && (
                                    <option value={this.state.student_district}>
                                      {this.state.student_district}
                                    </option>
                                  )}
                              </select>
                            </div>
                            <div className="col-6 pb-3">
                              <label>
                                {global[this.language]["subdistrict"]}:
                              </label>
                              <select
                                id="edit_student_subdistrict"
                                className="form-control"
                                value={this.state.student_subdistrict}
                                onChange={(event) => {
                                  this.setState({
                                    student_subdistrict: event.target.value,
                                  });
                                  this.GetZipcode(event.target.value);
                                  document.getElementById(
                                    "edit_student_subdistrict"
                                  ).className = "form-control";
                                }}
                              >
                                {this.state.student_subdistrict === "" && (
                                  <option value={""}>
                                    --
                                    {
                                      global[this.language][
                                        "select_subdistrict"
                                      ]
                                    }
                                    --
                                  </option>
                                )}
                                {this.state.sub_district &&
                                  this.state.sub_district.map((item, index) => (
                                    <option value={index}>
                                      {item.SUB_DISTRICT_NAME}
                                    </option>
                                  ))}{" "}
                                {this.state.student_subdistrict !== "" &&
                                  typeof this.state.student_subdistrict !==
                                    "number" && (
                                    <option
                                      value={this.state.student_subdistrict}
                                    >
                                      {this.state.student_subdistrict}
                                    </option>
                                  )}
                              </select>
                            </div>
                            <div className="col-6 pb-3">
                              <label>{global[this.language]["zipcode"]}:</label>
                              <select
                                id="edit_student_zipcode"
                                className="form-control"
                                value={this.state.student_zipcode}
                                onChange={(event) => {
                                  this.setState({
                                    student_zipcode: event.target.value,
                                  });
                                  document.getElementById(
                                    "edit_student_zipcode"
                                  ).className = "form-control";
                                }}
                              >
                                {this.state.student_zipcode === "" && (
                                  <option value={""}>
                                    --{global[this.language]["select_zipcode"]}
                                    --
                                  </option>
                                )}
                                {this.state.zipcode &&
                                  this.state.zipcode.map((item, index) => (
                                    <option value={index}>
                                      {item.ZIPCODE}
                                    </option>
                                  ))}
                                {this.state.student_zipcode !== "" &&
                                  typeof this.state.student_zipcode !==
                                    "number" && (
                                    <option value={this.state.student_zipcode}>
                                      {this.state.student_zipcode}
                                    </option>
                                  )}
                              </select>
                            </div>
                            <div className="col-6 pb-3">
                              <label>
                                {global[this.language]["fullname_parent"]}:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={global[this.language]["text_hint"]}
                                value={this.state.parents_name}
                                onChange={(event) => {
                                  this.setState({
                                    parents_name: event.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-6 pb-3">
                              <label>
                                {global[this.language]["Phone_Parents"]}:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={global[this.language]["text_hint"]}
                                maxLength={10}
                                value={this.state.parents_phone}
                                onChange={(event) => {
                                  this.setState({
                                    parents_phone: event.target.value,
                                  });
                                }}
                              />
                            </div>
                            {/* <div className="col-6 pb-3">
                              <button
                                className="btn btn-secondary w-100"
                                onClick={() => {
                                  this.Cancel();
                                }}
                              >
                                {global[this.language]["cancel"]}
                              </button>
                            </div> */}
                            <div className="col-12 pb-3">
                              <button
                                className="btn btn-primary w-100"
                                onClick={() => {
                                  this.Submit_edit_profile_student();
                                }}
                              >
                                {global[this.language]["save"]}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {/* TEACHER */}
                  {cookies.get("user") &&
                    cookies.get("user").user.status === "teacher" && (
                      <div className="card border-0 p-4 mb-3 ">
                        <div className="flex-row justify-content-between align-items-center row p-4">
                          <div>
                            <h3>
                              <b> {global[this.language]["edit_user"]}</b>
                            </h3>
                          </div>
                        </div>
                        <div>
                          <div className="row">
                            <div className="col-6 pb-3">
                              <label>
                                {global[this.language]["fullname"]}:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={global[this.language]["text_hint"]}
                                value={this.state.teacher_name}
                                onChange={(event) => {
                                  this.setState({
                                    teacher_name: event.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-6 pb-3">
                              <label>
                                {global[this.language]["nickname"]}:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={global[this.language]["text_hint"]}
                                value={this.state.teacher_nickname}
                                onChange={(event) => {
                                  this.setState({
                                    teacher_nickname: event.target.value,
                                  });
                                }}
                              />
                            </div>

                            <div className="col-6 pb-3">
                              <label>{global[this.language]["phone"]}:</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={global[this.language]["text_hint"]}
                                maxLength={10}
                                value={this.state.teacher_phone}
                                onChange={(event) => {
                                  this.setState({
                                    teacher_phone: event.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-6 pb-3">
                              <label>{global[this.language]["gender"]}:</label>
                              <select
                                className="form-control"
                                value={this.state.teacher_gender}
                                onChange={(event) => {
                                  this.setState({
                                    teacher_gender: event.target.value,
                                  });
                                }}
                              >
                                <option value="">
                                  --
                                  {global[this.language]["selected_gender"]}
                                  --
                                </option>
                                <option value="ชาย">
                                  {global[this.language]["male"]}
                                </option>
                                <option value="หญิง">
                                  {global[this.language]["female"]}
                                </option>
                                <option value="ไม่ระบุ">
                                  {global[this.language]["other"]}
                                </option>
                              </select>
                            </div>
                            <div className="col-12 pb-3">
                              <label>{global[this.language]["ID_card"]}:</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={global[this.language]["text_hint"]}
                                maxLength={13}
                                value={this.state.teacher_idcard}
                                onChange={(event) => {
                                  this.setState({
                                    teacher_idcard: event.target.value,
                                  });
                                }}
                              />
                            </div>

                            {/* <div className="col-6 pb-3">
                      <label>ประเภทอัตราจ้าง:</label>
                      <div className="toggle-bar">
                        <button
                          onClick={() => {
                            this.setState({
                              teacher_type: "รายชั่วโมง",
                            });
                          }}
                          className={
                            this.state.teacher_type === "รายชั่วโมง"
                              ? "toggle-left-active"
                              : "toggle-left"
                          }
                        >
                          รายชั่วโมง
                        </button>
                        <button
                          onClick={() => {
                            this.setState({
                              teacher_type: "รายเดือน",
                            });
                          }}
                          className={
                            this.state.teacher_type === "รายเดือน"
                              ? "toggle-right-active"
                              : "toggle-right"
                          }
                        >
                          รายเดือน
                        </button>
                      </div>
                    </div>

                    <div className="col-6 pb-3">
                      <label>สีประจำตัว:</label>
                      <input
                        type="color"
                        className="form-control"
                        placeholder=""
                        value={this.state.teacher_color}
                        onChange={(event) => {
                          this.setState({
                            teacher_color: event.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="col-6 pb-3">
                      <label>เลขผู้เสียภาษี:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.teacher_tax}
                        onChange={(event) => {
                          this.setState({
                            teacher_tax: event.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-6 pb-3">
                      <label>อัตราค่าจ้าง:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={global[this.language]["text_hint"]}
                        value={this.state.teacher_salary}
                        onChange={(event) => {
                          this.setState({
                            teacher_salary: event.target.value,
                          });
                        }}
                      />
                    </div> */}
                            {/* facebook */}
                            <div className="col-6 pb-3">
                              <label>Facebook:</label>

                              <input
                                type="text"
                                className="form-control"
                                placeholder={global[this.language]["text_hint"]}
                                value={this.state.teacher_facebook}
                                onChange={(event) => {
                                  this.setState({
                                    teacher_facebook: event.target.value,
                                  });
                                }}
                              />
                            </div>
                            {/* Line */}
                            <div className="col-6 pb-3">
                              <label>Line:</label>

                              <input
                                type="text"
                                className="form-control"
                                placeholder={global[this.language]["text_hint"]}
                                value={this.state.teacher_line}
                                onChange={(event) => {
                                  this.setState({
                                    teacher_line: event.target.value,
                                  });
                                }}
                              />
                            </div>
                            {/* ที่อยู่ปัจจุบัน */}
                            <div className="col-12 pb-3">
                              <label>{global[this.language]["address"]}:</label>

                              <input
                                type="text"
                                className="form-control"
                                placeholder={global[this.language]["text_hint"]}
                                value={this.state.teacher_address}
                                onChange={(event) => {
                                  this.setState({
                                    teacher_address: event.target.value,
                                  });
                                }}
                              />
                            </div>
                            {/* จังหวัด */}
                            <div className="col-6 pb-3">
                              <label>
                                {global[this.language]["province"]}:
                              </label>

                              <select
                                className="form-control"
                                value={this.state.teacher_province}
                                onChange={(event) => {
                                  this.setState({
                                    teacher_province: event.target.value,
                                  });
                                  console.log(event.target.value);
                                  this.GetDistrict(event.target.value);
                                }}
                              >
                                {this.state.province &&
                                  this.state.province.map((item, index) => (
                                    <option value={index}>
                                      {item.PROVINCE_NAME}
                                    </option>
                                  ))}
                                {typeof this.state.teacher_province !==
                                  "number" && (
                                  <option value={this.state.teacher_province}>
                                    {this.state.teacher_province}
                                  </option>
                                )}
                              </select>
                            </div>
                            <div className="col-6 pb-3">
                              <label>
                                {global[this.language]["district"]}:
                              </label>

                              <select
                                className="form-control"
                                value={this.state.teacher_district}
                                onChange={(event) => {
                                  this.setState({
                                    teacher_district: event.target.value,
                                  });
                                  this.GetSubDistrict(event.target.value);
                                }}
                              >
                                {" "}
                                {this.state.teacher_district === "" && (
                                  <option value={""}>
                                    --{global[this.language]["select_district"]}
                                    --
                                  </option>
                                )}
                                {this.state.district &&
                                  this.state.district.map((item, index) => (
                                    <option value={index}>
                                      {item.DISTRICT_NAME}
                                    </option>
                                  ))}
                                {this.state.teacher_district !== "" &&
                                  typeof this.state.teacher_district !==
                                    "number" && (
                                    <option value={this.state.teacher_district}>
                                      {this.state.teacher_district}
                                    </option>
                                  )}
                              </select>
                            </div>
                            <div className="col-6 pb-3">
                              <label>
                                {global[this.language]["subdistrict"]}:
                              </label>

                              <select
                                className="form-control"
                                value={this.state.teacher_subdistrict}
                                onChange={(event) => {
                                  this.setState({
                                    teacher_subdistrict: event.target.value,
                                  });
                                  this.GetZipcode(event.target.value);
                                }}
                              >
                                {this.state.teacher_subdistrict === "" && (
                                  <option value={""}>
                                    --
                                    {
                                      global[this.language][
                                        "select_subdistrict"
                                      ]
                                    }
                                    --
                                  </option>
                                )}
                                {this.state.sub_district &&
                                  this.state.sub_district.map((item, index) => (
                                    <option value={index}>
                                      {item.SUB_DISTRICT_NAME}
                                    </option>
                                  ))}{" "}
                                {this.state.teacher_subdistrict !== "" &&
                                  typeof this.state.teacher_subdistrict !==
                                    "number" && (
                                    <option
                                      value={this.state.teacher_subdistrict}
                                    >
                                      {this.state.teacher_subdistrict}
                                    </option>
                                  )}
                              </select>
                            </div>
                            <div className="col-6 pb-3">
                              <label>{global[this.language]["zipcode"]}:</label>

                              <select
                                className="form-control"
                                value={this.state.teacher_zipcode}
                                onChange={(event) => {
                                  this.setState({
                                    teacher_zipcode: event.target.value,
                                  });
                                }}
                              >
                                {this.state.teacher_zipcode === "" && (
                                  <option value={""}>
                                    --{global[this.language]["select_zipcode"]}
                                    --
                                  </option>
                                )}
                                {this.state.zipcode &&
                                  this.state.zipcode.map((item, index) => (
                                    <option value={index}>
                                      {item.ZIPCODE}
                                    </option>
                                  ))}
                                {this.state.teacher_zipcode !== "" &&
                                  typeof this.state.teacher_zipcode !==
                                    "number" && (
                                    <option value={this.state.teacher_zipcode}>
                                      {this.state.teacher_zipcode}
                                    </option>
                                  )}
                              </select>
                            </div>
                            {/* <div className="col-6 pb-3">
                              <button
                                className="btn btn-secondary w-100"
                                onClick={() => {
                                  this.Cancel();
                                }}
                              >
                                {global[this.language]["cancel"]}
                              </button>
                            </div> */}
                            <div className="col-12 pb-3">
                              <button
                                className="btn btn-primary w-100"
                                onClick={() => {
                                  this.Submit_edit_profile_teacher();
                                }}
                              >
                                {global[this.language]["save"]}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {/* ADMIN */}
                  {cookies.get("user") &&
                    cookies.get("user").user.status === "admin" && (
                      <div className="card border-0 p-4 mb-3">
                        <div className="flex-row justify-content-between align-items-center row p-4">
                          <div>
                            <h3>
                              <b> {global[this.language]["edit_user"]}</b>
                            </h3>
                          </div>
                        </div>
                        <div>
                          <div className="row">
                            <div className="col-12 pb-3">
                              <label>
                                {global[this.language]["fullname"]}:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={global[this.language]["text_hint"]}
                                value={this.state.admin_name}
                                onChange={(event) => {
                                  this.setState({
                                    admin_name: event.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-12 pb-3">
                              <label>{global[this.language]["email"]}:</label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder={global[this.language]["text_hint"]}
                                value={this.state.admin_email}
                                onChange={(event) => {
                                  this.setState({
                                    admin_email: event.target.value,
                                  });
                                }}
                              />
                            </div>

                            <div className="col-12 pb-3">
                              <label>{global[this.language]["phone"]}:</label>
                              <input
                                type="phone"
                                className="form-control"
                                placeholder={global[this.language]["text_hint"]}
                                maxLength={10}
                                value={this.state.admin_phone}
                                onChange={(event) => {
                                  this.setState({
                                    admin_phone: event.target.value,
                                  });
                                }}
                              />
                            </div>

                            {/* <div className="col-6 pb-3">
                              <button
                                className="btn btn-secondary w-100"
                                onClick={() => {
                                  this.Cancel();
                                }}
                              >
                                {global[this.language]["cancel"]}
                              </button>
                            </div> */}
                            <div className="col-12 pb-3">
                              <button
                                className="btn btn-primary w-100"
                                onClick={() => {
                                  this.Submit_edit_profile();
                                }}
                              >
                                {global[this.language]["save"]}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>

                <div className="col-2" />
              </div>
              {/* MODAL */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
